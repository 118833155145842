import React from 'react';
import {Route, Routes, useLocation, useParams} from 'react-router-dom';
import {OrganizationPageRouter} from "./main";
import {SeasonHomePage} from "./season";
import {useOrganizationContextInfoQuery} from "./graphql";
import {ApolloProviderOrganization} from "../../ApolloProviderOrganization";
import {Box} from '@chakra-ui/react';

export type OrganizationViewingContextType = {
  organizationId: string
  organizationName: string
  organizationAvatar: string
  viewerId: string
  displayName: string
  viewerRole: string
}

export const OrganizationViewingContext = React.createContext<OrganizationViewingContextType>({
  organizationId: '',
  organizationName: '',
  organizationAvatar: '',
  viewerId: '',
  displayName: '',
  viewerRole: ''
})

export function OrganizationRoot() {

  const { organizationId } = useParams()

  return (
    <Box h={'100vh'} overflow={'auto'}>
      <ApolloProviderOrganization organizationId={organizationId}>
        <RealRoot />
      </ApolloProviderOrganization>
    </Box>
  )
}

function RealRoot() {

  const { data } = useOrganizationContextInfoQuery()

  if (!data) return <div />

  const contextValue = {
    organizationId: data.organization.id,
    organizationName: data.organization.name,
    organizationAvatar: '',
    viewerId: data.viewer.id,
    displayName: data.viewer.displayName,
    viewerRole: data.viewer.role
  }

  return (
    <OrganizationViewingContext.Provider value={contextValue}>
      <Routes>
        <Route
          path={"/*"}
          element={<OrganizationPageRouter />}
        />

        <Route
          path={'s/:seasonId/*'}
          element={<SeasonHomePage />}
        />
      </Routes>
    </OrganizationViewingContext.Provider>
  )
}
