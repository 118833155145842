import React from "react";
import {RegisterOptions} from "react-hook-form";
import {useSmartFormContext} from "../context";
import {FormControl, FormHelperText, FormLabel, Input} from "@chakra-ui/react";
import * as _ from "lodash";

export interface SmartFormTextInputProps {
  name: string
  label: string
  placeholder?: string
  helperText?: string
  isRequired?: boolean
  registerOptions?: Omit<RegisterOptions, 'required'>
  autocomplete?: string
  isDisabled?: boolean
  type?: 'text' | 'email'
}

export function SmartFormTextInput(props: React.PropsWithChildren<SmartFormTextInputProps>) {

  const {
    name,
    label,
    placeholder,
    helperText,
    isRequired = false,
    registerOptions = {},
    autocomplete = 'off',
    isDisabled = false,
    type = 'text'
  } = props

  const { useFormReturn: { register, formState: { errors } } } = useSmartFormContext();

  return (
    <FormControl isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <Input
        type={type}
        isInvalid={errors && errors[name] && errors[name].message}
        {...register(name,{ required: isRequired, ...registerOptions })}
        placeholder={placeholder}
        autoComplete={autocomplete}
        isDisabled={isDisabled}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {_.get(errors, `${name}.message`) && <FormHelperText color={'red'}>{errors[name].message}</FormHelperText>}
    </FormControl>
  )
}
