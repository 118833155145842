import React from 'react';
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {FormComponent} from "./index";
import {Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Link} from '@chakra-ui/react';
import {useKeycloak} from "@react-keycloak/web";

export function PublicRegistrationForm() {

  const { keycloak } = useKeycloak()
  const { organizationId, seasonId } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [formContent, setFormContent] = React.useState<any>(null);

  React.useEffect(() => {
    if (keycloak.authenticated) {
      navigate(`/u${pathname}`)
    }
  })

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_PUBLIC_ENDPOINT}/season/${seasonId}/registration`)
      .then(res => res.json())
      .then(res => {
        setFormContent(res)
      })
  }, [])

  if (!formContent) {
    return <>loading...</>
  }

  return (
    <Box pos={'relative'} filter={'blur(4px)'}>
      <Box pos={'fixed'} opacity={0.2} top={0} left={0} right={0} bottom={0} bgColor={'white'} border={'1px solid red'} zIndex={100} />

      <Modal isOpen={!keycloak.authenticated} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Powered by LeagueBrain</ModalHeader>

          <ModalBody>
            The GHVBL uses LeagueBrain to safely and securely manage your season.
            Please <Link textDecorationThickness={'1px'} textDecoration={'underline dotted'} color={'blue.500'} href={keycloak.createLoginUrl()}>sign in </Link> or{' '}
            <Link textDecorationThickness={'1px'} textDecoration={'underline dotted'} color={'blue.500'} href={keycloak.createRegisterUrl()}>create a new account </Link>
            to get started. It only takes a few seconds!
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme='blue'
              variant={'ghost'}
              mr={3}
              onClick={() => {
                window.location.assign(keycloak.createRegisterUrl())
              }}
            >
              Create Account
            </Button>

            <Button
              colorScheme='blue'
              onClick={() => {
                window.location.assign(keycloak.createLoginUrl())
              }}
            >
              Sign In
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}
