import React from 'react';
import {ApolloProviderPublic} from "../../ApolloProviderPublic";
import {Route, Routes, useLocation, useMatch} from "react-router-dom";
import {SeasonTeamHomePage} from "./games/SeasonTeamHomePage";
import {StandingsPageRouter} from "./games/standings";
import {TodaysGamesPage} from "./games/today";

export function PublicPagesRouter() {

  return (
    <ApolloProviderPublic>
      <Routes>
        <Route
          path={`seasons/:seasonId/standings/:divisionId`}
          element={<StandingsPageRouter />}
        />

        <Route
          path={`seasons/:seasonId/standings`}
          element={<StandingsPageRouter />}
        />

        <Route
          path={`seasons/:seasonId/teams/:seasonTeamId`}
          element={<SeasonTeamHomePage />}
        />

        <Route
          path={`seasons/:seasonId/teams`}
          element={<SeasonTeamHomePage />}
        />

        <Route
          path={`seasons/:seasonId/today`}
          element={<TodaysGamesPage />}
        />
      </Routes>
    </ApolloProviderPublic>
  )
}
