import React, {ReactNode, useEffect, useRef} from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonProps,
  Center,
  Flex,
  IconButton,
  Image,
  InputGroup,
  LinkBox,
  LinkOverlay,
  Text,
  Tooltip,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToken,
  VStack
} from "@chakra-ui/react";
import Uppy, {UploadResult, UppyFile} from "@uppy/core";
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'
import {Icon} from "../../../components/FontAwesomeIcon";
import {faCloudUpload} from "@fortawesome/pro-regular-svg-icons";
import {DashboardModal, useUppy} from "@uppy/react";
import {withMultipartUpload} from "../../../util/uppy";
import ImageEditor from '@uppy/image-editor'
import {faImage, faPencil, faTimes, faUser} from "@fortawesome/pro-solid-svg-icons";
import _ from 'lodash';
import {faFileAlt, faFileArchive, faFileCsv, faFilePdf, faFileVideo} from "@fortawesome/pro-duotone-svg-icons";
import {useKeycloak} from "@react-keycloak/web";
import AwsS3Multipart, {AwsS3Part} from "@uppy/aws-s3-multipart";
import GoogleDrive from "@uppy/google-drive";
import Dropbox from "@uppy/dropbox";
import {useGetUserInfoQuery} from "../graphql";

type SingleFileUploadInputProps = {
  accept?: string
  children?: ReactNode
  buttonProps?: ButtonProps
  onComplete?(result: any): void

  value?: string | null
  onChange?(value: string | null): void
}

const SingleFileInput = React.forwardRef((props: SingleFileUploadInputProps, ref) => {
  const { accept, buttonProps, onComplete, value, onChange, children } = props
  const inputRef = useRef<HTMLInputElement>(null)

  const [fileInputValue, setFileInputValue] = React.useState<string | null>(value || null);

  const [uploadProgress, setUploadProgress] = React.useState(0);
  const { keycloak } = useKeycloak()

  useEffect(() => {
    if (onChange) {
      onChange(fileInputValue)
    }
  }, [onChange, fileInputValue])

  const handleClick = () => inputRef.current?.click()

  const uppy = useUppy(() => {
    const instance = Uppy({
      meta: { type: 'avatar', isUploading: false },
      restrictions: { maxNumberOfFiles: 1 },
      autoProceed: true
    }).on('complete', (result: UploadResult<any, any>) => {
      if (onComplete) {
        onComplete(result)
      }

      console.log(result.successful[0])

      uppy.setMeta({ isUploading: false })
      uppy.reset()
      setFileInputValue(result.successful[0]?.response?.body?.serverId)
    }).on('file-removed', () => {
      uppy.reset()
      setFileInputValue(null)
    }).on('file-added', () => {
      uppy.setMeta({ isUploading: true })
    }).on('progress', (progress) => {
      setUploadProgress(progress)
    })

    return withMultipartUpload(instance, keycloak)
  })

  useEffect(() => {
    if (!inputRef?.current) return

    const uploadElement = inputRef.current

    const onChangeHandler = (event: any) => {
      const files: File[] = Array.from(event.target.files)

      files.forEach((file: File) => {
        try {
          uppy.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file
          })
        } catch (err) {
          if (err.isRestriction) {
            // handle restrictions
            console.log('Restriction error:', err)
          } else {
            // handle other errors
            console.error(err)
          }
        }
      })
    }

    uploadElement.addEventListener('change', onChangeHandler)

    return () => uploadElement.removeEventListener('change', onChangeHandler)
  }, [uppy, inputRef.current])

  return (
    <InputGroup onClick={handleClick}>
      <input
        type={'file'}
        multiple={false}
        hidden
        accept={accept}
        ref={(e) => {
          inputRef.current = e
          if (ref) {
            ref.current = e
          }
        }}
      />
      <Button
        isLoading={uppy.getState().meta.isUploading || false}
        loadingText={'Uploading...'}
        leftIcon={<Icon icon={faCloudUpload} />}
        {...buttonProps}
      >
        {children}
      </Button>
    </InputGroup>
  )
})

export interface DashboardFileUploadProps {
  value?: BasicFileProperties[]
  onChange?(value: BasicFileProperties[]): void

  maxFiles?: number
}

export interface BasicFileProperties {
  id: string
  name?: string | null
  url?: string | null
}

export type UploadedFile = BasicFileProperties & {
  mimeType?: string
}

export const DashboardFileUpload = React.forwardRef((props: DashboardFileUploadProps, ref: any) => {

  const {
    maxFiles,
    value = [],
    onChange = () => {}
  } = props

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { keycloak } = useKeycloak()

  const [uploadedFiles, setUploadedFiles] = React.useState<BasicFileProperties[]>(value ?? []);

  useEffect(() => {
    setUploadedFiles(value)
  }, [JSON.stringify(value)])

  const uppy = useUppy(() => {
    const instance = Uppy({
      restrictions: {
        maxNumberOfFiles: maxFiles,
        // allowedFileTypes: ['image/*']
      }
    }).on('complete', (result: UploadResult<any, any>) => {

    }).on('file-removed', () => {

    }).on('file-added', () => {

    }).on('progress', (progress) => {

    }).use(ImageEditor, {
      id: 'ImageEditor',
      quality: 1,
      cropperOptions: {
        dragMode: 'crop'
      }
    })

    return withMultipartUpload(instance, keycloak)
  })

  useEffect(() => {
    if (maxFiles && maxFiles - uploadedFiles.length <= 0) return
    uppy.setOptions({
      restrictions: {
        maxNumberOfFiles: maxFiles ? maxFiles - uploadedFiles.length : undefined
      }
    })
  }, [JSON.stringify(uploadedFiles)])

  useEffect(() => {
    onChange(uploadedFiles)
  }, [JSON.stringify(uploadedFiles)])

  useEffect(() => {
    const handler = (file: UppyFile, response: any) => {
      console.log(file)
      console.log(response)

      if (file.source === 'GoogleDrive') {
        fetch(`${process.env.REACT_APP_UPPY_MULTIPART_HOST}/aws/generateFileId`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
          },
          body: JSON.stringify({
            key: response.uploadURL.replace('https://cdnstack-bucket83908e77-1ur7l84x32mjf.s3.us-east-1.amazonaws.com/', ''),
            uploadId: file.id
          })
        })
          .then(res => res.json())
          .then(data => {
            setUploadedFiles(_.uniqBy([
              ...uploadedFiles,
              {
                id: data.serverId,
                name: file.name,
                url: data.location,
                mimeType: file.meta.type
              }
            ], 'id'))
          })

        return
      }

      setUploadedFiles(_.uniqBy([
        ...uploadedFiles,
        {
          id: response.body.serverId,
          name: file.name,
          url: response.uploadURL,
          mimeType: file.meta.type
        }
      ], 'id'))
    };

    uppy.on('upload-success', handler);

    return () => {
      uppy.off('upload-success', handler);
    };
  }, [JSON.stringify(uploadedFiles)]);

  const fileUploadEntryRowBgColor = useColorModeValue('gray.50', 'gray.700')
  const fileUploadEntryRowColor = useColorModeValue('gray.600', 'gray.50')

  return (
    <Box w={'full'} ref={ref}>
      <Flex justify={'space-between'} align={'center'}>
        <Button
          onClick={onOpen}
          isDisabled={!!maxFiles && (maxFiles - uploadedFiles.length <= 0)}
          rightIcon={<Icon icon={faCloudUpload} />}
        >
          Upload Files
        </Button>

        {maxFiles &&
          <Text>{uploadedFiles.length}/{maxFiles} files</Text>
        }
      </Flex>

      <DashboardModal
        uppy={uppy}
        plugins={['ImageEditor', 'GoogleDrive', 'Dropbox']}
        width={'100%'}
        height={'500px'}
        theme={useColorMode().colorMode}
        proudlyDisplayPoweredByUppy={false}
        closeModalOnClickOutside
        open={isOpen}
        onRequestClose={onClose}
      />

      <VStack spacing={2} w={'full'}>
        {uploadedFiles.map((file, idx) => (
          <Flex
            key={file.url}
            w={'full'}
            justify={'space-between'}
            align={'center'}
            p={2}
            mt={4}
            bgColor={fileUploadEntryRowBgColor}
            borderRadius={'md'}
            position={'relative'}
            top={0}
            _hover={{
              top: '-2px',
              boxShadow: 'sm',
            }}
            transition={'all 150ms'}
            color={fileUploadEntryRowColor}
            fontWeight={'semibold'}
          >
            <LinkBox flexGrow={1}>
              <LinkOverlay href={file.url} target={'_blank'}>
                <Flex w={'100%'} align={'center'} minH={'40px'} pos={'relative'}>
                  <Image
                    boxSize={'40px'}
                    borderRadius={'md'}
                    src={file.url}
                    fallback={
                      <Center flexBasis={'30px'} flexShrink={0} px={3}>
                        <IconForFileType fileType={file.mimeType} />
                      </Center>
                    }
                  />

                  <Text fontSize={'sm'} ml={2}>{file.name}</Text>
                </Flex>
              </LinkOverlay>
            </LinkBox>

            <IconButton
              aria-label={'Remove file'}
              icon={<Icon icon={faTimes} />}
              ml={1}
              variant={'ghost'}
              onClick={() => {
                setUploadedFiles(_.filter(uploadedFiles, function(curr) {
                  return file.url !== curr.url
                }))
              }}
            />
          </Flex>
        ))}
      </VStack>
    </Box>
  )
})

export interface SingleImageUploadProps {
  value?: BasicFileProperties
  onChange?(value: BasicFileProperties | null): void
}

const UPPY_MULTIPART_HOST = process.env.REACT_APP_UPPY_MULTIPART_HOST
const UPPY_COMPANION_HOST = "https://apiv2.leaguebrain-staging.com" // process.env.REACT_APP_UPPY_MULTIPART_HOST

export const SingleImageUpload = React.forwardRef((props: SingleImageUploadProps, ref: any) => {

  const {
    value = null,
    onChange = () => {}
  } = props

  const { keycloak } = useKeycloak()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [uploadedFiles, setUploadedFiles] = React.useState<BasicFileProperties | null>(value ?? null);

  useEffect(() => {
    setUploadedFiles(value)
  }, [JSON.stringify(value)])

  const uppy = useUppy(() => {
    const instance = Uppy({
      restrictions: {
        allowedFileTypes: ['image/*']
      }
    }).on('complete', (result: UploadResult<any, any>) => {

    }).on('file-removed', () => {

    }).on('file-added', () => {

    }).on('progress', (progress) => {

    }).use(ImageEditor, {
      id: 'ImageEditor',
      quality: 1,
      cropperOptions: {
        dragMode: 'crop'
      }
    }).use(AwsS3Multipart, {
      limit: 4,
      createMultipartUpload(file: UppyFile) {
        return fetch(`${UPPY_MULTIPART_HOST}/aws/createMultipartUpload`, {
          method: 'POST',
          headers: {
            Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: file.name,
            type: file.type
          })
        }).then(res => res.json())
      },
      listParts(file: UppyFile, opts: { uploadId: string; key: string }) {
        return fetch(`${UPPY_MULTIPART_HOST}/aws/listParts`, {
          method: 'POST',
          headers: {
            Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            uploadId: opts.uploadId,
            key: opts.key
          })
        }).then(res => res.json())
      },
      prepareUploadPart(file: UppyFile, partData: { uploadId: string; key: string; body: Blob; number: number }) {
        return fetch(`${UPPY_MULTIPART_HOST}/aws/prepareUploadPart`, {
          method: 'POST',
          headers: {
            Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            uploadId: partData.uploadId,
            key: partData.key,
            partNumber: partData.number
          })
        }).then(res => res.json())
      },
      abortMultipartUpload(file: UppyFile, opts: { uploadId: string; key: string }) {
        return fetch(`${UPPY_MULTIPART_HOST}/aws/abortMultipartUpload`, {
          method: 'POST',
          headers: {
            Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            uploadId: opts.uploadId,
            key: opts.key
          })
        }).then(res => res.json())
      },
      completeMultipartUpload(file: UppyFile, opts: { uploadId: any; key: string; parts: AwsS3Part[] }) {
        return fetch(`${UPPY_MULTIPART_HOST}/aws/completeMultipartUpload`, {
          method: 'POST',
          headers: {
            Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            uploadId: opts.uploadId,
            key: opts.key,
            parts: opts.parts
          })
        }).then(res => res.json())
      }
    }).use(GoogleDrive, {
      id: 'GoogleDrive',
      companionUrl: `${UPPY_COMPANION_HOST}/companion`
    }).use(Dropbox, {
      id: 'Dropbox',
      companionUrl: `${UPPY_COMPANION_HOST}/companion`
    })

    return instance // withMultipartUpload(instance)
  })

  useEffect(() => {
    uppy.setOptions({
      restrictions: {
        maxNumberOfFiles: 1
      }
    })
  }, [JSON.stringify(uploadedFiles)])

  useEffect(() => {
    onChange(uploadedFiles)
  }, [JSON.stringify(uploadedFiles)])

  useEffect(() => {
    const handler = (file: UppyFile, response: any) => {
      if (file.source === 'GoogleDrive') {
        fetch(`${process.env.REACT_APP_UPPY_MULTIPART_HOST}/aws/generateFileId`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            key: response.uploadURL.replace('https://cdnstack-bucket83908e77-1ur7l84x32mjf.s3.us-east-1.amazonaws.com/', ''),
            uploadId: file.id
          })
        })
          .then(res => res.json())
          .then(data => {
            setUploadedFiles({
              id: data.serverId,
              name: file.name,
              url: data.location,
              mimeType: file.meta.type
            })
          })

        return
      }

      setUploadedFiles({
        id: response.body.serverId,
        name: file.name,
        url: response.uploadURL,
        mimeType: file.meta.type
      })
    };

    uppy.on('upload-success', handler);

    return () => {
      uppy.off('upload-success', handler);
    };
  }, [JSON.stringify(uploadedFiles)]);

  const [gray100, gray700] = useToken(
    // the key within the theme, in this case `theme.colors`
    "colors",
    // the subkey(s), resolving to `theme.colors.red.100`
    ["gray.100", "gray.700"],
    // a single fallback or fallback array matching the length of the previous arg
  )

  return (
    <Box w={'full'} ref={ref}>
      <Avatar
        pos={'relative'}
        size={'2xl'}
        src={uploadedFiles?.url || undefined}
        icon={<Icon icon={faImage} color={'gray.500'} />}
        bgColor={useColorModeValue(gray100, gray700)}
      >
        <Tooltip hasArrow label="Edit Image" placement={'top-start'}>
          <IconButton
            aria-label={'Edit profile image'}
            pos={'absolute'}
            top={'-5%'}
            right={'-5%'}
            size={'md'}
            colorScheme={'blue'}
            icon={<Icon icon={faPencil} />}
            border={'5px solid'}
            onClick={onOpen}
            borderRadius={'50px'}
          />
        </Tooltip>

        <Tooltip hasArrow label="Remove Image" placement={'top-start'}>
          <IconButton
            aria-label={'Remove image'}
            pos={'absolute'}
            top={'-5%'}
            left={'-5%'}
            size={'md'}
            colorScheme={'red'}
            icon={<Icon icon={faTimes} />}
            border={'5px solid'}
            onClick={() => setUploadedFiles(null)}
            borderRadius={'50px'}
          />
        </Tooltip>
      </Avatar>

      <DashboardModal
        uppy={uppy}
        plugins={['ImageEditor', 'GoogleDrive', 'Dropbox']}
        width={'100%'}
        height={'500px'}
        theme={useColorMode().colorMode}
        autoOpenFileEditor={true}
        proudlyDisplayPoweredByUppy={false}
        closeModalOnClickOutside
        open={isOpen}
        onRequestClose={onClose}
      />
    </Box>
  )
})

export const UserAvatarImageUpload = React.forwardRef((props: SingleImageUploadProps, ref: any) => {

  const {
    value = null,
    onChange = () => {}
  } = props

  const { keycloak } = useKeycloak()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [uploadedFiles, setUploadedFiles] = React.useState<BasicFileProperties | null>(value ?? null);

  useEffect(() => {
    setUploadedFiles(value)
  }, [JSON.stringify(value)])

  const uppy = useUppy(() => {
    const instance = Uppy({
      restrictions: {
        allowedFileTypes: ['image/*']
      }
    }).on('complete', (result: UploadResult<any, any>) => {

    }).on('file-removed', () => {

    }).on('file-added', () => {

    }).on('progress', (progress) => {

    }).use(ImageEditor, {
      id: 'ImageEditor',
      quality: 1,
      cropperOptions: {
        dragMode: 'crop'
      }
    }).use(AwsS3Multipart, {
      limit: 4,
      createMultipartUpload(file: UppyFile) {
        return fetch(`${UPPY_MULTIPART_HOST}/aws/createMultipartUpload`, {
          method: 'POST',
          headers: {
            Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: file.name,
            type: file.type
          })
        }).then(res => res.json())
      },
      listParts(file: UppyFile, opts: { uploadId: string; key: string }) {
        return fetch(`${UPPY_MULTIPART_HOST}/aws/listParts`, {
          method: 'POST',
          headers: {
            Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            uploadId: opts.uploadId,
            key: opts.key
          })
        }).then(res => res.json())
      },
      prepareUploadPart(file: UppyFile, partData: { uploadId: string; key: string; body: Blob; number: number }) {
        return fetch(`${UPPY_MULTIPART_HOST}/aws/prepareUploadPart`, {
          method: 'POST',
          headers: {
            Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            uploadId: partData.uploadId,
            key: partData.key,
            partNumber: partData.number
          })
        }).then(res => res.json())
      },
      abortMultipartUpload(file: UppyFile, opts: { uploadId: string; key: string }) {
        return fetch(`${UPPY_MULTIPART_HOST}/aws/abortMultipartUpload`, {
          method: 'POST',
          headers: {
            Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            uploadId: opts.uploadId,
            key: opts.key
          })
        }).then(res => res.json())
      },
      completeMultipartUpload(file: UppyFile, opts: { uploadId: any; key: string; parts: AwsS3Part[] }) {
        return fetch(`${UPPY_MULTIPART_HOST}/aws/completeMultipartUpload`, {
          method: 'POST',
          headers: {
            Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            uploadId: opts.uploadId,
            key: opts.key,
            parts: opts.parts
          })
        }).then(res => res.json())
      }
    }).use(GoogleDrive, {
      id: 'GoogleDrive',
      companionUrl: `${UPPY_COMPANION_HOST}/companion`
    }).use(Dropbox, {
      id: 'Dropbox',
      companionUrl: `${UPPY_COMPANION_HOST}/companion`
    }).on('file-editor:complete', (updatedFile) => {
      instance.upload()
    })

    return instance // withMultipartUpload(instance)
  })

  useEffect(() => {
    uppy.setOptions({
      restrictions: {
        maxNumberOfFiles: 1
      }
    })
  }, [JSON.stringify(uploadedFiles)])

  useEffect(() => {
    onChange(uploadedFiles)
  }, [JSON.stringify(uploadedFiles)])

  useEffect(() => {
    const handler = (file: UppyFile, response: any) => {
      if (file.source === 'GoogleDrive') {
        fetch(`${process.env.REACT_APP_UPPY_MULTIPART_HOST}/aws/generateFileId`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            key: response.uploadURL.replace('https://cdnstack-bucket83908e77-1ur7l84x32mjf.s3.us-east-1.amazonaws.com/', ''),
            uploadId: file.id
          })
        })
          .then(res => res.json())
          .then(data => {
            setUploadedFiles({
              id: data.serverId,
              name: file.name,
              url: data.location,
              mimeType: file.meta.type
            })
          })

        return
      }

      setUploadedFiles({
        id: response.body.serverId,
        name: file.name,
        url: response.uploadURL,
        mimeType: file.meta.type
      })
    };

    uppy.on('upload-success', handler);

    return () => {
      uppy.off('upload-success', handler);
    };
  }, [JSON.stringify(uploadedFiles)]);

  const [gray100, gray700] = useToken(
    // the key within the theme, in this case `theme.colors`
    "colors",
    // the subkey(s), resolving to `theme.colors.red.100`
    ["gray.100", "gray.700"],
    // a single fallback or fallback array matching the length of the previous arg
  )

  const { data } = useGetUserInfoQuery()
  const borderColor = useColorModeValue(gray100, gray700)

  return (
    <Box w={'full'} ref={ref}>
      <Avatar
        pos={'relative'}
        size={'2xl'}
        src={uploadedFiles?.url || data?.viewer?.defaultAvatar?.url}
        border={(!uploadedFiles || (uploadedFiles?.url === data?.viewer?.defaultAvatar?.url)) ? `4px solid ${borderColor}` : undefined}
        icon={<Icon icon={faImage} color={'gray.500'} />}
        bgColor={useColorModeValue(gray100, gray700)}
      >
        <Tooltip hasArrow label="Edit Image" placement={'top-start'}>
          <IconButton
            aria-label={'Edit profile image'}
            pos={'absolute'}
            top={'-5%'}
            right={'-5%'}
            size={'md'}
            colorScheme={'blue'}
            icon={<Icon icon={faPencil} />}
            border={'5px solid'}
            onClick={onOpen}
            borderRadius={'50px'}
          />
        </Tooltip>

        <Tooltip hasArrow label="Remove Image" placement={'top-start'}>
          <IconButton
            aria-label={'Remove image'}
            pos={'absolute'}
            top={'-5%'}
            left={'-5%'}
            size={'md'}
            colorScheme={'red'}
            icon={<Icon icon={faTimes} />}
            border={'5px solid'}
            onClick={() => setUploadedFiles(null)}
            borderRadius={'50px'}
          />
        </Tooltip>
      </Avatar>

      <DashboardModal
        uppy={uppy}
        plugins={['ImageEditor', 'GoogleDrive', 'Dropbox']}
        width={'100%'}
        height={'500px'}
        theme={useColorMode().colorMode}
        autoOpenFileEditor={true}
        proudlyDisplayPoweredByUppy={false}
        closeModalOnClickOutside
        open={isOpen}
        onRequestClose={onClose}
      />
    </Box>
  )
})

function IconForFileType(props: { fileType?: string | undefined }) {

  const {
    fileType
  } = props

  const [red500, green400, blue400] = useToken(
    "colors",
    ["red.500", "green.400", 'blue.400'],
  )

  if (fileType === 'application/pdf') {
    return <Icon
          boxShadow={'lg'}
          size={'lg'}
          fontSize={'2xl'}
          icon={faFilePdf}
          sx={{
            '--fa-primary-opacity': 1,
            '--fa-secondary-opacity': 1,
            '--fa-primary-color': '#f0f0f0',
            '--fa-secondary-color': red500
          }}
        />
  }

  if (fileType === 'text/csv') {
    return <Icon
      boxShadow={'lg'}
      size={'lg'}
      fontSize={'2xl'}
      icon={faFileCsv}
      sx={{
        '--fa-primary-opacity': 1,
        '--fa-secondary-opacity': 1,
        '--fa-primary-color': '#f0f0f0',
        '--fa-secondary-color': green400
      }}
    />
  }

  if (fileType === 'application/zip' || fileType === 'application/gzip') {
    return <Icon
      boxShadow={'lg'}
      size={'lg'}
      fontSize={'2xl'}
      icon={faFileArchive}
      sx={{
        '--fa-primary-opacity': 1,
        '--fa-secondary-opacity': 1,
        '--fa-primary-color': '#f0f0f0',
        '--fa-secondary-color': green400
      }}
    />
  }

  if (fileType?.startsWith('video/') === true) {
    return <Icon
      boxShadow={'lg'}
      size={'lg'}
      fontSize={'2xl'}
      icon={faFileVideo}
      sx={{
        '--fa-primary-opacity': 1,
        '--fa-secondary-opacity': 1,
        '--fa-primary-color': '#f0f0f0',
        '--fa-secondary-color': blue400
      }}
    />
  }

  return <Icon
    boxShadow={'lg'}
    size={'lg'}
    fontSize={'2xl'}
    icon={faFileAlt}
  />
}

export default SingleFileInput;
