import React from "react";
import {UseFormReturn} from "react-hook-form";

export type SmartFormContext<T = any> = {
  useFormReturn: UseFormReturn<T>
  shouldEnterSubmitForm: boolean
}

const SmartFormContextReact = React.createContext<SmartFormContext<any> | undefined>(undefined)

export const SmartFormContextProvider = SmartFormContextReact.Provider

export function useSmartFormContext<T = any>(): SmartFormContext<T> {
  const context = React.useContext(SmartFormContextReact)

  if (!context) {
    throw new Error("Must provide a valid SmartFormContext!!")
  }

  return context
}
