import React, {useContext} from 'react';
import {Route, Routes, useLocation, useMatch, Outlet} from "react-router-dom";
import {OrganizationSettingsPage} from "./settings";
import {Box, Heading, HStack} from "@chakra-ui/react";
import {NavBar} from "../components/nav";
import {organizationPageLinks} from "../components/nav/_data";
import {Header} from "../components/header";
import {OrganizationViewingContext} from "../index";
import {Icon} from "../../../components/FontAwesomeIcon";
import {faStore} from "@fortawesome/pro-solid-svg-icons";
import {SeasonsPage} from "./seasons";
import {VenuesPage} from "./venues";

export function OrganizationPageRouter() {

  const { pathname: url } = useLocation()
  const organizationViewingContext = useContext(OrganizationViewingContext)

  return (
    <Box>
      <Header
        leftElement={
          <HStack>
            <Icon icon={faStore} color={'gray.500'} />
            <Heading p={0} pt={'2px'} m={0} fontSize={'md'}>
              {organizationViewingContext.organizationName}
            </Heading>
          </HStack>
        }
      />

      <Box mx={24}>
        <Routes>
          <Route
            path={'/*'}
            element={
              <>
                <NavBar links={organizationPageLinks(organizationViewingContext.organizationId)} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`settings`}
              element={<OrganizationSettingsPage />}
            />
            <Route
              path={`seasons`}
              element={<SeasonsPage />}
            />
            <Route
              path={`venues`}
              element={<VenuesPage />}
            />
          </Route>
        </Routes>
      </Box>
    </Box>
  )
}
