import React from 'react';
import Uppy, {UppyFile} from "@uppy/core";
import GoogleDrive from '@uppy/google-drive';
import Dropbox from '@uppy/dropbox';
import AwsS3Multipart, {AwsS3Part} from "@uppy/aws-s3-multipart";
import {useKeycloak} from "@react-keycloak/web";
import {useUppy} from "@uppy/react";
import {KeycloakInstance} from "keycloak-js";

const UPPY_MULTIPART_HOST = process.env.REACT_APP_UPPY_MULTIPART_HOST
const UPPY_COMPANION_HOST = "https://apiv2.leaguebrain-staging.com" // process.env.REACT_APP_UPPY_MULTIPART_HOST

export interface UseUppyWithMultipartUploadProps {
  options?: Uppy.UppyOptions
  deps?: any[]
}

export function useUppyWithMultipartUpload(props: UseUppyWithMultipartUploadProps = {}): Uppy.Uppy {

  const {
    options = {},
    deps = []
  } = props

  const { keycloak } = useKeycloak()

  return useUppy(() => {
    return Uppy(options)
      .use(AwsS3Multipart, {
        limit: 4,
        createMultipartUpload(file: UppyFile) {
          return fetch(`${UPPY_MULTIPART_HOST}/aws/createMultipartUpload`, {
            method: 'POST',
            headers: {
              Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              name: file.name,
              type: file.type
            })
          }).then(res => res.json())
        },
        listParts(file: UppyFile, opts: { uploadId: string; key: string }) {
          return fetch(`${UPPY_MULTIPART_HOST}/aws/listParts`, {
            method: 'POST',
            headers: {
              Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              uploadId: opts.uploadId,
              key: opts.key
            })
          }).then(res => res.json())
        },
        prepareUploadPart(file: UppyFile, partData: { uploadId: string; key: string; body: Blob; number: number }) {
          return fetch(`${UPPY_MULTIPART_HOST}/aws/prepareUploadPart`, {
            method: 'POST',
            headers: {
              Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              uploadId: partData.uploadId,
              key: partData.key,
              partNumber: partData.number
            })
          }).then(res => res.json())
        },
        abortMultipartUpload(file: UppyFile, opts: { uploadId: string; key: string }) {
          return fetch(`${UPPY_MULTIPART_HOST}/aws/abortMultipartUpload`, {
            method: 'POST',
            headers: {
              Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              uploadId: opts.uploadId,
              key: opts.key
            })
          }).then(res => res.json())
        },
        completeMultipartUpload(file: UppyFile, opts: { uploadId: any; key: string; parts: AwsS3Part[] }) {
          return fetch(`${UPPY_MULTIPART_HOST}/aws/completeMultipartUpload`, {
            method: 'POST',
            headers: {
              Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              uploadId: opts.uploadId,
              key: opts.key,
              parts: opts.parts
            })
          }).then(res => res.json())
        }
      })
  })
}

export function withMultipartUpload(uppy: Uppy.Uppy, keycloak: KeycloakInstance): Uppy.Uppy {
  return uppy.use(AwsS3Multipart, {
    limit: 4,
    createMultipartUpload(file: UppyFile) {
      return fetch(`${UPPY_MULTIPART_HOST}/aws/createMultipartUpload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
        },
        body: JSON.stringify({
          name: file.name,
          type: file.type
        })
      }).then(res => res.json())
    },
    listParts(file: UppyFile, opts: { uploadId: string; key: string }) {
      return fetch(`${UPPY_MULTIPART_HOST}/aws/listParts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
        },
        body: JSON.stringify({
          uploadId: opts.uploadId,
          key: opts.key
        })
      }).then(res => res.json())
    },
    prepareUploadPart(file: UppyFile, partData: { uploadId: string; key: string; body: Blob; number: number }) {
      return fetch(`${UPPY_MULTIPART_HOST}/aws/prepareUploadPart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
        },
        body: JSON.stringify({
          uploadId: partData.uploadId,
          key: partData.key,
          partNumber: partData.number
        })
      }).then(res => res.json())
    },
    abortMultipartUpload(file: UppyFile, opts: { uploadId: string; key: string }) {
      return fetch(`${UPPY_MULTIPART_HOST}/aws/abortMultipartUpload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
        },
        body: JSON.stringify({
          uploadId: opts.uploadId,
          key: opts.key
        })
      }).then(res => res.json())
    },
    completeMultipartUpload(file: UppyFile, opts: { uploadId: any; key: string; parts: AwsS3Part[] }) {
      return fetch(`${UPPY_MULTIPART_HOST}/aws/completeMultipartUpload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
        },
        body: JSON.stringify({
          uploadId: opts.uploadId,
          key: opts.key,
          parts: opts.parts
        })
      }).then(res => res.json())
    }
  }).use(GoogleDrive, {
    id: 'GoogleDrive',
    companionUrl: `${UPPY_COMPANION_HOST}/companion`
  }).use(Dropbox, {
    id: 'Dropbox',
    companionUrl: `${UPPY_COMPANION_HOST}/companion`
  })
}
