import * as React from 'react'
import {useCallback} from 'react'
import {Navigate, useLocation} from 'react-router-dom'

import {useKeycloak} from '@react-keycloak/web'
import {FullScreenLoader} from "./FullScreenLoader";

const LoginPage = () => {

  const location = useLocation()

  const currentLocationState = location.state || {
    from: { pathname: '/' },
  }

  console.log(location)

  const { keycloak } = useKeycloak()

  const login = useCallback(() => {
    keycloak?.login({
      redirectUri: `${process.env.REACT_APP_URL_BASE}${currentLocationState?.from.pathname}`
    })
  }, [currentLocationState?.from.pathname, keycloak])

  if (keycloak?.authenticated) {
    return <Navigate to={currentLocationState?.from as string}/>
  } else {
    login()
  }

  return <FullScreenLoader />
}

export default LoginPage
