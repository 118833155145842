import React from 'react';
import {createStyles, Navbar, ScrollArea} from "@mantine/core";
import {LinksGroup} from "../NavbarLinksGroup";

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    paddingBottom: 0,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md + 1,
  },

  linksInner: {
    paddingBottom: theme.spacing.xl,
  },
}));

export interface ApplicationNavbarProps {
  isOpen: boolean
  navigationDefinition: any[]
}

export function ApplicationNavbar(props: ApplicationNavbarProps) {

  const { classes } = useStyles();

  const links = props.navigationDefinition.map((item) => {
    return (
      <LinksGroup {...item} key={item.label} />
    )
  });

  return (
    <Navbar
      hidden={!props.isOpen}
      className={classes.navbar}
      height={'100vh'}
      pl={'lg'}
      pt={'70px'}
      mt={-50}
      sx={{ zIndex: 10 }}
      width={{ md: 300 }}
      p="md"
      hiddenBreakpoint="md"
    >
      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>
    </Navbar>
  )
}
