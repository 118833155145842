import * as React from 'react'
import {ManageUserProfileFieldsFragment, useUpdateUserAvatarMutation, useUpdateUserInfoMutation} from "../graphql";
import '@uppy/core/dist/style.css'
import '@uppy/file-input/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import _ from 'lodash';
import {
  SmartForm,
  SmartFormCancelButton,
  SmartFormFooter,
  SmartFormSection,
  SmartFormSubmitButton,
  SmartFormWrapper
} from "../../../components/smart-forms";
import {Heading} from "@chakra-ui/react";
import {UploadedFile} from './SingleFileInput';
import {SmartFormTextInput} from '../../../components/smart-forms/inputs/TextInput';
import {SmartUserAvatarFileUpload} from "../../../components/smart-forms/inputs/SmartUserAvatarFileUpload";

interface UpdateUserInfoResult {
  realName: string
  displayName: string
}

interface UpdateUserAvatarResult {
  avatar: UploadedFile
}

export interface ManageProfileFormProps {
  defaultValues?: ManageUserProfileFieldsFragment
}

export const ManageProfileForm = (props: ManageProfileFormProps) => {

  const {
    defaultValues
  } = props

  const [updateUserInfoMutation] = useUpdateUserInfoMutation()

  const [updateUserAvatarMutation] = useUpdateUserAvatarMutation()

  async function onSubmit(data: UpdateUserInfoResult) {
    return await updateUserInfoMutation({
      variables: {
        input: _.omit(data, ['email'])
      }
    })
  }

  return (
    <SmartFormWrapper>
      <Heading>Account Settings</Heading>

      <SmartForm<UpdateUserAvatarResult>
        defaultValues={{
          ...defaultValues,
          avatar: {
            id: 'u',
            url: defaultValues?.avatar
          },
        }}
        onSubmit={async (data: UpdateUserAvatarResult) => {
          await updateUserAvatarMutation({
            variables: {
              avatar: data?.avatar?.id
            }
          })
        }}
      >
        <SmartFormSection title={'Profile Photo'}>
          <SmartUserAvatarFileUpload
            label={'Avatar'}
            name={'avatar'}
          />
        </SmartFormSection>

        <SmartFormFooter>
          <SmartFormCancelButton />
          <SmartFormSubmitButton>Save</SmartFormSubmitButton>
        </SmartFormFooter>
      </SmartForm>

      <SmartForm<UpdateUserInfoResult, any>
        defaultValues={_.pick(defaultValues, ['email', 'realName', 'displayName'])}
        onSubmit={onSubmit}
        onComplete={(data: any) => {
          return {
            toast: {
              title: `Saved!`
            }
          }
        }}
      >
        <SmartFormSection title={'Personal Info'}>
          <SmartFormTextInput
            isDisabled
            label={'Email'}
            name={'email'}
          />

          <SmartFormTextInput
            isRequired
            label={'Name'}
            name={'realName'}
          />

          <SmartFormTextInput
            isRequired
            label={'Display Name'}
            name={'displayName'}
            registerOptions={{
              minLength: 4
            }}
          />
        </SmartFormSection>

        <SmartFormFooter>
          <SmartFormCancelButton />
          <SmartFormSubmitButton>Save</SmartFormSubmitButton>
        </SmartFormFooter>
      </SmartForm>
    </SmartFormWrapper>
  )
}

/*
    <Box px={{ base: '4', md: '10' }} py="8" w={'full'} mx="auto" overflowY={'auto'}>
      <forms onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="4" divider={<StackDivider />}>
          <Heading size="lg" as="h1" paddingBottom="4">
            Account Settings
          </Heading>
          <FieldGroup title="Profile Photo">
            <Stack direction="row" spacing="6" align="center" width="full">
              <UserAvatar size={'xl'} />
              <Box>
                <HStack spacing="5" alignItems={'center'}>
                  <SingleFileInput
                    ref={uploadRef}
                    onComplete={(result) => {
                      updateUserAvatarMutation({
                        variables: {
                          avatar: result.successful[0].s3Multipart.uploadId
                        }
                      })
                    }}
                  >
                    Upload
                  </SingleFileInput>

                  {defaultValues?.avatarUrl &&
                    <ConfirmActionButton
                      variant="ghost"
                      colorScheme="red"
                      title={'Remove Profile Picture'}
                      description={'Are you sure you want to remove your profile picture? This action cannot be undone.'}
                      onConfirm={() => {
                        updateUserAvatarMutation({
                          variables: {
                            avatar: null
                          }
                        })
                      }}
                    >
                      <Button variant={'ghost'} colorScheme={'red'}>
                        Remove
                      </Button>
                    </ConfirmActionButton>
                  }
                </HStack>
              </Box>
            </Stack>
          </FieldGroup>
          <FieldGroup title="Personal Info">
            <VStack width="full" spacing="6">
              <FormControl id="email">
                <FormLabel>
                  <HStack alignItems={'center'}>
                    <span>Email</span>
                  <IconButton
                    as={Link}
                    href={keycloak.createAccountUrl()}
                    isExternal
                    variant={'ghost'}
                    icon={<Icon icon={faExternalLink} />}
                    aria-label={'update-email'}
                    size={'sm'}
                    borderRadius={'md'}
                    ml={4}
                  />
                  </HStack>
                </FormLabel>
                <Input isDisabled={true} type="text" defaultValue={defaultValues?.email} variant={'filled'} />
              </FormControl>

              <HStack w={'full'}>
                <FormControl id="firstName">
                  <FormLabel>First Name</FormLabel>
                  <Input type="text" {...register('firstName')} />
                </FormControl>

                <FormControl id="lastName">
                  <FormLabel>Last Name</FormLabel>
                  <Input type="text" {...register('lastName')} />
                </FormControl>
              </HStack>

              <FormControl id="displayName">
                <FormLabel>Display Name</FormLabel>
                <Input type="text" {...register('displayName')} />
              </FormControl>
            </VStack>
          </FieldGroup>

          <FieldGroup title="Notifications">
            <Stack width="full" spacing="4">
              <Checkbox>Get updates about your teams.</Checkbox>
              <Checkbox>Get notifications about your account activities</Checkbox>
            </Stack>
          </FieldGroup>

        </Stack>
        <FieldGroup mt="8">
          <HStack width="full">
            <Button variant="outline">Cancel</Button>
            <Button type="submit" colorScheme="blue" loadingText={'Saving...'} isLoading={isSubmitting}>
              Save Changes
            </Button>
          </HStack>
        </FieldGroup>
      </forms>
    </Box>
    */

