import React from 'react';
import {Box, Button, Group, Table, TextInput} from "@mantine/core";
import {useAddVenueMutation, useAllVenuesQuery} from '../../graphql';
import {useModals} from "@mantine/modals";
import {useForm} from "react-hook-form";
import {useNotifications} from "@mantine/notifications";
import { gql } from '@apollo/client';

export function VenuesPage() {

  const { data } = useAllVenuesQuery()

  if (!data) {
    return <>loading...</>
  }

  const rows = data.organization.venues.map((venue) => (
    <tr key={venue.id}>
      <td>{venue.name}</td>
    </tr>
  ));

  return (
    <Box pt={4}>
      <Group direction={'row'} position={'right'}>
        <AddVenuePopup />
      </Group>
      <Table>
        <thead>
        <tr>
          <th>Field Name</th>
        </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </Box>
  )
}

function AddVenuePopup() {

  const modals = useModals()
  const notifications = useNotifications()
  const [addVenueMutation] = useAddVenueMutation({
    update(cache, { data}) {
      cache.modify({
        fields: {
          organization(currentOrganizationRef) {
            cache.modify({
              id: currentOrganizationRef.__ref,
              fields: {
                venues(existingVenues = []) {
                  const newVenueRef = cache.writeFragment({
                    data: data?.addVenue,
                    fragment: gql`
                        fragment NewVenue on Venue {
                            id
                            name
                        }
                    `
                  });
                  return [...existingVenues, newVenueRef];
                }
              }
            });
          }
        }
      });
    }
  })

  function addVenue(data: AddVenueFormData): Promise<any> {
    return addVenueMutation({
      variables: {
        data: {
          name: data.name
        }
      }
    }).then((res) => {
      modals.closeAll()

      if (res.data?.addVenue) {
        notifications.showNotification({
          color: 'green',
          title: 'Venue Added',
          message: `Your new venue, ${res.data.addVenue.venue?.name}, has been added.`
        })
      } else {
        notifications.showNotification({
          color: 'red',
          title: 'Something went wrong',
          message: 'We were unable to add your venue. Please try again.'
        })
      }
    })
  }

  function openAddVenueModal() {
    modals.openModal({
      title: 'Add Venue',
      children: (
        <>
          <AddVenueForm onSubmit={addVenue} />
        </>
      )
    })
  }

  return (
    <Button onClick={openAddVenueModal}>
      Add Venue
    </Button>
  )
}

interface AddVenueFormData {
  name: string
}

interface AddVenueFormProps {
  onSubmit(data: AddVenueFormData): Promise<any>
}

function AddVenueForm(props: AddVenueFormProps) {

  const {
    onSubmit
  } = props

  const {
    register,
    formState,
    handleSubmit
  } = useForm<AddVenueFormData>({
    mode: 'onChange'
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Group direction={'column'} align={'stretch'}>
        <TextInput
          label={'Venue Name'}
          {...register('name', { required: true })}
        />

        <Button
          type={'submit'}
          loading={formState.isSubmitting}
          disabled={!formState.isValid}
        >
          Add Venue
        </Button>
      </Group>
    </form>
  )
}
