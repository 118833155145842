import React from "react";
import {RouteDefinition} from "../../../../../../components/router-traversal/route-visitor";
import {TeamRequirementPanel, TeamRequirementsPage} from "./requirements";
import {SeasonViewingContext} from "../../../../../organization/season";
import {useGetTeamTableSegmentsQuery, useUpdateTeamSegmentMutation} from "../../../graphql";
import {TeamListTable} from "./list";
import {AppShellContentLayout} from "../../../../components/app-shell/content";

export const teamRoutes: RouteDefinition[] = [
  {
    path: '',
    element: <TeamRequirementsPage />,
    children: [
      {
        path: ':seasonTeamId',
        element: <TeamRequirementPanel />,
      }
    ]
  },
  {
    path: ':teamId',
    element: <>
      Current Team
    </>,
  },
  {
    path: 'requirements',
    element: <DemoListViewWithHeader />, // <>Team Requirements</>,
    extras: {
      spotlight: {
        title: 'Team Requirements',
        description: 'Manage your requirements'
      },
      details: {
        pageName: 'Team Requirements'
      },
    },
    children: [
      {
        path: ':seasonTeamId',
        element: <TeamRequirementPanel />,
      }
    ]
  }
]

function DemoListViewWithHeader() {

  const seasonContext = React.useContext(SeasonViewingContext)

  const { loading, data, refetch } = useGetTeamTableSegmentsQuery({
    variables: {
      seasonId: seasonContext.seasonId,
      teamIds: null,
      segments: ['_id', 'team.registration_fee_status', 'team.confirmed_game_count', 'team.is_committed_to_playoffs', 'team.name', 'team.division', 'team.registration.*']
    },
    skip: !seasonContext.seasonId
  })

  const [updateSegmentMutation] = useUpdateTeamSegmentMutation()

  const updateValue = React.useCallback((rowId: string, columnId: string, value: string) => {
    return updateSegmentMutation({
      variables: {
        seasonId: seasonContext.seasonId,
        teamId: rowId,
        segment: columnId,
        value: value
      }
    }).then(res => res?.data?.season?.updateTeamSegmentValue ?? false)
  }, [updateSegmentMutation, seasonContext.seasonId])

  const refreshData = React.useCallback((ids: string[]) => {
    return refetch({
      seasonId: seasonContext.seasonId,
      teamIds: ids
    }).then(res => {
      if (res.data.season?.teamSegmentTable) {
        return JSON.parse(res.data.season.teamSegmentTable)
      }
      throw Error("Unable to refresh")
    })
  }, [seasonContext.seasonId])

  if (!data?.season?.teamSegmentTable) {
    return <>loading...</>
  }

  return (
    <AppShellContentLayout.Static>
      <TeamListTable
        tableData={JSON.parse(data.season.teamSegmentTable)}
        updateValue={updateValue}
        refreshData={refreshData}
      />
    </AppShellContentLayout.Static>
  )
}
