import React from 'react';
import {
  Anchor,
  AppShell,
  Box,
  Group,
  Header,
  LoadingOverlay,
  Table,
  Title,
  Tooltip,
  useMantineTheme,
  Center, Loader, Text
} from '@mantine/core';
import {useSeasonByIdQuery, useTeamsInDivisionStatisticsQuery} from "../../graphql";
import {DivisionSelector} from "./DivisionSelector";
import {Link as ReactRouterLink, useNavigate, useParams} from "react-router-dom";
import {useMediaQuery} from "@mantine/hooks";
import {Icon} from "../../../../components/FontAwesomeIcon";
import {faTurnDownLeft} from "@fortawesome/pro-solid-svg-icons";
import {orderBy} from "lodash";

export function StandingsPageRouter() {

  const { divisionId, seasonId } = useParams()

  const theme = useMantineTheme();
  const navigate = useNavigate()

  const { loading, data } = useSeasonByIdQuery({
    variables: {
      seasonId: seasonId!!
    }
  })

  const matches = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);

  function onDivisionChange(division: string | null) {
    if (division) {
      navigate({
        pathname: `/public/seasons/${seasonId}/standings/${division}`
      })
    } else {
      navigate({
        pathname: `/public/seasons/${seasonId}/standings`
      })
    }
  }

  return (
    <AppShell
      // navbarOffsetBreakpoint controls when navbar should no longer be offset with padding-left
      navbarOffsetBreakpoint="sm"
      // fixed prop on AppShell will be automatically added to Header and Navbar
      fixed
      padding={0}
      /*
      navbar={
        <Navbar
          p="md"
          // Breakpoint at which navbar will be hidden if hidden prop is true
          hiddenBreakpoint="sm"
          // Hides navbar when viewport size is less than value specified in hiddenBreakpoint
          hidden={!opened}
          // when viewport size is less than theme.breakpoints.sm navbar width is 100%
          // viewport size > theme.breakpoints.sm – width is 300px
          width={{ sm: 300 }}
        >
          <Text>Application navbar</Text>
        </Navbar>
      }
       */
      header={
        <Header sx={{ backgroundColor: '#0F3F6B !important' }} height={matches ? 70 : 100} p="md">
          {/* Handle other responsive styles with MediaQuery component or createStyles function */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: matches ? 'row' : 'column-reverse',
              alignItems: 'center',
              justifyContent: matches ? 'space-between' : 'flex-start',
              gap: '8px'
            }}
          >
            <DivisionSelector
              seasonId={seasonId!!}
              value={divisionId!!}
              onChange={onDivisionChange}
            />
            <Title sx={{ color: 'white' }} order={4}>{data?.season?.name} Standings</Title>
          </Box>

          {/*
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>

            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>

          </div>
          */}
        </Header>
      }
    >
      <LoadingOverlay visible={loading} />

      <Box
        style={{
          position: 'absolute',
          inset: 0,
          top: matches ? 70 : 100,
          left: 0, // matches ? '300px' : 0,
          padding: '12px',
          overflowY: 'auto'
        }}
      >
        {!divisionId &&
          <Group>
            <Icon icon={faTurnDownLeft} ml={'12px'} sx={{ transform: 'rotate(90deg)' }} />
            <Title order={2}>
              Select a division to view its standings
            </Title>
          </Group>
        }

        <Box
          pt={'xl'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {divisionId && (
            <Anchor
              size={'sm'}
              href={'https://www.ghvbl.com/wp-content/uploads/2021/03/ghvbl-point-system-Sheet1-1.pdf'}
              target={'_blank'}
              align={'right'}
              sx={{ width: '100%', maxWidth: '960px' }}
            >
              View GHVBL Point System
            </Anchor>
          )}

          <Box sx={{ position: 'relative', overflowX: 'auto', width: '100%', maxWidth: '960px' }}>
            {divisionId && <StandingsTable divisionId={divisionId} />}
          </Box>
        </Box>
      </Box>
    </AppShell>
  )
}

interface StandingsTableProps {
  divisionId: string
}

const MINIMUM_GAMES_FOR_PLAYOFFS = 8

function StandingsTable(props: StandingsTableProps) {

  const {
    divisionId
  } = props

  const { data } = useTeamsInDivisionStatisticsQuery({
    variables: {
      divisionId: divisionId
    }
  })

  const { seasonId } = useParams()

  if (!data?.division?.teams) {
    return (
      <Group direction={'column'} align={'center'} mt={'xl'}>
        <Loader variant={'dots'} />
        <Text>Crunching the latest numbers...</Text>
      </Group>
    )
  }

  const sortedRows = orderBy((data?.division?.teams ?? []), [
    function (team) { return team.statistics.points; },
    function (team) { return team.isCommittedToPlayoffs; },
    function (team) { return team.statistics.scoresAgainstAverage; }
  ], ["desc", "desc", "asc"]);

  const mappedRows: any[] = []

  let currSeed = 1;
  for (let i = 0; i < sortedRows.length; i++) {
    const row = sortedRows[i]

    mappedRows.push({
      team: row,
      seed: row.isCommittedToPlayoffs ? currSeed++ : '-',
      totalGames: row.statistics.expectedWinLoss.wins + row.statistics.expectedWinLoss.losses
    })
  }

  const rows = mappedRows.map((_team, idx) => {
    const { team, seed, totalGames } = _team
    const { statistics: stats } = team

    return (
      <tr style={{ position: 'relative' }} key={team.id}>
        <td
          style={{
            position: 'sticky',
            left: 0,
            top: 'auto',
            width: '240px',
            backgroundColor: '#F1F3F5',
          }}
        >
          <ReactRouterLink to={`/public/seasons/${seasonId}/teams/${team.id}`}>
            <Box
              component={'span'}
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.colors.blue[4]}`
              })}
            >
              {team.name}
            </Box>
          </ReactRouterLink>
        </td>

        <td>{stats.record.wins}</td>
        <td>{stats.record.losses}</td>
        <td>{stats.record.ties}</td>

        <td style={{ borderRight: '1px solid lightgrey' }}>{formatPercentage(stats.winPercentage)}</td>

        <td>{stats.recordOverLastFive.wins}-{stats.recordOverLastFive.losses}-{stats.recordOverLastFive.ties}</td>
        <td style={{ borderRight: '1px solid lightgrey' }}>{stats.currentActiveStreak}</td>

        <td>{stats.scoresFor}</td>
        <td>{stats.scoresAgainst}</td>
        <td>{formatDifferential(stats.scoreDifferential)}</td>
        <td>{stats.scoresAgainstAverage.toFixed(1)}</td>

        <td style={{ borderRight: '1px solid lightgrey' }}>{stats.expectedWinLoss.wins}-{stats.expectedWinLoss.losses}</td>

        <td>{stats.points}</td>
        {(totalGames < MINIMUM_GAMES_FOR_PLAYOFFS && seed !== '-') ? (
            <td>
              <Tooltip label={`Needs ${MINIMUM_GAMES_FOR_PLAYOFFS - totalGames} more game${MINIMUM_GAMES_FOR_PLAYOFFS - totalGames > 1 ? 's' : ''} to qualify for playoffs`}>
                {seed}*
              </Tooltip>
            </td>
        ) : (
          <td>{seed}</td>
        )}
      </tr>
    )
  });

  return (
    <Table captionSide={'bottom'} sx={{ minWidth: '960px', border: '1px solid #DEE2E6' }}>
      <thead>
      <tr style={{ position: 'relative', paddingLeft: '120px', cursor: 'default', backgroundColor: '#E9ECEF' }}>
        <th
          style={{
            position: 'sticky',
            left: 0,
            top: 'auto',
            width: '240px',
            zIndex: 10,
            backgroundColor: '#E9ECEF',
          }}
        >
          Team
        </th>

        <th>
          <Tooltip label={'Wins'}>
            W
          </Tooltip>
        </th>

        <th>
          <Tooltip label={'Losses'}>
            L
          </Tooltip>
        </th>

        <th>
          <Tooltip label={'Ties'}>
            T
          </Tooltip>
        </th>

        <th style={{ borderRight: '1px solid lightgrey' }}>
          <Tooltip label={'Win percentage. Ties count as half a win.'}>
            W%
          </Tooltip>
        </th>

        <th>
          <Tooltip label={'Record in the last five games'}>
            L5
          </Tooltip>
        </th>

        <th style={{ borderRight: '1px solid lightgrey' }}>
          <Tooltip label={'Current streak'}>
            STRK
          </Tooltip>
        </th>

        <th>
          <Tooltip label={'Runs scored'}>
            RS
          </Tooltip>
        </th>

        <th>
          <Tooltip label={'Runs against'}>
            RA
          </Tooltip>
        </th>

        <th>
          <Tooltip label={'Run differential'}>
            DIFF
          </Tooltip>
        </th>

        <th>
          <Tooltip label={'Runs against average'}>
            RAA
          </Tooltip>
        </th>

        <th style={{ borderRight: '1px solid lightgrey' }}>
          <Tooltip label={'Expected win/loss record based on runs scored and runs allowed'}>
            X-W/L
          </Tooltip>
        </th>

        <th>
          <Tooltip label={'League Points'}>
            Points
          </Tooltip>
        </th>

        <th>
          <Tooltip label={'League Seed'}>
            Seed
          </Tooltip>
        </th>
      </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}

function formatPercentage(value: number): string {
  if (value >= 1) {
    return value.toFixed(3)
  }

  return value.toFixed(3).toString().replace('0.', '.')
}

function formatDifferential(value: number): string {
  if (value === 0) {
    return '±' + value
  }

  if (value > 0) {
    return '+' + value
  }

  return value.toString()
}
