import React from 'react';
import {
  SmartForm,
  SmartFormCancelButton,
  SmartFormFooter,
  SmartFormSection,
  SmartFormSubmitButton,
  SmartFormWrapper
} from "../../../../components/smart-forms";
import {AllTeamsDocument, RegistrationFormForSeasonDocument, useAddTeamMutation} from "../../graphql";
import {useNavigate, useLocation} from "react-router-dom";
import {Heading} from "@chakra-ui/react";
import {UploadedFile} from "../../manage-profile/SingleFileInput";
import {SmartFormTextInput} from '../../../../components/smart-forms/inputs/TextInput';
import {SmartFileUploadSingleImage} from '../../../../components/smart-forms/inputs/SmartFileUploadSingleImage';
import {mapValues} from 'lodash';

export interface CreateTeamInput {
  teamName: string
  description?: string
  avatarImage?: UploadedFile[]
}

export function CreateTeamPage() {

  const navigate = useNavigate()
  const { search } = useLocation()

  const [createTeamMutation] = useAddTeamMutation({
    refetchQueries: [{
      query: AllTeamsDocument
    }]
  })

  return (
    <SmartFormWrapper>
      <Heading>Create Team</Heading>

      <SmartForm<CreateTeamInput>
        onSubmit={async (data: CreateTeamInput) => {
          const resultData = mapValues(data, value => {
            if (typeof value !== 'string' || value.length > 0) {
              return value
            } else {
              return null
            }
          });

          return await createTeamMutation({
            variables: {
              data: {
                ...resultData,
                avatarImage: resultData?.avatarImage?.id
              }
            },
          }).then(res => res.data?.addTeam?.team)
            .then(result => {
              const queryParams = new URLSearchParams(search)
              if (queryParams.has('r')) {
                if (!queryParams.has('s')) {
                  navigate(queryParams.get('r')!!)
                } else {
                  const newSearchParams = new URLSearchParams()
                  newSearchParams.set('team', result.id)

                  navigate(queryParams.get('r')!!, { state: newSearchParams })
                }
              } else {
                navigate(`/u/teams/${result?.id}`)
              }
            })
        }}
      >
        <SmartFormSection
          title={'Basic Info'}
        >
          {/*
          <SmartFileUploadSingleImage
            label={'Avatar'}
            name={'avatarImage'}
          />
          */}

          <SmartFormTextInput
            isRequired
            label={'Team Name'}
            name={'teamName'}
            placeholder={'e.g. NY Yankees'}
            registerOptions={{
              minLength: {
                value: 4,
                message: 'Must be at least 4 characters'
              }
            }}
          />

          <SmartFormTextInput
            label={'Description'}
            name={'description'}
            helperText={'Team descriptions can help you stay organized. They are only visible to you.'}
          />
        </SmartFormSection>

        <SmartFormFooter>
          <SmartFormCancelButton />
          <SmartFormSubmitButton>Create Team</SmartFormSubmitButton>
        </SmartFormFooter>
      </SmartForm>
    </SmartFormWrapper>
  )
}
