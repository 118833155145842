import React from 'react';
import {FormDefinition} from "./formDefinition";
import {FormQuestion} from "./FormQuestion";
import {Stack, Title} from '@mantine/core';

export interface FormSectionProps {
  definition: FormDefinition['sections'][number]
}

export function FormSection(props: FormSectionProps) {

  const {
    definition,
  } = props

  return (
    <Stack align={'stretch'} spacing={'sm'}>
      <Title>{definition.title}</Title>

      {definition.questions.map((question) => (
        <FormQuestion key={question.key} sectionKey={'questions.' + definition.key} definition={question} />
      ))}
    </Stack>
  )
}
