import {RouteDefinition} from "../../../../../components/router-traversal/route-visitor";
import React from "react";
import {Text} from "@mantine/core";
import {Outlet} from "react-router-dom";

export const venueRoutes: RouteDefinition[] = [
  {
    path: ':venueId',
    element: <>
      <Text>Current Venue</Text>
      <Outlet />
    </>
  }
]
