import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An RFC-3339 compliant DateTime Scalar */
  DateTime: any;
  /** A representation of an email address */
  Email: any;
  /** A representation of a datetime without a time zone */
  LocalDateTime: any;
  /** A representation of a non blank String value */
  NonBlankString: any;
  /** A representation of an Olson time zone */
  TimeZone: any;
  /** A Url scalar */
  Url: any;
};


export type Actor = {
  readonly displayName: Scalars['NonBlankString'];
  readonly id: Scalars['ID'];
};

export type AddVenueInput = {
  readonly name: Scalars['NonBlankString'];
};

export type AddVenuePayload = {
  readonly __typename?: 'AddVenuePayload';
  readonly venue?: Maybe<Venue>;
};

export enum ApprovalStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type ApprovingEntity = OrganizationApprovingEntity | SeasonTeamApprovingEntity;

export type CancelGameDataInput = {
  readonly cancelReason?: Maybe<Scalars['NonBlankString']>;
  readonly gameId: Scalars['ID'];
  readonly gameVersion?: Maybe<Scalars['Int']>;
};

export type CanceledGame = Game & GameSchedulingRow & {
  readonly __typename?: 'CanceledGame';
  readonly assignedOfficials: ReadonlyArray<Official>;
  readonly awayTeam: SeasonTeam;
  readonly cancellingTeam: SeasonTeam;
  readonly date: ZonedDateTimeParts;
  readonly eventHistory: ReadonlyArray<OrganizationGameEvent>;
  readonly fingerprint: Scalars['NonBlankString'];
  readonly homeTeam: SeasonTeam;
  readonly id: Scalars['ID'];
  readonly lastSchedulingUpdate: Scalars['DateTime'];
  readonly venue: Venue;
  readonly version: Scalars['Int'];
};

export type Cash = RegistrationFeePaymentDetails & {
  readonly __typename?: 'Cash';
  readonly notes?: Maybe<Scalars['NonBlankString']>;
};

export type CashInput = {
  readonly amountReceived: Scalars['Int'];
};

export type Check = RegistrationFeePaymentDetails & {
  readonly __typename?: 'Check';
  readonly checkId?: Maybe<Scalars['NonBlankString']>;
  readonly notes?: Maybe<Scalars['NonBlankString']>;
};

export type CheckInput = {
  readonly checkId?: Maybe<Scalars['NonBlankString']>;
};

export type Completed = StripeOnboardStatus & {
  readonly __typename?: 'Completed';
  readonly isOnboarded: Scalars['Boolean'];
};

export type ConfirmedGame = Game & GameSchedulingRow & {
  readonly __typename?: 'ConfirmedGame';
  readonly assignedOfficials: ReadonlyArray<Official>;
  readonly awayTeam: SeasonTeam;
  readonly date: ZonedDateTimeParts;
  readonly eventHistory: ReadonlyArray<OrganizationGameEvent>;
  readonly fingerprint: Scalars['NonBlankString'];
  readonly homeTeam: SeasonTeam;
  readonly id: Scalars['ID'];
  readonly lastSchedulingUpdate: Scalars['DateTime'];
  readonly reportedScore?: Maybe<GameResultSubmission>;
  readonly venue: Venue;
  readonly version: Scalars['Int'];
};


export type Decision = {
  readonly __typename?: 'Decision';
  readonly awayTeamScore: Scalars['Int'];
  readonly homeTeamScore: Scalars['Int'];
};

export type Division = {
  readonly __typename?: 'Division';
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
  readonly specification: ReadonlyArray<SpecificationPart>;
};

export type DivisionDescriptor = {
  readonly __typename?: 'DivisionDescriptor';
  readonly description?: Maybe<Scalars['NonBlankString']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
};

export type DivisionDescriptorGroup = {
  readonly __typename?: 'DivisionDescriptorGroup';
  readonly description?: Maybe<Scalars['NonBlankString']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
};


export type Forfeit = {
  readonly __typename?: 'Forfeit';
  readonly forfeitingTeam: SeasonTeam;
};

export type GhvblSpecific = {
  readonly __typename?: 'GHVBLSpecific';
  readonly gamesReport: Scalars['String'];
  readonly teamsReport: Scalars['String'];
};


export type GhvblSpecificGamesReportArgs = {
  assignor?: Maybe<Scalars['String']>;
};

export type Game = {
  readonly assignedOfficials: ReadonlyArray<Official>;
  readonly awayTeam: SeasonTeam;
  readonly date?: Maybe<ZonedDateTimeParts>;
  readonly eventHistory: ReadonlyArray<OrganizationGameEvent>;
  readonly fingerprint: Scalars['NonBlankString'];
  readonly homeTeam: SeasonTeam;
  readonly id: Scalars['ID'];
  readonly lastSchedulingUpdate: Scalars['DateTime'];
  readonly venue: Venue;
  readonly version: Scalars['Int'];
};

export type GameApprovalStatus = {
  readonly __typename?: 'GameApprovalStatus';
  readonly approvingEntities: ReadonlyArray<PendingGameApprovalStatusEntry>;
  readonly status: ApprovalStatus;
};

export type GameMutations = {
  readonly __typename?: 'GameMutations';
  readonly cancelGame: GenericGameActionPayload;
  readonly proposeSchedulingChange: GenericGameActionPayload;
  readonly scheduleGame: GenericGameActionPayload;
  readonly submitGameResult: GenericGameActionPayload;
  readonly updateOfficialAssignments: GenericGameActionPayload;
};


export type GameMutationsCancelGameArgs = {
  data: CancelGameDataInput;
};


export type GameMutationsProposeSchedulingChangeArgs = {
  data: ProposeGameSchedulingChangeDataInput;
};


export type GameMutationsScheduleGameArgs = {
  data: ScheduleGameDataInput;
};


export type GameMutationsSubmitGameResultArgs = {
  data: SubmitGameResultDataInput;
};


export type GameMutationsUpdateOfficialAssignmentsArgs = {
  data: UpdateOfficialAssignmentsInput;
};

export type GameResult = Decision | Forfeit;

export type GameResultInput = {
  readonly decision?: Maybe<GameResultInputDecisionInput>;
  readonly forfeit?: Maybe<GameResultInputForfeitInput>;
};

export type GameResultInputDecisionInput = {
  readonly awayTeamScore: Scalars['Int'];
  readonly homeTeamScore: Scalars['Int'];
};

export type GameResultInputForfeitInput = {
  readonly forfeitingTeam: Scalars['ID'];
};

export type GameResultSubmission = {
  readonly __typename?: 'GameResultSubmission';
  readonly result: GameResult;
  readonly submitter?: Maybe<GraphQlOrganizationOrSeasonTeam>;
};

export type GameSchedulingActorOrganizationStaffMember = OrganizationGraphQlGameSchedulingActor & {
  readonly __typename?: 'GameSchedulingActorOrganizationStaffMember';
  readonly organization: Organization;
  readonly user: LeagueBrainUser;
};

export type GameSchedulingActorTeamStaffMember = OrganizationGraphQlGameSchedulingActor & {
  readonly __typename?: 'GameSchedulingActorTeamStaffMember';
  readonly seasonTeam: SeasonTeam;
  readonly user: LeagueBrainUser;
};

export type GameSchedulingRow = {
  readonly assignedOfficials: ReadonlyArray<Official>;
  readonly awayTeam: SeasonTeam;
  readonly date?: Maybe<ZonedDateTimeParts>;
  readonly homeTeam: SeasonTeam;
  readonly id: Scalars['ID'];
  readonly venue: Venue;
  readonly version: Scalars['Int'];
};

export type GenericGameActionPayload = {
  readonly __typename?: 'GenericGameActionPayload';
  readonly error?: Maybe<Scalars['String']>;
  readonly game?: Maybe<GameSchedulingRow>;
};

export type GhostGame = GameSchedulingRow & {
  readonly __typename?: 'GhostGame';
  readonly assignedOfficials: ReadonlyArray<Official>;
  readonly awayTeam: SeasonTeam;
  readonly date: ZonedDateTimeParts;
  readonly homeTeam: SeasonTeam;
  readonly id: Scalars['ID'];
  readonly source: Game;
  readonly venue: Venue;
  readonly version: Scalars['Int'];
};

export enum GhostGameSortOrder {
  DateAndField = 'DATE_AND_FIELD',
  Default = 'DEFAULT'
}

export type GhostGamesQueryInput = {
  readonly ageGroupId?: Maybe<Scalars['ID']>;
  readonly end: Scalars['DateTime'];
  readonly sortOrder?: Maybe<GhostGameSortOrder>;
  readonly start: Scalars['DateTime'];
  readonly teams?: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type GraphQlOrganizationOrSeasonTeam = Organization | SeasonTeam;

export type Incomplete = StripeOnboardStatus & {
  readonly __typename?: 'Incomplete';
  readonly accountLinkUrl: Scalars['Url'];
  readonly isOnboarded: Scalars['Boolean'];
};

export type LeagueBrainUser = Actor & Node & {
  readonly __typename?: 'LeagueBrainUser';
  readonly avatar: Scalars['Url'];
  readonly defaultAvatar: Scalars['Url'];
  readonly displayName: Scalars['NonBlankString'];
  readonly email: Scalars['Email'];
  readonly id: Scalars['ID'];
  readonly publicProfile: LeagueBrainUserProfile;
  readonly realName: Scalars['NonBlankString'];
};

export type LeagueBrainUserProfile = Actor & Node & {
  readonly __typename?: 'LeagueBrainUserProfile';
  readonly avatar: Scalars['Url'];
  readonly displayName: Scalars['NonBlankString'];
  readonly email: Scalars['Email'];
  readonly id: Scalars['ID'];
};

export type LineItem = {
  readonly __typename?: 'LineItem';
  readonly formattedPrice: Scalars['String'];
  readonly productName: Scalars['NonBlankString'];
  readonly quantity: Scalars['Int'];
};


export type MarkOrderAsPaidInput = {
  readonly orderId: Scalars['ID'];
  readonly paymentDetails: SeasonTeamOrderFeePaymentTypeInput;
};

export type MarkOrderAsPaidPayload = {
  readonly __typename?: 'MarkOrderAsPaidPayload';
  readonly order?: Maybe<SeasonTeamOrder>;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly addVenue: AddVenuePayload;
  readonly initializeStripe?: Maybe<StripeSettings>;
  readonly season: SeasonMutations;
  readonly team: SeasonTeamMutations;
  readonly updateBasicInfo: Scalars['Boolean'];
};


export type MutationAddVenueArgs = {
  data: AddVenueInput;
};


export type MutationSeasonArgs = {
  id: Scalars['ID'];
};


export type MutationTeamArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateBasicInfoArgs = {
  name: Scalars['NonBlankString'];
};

export type Node = {
  readonly id: Scalars['ID'];
};


export type Official = {
  readonly __typename?: 'Official';
  readonly cellPhoneNumber?: Maybe<Scalars['NonBlankString']>;
  readonly displayName: Scalars['NonBlankString'];
  readonly id: Scalars['ID'];
};

export type Organization = {
  readonly __typename?: 'Organization';
  readonly email?: Maybe<Scalars['Email']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
  readonly officials: ReadonlyArray<OrganizationOfficial>;
  readonly seasons: ReadonlyArray<Season>;
  readonly settings: OrganizationSettings;
  readonly staffMembers: ReadonlyArray<StaffMember>;
  readonly venues: ReadonlyArray<Venue>;
  readonly website?: Maybe<Scalars['Url']>;
};

export type OrganizationApprovingEntity = {
  readonly __typename?: 'OrganizationApprovingEntity';
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
};

export type OrganizationGameEvent = {
  readonly __typename?: 'OrganizationGameEvent';
  readonly actor: OrganizationGraphQlGameSchedulingActor;
  readonly name: Scalars['String'];
  readonly timestamp: Scalars['DateTime'];
};

export type OrganizationGraphQlGameSchedulingActor = {
  readonly user: LeagueBrainUser;
};

export type OrganizationOfficial = {
  readonly __typename?: 'OrganizationOfficial';
  readonly displayName: Scalars['NonBlankString'];
  readonly id: Scalars['ID'];
};

export type OrganizationSettings = {
  readonly __typename?: 'OrganizationSettings';
  readonly stripe?: Maybe<StripeSettings>;
};

export enum OrganizationStaffMemberRole {
  Administrator = 'ADMINISTRATOR',
  OfficialAssignor = 'OFFICIAL_ASSIGNOR',
  Owner = 'OWNER'
}

export type Paid = RegistrationFeePaymentStatus & {
  readonly __typename?: 'Paid';
  readonly isPaid: Scalars['Boolean'];
  readonly paymentDetails: RegistrationFeePaymentDetails;
};

export type PendingGame = Game & GameSchedulingRow & {
  readonly __typename?: 'PendingGame';
  readonly approvalStatus: GameApprovalStatus;
  readonly assignedOfficials: ReadonlyArray<Official>;
  readonly awayTeam: SeasonTeam;
  readonly date: ZonedDateTimeParts;
  readonly eventHistory: ReadonlyArray<OrganizationGameEvent>;
  readonly fingerprint: Scalars['NonBlankString'];
  readonly homeTeam: SeasonTeam;
  readonly id: Scalars['ID'];
  readonly lastSchedulingUpdate: Scalars['DateTime'];
  readonly venue: Venue;
  readonly version: Scalars['Int'];
};

export type PendingGameApprovalStatusEntry = {
  readonly __typename?: 'PendingGameApprovalStatusEntry';
  readonly approvingEntity: ApprovingEntity;
  readonly status: ApprovalStatus;
};

export type ProposeGameSchedulingChangeDataInput = {
  readonly awayTeamId: Scalars['ID'];
  readonly date: ZonedDateTimePartsInput;
  readonly gameId: Scalars['ID'];
  readonly gameVersion?: Maybe<Scalars['Int']>;
  readonly homeTeamId: Scalars['ID'];
  readonly officials: ReadonlyArray<Scalars['ID']>;
  readonly venue: Scalars['ID'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly game?: Maybe<Game>;
  readonly ghvblSpecific: GhvblSpecific;
  readonly organization: Organization;
  readonly ping: Scalars['String'];
  readonly rateLimit: RateLimit;
  readonly season?: Maybe<Season>;
  readonly team?: Maybe<SeasonTeam>;
  readonly viewer: Viewer;
};


export type QueryGameArgs = {
  id: Scalars['ID'];
};


export type QuerySeasonArgs = {
  id: Scalars['ID'];
};


export type QueryTeamArgs = {
  id: Scalars['ID'];
};

export type RateLimit = {
  readonly __typename?: 'RateLimit';
  readonly cost: Scalars['Int'];
  readonly nodeCount: Scalars['Int'];
  readonly remaining: Scalars['Int'];
};

export type RegistrationFeePaymentDetails = {
  readonly notes?: Maybe<Scalars['NonBlankString']>;
};

export type RegistrationFeePaymentStatus = {
  readonly isPaid: Scalars['Boolean'];
};

export type RegistrationFeePaymentTypeInput = {
  readonly cash?: Maybe<CashInput>;
  readonly check?: Maybe<CheckInput>;
  readonly stripe?: Maybe<StripeInput>;
};

export type RegistrationForm = {
  readonly __typename?: 'RegistrationForm';
  readonly formDefinition: Scalars['String'];
  readonly isOpen: Scalars['Boolean'];
  readonly name: Scalars['NonBlankString'];
};

export type RegistrationFormQuestionResponse = {
  readonly __typename?: 'RegistrationFormQuestionResponse';
  readonly questionId: Scalars['String'];
  readonly value: Scalars['String'];
};

export type S3File = UniformResourceLocatable & {
  readonly __typename?: 'S3File';
  readonly id: Scalars['ID'];
  readonly metadata: S3FileMetadata;
  readonly url: Scalars['Url'];
};

export type S3FileMetadata = {
  readonly __typename?: 'S3FileMetadata';
  readonly fileId: Scalars['String'];
  readonly s3Key: Scalars['String'];
  readonly s3VersionId: Scalars['String'];
};

export type ScheduleGameDataInput = {
  readonly awayTeamId: Scalars['ID'];
  readonly date: ZonedDateTimePartsInput;
  readonly homeTeamId: Scalars['ID'];
  readonly officials: ReadonlyArray<Scalars['ID']>;
  readonly venue: Scalars['ID'];
};

export type Season = {
  readonly __typename?: 'Season';
  readonly divisions: ReadonlyArray<Division>;
  readonly ghostGames: ReadonlyArray<GameSchedulingRow>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
  readonly organization: Organization;
  readonly registrationForm?: Maybe<RegistrationForm>;
  readonly searchTeams: ReadonlyArray<SeasonTeam>;
  readonly sportType: SportType;
  readonly teamSegmentTable: Scalars['String'];
  readonly teams: ReadonlyArray<SeasonTeam>;
  readonly timeZone: Scalars['TimeZone'];
};


export type SeasonGhostGamesArgs = {
  data: GhostGamesQueryInput;
};


export type SeasonSearchTeamsArgs = {
  query: Scalars['String'];
};


export type SeasonTeamSegmentTableArgs = {
  segments: ReadonlyArray<Scalars['String']>;
  teamIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type SeasonMutations = {
  readonly __typename?: 'SeasonMutations';
  readonly games: GameMutations;
  readonly team: SeasonTeamMutations;
  readonly updateTeamSegmentValue: Scalars['Boolean'];
};


export type SeasonMutationsTeamArgs = {
  seasonTeamId: Scalars['ID'];
};


export type SeasonMutationsUpdateTeamSegmentValueArgs = {
  segment: Scalars['String'];
  teamId: Scalars['ID'];
  value: Scalars['String'];
};

export type SeasonTeam = {
  readonly __typename?: 'SeasonTeam';
  readonly confirmedGameCount: Scalars['Int'];
  readonly division: Division;
  readonly id: Scalars['ID'];
  readonly isCommittedToPlayoffs: Scalars['Boolean'];
  readonly name: Scalars['NonBlankString'];
  readonly orders: ReadonlyArray<SeasonTeamOrder>;
  readonly qualifiedName: Scalars['NonBlankString'];
  readonly registrationFeePaymentStatus: RegistrationFeePaymentStatus;
  readonly registrationResponses: ReadonlyArray<RegistrationFormQuestionResponse>;
  readonly requirementStatus: SeasonTeamRequirements;
};

export type SeasonTeamApprovingEntity = {
  readonly __typename?: 'SeasonTeamApprovingEntity';
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
};

export type SeasonTeamMutations = {
  readonly __typename?: 'SeasonTeamMutations';
  readonly markOrderAsPaid: MarkOrderAsPaidPayload;
  readonly updateDivision: UpdateSeasonTeamDivisionPayload;
  readonly updateName: UpdateSeasonTeamNamePayload;
  readonly updatePlayoffCommitmentStatus: UpdateSeasonTeamPlayoffCommitmentStatusPayload;
  readonly updateRegistrationPaymentStatus: UpdateSeasonTeamRegistrationFeePaymentPayload;
  readonly updateRegistrationQuestionResponse: UpdateRegistrationQuestionResponsePayload;
};


export type SeasonTeamMutationsMarkOrderAsPaidArgs = {
  data: MarkOrderAsPaidInput;
};


export type SeasonTeamMutationsUpdateDivisionArgs = {
  divisionId: Scalars['ID'];
};


export type SeasonTeamMutationsUpdateNameArgs = {
  name: Scalars['NonBlankString'];
};


export type SeasonTeamMutationsUpdatePlayoffCommitmentStatusArgs = {
  status: Scalars['Boolean'];
};


export type SeasonTeamMutationsUpdateRegistrationPaymentStatusArgs = {
  data: RegistrationFeePaymentTypeInput;
};


export type SeasonTeamMutationsUpdateRegistrationQuestionResponseArgs = {
  data: UpdateRegistrationQuestionResponseInput;
};

export type SeasonTeamOrder = {
  readonly __typename?: 'SeasonTeamOrder';
  readonly formattedTotal: Scalars['String'];
  readonly haveReceivedPayment: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly items: ReadonlyArray<LineItem>;
};

export type SeasonTeamOrderCashTypeInput = {
  readonly amountReceived: Scalars['Int'];
};

export type SeasonTeamOrderCheckTypeInput = {
  readonly checkId?: Maybe<Scalars['NonBlankString']>;
};

export type SeasonTeamOrderFeePaymentTypeInput = {
  readonly cash?: Maybe<SeasonTeamOrderCashTypeInput>;
  readonly check?: Maybe<SeasonTeamOrderCheckTypeInput>;
  readonly stripe?: Maybe<SeasonTeamOrderStripeTypeInput>;
};

export type SeasonTeamOrderStripeTypeInput = {
  readonly paymentIntentId: Scalars['NonBlankString'];
};

export type SeasonTeamRequirementOverview = {
  readonly __typename?: 'SeasonTeamRequirementOverview';
  readonly completedRequirements: Scalars['Int'];
  readonly hasCompletedAllRequirements: Scalars['Boolean'];
  readonly totalRequirements: Scalars['Int'];
};

export type SeasonTeamRequirements = {
  readonly __typename?: 'SeasonTeamRequirements';
  readonly overview: SeasonTeamRequirementOverview;
};

export type SpecificationPart = {
  readonly __typename?: 'SpecificationPart';
  readonly descriptor: DivisionDescriptor;
  readonly group: DivisionDescriptorGroup;
};

export enum SportType {
  Baseball = 'BASEBALL'
}

export type StaffMember = {
  readonly __typename?: 'StaffMember';
  readonly displayName: Scalars['String'];
  readonly role: OrganizationStaffMemberRole;
  readonly userId: Scalars['ID'];
};

export type StaticFile = UniformResourceLocatable & {
  readonly __typename?: 'StaticFile';
  readonly url: Scalars['Url'];
};

export type Stripe = RegistrationFeePaymentDetails & {
  readonly __typename?: 'Stripe';
  readonly notes?: Maybe<Scalars['NonBlankString']>;
  readonly paymentIntent: Scalars['NonBlankString'];
};

export type StripeInput = {
  readonly paymentIntentId: Scalars['NonBlankString'];
};

export type StripeOnboardStatus = {
  readonly isOnboarded: Scalars['Boolean'];
};

export type StripeSettings = {
  readonly __typename?: 'StripeSettings';
  readonly accountId: Scalars['String'];
  readonly onboardStatus: StripeOnboardStatus;
};

export type SubmitGameResultDataInput = {
  readonly gameId: Scalars['ID'];
  readonly gameVersion?: Maybe<Scalars['Int']>;
  readonly result: GameResultInput;
};


export type UniformResourceLocatable = {
  readonly url: Scalars['Url'];
};

export type Unpaid = RegistrationFeePaymentStatus & {
  readonly __typename?: 'Unpaid';
  readonly isPaid: Scalars['Boolean'];
};

export type UpdateOfficialAssignmentsInput = {
  readonly gameId: Scalars['ID'];
  readonly gameVersion?: Maybe<Scalars['Int']>;
  readonly officials: ReadonlyArray<Scalars['ID']>;
};

export type UpdateRegistrationQuestionResponseInput = {
  readonly questionId: Scalars['String'];
  readonly value: Scalars['NonBlankString'];
};

export type UpdateRegistrationQuestionResponsePayload = {
  readonly __typename?: 'UpdateRegistrationQuestionResponsePayload';
  readonly team: SeasonTeam;
};

export type UpdateSeasonTeamDivisionPayload = {
  readonly __typename?: 'UpdateSeasonTeamDivisionPayload';
  readonly team: SeasonTeam;
};

export type UpdateSeasonTeamNamePayload = {
  readonly __typename?: 'UpdateSeasonTeamNamePayload';
  readonly team: SeasonTeam;
};

export type UpdateSeasonTeamPlayoffCommitmentStatusPayload = {
  readonly __typename?: 'UpdateSeasonTeamPlayoffCommitmentStatusPayload';
  readonly team: SeasonTeam;
};

export type UpdateSeasonTeamRegistrationFeePaymentPayload = {
  readonly __typename?: 'UpdateSeasonTeamRegistrationFeePaymentPayload';
  readonly team?: Maybe<SeasonTeam>;
};


export type Venue = {
  readonly __typename?: 'Venue';
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
};

export type Viewer = {
  readonly __typename?: 'Viewer';
  readonly displayName: Scalars['NonBlankString'];
  readonly id: Scalars['ID'];
  readonly role: OrganizationStaffMemberRole;
};

export type ZonedDateTimeParts = {
  readonly __typename?: 'ZonedDateTimeParts';
  readonly timestamp: Scalars['LocalDateTime'];
  readonly timezone: Scalars['TimeZone'];
};

export type ZonedDateTimePartsInput = {
  readonly timestamp: Scalars['LocalDateTime'];
  readonly timezone: Scalars['TimeZone'];
};

export type SeasonViewingContextQueryVariables = Exact<{
  seasonId: Scalars['ID'];
}>;


export type SeasonViewingContextQuery = { readonly __typename?: 'Query', readonly viewer: { readonly __typename?: 'Viewer', readonly id: string, readonly displayName: any, readonly role: OrganizationStaffMemberRole }, readonly organization: { readonly __typename?: 'Organization', readonly id: string, readonly name: any }, readonly season?: Maybe<{ readonly __typename?: 'Season', readonly id: string, readonly name: any }> };

export type GetTeamTableSegmentsQueryVariables = Exact<{
  seasonId: Scalars['ID'];
  teamIds?: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
  segments: ReadonlyArray<Scalars['String']> | Scalars['String'];
}>;


export type GetTeamTableSegmentsQuery = { readonly __typename?: 'Query', readonly season?: Maybe<{ readonly __typename?: 'Season', readonly teamSegmentTable: string }> };

export type UpdateTeamSegmentMutationVariables = Exact<{
  seasonId: Scalars['ID'];
  teamId: Scalars['ID'];
  segment: Scalars['String'];
  value: Scalars['String'];
}>;


export type UpdateTeamSegmentMutation = { readonly __typename?: 'Mutation', readonly season: { readonly __typename?: 'SeasonMutations', readonly updateTeamSegmentValue: boolean } };

export type SearchTeamsInSeasonQueryVariables = Exact<{
  seasonId: Scalars['ID'];
  query: Scalars['String'];
}>;


export type SearchTeamsInSeasonQuery = { readonly __typename?: 'Query', readonly season?: Maybe<{ readonly __typename?: 'Season', readonly id: string, readonly searchTeams: ReadonlyArray<{ readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any, readonly division: { readonly __typename?: 'Division', readonly id: string, readonly name: any }, readonly registrationFeePaymentStatus: { readonly __typename?: 'Paid', readonly isPaid: boolean } | { readonly __typename?: 'Unpaid', readonly isPaid: boolean }, readonly requirementStatus: { readonly __typename?: 'SeasonTeamRequirements', readonly overview: { readonly __typename?: 'SeasonTeamRequirementOverview', readonly completedRequirements: number, readonly totalRequirements: number, readonly hasCompletedAllRequirements: boolean } } }> }> };

export type SeasonTeamByIdQueryVariables = Exact<{
  seasonTeamId: Scalars['ID'];
}>;


export type SeasonTeamByIdQuery = { readonly __typename?: 'Query', readonly team?: Maybe<{ readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any, readonly requirementStatus: { readonly __typename?: 'SeasonTeamRequirements', readonly overview: { readonly __typename?: 'SeasonTeamRequirementOverview', readonly completedRequirements: number, readonly totalRequirements: number, readonly hasCompletedAllRequirements: boolean } } }> };

export type AllSeasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllSeasonsQuery = { readonly __typename?: 'Query', readonly organization: { readonly __typename?: 'Organization', readonly id: string, readonly seasons: ReadonlyArray<{ readonly __typename?: 'Season', readonly id: string, readonly name: any }> } };


export const SeasonViewingContextDocument = gql`
    query SeasonViewingContext($seasonId: ID!) {
  viewer {
    id
    displayName
    role
  }
  organization {
    id
    name
  }
  season(id: $seasonId) {
    id
    name
  }
}
    `;

/**
 * __useSeasonViewingContextQuery__
 *
 * To run a query within a React component, call `useSeasonViewingContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonViewingContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonViewingContextQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useSeasonViewingContextQuery(baseOptions: Apollo.QueryHookOptions<SeasonViewingContextQuery, SeasonViewingContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonViewingContextQuery, SeasonViewingContextQueryVariables>(SeasonViewingContextDocument, options);
      }
export function useSeasonViewingContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonViewingContextQuery, SeasonViewingContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonViewingContextQuery, SeasonViewingContextQueryVariables>(SeasonViewingContextDocument, options);
        }
export type SeasonViewingContextQueryHookResult = ReturnType<typeof useSeasonViewingContextQuery>;
export type SeasonViewingContextLazyQueryHookResult = ReturnType<typeof useSeasonViewingContextLazyQuery>;
export type SeasonViewingContextQueryResult = Apollo.QueryResult<SeasonViewingContextQuery, SeasonViewingContextQueryVariables>;
export const GetTeamTableSegmentsDocument = gql`
    query GetTeamTableSegments($seasonId: ID!, $teamIds: [ID!], $segments: [String!]!) {
  season(id: $seasonId) {
    teamSegmentTable(segments: $segments, teamIds: $teamIds)
  }
}
    `;

/**
 * __useGetTeamTableSegmentsQuery__
 *
 * To run a query within a React component, call `useGetTeamTableSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamTableSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamTableSegmentsQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      teamIds: // value for 'teamIds'
 *      segments: // value for 'segments'
 *   },
 * });
 */
export function useGetTeamTableSegmentsQuery(baseOptions: Apollo.QueryHookOptions<GetTeamTableSegmentsQuery, GetTeamTableSegmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamTableSegmentsQuery, GetTeamTableSegmentsQueryVariables>(GetTeamTableSegmentsDocument, options);
      }
export function useGetTeamTableSegmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamTableSegmentsQuery, GetTeamTableSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamTableSegmentsQuery, GetTeamTableSegmentsQueryVariables>(GetTeamTableSegmentsDocument, options);
        }
export type GetTeamTableSegmentsQueryHookResult = ReturnType<typeof useGetTeamTableSegmentsQuery>;
export type GetTeamTableSegmentsLazyQueryHookResult = ReturnType<typeof useGetTeamTableSegmentsLazyQuery>;
export type GetTeamTableSegmentsQueryResult = Apollo.QueryResult<GetTeamTableSegmentsQuery, GetTeamTableSegmentsQueryVariables>;
export const UpdateTeamSegmentDocument = gql`
    mutation UpdateTeamSegment($seasonId: ID!, $teamId: ID!, $segment: String!, $value: String!) {
  season(id: $seasonId) {
    updateTeamSegmentValue(teamId: $teamId, segment: $segment, value: $value)
  }
}
    `;
export type UpdateTeamSegmentMutationFn = Apollo.MutationFunction<UpdateTeamSegmentMutation, UpdateTeamSegmentMutationVariables>;

/**
 * __useUpdateTeamSegmentMutation__
 *
 * To run a mutation, you first call `useUpdateTeamSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamSegmentMutation, { data, loading, error }] = useUpdateTeamSegmentMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      teamId: // value for 'teamId'
 *      segment: // value for 'segment'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateTeamSegmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamSegmentMutation, UpdateTeamSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamSegmentMutation, UpdateTeamSegmentMutationVariables>(UpdateTeamSegmentDocument, options);
      }
export type UpdateTeamSegmentMutationHookResult = ReturnType<typeof useUpdateTeamSegmentMutation>;
export type UpdateTeamSegmentMutationResult = Apollo.MutationResult<UpdateTeamSegmentMutation>;
export type UpdateTeamSegmentMutationOptions = Apollo.BaseMutationOptions<UpdateTeamSegmentMutation, UpdateTeamSegmentMutationVariables>;
export const SearchTeamsInSeasonDocument = gql`
    query SearchTeamsInSeason($seasonId: ID!, $query: String!) {
  season(id: $seasonId) {
    id
    searchTeams(query: $query) {
      id
      name
      division {
        id
        name
      }
      registrationFeePaymentStatus {
        isPaid
      }
      requirementStatus {
        overview {
          completedRequirements
          totalRequirements
          hasCompletedAllRequirements
        }
      }
    }
  }
}
    `;

/**
 * __useSearchTeamsInSeasonQuery__
 *
 * To run a query within a React component, call `useSearchTeamsInSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTeamsInSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTeamsInSeasonQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchTeamsInSeasonQuery(baseOptions: Apollo.QueryHookOptions<SearchTeamsInSeasonQuery, SearchTeamsInSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTeamsInSeasonQuery, SearchTeamsInSeasonQueryVariables>(SearchTeamsInSeasonDocument, options);
      }
export function useSearchTeamsInSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTeamsInSeasonQuery, SearchTeamsInSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTeamsInSeasonQuery, SearchTeamsInSeasonQueryVariables>(SearchTeamsInSeasonDocument, options);
        }
export type SearchTeamsInSeasonQueryHookResult = ReturnType<typeof useSearchTeamsInSeasonQuery>;
export type SearchTeamsInSeasonLazyQueryHookResult = ReturnType<typeof useSearchTeamsInSeasonLazyQuery>;
export type SearchTeamsInSeasonQueryResult = Apollo.QueryResult<SearchTeamsInSeasonQuery, SearchTeamsInSeasonQueryVariables>;
export const SeasonTeamByIdDocument = gql`
    query SeasonTeamById($seasonTeamId: ID!) {
  team(id: $seasonTeamId) {
    id
    name
    requirementStatus {
      overview {
        completedRequirements
        totalRequirements
        hasCompletedAllRequirements
      }
    }
  }
}
    `;

/**
 * __useSeasonTeamByIdQuery__
 *
 * To run a query within a React component, call `useSeasonTeamByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonTeamByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonTeamByIdQuery({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *   },
 * });
 */
export function useSeasonTeamByIdQuery(baseOptions: Apollo.QueryHookOptions<SeasonTeamByIdQuery, SeasonTeamByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonTeamByIdQuery, SeasonTeamByIdQueryVariables>(SeasonTeamByIdDocument, options);
      }
export function useSeasonTeamByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonTeamByIdQuery, SeasonTeamByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonTeamByIdQuery, SeasonTeamByIdQueryVariables>(SeasonTeamByIdDocument, options);
        }
export type SeasonTeamByIdQueryHookResult = ReturnType<typeof useSeasonTeamByIdQuery>;
export type SeasonTeamByIdLazyQueryHookResult = ReturnType<typeof useSeasonTeamByIdLazyQuery>;
export type SeasonTeamByIdQueryResult = Apollo.QueryResult<SeasonTeamByIdQuery, SeasonTeamByIdQueryVariables>;
export const AllSeasonsDocument = gql`
    query AllSeasons {
  organization {
    id
    seasons {
      id
      name
    }
  }
}
    `;

/**
 * __useAllSeasonsQuery__
 *
 * To run a query within a React component, call `useAllSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSeasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSeasonsQuery(baseOptions?: Apollo.QueryHookOptions<AllSeasonsQuery, AllSeasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSeasonsQuery, AllSeasonsQueryVariables>(AllSeasonsDocument, options);
      }
export function useAllSeasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSeasonsQuery, AllSeasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSeasonsQuery, AllSeasonsQueryVariables>(AllSeasonsDocument, options);
        }
export type AllSeasonsQueryHookResult = ReturnType<typeof useAllSeasonsQuery>;
export type AllSeasonsLazyQueryHookResult = ReturnType<typeof useAllSeasonsLazyQuery>;
export type AllSeasonsQueryResult = Apollo.QueryResult<AllSeasonsQuery, AllSeasonsQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Actor": [
      "LeagueBrainUser",
      "LeagueBrainUserProfile"
    ],
    "ApprovingEntity": [
      "OrganizationApprovingEntity",
      "SeasonTeamApprovingEntity"
    ],
    "Game": [
      "CanceledGame",
      "ConfirmedGame",
      "PendingGame"
    ],
    "GameResult": [
      "Decision",
      "Forfeit"
    ],
    "GameSchedulingRow": [
      "CanceledGame",
      "ConfirmedGame",
      "GhostGame",
      "PendingGame"
    ],
    "GraphQLOrganizationOrSeasonTeam": [
      "Organization",
      "SeasonTeam"
    ],
    "Node": [
      "LeagueBrainUser",
      "LeagueBrainUserProfile"
    ],
    "OrganizationGraphQLGameSchedulingActor": [
      "GameSchedulingActorOrganizationStaffMember",
      "GameSchedulingActorTeamStaffMember"
    ],
    "RegistrationFeePaymentDetails": [
      "Cash",
      "Check",
      "Stripe"
    ],
    "RegistrationFeePaymentStatus": [
      "Paid",
      "Unpaid"
    ],
    "StripeOnboardStatus": [
      "Completed",
      "Incomplete"
    ],
    "UniformResourceLocatable": [
      "S3File",
      "StaticFile"
    ]
  }
};
      export default result;
    