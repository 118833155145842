import React from "react";

export type UserContextType = {
  id: string
  firstName: string
  lastName: string
  displayName: string
  avatar: string | null
}

export const UserContext = React.createContext<UserContextType>({
  id: '',
  firstName: '',
  lastName: '',
  displayName: '',
  avatar: '',
})
