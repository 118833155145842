import React from 'react';
import {AppShell, Box, Group, Header, LoadingOverlay, Title, useMantineTheme} from "@mantine/core";
import {SeasonTeamGamesPage} from "./season-team";
import {useDidUpdate, useMediaQuery} from "@mantine/hooks";
import {TeamSelector} from "./season-team/TeamSelector";
import {useNavigate, useLocation, useParams, useMatch} from "react-router-dom";
import {Icon} from "../../../components/FontAwesomeIcon";
import {faTurnDownLeft} from "@fortawesome/pro-solid-svg-icons";
import {useSeasonByIdQuery} from "../graphql";

export function SeasonTeamHomePage() {

  const { seasonId, seasonTeamId } = useParams()
  const navigate = useNavigate()

  const theme = useMantineTheme();

  const matches = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);

  function onTeamChange(team: string | null) {
    if (team) {
      navigate({
        pathname: `/public/seasons/${seasonId}/teams/${team}`
      })
    } else {
      navigate({
        pathname: `/public/seasons/${seasonId}/teams`
      })
    }
  }

  const { loading, data } = useSeasonByIdQuery({
    variables: {
      seasonId: seasonId!!
    }
  })

  return (
    <AppShell
      // navbarOffsetBreakpoint controls when navbar should no longer be offset with padding-left
      navbarOffsetBreakpoint="sm"
      // fixed prop on AppShell will be automatically added to Header and Navbar
      fixed
      padding={0}
      /*
      navbar={
        <Navbar
          p="md"
          // Breakpoint at which navbar will be hidden if hidden prop is true
          hiddenBreakpoint="sm"
          // Hides navbar when viewport size is less than value specified in hiddenBreakpoint
          hidden={!opened}
          // when viewport size is less than theme.breakpoints.sm navbar width is 100%
          // viewport size > theme.breakpoints.sm – width is 300px
          width={{ sm: 300 }}
        >
          <Text>Application navbar</Text>
        </Navbar>
      }
       */
      header={
        <Header sx={{ backgroundColor: '#0F3F6B !important' }} height={matches ? 70 : 100} p="md">
          {/* Handle other responsive styles with MediaQuery component or createStyles function */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: matches ? 'row' : 'column-reverse',
              alignItems: 'center',
              justifyContent: matches ? 'space-between' : 'flex-start',
              gap: '8px'
            }}
          >
            <TeamSelector seasonId={seasonId!!} value={seasonTeamId || null} onChange={onTeamChange} />
            <Title sx={{ color: 'white' }} order={4}>{data?.season?.name} Team Schedules & Results</Title>
          </Box>

          {/*
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>

            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>

          </div>
          */}
        </Header>
      }
    >
      <LoadingOverlay visible={loading} />

      <Box
        style={{
          position: 'absolute',
          inset: 0,
          top: matches ? 70 : 100,
          left: 0, // matches ? '300px' : 0,
          padding: '12px',
          overflowY: 'auto'
        }}
      >
        {seasonTeamId === null &&
          <Group>
            <Icon icon={faTurnDownLeft} ml={'12px'} sx={{ transform: 'rotate(90deg)' }} />
            <Title order={2}>
              Select a team to view their schedule
            </Title>
          </Group>
        }

        {seasonTeamId && <SeasonTeamGamesPage seasonTeamId={seasonTeamId} />}
      </Box>
    </AppShell>
  )
}
