import {MantineSize, Slider} from "@mantine/core";
import React from "react";

export interface PaddingSelectorProps {
  onChange(padding: MantineSize): void
}

export function PaddingSelector(props: PaddingSelectorProps) {

  const [padding, setPadding] = React.useState(0);

  React.useEffect(() => {
    let p: MantineSize = 'md'

    switch (padding) {
      case 0:
        p = 'xs'
        break;
      case 25:
        p = 'sm'
        break;
      case 75:
        p = 'lg'
        break;
      case 100:
        p = 'xl'
        break;
      default:
        break;
    }

    props.onChange(p)
  }, [padding])

  return (
    <Slider
      sx={{ minWidth: '160px' }}
      value={padding}
      onChange={setPadding}
      step={25}
      size={'xs'}
      label={null}
      showLabelOnHover={false}
      styles={theme => ({
        bar: {
          backgroundColor: 'inherit'
        },
        markFilled: {
          border: `1px solid ${theme.colors.gray[2]}`
        }
      })}
      marks={[
        { value: 0, label: 'xs' },
        { value: 25, label: 'sm' },
        { value: 50, label: 'md' },
        { value: 75, label: 'lg' },
        { value: 100, label: 'xl' },
      ]}
    />
  )
}
