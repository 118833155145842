import React from 'react';
import {Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink} from "@chakra-ui/react";
import {Link as ReactRouterLink, Route, Routes, useLocation} from "react-router-dom";
import {Icon} from "../../../../components/FontAwesomeIcon";
import {faChevronRight} from "@fortawesome/pro-solid-svg-icons";
import {SeasonSettingsPage} from "./index";

export function SeasonSettingsRouter() {

  const { pathname: url } = useLocation()!!

  const isExact = useLocation()?.pathname === url

  if (isExact) {
    return <SeasonSettingsPage />
  }

  return (
    <Box>

      <Routes>
        <Route
          path={`season`}
          element={
            <>
              <SettingsBreadcrumbs title={'Season Details'} settingsPath={url} />
              Season Details
            </>
          }
        />

      </Routes>
    </Box>
  )
}

type SettingsBreadcrumbsProps = {
  title: string
  settingsPath: string
}

function SettingsBreadcrumbs(props: SettingsBreadcrumbsProps) {
  return (
    <Breadcrumb py={6} color={'gray.700'} spacing={2} separator={<Icon size={'sm'} icon={faChevronRight} color={'gray.500'} />}>
      <BreadcrumbItem fontWeight={'500'}>
        <BreadcrumbLink
          as={ReactRouterLink}
          to={props.settingsPath}
        >
          Settings
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink fontWeight={'semibold'} color={'purple.600'}>{props.title}</BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  )
}
