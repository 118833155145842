import React, {useRef} from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay, Box,
  Button,
  ButtonProps,
  Divider
} from '@chakra-ui/react';

export type ConfirmActionButtonProps = ButtonProps & {
  colorScheme?: string // 'primary' | 'danger' | 'success' | 'warning'
  title: string
  description: React.ReactNode
  confirmLabel?: string
  cancelLabel?: string
  onConfirm(): void
  onCancel?(): void
}

export const ConfirmActionButton = (props: React.PropsWithChildren<ConfirmActionButtonProps & any>) => {

  const {
    title,
    description,
    colorScheme,
    confirmLabel,
    cancelLabel,
    onConfirm,
    onCancel
  } = props

  const cancelRef = useRef(null)

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const onClose = () => setIsOpen(false)

  return (
    <>
      {
        // @ts-ignore
        React.cloneElement(props.children, {
          onClick: () => setIsOpen(true)
        })
      }

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <Divider />

            <AlertDialogBody>
              {description}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  if (onCancel) { onCancel() }
                  onClose()
                }}
              >
                {cancelLabel ?? 'Cancel'}
              </Button>
              <Button
                colorScheme={colorScheme}
                onClick={() => {
                  onConfirm()
                  onClose()
                }}
                ml={3}
              >
                {confirmLabel ?? 'Confirm'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
