import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, Center, Flex, Heading, Text, useColorModeValue, VStack} from "@chakra-ui/react";
import {
  AcceptTeamStaffInviteFragment,
  useAcceptTeamStaffMemberInviteMutation,
  useGetTeamStaffMemberInviteByIdQuery
} from "../../graphql";
import {useUserErrorToast} from "../../../../components/user-error-toast";

export function AcceptTeamInvitePage() {

  const { inviteId } = useParams()

  const { data } = useGetTeamStaffMemberInviteByIdQuery({
    variables: {
      inviteId: inviteId!!
    }
  })

  if (!data) {
    return <div />
  }

  const invite = data.teamStaffMemberInvite

  if (!invite) {
    return (
      <VStack py={8}>
        <Heading>Invalid Invite Link</Heading>

        <Text>
          This invite has either expired or, maybe, it never existed at all...
        </Text>
      </VStack>
    )
  }

  return (
    <AcceptInviteContent invite={invite} />
  )

}

interface AcceptInviteContentProps {
  invite: AcceptTeamStaffInviteFragment
}

function AcceptInviteContent(props: AcceptInviteContentProps) {

  const { invite } = props

  const navigate = useNavigate()

  const [acceptInviteMutation] = useAcceptTeamStaffMemberInviteMutation()

  const userErrorToast = useUserErrorToast()

  return (
    <Box p={4}>
      <Heading textAlign={'center'} fontWeight={'400'}>You have been invited to the</Heading>

      <Center py={8} my={4} bgColor={useColorModeValue('gray.100', 'gray.600')} borderRadius={'lg'}>
        <Heading fontWeight={'600'}>{invite.invitingTeam.name}</Heading>
      </Center>

      <Flex justify={'center'} mt={8}>
        <Button
          size={'lg'}
          colorScheme={'green'}
          onClick={() => {
            acceptInviteMutation({
              variables: {
                inviteId: invite.id
              }
            }).then((result) => {
              if (result.data?.acceptTeamStaffInvite.success) {
                navigate(`/u/teams/${invite.invitingTeam.id}`)
              } else {
                // userErrorToast(result.data?.acceptTeamStaffInvite.userError)
                userErrorToast({
                  type: "You are already on this team.",
                  description: "You can't join a team you are already on.",
                })
              }
            })
          }}
        >
          Accept Invite
        </Button>
      </Flex>
    </Box>
  )
}
