import {
  Avatar,
  Box,
  Flex,
  HStack,
  Link as ChakraLink,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useColorModeValue as mode,
  useMenuButton,
  UseMenuButtonProps,
  useToken,
} from '@chakra-ui/react'
import * as React from 'react'
import {useGetUserInfoQuery} from "../../../graphql";
import {useKeycloak} from "@react-keycloak/web";
import {Link, useLocation, useMatch, useRouteMatch} from "react-router-dom";
import {faCogs, faMoonStars, faSignOut, faSun} from "@fortawesome/pro-duotone-svg-icons";
import {faUser} from "@fortawesome/pro-regular-svg-icons";
import {Icon} from '../../../../../components/FontAwesomeIcon';

export const UserAvatar = (props: ProfileDropdownProps) => {

  const { data } = useGetUserInfoQuery()

  const [didImageLoadFail, setDidImageLoadFail] = React.useState<boolean>(false);

  const [gray100, gray700] = useToken(
    // the key within the theme, in this case `theme.colors`
    "colors",
    // the subkey(s), resolving to `theme.colors.red.100`
    ["gray.100", "gray.700"],
    // a single fallback or fallback array matching the length of the previous arg
  )

  const {
    size = 'md'
  } = props

  return (
    <Avatar
      size={size}
      name={(didImageLoadFail && !data?.viewer?.avatar) ? data?.viewer?.realName : undefined}
      src={data?.viewer?.avatar}
      border={(data?.viewer?.avatar === data?.viewer?.defaultAvatar) ? `4px solid ${mode(gray100, gray700)}` : undefined}
      bgColor={mode(gray100, gray700)}
      icon={<Icon icon={faUser} />}
      onError={() => setDidImageLoadFail(true)}
    />
  )
}

const ProfileMenuButton = (props: UseMenuButtonProps & ProfileDropdownProps) => {
  const {
    size = 'md'
  } = props

  const buttonProps = useMenuButton(props)
  return (
    <Flex
      {...buttonProps}
      as="button"
      flexShrink={0}
      rounded="full"
      outline="0"
      _focus={{ shadow: 'outline' }}
    >
      <Box srOnly>Open user menu</Box>
      <UserAvatar size={size} />
    </Flex>
  )
}

export type ProfileDropdownProps = {
  size?: string
}

export const ProfileDropdown = (props: ProfileDropdownProps) => {

  const {
    size = 'md'
  } = props

  const { keycloak } = useKeycloak()

  const { data } = useGetUserInfoQuery()

  let { pathname: url } = useLocation()!!;

  const { toggleColorMode } = useColorMode()
  const switchIcon = mode(faMoonStars, faSun)

  return (
    <Menu autoSelect={false}>
      <ProfileMenuButton size={size} />
      <MenuList rounded="md" shadow="lg" py="1" color={mode('gray.600', 'inherit')} fontSize="sm">
        <HStack px="3" py="4">
          <UserAvatar />
          <Box lineHeight="1">
            <Text fontWeight="semibold" maxW={'180px'} isTruncated>{data?.viewer?.realName}</Text>
            <Text mt="1" fontSize="xs" color="gray.500">
              {data?.viewer?.email}
            </Text>
          </Box>
        </HStack>

        {/*
        <MenuItem icon={<Icon icon={switchIcon} size={'lg'} />} fontWeight="medium" onClick={toggleColorMode}>
          Toggle Theme
        </MenuItem>
        */}

        {/*
        <MenuItem icon={<Icon icon={faCogs} size={'lg'} />} fontWeight="medium" as={Link} to={`${url}/profile`}>
          Account Settings
        </MenuItem>
        */}

        <MenuDivider />

        <MenuItem as={ChakraLink} href={keycloak?.createLogoutUrl()} fontWeight="medium" color={mode('red.500', 'red.300')} icon={<Icon size={'lg'} icon={faSignOut} />}>
          Sign out
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
