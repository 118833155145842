import React from "react";
import {
  Accordion,
  Box,
  Center,
  Divider,
  Group,
  MantineSize,
  MultiSelect,
  Paper,
  Progress,
  ScrollArea,
  Stack,
  Tabs,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
  Alert, Code, Highlight
} from "@mantine/core";
import {AppShellContentLayout} from "../../../../../components/app-shell/content";
import {Icon} from "../../../../../../../components/FontAwesomeIcon";
import {faFilter} from "@fortawesome/pro-solid-svg-icons";
import {faBadgeDollar, faDollarSign} from "@fortawesome/pro-duotone-svg-icons";
import {useVirtual} from "react-virtual";
import {generatePath, Link, useOutlet, useParams, useSearchParams} from "react-router-dom";
import {useDebouncedValue, useViewportSize} from "@mantine/hooks";
import {useSearchTeamsInSeasonQuery, useSeasonTeamByIdQuery} from "../../../../graphql";
import {PaddingSelector} from "./PaddingSelector";
import {SearchBar} from "./SearchBar";
import {ListViewWithDetail} from "../../../../../components/app-shell/content/ListViewWithDetail";

export interface TeamRequirementsPageProps {
  isSpecific?: boolean
}

export function TeamRequirementsPage(props: TeamRequirementsPageProps) {

  const isSpecific = useOutlet() !== null

  const { seasonId, seasonTeamId } = useParams()

  const theme = useMantineTheme()
  const [padding, setPadding] = React.useState<MantineSize>('xs');

  const [searchText, setSearchText] = React.useState('');
  const [debouncedSearchText] = useDebouncedValue(searchText, 200)

  const parentRef = React.useRef(null)

  const { width } = useViewportSize();

  const isSmallerScreen = width < theme.breakpoints['md'];

  const { loading, data } = useSearchTeamsInSeasonQuery({
    variables: {
      seasonId: seasonId!!,
      query: debouncedSearchText ?? ''
    },
  })

  const [searchHasFocus, setSearchHasFocus] = React.useState(false);


  const teamData = React.useMemo(() => (data?.season?.searchTeams ?? []).map(team => {
    return {
      id: team.id,
      name: team.name,
      division: team.division.name,
      hasPaid: team.registrationFeePaymentStatus.isPaid,
      progress: team.requirementStatus.overview,
    }
  }), [data])

  const rowVirtualizer = useVirtual({
    size: teamData.length,
    parentRef,
    estimateSize: React.useCallback(() => {
      return 40 + (theme.spacing[padding] * 2)
    }, [padding]),
  })

  return (
    <AppShellContentLayout.Static>

      <ListViewWithDetail
        header={
          <>
            <Title>All Teams</Title>
            <Divider mt={'xs'} mb={'lg'} />

            <Group mb={'xl'}>
              <SearchBar
                loading={loading}
                value={searchText}
                onChange={setSearchText}
                onFocus={() => setSearchHasFocus(true)}
                onBlur={() => setSearchHasFocus(false)}
              />

              <RequirementsFilter />
              <PaddingSelector onChange={setPadding} />
            </Group>
          </>
        }
        emptyContent={
          <Center sx={{ height: '100%' }}>
            <Text color={'dimmed'} size={'xl'}>
              Select a Team
            </Text>
          </Center>
        }
        list={
          <Paper<typeof ScrollArea>
            component={ScrollArea}
            viewportRef={parentRef}
            withBorder
            sx={{
              position: 'relative',
              height: '100%',
              minWidth: '320px',
              display: isSmallerScreen && isSpecific ? 'none' : undefined,
              width: isSmallerScreen ? '100%' : '25%',
              maxWidth: isSmallerScreen ? '100%' : '400px',
            }}
          >
            <Box
              sx={theme => ({
                height: `${rowVirtualizer.totalSize}px`,
                width: '100%',
                position: 'relative',
                '& > *': {
                  borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`
                },
              })}
            >
              {rowVirtualizer.virtualItems.map(virtualRow => {

                const data = teamData[virtualRow.index]

                const total = data.progress.totalRequirements

                const hasFulfilledAll = data.progress.hasCompletedAllRequirements

                return (
                  <Paper<typeof Link>
                    key={virtualRow.index}
                    component={Link}
                    to={generatePath('./:seasonTeamId', { seasonTeamId: data.id })}
                    replace
                    radius={0}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                      backgroundColor: seasonTeamId === data.id ? theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0] : undefined,
                      '&:hover': {
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0]
                      }
                    }}
                  >
                    <Group
                      p={padding}
                      pr={'lg'}
                      noWrap={true}
                      position={'apart'}
                      align={'center'}
                    >
                      <Stack spacing={0}>
                        <Text lineClamp={1} color={'dimmed'} size={'xs'} weight={'bold'}>{data.division}</Text>
                        <Highlight
                          highlight={searchHasFocus ? searchText : ''}
                          lineClamp={1}
                          sx={{ fontFamily: 'Greycliff CF' }}
                        >
                          {data.name}
                        </Highlight>
                      </Stack>

                      <Group pr={'xs'} noWrap>
                        <Stack spacing={0}>
                          <Tooltip label={`${data.progress.completedRequirements}/${total} Requirements`}>
                            <Progress
                              size={'lg'}
                              color={hasFulfilledAll ? 'green' : 'yellow'}
                              value={(data.progress.completedRequirements / total) * 100}
                              label={data.progress.completedRequirements < total ? getPercent(data.progress.completedRequirements, total) : undefined}
                              sx={{ width: '75px' }}
                            />
                          </Tooltip>
                        </Stack>

                        <Tooltip label={data.hasPaid ? 'Received registration fee' : 'Have not received registration fee'}>
                          <Icon
                            size={'lg'}
                            color={data.hasPaid ? 'green.500' : 'gray'}
                            icon={faBadgeDollar}
                            style={{ opacity: data.hasPaid ? 1 : 0.3 }}
                          />
                        </Tooltip>
                      </Group>
                    </Group>
                  </Paper>
                )
              })}
            </Box>
          </Paper>
        }
      />

    </AppShellContentLayout.Static>
  )
}

const tabs = [
  {
    title: 'Overview'
  },
  {
    title: 'Requirements'
  },
  {
    title: 'Payments'
  },
]

export function TeamRequirementPanel() {

  const { seasonTeamId } = useParams()

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = tabs.findIndex(tab => tab.title === searchParams.get('tab')) ?? 0

  const { data } = useSeasonTeamByIdQuery({
    variables: {
      seasonTeamId: seasonTeamId!!
    }
  })

  if (!data?.team) {
    return (
      <Center sx={{ height: '100%' }}>
        <Text size={'xl'} color={'dimmed'}>Select a Team</Text>
      </Center>
    )
  }

  return (
    <>
      <Title mb={'md'} order={2}>{data.team.name}</Title>

      <Tabs
        variant="outline"
        active={activeTab}
        onTabChange={(tabIdx, key) => {
          console.log(key)
          setSearchParams({
            tab: key ?? 'overview'
          }, {
            replace: true
          })
        }}
      >
        {tabs.map(tab => (
          <Tabs.Tab
            key={tab.title}
            label={tab.title}
            tabKey={tab.title}
          >
            <Stack spacing={'lg'}>
              <Paper>
                <Group position={'apart'} align={'center'}>
                  <Title order={3}>Requirements</Title>

                  <Text  size={'xl'} weight={'bold'}>
                    {data.team.requirementStatus.overview.completedRequirements} / {data.team.requirementStatus.overview.totalRequirements}
                  </Text>
                </Group>

                <Divider my={'sm'} sx={{ width: '100%' }} />

                <Alert title="Missing Requirements" color="yellow">
                  Missing <Code>Code of Conduct</Code>, <Code>Insurance Form</Code> +3 more.
                </Alert>


                <Accordion multiple>
                  <Accordion.Item label="Code of Conduct">
                    Colors, fonts, shadows and many other parts are customizable to fit your design needs
                  </Accordion.Item>

                  <Accordion.Item label="Insurance">
                    Configure components appearance and behavior with vast amount of settings or overwrite any part of component styles
                  </Accordion.Item>

                  <Accordion.Item label="Roster/Waiver">
                    With new :focus-visible pseudo-class focus ring appears only when user navigates with keyboard
                  </Accordion.Item>
                </Accordion>
              </Paper>

              <Paper>
                <Title order={3}>Orders</Title>
              </Paper>
            </Stack>
          </Tabs.Tab>
        ))}
      </Tabs>
    </>
  )
}

function getPercent(part: number, total: number): string {
  return `${((part / total) * 100).toFixed(0)}%`
}

function RequirementsFilter() {

  return (
    <MultiSelect
      placeholder="Filter requirements..."
      itemComponent={SelectItem}
      icon={<Icon icon={faFilter} />}
      radius={'xl'}
      clearable
      searchable
      sx={{
        minWidth: '320px'
      }}
      data={[
        { value: 'roster', label: 'Roster / Waiver', group: 'Documents' },
        { value: 'insurance', label: 'Insurance', group: 'Documents' },
        { value: 'coc', label: 'Code of Conduct', group: 'Agreements' },
      ]}
    />
  )
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  group: string;
}

const SelectItem = React.forwardRef<HTMLDivElement, ItemProps>(
  ({ label, group, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text>{label}</Text>
          <Text size="xs" color="dimmed">
            1/3
          </Text>
        </div>
      </Group>
    </div>
  )
);
