import React from "react";
import {TextInput} from "@mantine/core";
import {getHotkeyHandler} from "@mantine/hooks";
import {CellRenderProps} from "./CellRenderProps";
import {TableContext} from "../TableContext";

export const EditableNumberCell = React.forwardRef((props: CellRenderProps<any>, ref) => {

  const {
    value: initialValue,
    row: {index, original: {id: rowId}},
    column: {id},
    updateMyData, // This is a custom function that we supplied to our table instance
    ...rest
  } = props

  const tableContext = React.useContext(TableContext)

  const inputRef = React.useRef(null)

  React.useEffect(() => {
    inputRef?.current?.focus()
  }, [inputRef])

  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = (e: any) => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    // updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])


  return (
    <TextInput
      ref={inputRef}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      variant={'unstyled'}
      size={'md'}
      onKeyDown={getHotkeyHandler([
        ['Enter', () => {
          updateMyData(rowId, id, value)
          tableContext.endEditing()
        }],
        ['Escape', () => {
          tableContext.endEditing()
        }],
      ])}
    />
  )
})
