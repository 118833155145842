import React from 'react';
import {Box, Center, Flex, IconButton, Portal, Text, useDisclosure, VStack} from "@chakra-ui/react";
import {faPlus} from "@fortawesome/pro-solid-svg-icons";
import {Icon} from "../FontAwesomeIcon";

const MobileActionMenuContext = React.createContext(() => {})

export interface MobileActionMenuProps {
  icon?: React.ReactElement
  actions?: ActionMenuItemProps[]
}

export function MobileActionMenu(props: MobileActionMenuProps) {

  const {
    icon = <Icon icon={faPlus} />,
    actions = []
  } = props

  const { isOpen, onToggle, onClose } = useDisclosure()

  return (
    <MobileActionMenuContext.Provider value={onClose}>
      <Portal>
      <Box position={'relative'} display={{ base: 'block', md: 'none' }} zIndex={10}>
        <Center
          position={'fixed'}
          bottom={'25px'}
          right={'25px'}
          w={'55px'}
          h={'55px'}
          borderRadius={'50%'}
          bgColor={'blue.400'}
          zIndex={2}
        >
          <IconButton
            aria-label={'Toggle Menu'}
            onClick={onToggle}
            size={'lg'}
            variant={'unstyled'}
            color={'white'}
            transform={!isOpen ? 'none' : 'rotate(45deg)'}
            transition={'transform 50ms ease-in'}
            icon={icon}
          />
        </Center>

        <Box
          display={isOpen ? 'block' : 'none'}
          position={'fixed'}
          top={0}
          right={0}
          bottom={0}
          left={0}
          bgColor={'gray.800'}
          opacity={0.7}

        />

        <Box
          display={isOpen ? 'block' : 'none'}
          position={'fixed'}
          top={0}
          right={0}
          bottom={0}
          left={0}
          zIndex={101}
          pb={'60px'}
        >
          <Box
            position={'fixed'}
            top={0}
            right={0}
            bottom={0}
            left={0}
            onClick={onToggle}
          />

          <VStack
            h={'full'}
            flexDirection={'column-reverse'}
            align={'flex-end'}
            spacing={4}
            pr={'34px'}
          >
            <Box
              // Needed due to bug in chakra - spacing issue
              opacity={0}
              w={'100px'}
              h={'40px'}
            />

            {actions.map((action, idx) => <ActionMenuItem key={idx} {...action} />)}

          </VStack>
        </Box>
      </Box>
      </Portal>
    </MobileActionMenuContext.Provider>
  )
}

export interface ActionMenuItemProps {
  name?: string
  icon?: React.ReactElement
  onSelect?(): void
}

function ActionMenuItem(props: ActionMenuItemProps) {

  const {
    name = 'Action Item...',
    icon,
    onSelect = () => {}
  } = props

  const actionMenuContext = React.useContext(MobileActionMenuContext)

  return (
    <Flex
      h={'40px'}
      direction={'row-reverse'}
      align={'center'}
      color={'white'}
    >
      <IconButton
        aria-label={name}
        icon={icon}
        variant={'unstyled'}
        onClick={() => {
          actionMenuContext()
          setTimeout(onSelect, 75)
        }}

        w={'38px'}
        h={'38px'}
        ml={4}
        borderRadius={'50%'}
        bgColor={'blue.400'}
      />

      <Center
        bgColor={'gray.500'}
        color={'white'}
        fontWeight={'semibold'}
        px={2}
        py={1}
        borderRadius={'md'}
      >
        <Text fontSize={'sm'}>
          {name}
        </Text>
      </Center>

    </Flex>
  )
}
