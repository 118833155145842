import React, {ReactElement} from 'react';
import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react';
import {Icon} from "../FontAwesomeIcon";
import {faEllipsisH} from "@fortawesome/pro-solid-svg-icons";
import {ConfirmActionButton, ConfirmActionButtonProps} from "../confirm-action-prompt";
import {IconDefinition} from "@fortawesome/pro-duotone-svg-icons";

export interface CollapsableActionItemMenuProps {
  isCollapsed?: boolean
  breadcrumbButtonVariant?: 'solid' | 'outline' | 'ghost'
  collapseBreakpoint?: string
}

export function CollapsableActionItemMenu(props: React.PropsWithChildren<CollapsableActionItemMenuProps>) {

  const {
    isCollapsed,
    breadcrumbButtonVariant = 'solid',
    collapseBreakpoint = 'md'
  } = props

  const shouldBeCollapsed = useBreakpointValue({ base: true, [collapseBreakpoint]: isCollapsed !== undefined ? isCollapsed : false })

  const stackBackgroundColor = useColorModeValue('gray.50', 'gray.700')
  const stackBorderColor = useColorModeValue('#efefff', 'gray.800')

  if (React.Children.toArray(props.children).length === 0) {
    return <div />
  }

  return (
    <>
      {shouldBeCollapsed &&
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label={'More Actions'}
            icon={<Icon icon={faEllipsisH}/>}
            variant={breadcrumbButtonVariant}
            borderRadius={'full'}
          />

          <MenuList>
            {React.Children.map(props.children, (node: ReactElement<ActionItemProps>) => (
              <>
                {node.props.confirmActionButtonProps ? (
                  <ConfirmActionButton {...node.props.confirmActionButtonProps} onConfirm={node.props.onClick}>
                    <MenuItem
                      aria-label={node.props.label}
                      icon={<Icon color={node.props.colorScheme} icon={node.props.icon} />}
                    >
                      {node.props.label}
                    </MenuItem>
                  </ConfirmActionButton>
                ) : (
                  <MenuItem
                    aria-label={node.props.label}
                    icon={<Icon color={node.props.colorScheme} icon={node.props.icon} />}
                    onClick={node.props.onClick}
                  >
                    {node.props.label}
                  </MenuItem>
                )}
              </>
            ))}
          </MenuList>
        </Menu>
      }

      {!shouldBeCollapsed &&
        <HStack
          bgColor={stackBackgroundColor}
          spacing={1}
          border={'1px solid'}
          borderColor={stackBorderColor}
          borderRadius={'full'}
          p={'2px'}
          my={'auto'}
        >
          {React.Children.map(props.children, (node: ReactElement<ActionItemProps>) => (
            <>
            {node.props.confirmActionButtonProps ? (
              <ConfirmActionButton {...node.props.confirmActionButtonProps} onConfirm={node.props.onClick}>
                <IconButton
                  aria-label={node.props.label}
                  colorScheme={node.props.colorScheme}
                  borderRadius={'full'}
                  variant={'ghost'}
                  icon={<Icon icon={node.props.icon} />}
                />
              </ConfirmActionButton>
            ) : (
              <Tooltip placement={'top-end'} label={node.props.label}>
                <IconButton
                  aria-label={node.props.label}
                  borderRadius={'full'}
                  colorScheme={node.props.colorScheme}
                  icon={<Icon icon={node.props.icon} />}
                  variant={'ghost'}
                  onClick={node.props.onClick}
                />
              </Tooltip>
            )}
            </>
          ))}
        </HStack>
      }
    </>
  )
}

export interface ActionItemProps {
  label: string
  colorScheme?: string
  icon: IconDefinition
  onClick(): void,
  confirmActionButtonProps?: Omit<ConfirmActionButtonProps, 'onConfirm'>
}

export function ActionItem(props: React.PropsWithChildren<ActionItemProps>) {

  const {
    label,
    icon,
    onClick,
    confirmActionButtonProps
  } = props

  return <></>
}
