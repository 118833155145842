import {
  Avatar,
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  IconButton,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue as mode,
  useToken,
} from '@chakra-ui/react'
import {Title, Tooltip as MantineTooltip} from '@mantine/core';
import * as React from 'react'
import {Icon} from "../../../components/FontAwesomeIcon";
import {faBaseball, faEllipsisH, faPlus} from "@fortawesome/pro-regular-svg-icons";
import {Link as ReactRouterLink, useMatch, useNavigate} from 'react-router-dom';
import {useAllTeamsQuery, useDeleteTeamMutation} from "../graphql";
import {MobileActionMenu} from "../../../components/mobile-action-menu";
import {faCrown, faExclamationSquare, faHome, faTrashAlt} from "@fortawesome/pro-solid-svg-icons";
import {EntityListRow} from "../../../components/entity-list-page";
import {areEqual, FixedSizeList as List} from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import {useModals} from "@mantine/modals";
import {useNotifications} from '@mantine/notifications';

export const TeamListHeader = () => {

  const [gray100, gray700] = useToken(
    // the key within the theme, in this case `theme.colors`
    "colors",
    // the subkey(s), resolving to `theme.colors.red.100`
    ["gray.100", "gray.700"],
    // a single fallback or fallback array matching the length of the previous arg
  )

  const { loading, data, fetchMore } = useAllTeamsQuery()

  const navigate = useNavigate()

  const nodes = data?.teams?.edges.map((edge) => edge.node) ?? [];
  const pageInfo = data?.teams?.pageInfo ?? { hasNextPage: false, endCursor: "" };

  return (
    <Tabs isFitted h={'full'} display={'flex'} flexDirection={'column'}>
      <MobileActionMenu
        actions={[
          {
            name: 'Create Team',
            icon: <Icon icon={faPlus} />,
            onSelect() { navigate('/u/teams/create') }
          },
          {
            name: 'Home Page',
            icon: <Icon icon={faHome} />,
            onSelect() { navigate('/u/home') }
          }
        ]}
      />

      <Box bg={mode('gray.50', 'gray.800')} px="8" pt="8" borderBottom={`2px solid ${mode(gray100, gray700)}`}>
        <Flex
          direction={{ base: 'column', md: 'column' }}
          justify="space-between"
          align="flex-start"
          mb={{base: '4', md: '6'}}
        >
          <HStack mb={'0'}>
            <Heading size="lg">My Teams</Heading>
            <Text color={mode('gray.500', 'gray.300')} fontSize="sm">
              ({data?.teams?.totalCount ?? '~'} Teams)
            </Text>
          </HStack>

          <Text size={'sm'} mt={1}><i>Teams you are affiliated with</i></Text>

          <HStack display={{ base: 'none', md: 'flex' }} spacing={{ base: '2', md: '4' }}>
            {/*
            <Button as={ReactRouterLink} to={'/u/teams/create'} colorScheme="blue" leftIcon={<Icon icon={faPlus} />} fontSize="sm">
              Create Team
            </Button>
            */}
          </HStack>
        </Flex>
      </Box>

      <TabPanels flexGrow={1}>
        <TabPanel h={'full'} w={'full'} p={0}>
          <LoadableTeamList
            items={nodes}
            onLoadMore={() => {
              if (pageInfo.hasNextPage) {
                fetchMore({
                  variables: {
                    cursor: pageInfo.endCursor,
                  }
                });
              }
            }}
            hasNextPage={pageInfo.hasNextPage}
            isNextPageLoading={loading}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

interface LoadableTeamListProps {
  items: any[]
  onLoadMore(): void
  hasNextPage: boolean
  isNextPageLoading: boolean
}

function LoadableTeamList(props: LoadableTeamListProps) {

  const {
    items,
    onLoadMore,
    hasNextPage,
    isNextPageLoading
  } = props;

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : onLoadMore;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  const { pathname: url } = useMatch()

  const Row = React.memo(({ index, style } : { index: number, style: any }) => {
    if (!isItemLoaded(index)) {
      return (
        <Center style={style} align={'center'}>
          <Spinner />
        </Center>
      );
    }

    const team = items[index]
    return (
      <Flex style={style} align={'center'} w={'full'} p={4}>
        <LinkBox as={Flex} align={'center'} w={'full'}>
          <Avatar icon={<Icon icon={faBaseball}/>} src={team.avatar || undefined} mx={4} size={'md'} ml={0} bg={mode('gray.100', 'gray.300')}/>

          <Box flexGrow={1} overflow={'hidden'}>
            <LinkOverlay as={ReactRouterLink} to={`${url}/${team.id}`}>
              <Text isTruncated fontWeight={'600'} fontSize={'sm'}>{team.name}</Text>
            </LinkOverlay>
            <Text isTruncated color={mode('gray.500', 'gray.300')} fontSize={'sm'}>{team.description}</Text>
          </Box>
        </LinkBox>

        {team.isOwner && !team.isRegisteredForAtLeastOneSeason &&
          <DeleteTeamIcon teamId={team.id} teamName={team.name} />
        }

        {team.isOwner &&
        <Tooltip placement={'top-end'} label={'You own this team'}>
          <Icon ml={4} color={'yellow.400'} icon={faCrown} />
        </Tooltip>
        }
      </Flex>
    );
  }, areEqual);


  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <List
              height={height}
              width={width}
              onItemsRendered={onItemsRendered}
              ref={ref}
              itemCount={itemCount}
              itemSize={70}
            >
              {Row}
            </List>
          )}
        </InfiniteLoader>
    )}
    </AutoSizer>
  )
}

export interface TeamRowProps {
  team: any
}

export function TeamRow(props: TeamRowProps) {

  const { team } = props

  const { pathname: url } = useMatch()

  return (
    <EntityListRow
      to={team.id}
      menu={
        <Menu placement={'left-start'} isLazy>
          <MenuButton
            as={IconButton}
            aria-label={'more-options'}
            icon={<Icon icon={faEllipsisH} />}
            variant={'ghost'}
            borderRadius={'md'}
          />

          <MenuList>
            <MenuItem as={ReactRouterLink} to={`${url}/${team.id}/edit`}>Edit</MenuItem>
            <MenuItem>View Roster</MenuItem>
          </MenuList>
        </Menu>
      }
    >
      <Avatar icon={<Icon icon={faBaseball} />} src={team.avatar?.url || undefined} mx={4} size={'md'} ml={0} bg={mode('gray.100', 'gray.300')} />

      <Box flexGrow={1} overflow={'hidden'}>
        <Text isTruncated fontWeight={'600'} fontSize={'sm'}>{team.name}</Text>
        <Text isTruncated color={mode('gray.500', 'gray.300')} fontSize={'sm'}>{team.description}</Text>
      </Box>
    </EntityListRow>
  )
}

interface DeleteTeamIconProps {
  teamName: string
  teamId: string
}

function DeleteTeamIcon(props: DeleteTeamIconProps) {

  const {
    teamId,
    teamName
  } = props;

  const modals = useModals();
  const notifications = useNotifications();

  const [deleteTeamMutation] = useDeleteTeamMutation({
    variables: {
      teamId: teamId
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          teams(existingTeams = {}, { readField }) {
            return {
              ...existingTeams,
              totalCount: existingTeams.totalCount - 1,
              edges: [...existingTeams.edges.filter((edge: any) => readField('id', readField('node', edge)) !== data?.deleteTeam?.team?.id)]
            }
          }
        }
      });
    }
  })

  const openConfirmDeleteModal = () => modals.openConfirmModal({
    title: <Title order={3}>Delete team</Title>,
    children: (
      <Text size="sm">
        Are you sure you want to delete the <b>{teamName}</b>? This action cannot be undone, but you can always recreate the team if you need it again.
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: "Cancel" },
    confirmProps: { color: 'red' },
    onCancel: () => {},
    onConfirm: () => {
      const id = notifications.showNotification({
        title: 'Deleting Team...',
        message: <span>We are deleting the <i>{teamName}</i> for you.</span>,
        loading: true,
        color: 'red',
      })

      let tid: any = 0;
      tid = setTimeout(() => { tid = 0 }, 1000);

      deleteTeamMutation()
        .then(() => {
          setTimeout(() => {
            notifications.updateNotification(id, {
              id,
              color: 'red',
              title: 'Team Deleted',
              message:
                <span>The <i>{teamName}</i> team has been deleted.</span>,
              icon: <Icon icon={faTrashAlt} />,
              autoClose: 4000
            })
          }, (tid === 0) ? 0 : 1000)
        })
    }
  });

  return (
    <MantineTooltip
      closeDelay={500}
      position={'top'}
      placement={'end'}
      allowPointerEvents={true}
      width={220}
      wrapLines
      label={
        <span>This team is not registered for any seasons.{' '}
          <a onClick={openConfirmDeleteModal} style={{ borderBottom: '1px dotted', cursor: 'default', userSelect: 'none' }}>Delete this team</a>
        </span>
      }
    >
      <Icon color={'red.500'} icon={faExclamationSquare} />
    </MantineTooltip>
  )
}
