import React from 'react';
import {Link as ReactRouterLink, useLocation} from "react-router-dom";
import {RegistrationFormAvailableTeamEdgeFragment, SimpleDivisionDescriptionFragment} from "../pages/user/graphql";
import {Controller, useFormContext} from "react-hook-form";
import _ from 'lodash';
import {Anchor, Box, Group, Select, Text} from "@mantine/core";


type CommonRegistrationQuestionsProps = {
  orgId: string,
  seasonId: string,
  divisions: SimpleDivisionDescriptionFragment[],
  teams: RegistrationFormAvailableTeamEdgeFragment[]
}

export function CommonRegistrationQuestions(props: CommonRegistrationQuestionsProps) {

  const {
    orgId,
    seasonId,
    divisions,
    teams
  } = props

  const methods = useFormContext();
  const { search } = useLocation()

  React.useEffect(() => {
    const params = new URLSearchParams(search)
    if (params.get('team')) {
      const newValue = teams.map(team => {
        return {
          id: team.node.id,
          label: team.node.name,
          avatar: team.node.avatar.url,
          isDisabled: !team.isEligible
        }
      }).find(item => item.id === params.get('team'))

      if (newValue && !newValue.isDisabled) {
        methods.setValue('common.team', newValue)
      }
    }
  }, [search, JSON.stringify(teams)])

  const teamOptions = React.useMemo(() => {
    return teams.map(team => {
      return {
        value: team.node.id,
        label: team.node.name,
        avatar: team.node.avatar.url,
        disabled: !team.isEligible
      }
    })
  }, [teams])

  return (
    <>
      <Controller
        key={'teams'}
        name="common.team"
        control={methods.control}
        rules={{
          required: true
        }}
        render={({ field }) => (
          <Select
            {...field}
            value={field.value?.id}
            onChange={(v) => {
              field.onChange(teamOptions.find(t => t.value === v))
            }}
            required
            styles={{
              label: {
                width: '100%'
              },
              required: {
                display: 'none'
              }
            }}
            label={
              <Group position={'apart'} noWrap>
                <Text>Select a Team <span style={{ color: '#E03131' }}>*</span></Text>

                <Anchor
                  component={ReactRouterLink}
                  size={'sm'}
                  to={`/u/teams/create?r=${`/u/org/${orgId}/season/${seasonId}&s=teamId`}`}
                >
                  Create a new team
                </Anchor>
              </Group>
            }
            data={teamOptions}
          />
        )}
      />

      <Controller
        key={'divisions'}
        render={({ field }) => (
          <DivisionSelector
            {...field}
            divisions={divisions}
          />
        )}
        name="common.division"
        control={methods.control}
        defaultValue={null}
        rules={{
          required: true
        }}
      />
    </>
  )
}

type DivisionSelectorProps = {
  divisions: SimpleDivisionDescriptionFragment[]
}

function checker<T>(arr: T[], target: T[]): boolean {
  if (target.length === 0) return true
  return target.every(v => arr.includes(v));
}

type ListOption = {
  id: string
  value: string
  label: string
}

function createList(divisions: SimpleDivisionDescriptionFragment[], prevDescriptorIds: string[] = []): ListOption[] {
  const validDivisions: SimpleDivisionDescriptionFragment[] = []

  for (let i = 0; i < divisions.length; i++) {
    const currDivision = divisions[i]

    if (checker(currDivision.specification.map(g => g.descriptor.id), prevDescriptorIds)) {
      validDivisions.push(currDivision)
    }
  }

  return _.uniqBy(validDivisions.map(division => {
    const descriptor = division.specification[prevDescriptorIds.length].descriptor

    return {
      id: descriptor.id,
      value: descriptor.id,
      label: descriptor.name,
      description: descriptor.description,
      _label: (
        <p>
          {descriptor.name} <span
          style={{color: '#495057'}}>{descriptor.description ? ` - ${descriptor.description}` : ''}</span>
        </p>
      )
    }
  }), 'id')
}


interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  description: string;
}

const SelectItem = React.forwardRef<HTMLDivElement, ItemProps>(
  ({ label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <p>
        {label} <span style={{color: '#495057'}}>{description ? ` - ${description}` : ''}</span>
      </p>
    </div>
  )
);

const DivisionSelector = React.forwardRef((props: DivisionSelectorProps, ref:any) => {

  const {
    divisions
  } = props

  const methods = useFormContext();

  const [selectedDescriptors, setSelectedDescriptors] = React.useState<string[]>([]);

  const runningSelections: string[][] = selectedDescriptors.reduce((acc: string[][], curr: string, idx: number) => {

    if (idx === 0) {
      acc.push([])
      return acc
    }

    if (acc[acc.length-1]) {
      acc.push([...acc[acc.length-1], curr])
    } else {
      acc.push([curr])
    }
    return acc
  }, [])

  // console.log(runningSelections)

  return (
    <>
        {runningSelections.map(rs => {
          return (
            <Box>

              <Select
                key={JSON.stringify(rs)}
                required
                label={selectedDescriptors.length > 0 ? (
                  <Group position={'apart'}>
                    <Box>Select a {divisions[0].specification[rs.length].group.name}<span style={{ color: '#E03131' }}>*</span></Box>
                    <Anchor
                      size={'sm'}
                      target={'_blank'}
                      href={'https://www.ghvbl.com/division-structure/'}
                    >
                      How do I choose a division?
                    </Anchor>
                  </Group>
                ) : (
                  <>Select a {divisions[0].specification[rs.length].group.name}<span style={{ color: '#E03131' }}>*</span></>
                )}
                styles={{
                  label: {
                    width: '100%'
                  },
                  required: {
                    display: 'none'
                  }
                }}
                defaultValue={createList(divisions, rs).find(e => e.id === selectedDescriptors[rs.length])!!.id}
                data={createList(divisions, rs)}
                itemComponent={SelectItem}
                onChange={(e: any) => {
                  setSelectedDescriptors([...rs, e])
                }}
              />
            </Box>
          )
        })}

        <Select
          ref={ref}
          required
          styles={{
            label: {
              width: '100%'
            },
            required: {
              display: 'none'
            }
          }}
          key={'current' + JSON.stringify(selectedDescriptors)}
          label={selectedDescriptors.length !== 0 ? (
            <>Select a {divisions[0].specification[selectedDescriptors.length].group.name}<span style={{ color: '#E03131' }}>*</span></>
          ) : (
            <Group position={'apart'}>
              <Box>Select a {divisions[0].specification[selectedDescriptors.length].group.name}<span style={{ color: '#E03131' }}>*</span></Box>
              <Anchor
                size={'sm'}
                target={'_blank'}
                href={'https://www.ghvbl.com/division-structure/'}
              >
                How do I choose a division?
              </Anchor>
            </Group>
          )}
          data={createList(divisions, selectedDescriptors)}
          itemComponent={SelectItem}
          onChange={(e: any) => {
            if (selectedDescriptors.length === divisions[0].specification.length - 1) {
              const division = divisions.find(d => checker(d.specification.map(s => s.descriptor.id), [...selectedDescriptors, e]))
              methods.setValue("common.division", division)
            } else {
              setSelectedDescriptors([...selectedDescriptors, e])
            }
          }}
        />
    </>
  )
})
