import {HStack, Link, LinkProps, Text, useColorModeValue as mode} from '@chakra-ui/react'
import * as React from 'react'
import {IconDefinition} from "@fortawesome/pro-duotone-svg-icons";
import {Icon} from "../components/FontAwesomeIcon";
import {Link as ReactRouterLink, useLocation} from "react-router-dom";

interface NavLinkProps extends LinkProps {
  path?: string
  label: string
  icon: IconDefinition
}

export const NavLink = (props: NavLinkProps) => {
  const { icon, label, path, ...rest } = props

  const location = useLocation()

  const isActive = path && location.pathname?.endsWith(path)

  return (
    <Link
      as={path ? ReactRouterLink : Link}
      to={path}
      display="block"
      py="2"
      px="3"
      borderRadius="md"
      transition="all 0.3s"
      fontWeight="medium"
      fontSize="sm"
      userSelect="none"
      aria-current={isActive ? 'page' : undefined}
      color={mode({ base: 'gray.700', md: 'gray.200' }, 'gray.200')}
      _hover={{
        bg: mode({ base: 'gray.100', md: '#12497D' }, 'gray.700'),
        color: mode({ base: 'gray.700', md: 'gray.100' }, 'gray.100'),
      }}
      _activeLink={{
        bg: mode({ base: 'gray.200', md: '#868E96' }, 'gray.600'),
        color: 'inherit',
      }}
      {...rest}
      textDecoration={'none !important'}
    >
      <HStack spacing="4">
        <Icon icon={icon} fontSize="md" className={'fa-fw'} />
        <Text fontWeight={'500'} color={'inherit'} as="span">{label}</Text>
      </HStack>
    </Link>
  )
}
