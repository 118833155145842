import React from 'react';
import {useAllSeasonsQuery} from "../../graphql";
import {Box} from "@chakra-ui/react";

export interface SeasonsPageProps {

}

export function SeasonsPage(props: SeasonsPageProps) {

  const {} = props

  const { data } = useAllSeasonsQuery()

  if (!data) return <div />

  return (
    <Box>
      {data.organization.seasons.map(season => {
        return (
          <Box>
            {season.name}
          </Box>
        )
      })}
    </Box>
  )
}
