import React from 'react';
import {Game, useGamesForTeamQuery} from "../../graphql";
import {Box, Group, Paper, Text, Title} from "@mantine/core";
import {DateTime} from "luxon";
import {useElementSize} from "@mantine/hooks";
import {Icon} from "../../../../components/FontAwesomeIcon";
import {faLocationDot} from "@fortawesome/pro-duotone-svg-icons";

export type SeasonTeamGamesPageProps = {
  seasonTeamId: string
}

export function SeasonTeamGamesPage(props: SeasonTeamGamesPageProps) {

  const { seasonTeamId } = props;

  const { data } = useGamesForTeamQuery({
    variables: {
      seasonTeamId: seasonTeamId
    }
  })

  const { ref, width } = useElementSize()

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: '8px'
      }}
    >
      {data?.gamesForTeam?.length === 0 &&
        <Title align={'center'}>This Team Has No Games Scheduled</Title>
      }
      {(data?.gamesForTeam ?? []).map(game => {
        return (
          <Paper key={game.id} p={'sm'} withBorder>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: width > 900 ? 'row' : 'column',
                gap: width > 900 ? '16px' : '0',
                flexWrap: 'nowrap',
              }}
            >
              {width > 900 ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexBasis: '140px',
                      flexGrow: 1,
                      flexDirection: 'column'
                    }}
                  >
                    <Text size={'sm'} weight={'bold'} color={'dimmed'}>{game.awayTeam.division.name}</Text>
                    <Text lineClamp={1}>{game.awayTeam.name}</Text>
                  </Box>

                  <Text weight={'bold'} color={'dimmed'} sx={{ flexBasis: '24px' }}>@</Text>

                  <Box
                    sx={{
                      display: 'flex',
                      flexBasis: '140px',
                      flexGrow: 1,
                      flexDirection: 'column'
                    }}
                  >
                    <Text size={'sm'} weight={'bold'} color={'dimmed'}>{game.homeTeam.division.name}</Text>
                    <Text lineClamp={1}>{game.homeTeam.name}</Text>
                  </Box>

                  <Text sx={{ flexBasis: '140px', flexGrow: 1 }}>
                    {DateTime.fromISO(game.date.timestamp, { zone: game.date.timezone, setZone: false }).toFormat('ccc, LLLL d @ h:mm a')}
                  </Text>

                  {game.reportedScore ? (
                    <Text weight={'bold'} sx={{ flexBasis: '140px', flexGrow: 1 }}>
                      {formatScorePart(game)}
                    </Text>
                  ) : (
                    <Text sx={{ flexBasis: '140px', flexGrow: 1 }}>
                      <Icon icon={faLocationDot} mr={'4px'} secondaryColor={'red.500'} secondaryOpacity={1} primaryColor={'red.100'} />
                      {game.venue.name}
                    </Text>
                  )}

                  <Box/>
                </>
              ) : (
                <>
                  <Group spacing={0} direction={'column'} align={'center'}>
                    <Text weight={'bold'} color={'dimmed'}>
                      {DateTime.fromISO(game.date.timestamp, { zone: game.date.timezone, setZone: false }).toFormat('ccc, LLLL d @ h:mm a')}
                    </Text>

                    <Text weight={'bold'} color={'dimmed'}>
                      <Icon icon={faLocationDot} mr={'4px'} secondaryColor={'red.500'} secondaryOpacity={1} primaryColor={'red.100'} />
                      {game.venue.name}
                    </Text>
                  </Group>

                  <Text mt={'sm'} lineClamp={1}>{game.awayTeam.division.name} {game.awayTeam.name}</Text>
                  <Text weight={'bold'} color={'dimmed'} sx={{ width: '24px' }}>@</Text>
                  <Text lineClamp={1}>{game.homeTeam.division.name} {game.homeTeam.name}</Text>

                  {game.reportedScore && (
                    <Text mt={'md'} weight={'bold'}>
                      {formatScorePart(game)}
                    </Text>
                  )}
                </>
              )}
            </Box>
          </Paper>
        )
      })}
    </Box>
  )
}

function formatScorePart(game: Pick<Game, 'homeTeam' | 'awayTeam' | 'reportedScore'>): string {
  if (!game.reportedScore) {
    return 'N/A'
  }

  const result = game.reportedScore

  switch (result.__typename) {
    case 'Decision':
      let resultText = 'TIE' + ` (${result.homeTeamScore} - ${result.awayTeamScore})`

      if (result.homeTeamScore > result.awayTeamScore) {
        resultText = game.homeTeam.name + ` WON (${result.homeTeamScore} - ${result.awayTeamScore})`
      } else if (result.homeTeamScore < result.awayTeamScore) {
        resultText = game.awayTeam.name + ` WON  (${result.awayTeamScore} - ${result.homeTeamScore})`
      }

      return resultText
    case 'Forfeit':
      return `Forfeit by ${result.forfeitingTeam.name}`
    default:
      return 'Unknown'
  }
}
