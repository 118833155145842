import {RouteObject} from "react-router";

export type RouteDefinition<T = any> = RouteObject & {
  children?: RouteDefinition<T>[];
  extras?: T
}

export type RouteTraversalContext = {
  depth: number
  paths: string[]
  pathname: string
  adjacentChildren: RouteObject[]
}

export interface RouteTreeNode<T = any> {
  paths: string[]
  extras: T
  children?: RouteTreeNode<T>[]
}

export interface RouteTraverser<T, C = any> {
  createCollector(): C
  visit(
    context: RouteTraversalContext,
    extras: T,
    collector: C
  ): undefined | true | false
}

export function traverse<T, C = any>(
  routes: RouteDefinition<T> | RouteDefinition<T>[],
  traverser: RouteTraverser<T, C>,
): C {

  function initTraversal(root: RouteDefinition | RouteDefinition[]): C {
    const initialContext: RouteTraversalContext = {
      depth: 0,
      paths: [],
      pathname: '',
      adjacentChildren: []
    }

    const collector: C = traverser.createCollector()

    if (Array.isArray(root)) {
      for (let i = 0; i < root.length; i++) {
        traverse(
          root[i],
          {
            ...initialContext,
            adjacentChildren: root.filter(p => p.path !== root[i].path)
          },
          collector
        )
      }
    } else {
      traverse(root, initialContext, collector)
    }

    return collector
  }


  function traverse(root: RouteDefinition, ctx: RouteTraversalContext, collector: C) {
    let traversalResult: boolean | undefined = undefined
    traversalResult = traverser.visit(ctx, root.extras ?? {}, collector)

    if (!root.children || traversalResult === false) return

    for (let i = 0; i < root.children.length; i++) {
      const child = root.children[i]!!

      traverse(
        child,
        {
          depth: ctx.depth + 1,
          paths: root.path ? [...ctx.paths, root.path] : [...ctx.paths],
          pathname: child.path ?? '',
          adjacentChildren: root.children.filter((_, idx) => i !== idx)
        },
        collector
      )
    }
  }

  return initTraversal(routes)
}
