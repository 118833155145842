import React from "react";
import {useLocation} from "react-router-dom";
import {RouteDefinition, RouteTraversalContext, RouteTraverser, traverse} from "./route-visitor";
import {RouteDetailsCollector} from "./index";

export interface PageDetails {
  pageName: string
}

interface RoutePageDetailsConfig {
  details?: PageDetails
}

export function usePageDetails<T extends RoutePageDetailsConfig>(
  routes: RouteDefinition<T> | RouteDefinition<T>[]
): RouteDetailsCollector {

  const location = useLocation()

  return React.useMemo(() => {
    const traverser: RouteTraverser<any, RouteDetailsCollector> = {
      createCollector(): any {
        return {
          actions: []
        }
      },
      visit(context: RouteTraversalContext, extras: any, collector: RouteDetailsCollector): boolean | undefined {
        if (extras.details) {
          const parentPaths = [...context.paths, context.pathname].map(p => {
            if (p?.endsWith('/')) {
              return p?.slice(0, -1)
            }
            return p
          })
            .filter(p => p !== '')

          collector[parentPaths.join('/')] = extras.details
        }

        return undefined
      }
    }

    return traverse(routes, traverser)
  }, [location.key, location.pathname, routes])
}
