import React from "react";
import {generatePath, matchPath, useLocation, useNavigate, useResolvedPath} from "react-router-dom";
import {RouteDefinition, RouteTraversalContext, RouteTraverser, traverse} from "./route-visitor";
import {SpotlightAction, useSpotlight} from "@mantine/spotlight";
import {useMantineColorScheme} from "@mantine/core";

interface RouteSpotlightConfig {
  spotlight?: {
    title: string
    description?: string
    category?: string
    keywords?: string[]
  }
}

export function useSpotlightSearch<T extends RouteSpotlightConfig>(
  routes: RouteDefinition<T> | RouteDefinition<T>[]
) {

  const spotlight = useSpotlight()
  const navigate = useNavigate()

  const location = useLocation()
  const resolvedLocation = useResolvedPath('.')

  const colorMode = useMantineColorScheme().colorScheme

  const actions = React.useMemo(() => {
    const traverser: RouteTraverser<any, { location: string, actions: SpotlightAction[] }> = {
      createCollector(): any {
        return {
          location: location.pathname,
          actions: []
        }
      },
      visit(context: RouteTraversalContext, extras: any, collector: { location: string, actions: SpotlightAction[] }): boolean | undefined {
        function preparePath(pathParts: string[], pathname: string): string {
          let parsedCurrentLocationParts = (pathname.endsWith('/') ? pathname.slice(0, -1) : pathname).split('/')

          let parsedPath = pathParts.join('/')

          if (pathParts.length >= parsedCurrentLocationParts.length) {
            parsedPath = pathParts.slice(0, parsedCurrentLocationParts.length - 1).join('/')
          } else if (pathParts.length < parsedCurrentLocationParts.length) {
            parsedPath = [...pathParts, ...(parsedCurrentLocationParts.slice(pathParts.length + 1))].join('/')
          }

          return parsedPath
        }

        const params = matchPath(
          preparePath(
            [...resolvedLocation.pathname.split('/').filter(p => p !== '/' && p !== ''), ...(context.pathname ? [...context.paths, context.pathname] : [...context.paths]).filter(p => p !== '/' && p !== '')], collector.location),
          collector.location
        )?.params ?? {}

        let testUrl = ':'

        try {
          testUrl = generatePath([...context.paths, context.pathname].filter(p => p !== '' && p !== '/').join('/'), params)
        } catch (error) {
          // console.error(error)
        }

        const trailingSegment = testUrl.slice(testUrl.lastIndexOf('/') + 1)

        if (testUrl.includes(':') || (context.pathname !== trailingSegment && context.adjacentChildren.map(c => c.path).filter(p => !p?.includes(':')).includes(trailingSegment))) {
          return false
        }

        if (extras.spotlight) {
          const parentPaths = [...context.paths, context.pathname].map(p => {
            if (p?.endsWith('/')) {
              return p?.slice(0, -1)
            }
            return p
          })
            .filter(p => p !== '')

          collector.actions.push({
            id: '/' + parentPaths.join('/'),
            title: extras.spotlight.title,
            group: 'Navigation',
            description: extras.spotlight.description,
            onTrigger: () => navigate(testUrl),
            keywords: [...(extras.spotlight?.keywords ?? [])]
          })
        }

        return undefined
      }
    }

    return traverse(routes, traverser).actions
  }, [location.key, location.pathname, routes])

  React.useEffect(() => {
    const actionsToRemove = spotlight.actions.filter(a => a.group === 'Navigation').map(a => a.id ?? '')
    spotlight.removeActions(actionsToRemove)
    setTimeout(() => spotlight.registerActions(actions), 0)
  }, [JSON.stringify(actions), colorMode])
}

