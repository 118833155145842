import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An opaque cursor for paginating */
  Cursor: any;
  /** An RFC-3339 compliant Full Date Scalar */
  Date: any;
  /** An RFC-3339 compliant DateTime Scalar */
  DateTime: any;
  /** A representation of an email address */
  Email: any;
  /** A valid ip address */
  IpAddress: any;
  /** A representation of a datetime without a time zone */
  LocalDateTime: any;
  /** A representation of a time without a time zone */
  LocalTime: any;
  /** A representation of markdown text */
  Markdown: any;
  /** A representation of a non blank String value */
  NonBlankString: any;
  /** A representation of an Olson time zone */
  TimeZone: any;
  /** A representation of a file upload */
  Upload: any;
  /** A Url scalar */
  Url: any;
};


export type AcceptTeamStaffInvitePayload = {
  readonly __typename?: 'AcceptTeamStaffInvitePayload';
  readonly success: Scalars['Boolean'];
  readonly userError?: Maybe<UserError>;
};

export type Actor = {
  readonly displayName: Scalars['NonBlankString'];
  readonly id: Scalars['ID'];
};

export type AddTeamInput = {
  readonly avatarImage?: Maybe<Scalars['Upload']>;
  readonly description?: Maybe<Scalars['NonBlankString']>;
  readonly teamName: Scalars['NonBlankString'];
};

export type AddTeamPayload = {
  readonly __typename?: 'AddTeamPayload';
  readonly team?: Maybe<Team>;
};

export type AgreementContent = FileAgreementContent | MarkdownAgreementContent;

export type AgreementRequirement = GraphQlRequirement & {
  readonly __typename?: 'AgreementRequirement';
  readonly content: AgreementContent;
  readonly id: Scalars['ID'];
  readonly moreInfo?: Maybe<Scalars['Markdown']>;
  readonly name: Scalars['NonBlankString'];
};

export type AgreementRequirementCompletionData = RequirementCompletionData & {
  readonly __typename?: 'AgreementRequirementCompletionData';
  readonly completionDate: Scalars['DateTime'];
  readonly submitterIpAddress: Scalars['IpAddress'];
};

export type AgreementRequirementSubmissionDataInput = {
  readonly requirementId: Scalars['ID'];
};

export enum ApprovalStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type ApproveGameDataInput = {
  readonly gameId: Scalars['ID'];
  readonly gameVersion?: Maybe<Scalars['Int']>;
};

export type AvailableTeamEdge = GraphQlEdge & {
  readonly __typename?: 'AvailableTeamEdge';
  readonly cursor: Scalars['Cursor'];
  readonly isEligible: Scalars['Boolean'];
  readonly node: Team;
};

export type AvailableTeamsConnection = {
  readonly __typename?: 'AvailableTeamsConnection';
  readonly edges: ReadonlyArray<AvailableTeamEdge>;
  readonly nodes: ReadonlyArray<Team>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type CancelGameDataInput = {
  readonly cancelReason: Scalars['NonBlankString'];
  readonly cancellingTeamId: Scalars['ID'];
  readonly gameId: Scalars['ID'];
  readonly gameVersion?: Maybe<Scalars['Int']>;
};

export type CanceledGraphQlGame = GraphQlGame & {
  readonly __typename?: 'CanceledGraphQLGame';
  readonly assignedOfficials: ReadonlyArray<GraphQlOfficial>;
  readonly availableActions: GameAvailableActions;
  readonly awayTeam: SeasonTeam;
  readonly cancelReason?: Maybe<Scalars['NonBlankString']>;
  readonly cancellingTeam: SeasonTeam;
  readonly date: ZonedDateTimeParts;
  readonly eventHistory: ReadonlyArray<TeamGraphQlGameEvent>;
  readonly fingerprint: Scalars['NonBlankString'];
  readonly homeTeam: SeasonTeam;
  readonly id: Scalars['ID'];
  readonly venue: GraphQlVenue;
  readonly version: Scalars['Int'];
};


export type CanceledGraphQlGameAvailableActionsArgs = {
  teamId: Scalars['ID'];
};

export type ConfirmedGraphQlGame = GraphQlGame & {
  readonly __typename?: 'ConfirmedGraphQLGame';
  readonly assignedOfficials: ReadonlyArray<GraphQlOfficial>;
  readonly availableActions: GameAvailableActions;
  readonly awayTeam: SeasonTeam;
  readonly canReportScore: Scalars['Boolean'];
  readonly date: ZonedDateTimeParts;
  readonly eventHistory: ReadonlyArray<TeamGraphQlGameEvent>;
  readonly fingerprint: Scalars['NonBlankString'];
  readonly homeTeam: SeasonTeam;
  readonly id: Scalars['ID'];
  readonly reportedScore?: Maybe<GameResultSubmission>;
  readonly venue: GraphQlVenue;
  readonly version: Scalars['Int'];
};


export type ConfirmedGraphQlGameAvailableActionsArgs = {
  teamId: Scalars['ID'];
};

export type CreateGameDataInput = {
  readonly date: ZonedDateTimePartsInput;
  readonly isEnteringTeamHomeTeam: Scalars['Boolean'];
  readonly opponent: Scalars['ID'];
  readonly venue: Scalars['ID'];
};

export type CreateOrderInput = {
  readonly lineItems: ReadonlyArray<CreateOrderInputLineItemInput>;
  readonly teamId: Scalars['ID'];
};

export type CreateOrderInputLineItemInput = {
  readonly priceId: Scalars['ID'];
  readonly quantity: Scalars['Int'];
};

export type CreateOrderPayload = {
  readonly __typename?: 'CreateOrderPayload';
  readonly order?: Maybe<SeasonTeamOrder>;
};



export type DateRange = {
  readonly __typename?: 'DateRange';
  readonly end: Scalars['DateTime'];
  readonly start: Scalars['DateTime'];
};


export type Decision = {
  readonly __typename?: 'Decision';
  readonly awayTeamScore: Scalars['Int'];
  readonly homeTeamScore: Scalars['Int'];
};

export type DeleteStaffMemberInvitePayload = {
  readonly __typename?: 'DeleteStaffMemberInvitePayload';
  readonly success: Scalars['Boolean'];
  readonly userError?: Maybe<UserError>;
};

export type DeleteTeamPayload = {
  readonly __typename?: 'DeleteTeamPayload';
  readonly team?: Maybe<Team>;
};

export type Division = {
  readonly __typename?: 'Division';
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
  readonly sortValue: Scalars['Int'];
  readonly specification: ReadonlyArray<SpecificationPart>;
};

export type DivisionDescriptor = {
  readonly __typename?: 'DivisionDescriptor';
  readonly description?: Maybe<Scalars['NonBlankString']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
};

export type DivisionDescriptorGroup = {
  readonly __typename?: 'DivisionDescriptorGroup';
  readonly description?: Maybe<Scalars['NonBlankString']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
};


export type FileAgreementContent = {
  readonly __typename?: 'FileAgreementContent';
  readonly url: Scalars['Url'];
};

export type FileUploadRequirement = GraphQlRequirement & {
  readonly __typename?: 'FileUploadRequirement';
  readonly id: Scalars['ID'];
  readonly moreInfo?: Maybe<Scalars['Markdown']>;
  readonly name: Scalars['NonBlankString'];
  readonly supportingFile?: Maybe<Scalars['Url']>;
};

export type FileUploadRequirementCompletionData = RequirementCompletionData & {
  readonly __typename?: 'FileUploadRequirementCompletionData';
  readonly completionDate: Scalars['DateTime'];
  readonly submitterIpAddress: Scalars['IpAddress'];
  readonly uploadedFileId: Scalars['ID'];
};

export type FileUploadRequirementSubmissionDataInput = {
  readonly fileId: Scalars['ID'];
  readonly requirementId: Scalars['ID'];
};

export type FinalizeOrderInput = {
  readonly orderId: Scalars['ID'];
  readonly teamId: Scalars['ID'];
};

export type FinalizeOrderPayload = {
  readonly __typename?: 'FinalizeOrderPayload';
  readonly order?: Maybe<SeasonTeamOrder>;
};

export type Forfeit = {
  readonly __typename?: 'Forfeit';
  readonly forfeitingTeam?: Maybe<SeasonTeam>;
};

export type GameApprovalStatus = {
  readonly __typename?: 'GameApprovalStatus';
  readonly approvingEntities: ReadonlyArray<PendingGameApprovalStatusEntry>;
  readonly status: ApprovalStatus;
};

export type GameAvailableActions = {
  readonly __typename?: 'GameAvailableActions';
  readonly canApprove: Scalars['Boolean'];
  readonly canCancel: Scalars['Boolean'];
  readonly canProposeChange: Scalars['Boolean'];
  readonly canReject: Scalars['Boolean'];
};

export type GameMutations = {
  readonly __typename?: 'GameMutations';
  readonly approveGame: GenericGameActionPayload;
  readonly cancelGame: GenericGameActionPayload;
  readonly proposeSchedulingChange: GenericGameActionPayload;
  readonly rejectGame: GenericGameActionPayload;
  readonly reportScore: GenericGameActionPayload;
  readonly scheduleGame: ScheduleGamePayload;
};


export type GameMutationsApproveGameArgs = {
  data: ApproveGameDataInput;
};


export type GameMutationsCancelGameArgs = {
  data: CancelGameDataInput;
};


export type GameMutationsProposeSchedulingChangeArgs = {
  data: ProposeGameSchedulingChangeDataInput;
};


export type GameMutationsRejectGameArgs = {
  data: RejectGameDataInput;
};


export type GameMutationsReportScoreArgs = {
  data: ReportScoreDataInput;
};


export type GameMutationsScheduleGameArgs = {
  data: CreateGameDataInput;
};

export type GameResult = Decision | Forfeit;

export type GameResultSubmission = {
  readonly __typename?: 'GameResultSubmission';
  readonly result: GameResult;
  readonly submitter?: Maybe<GraphQlOrganizationOrSeasonTeam>;
};

export type GenericGameActionPayload = {
  readonly __typename?: 'GenericGameActionPayload';
  readonly error?: Maybe<Scalars['String']>;
  readonly game?: Maybe<GraphQlGame>;
};

export type GeoCoords = {
  readonly __typename?: 'GeoCoords';
  readonly distanceInMilesBetween: Scalars['Float'];
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};


export type GeoCoordsDistanceInMilesBetweenArgs = {
  other: GeoCoordsInput;
};

export type GeoCoordsInput = {
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type GraphQlAgreementRequirementStatus = GraphQlRequirementStatus & {
  readonly __typename?: 'GraphQLAgreementRequirementStatus';
  readonly completionState?: Maybe<AgreementRequirementCompletionData>;
  readonly id: Scalars['ID'];
  readonly isFulfilled: Scalars['Boolean'];
  readonly requirementDefinition: AgreementRequirement;
};

export type GraphQlApprovingEntity = OrganizationApprovingEntity | SeasonTeamApprovingEntity;

export type GraphQlCollaborativeSchedulingSettings = {
  readonly __typename?: 'GraphQLCollaborativeSchedulingSettings';
  readonly allowedDates: DateRange;
  readonly allowedTimes: TimeRange;
  readonly schedulingGracePeriod: Scalars['Int'];
};

export type GraphQlEdge = {
  readonly cursor: Scalars['Cursor'];
};

export type GraphQlFileUploadRequirementStatus = GraphQlRequirementStatus & {
  readonly __typename?: 'GraphQLFileUploadRequirementStatus';
  readonly completionState?: Maybe<FileUploadRequirementCompletionData>;
  readonly id: Scalars['ID'];
  readonly isFulfilled: Scalars['Boolean'];
  readonly requirementDefinition: FileUploadRequirement;
};

export type GraphQlGame = {
  readonly availableActions: GameAvailableActions;
  readonly eventHistory: ReadonlyArray<TeamGraphQlGameEvent>;
  readonly fingerprint: Scalars['NonBlankString'];
  readonly id: Scalars['ID'];
  readonly version: Scalars['Int'];
};


export type GraphQlGameAvailableActionsArgs = {
  teamId: Scalars['ID'];
};

export type GraphQlGameSchedulingActorOrganizationStaffMember = TeamGraphQlGameSchedulingActor & {
  readonly __typename?: 'GraphQLGameSchedulingActorOrganizationStaffMember';
  readonly organization: Organization;
  readonly user: LeagueBrainUser;
};

export type GraphQlGameSchedulingActorTeamStaffMember = TeamGraphQlGameSchedulingActor & {
  readonly __typename?: 'GraphQLGameSchedulingActorTeamStaffMember';
  readonly seasonTeam: SeasonTeam;
  readonly user: LeagueBrainUser;
};

export type GraphQlOfficial = {
  readonly __typename?: 'GraphQLOfficial';
  readonly cellPhoneNumber?: Maybe<Scalars['NonBlankString']>;
  readonly displayName: Scalars['NonBlankString'];
  readonly id: Scalars['ID'];
};

export type GraphQlOrganizationOrSeasonTeam = Organization | SeasonTeam;

export type GraphQlRequirement = {
  readonly id: Scalars['ID'];
  readonly moreInfo?: Maybe<Scalars['Markdown']>;
  readonly name: Scalars['NonBlankString'];
};

export type GraphQlRequirementCompletionStatus = {
  readonly __typename?: 'GraphQLRequirementCompletionStatus';
  readonly completedCount: Scalars['Int'];
  readonly isComplete: Scalars['Boolean'];
  readonly teamId: Scalars['ID'];
  readonly totalCount: Scalars['Int'];
};

export type GraphQlRequirementStatus = {
  readonly completionState?: Maybe<RequirementCompletionData>;
  readonly id: Scalars['ID'];
  readonly isFulfilled: Scalars['Boolean'];
  readonly requirementDefinition: GraphQlRequirement;
};

export type GraphQlVenue = {
  readonly __typename?: 'GraphQLVenue';
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
};

export type InviteStaffMemberToTeamPayload = {
  readonly __typename?: 'InviteStaffMemberToTeamPayload';
  readonly invite?: Maybe<TeamStaffMemberInvite>;
  readonly userError?: Maybe<UserError>;
};


export type LastPurchasedAtItem = {
  readonly __typename?: 'LastPurchasedAtItem';
  readonly order: SeasonTeamOrder;
  readonly purchaseDate: Scalars['DateTime'];
};

export type LeagueBrainUser = Actor & Node & {
  readonly __typename?: 'LeagueBrainUser';
  readonly avatar: Scalars['Url'];
  readonly defaultAvatar: Scalars['Url'];
  readonly displayName: Scalars['NonBlankString'];
  readonly email: Scalars['Email'];
  readonly id: Scalars['ID'];
  readonly publicProfile: LeagueBrainUserProfile;
  readonly realName: Scalars['NonBlankString'];
};

export type LeagueBrainUserProfile = Actor & Node & {
  readonly __typename?: 'LeagueBrainUserProfile';
  readonly avatar: Scalars['Url'];
  readonly displayName: Scalars['NonBlankString'];
  readonly email: Scalars['Email'];
  readonly id: Scalars['ID'];
};




export type MarkdownAgreementContent = {
  readonly __typename?: 'MarkdownAgreementContent';
  readonly content: Scalars['Markdown'];
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly acceptTeamStaffInvite: AcceptTeamStaffInvitePayload;
  readonly addLineItems: SetOrderPayload;
  readonly addTeam: AddTeamPayload;
  readonly createOrder: CreateOrderPayload;
  readonly deleteTeam: DeleteTeamPayload;
  readonly finalizeOrder: FinalizeOrderPayload;
  readonly removeLineItem: RemoveOrderLineItemPayload;
  readonly seasonTeam: SeasonTeamMutations;
  readonly sendTestMessage: Scalars['Boolean'];
  readonly setOrderItemQuantity: SetOrderItemQuantityPayload;
  readonly setOrderItems: SetOrderPayload;
  readonly submitRegistrationResponse: SubmitRegistrationResponsePayload;
  readonly submitRegistrationResponseNEW: SubmitRegistrationResponsePayload;
  readonly submitRequirement: SubmitRequirementPayload;
  readonly team?: Maybe<TeamOperations>;
  readonly updateOrder: UpdateOrderPayload;
  readonly updateTeamDetails: Team;
  readonly updateUserAvatar: UpdateUserAvatarPayload;
  readonly updateUserInfo: UpdateUserInfoPayload;
};


export type MutationAcceptTeamStaffInviteArgs = {
  inviteId: Scalars['ID'];
};


export type MutationAddLineItemsArgs = {
  data: SetOrderInput;
};


export type MutationAddTeamArgs = {
  data: AddTeamInput;
};


export type MutationCreateOrderArgs = {
  data: CreateOrderInput;
};


export type MutationDeleteTeamArgs = {
  id: Scalars['ID'];
};


export type MutationFinalizeOrderArgs = {
  data: FinalizeOrderInput;
};


export type MutationRemoveLineItemArgs = {
  data: RemoveOrderLineItemInput;
};


export type MutationSeasonTeamArgs = {
  id: Scalars['ID'];
};


export type MutationSendTestMessageArgs = {
  message: Scalars['String'];
};


export type MutationSetOrderItemQuantityArgs = {
  data: SetOrderItemQuantityInput;
};


export type MutationSetOrderItemsArgs = {
  data: SetOrderInput;
};


export type MutationSubmitRegistrationResponseArgs = {
  response: Scalars['NonBlankString'];
};


export type MutationSubmitRegistrationResponseNewArgs = {
  response: RegisterTeamDataInput;
};


export type MutationSubmitRequirementArgs = {
  data: SubmitRequirementDataInput;
  teamId: Scalars['ID'];
};


export type MutationTeamArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateOrderArgs = {
  data: UpdateOrderInput;
};


export type MutationUpdateTeamDetailsArgs = {
  details: UpdateTeamDetailsInput;
  id: Scalars['ID'];
};


export type MutationUpdateUserAvatarArgs = {
  imageFile?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateUserInfoArgs = {
  input: UpdateUserInfoInput;
};

export type MyTeamsConnection = {
  readonly __typename?: 'MyTeamsConnection';
  readonly edges: ReadonlyArray<TeamEdge>;
  readonly nodes: ReadonlyArray<Team>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

export type Node = {
  readonly id: Scalars['ID'];
};


export type Organization = {
  readonly __typename?: 'Organization';
  readonly avatar: UniformResourceLocatable;
  readonly email?: Maybe<Scalars['Email']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
  readonly products: ReadonlyArray<Product>;
  readonly season?: Maybe<Season>;
  readonly seasons: ReadonlyArray<Season>;
  readonly website?: Maybe<Scalars['Url']>;
};


export type OrganizationSeasonArgs = {
  id: Scalars['ID'];
};

export type OrganizationApprovingEntity = {
  readonly __typename?: 'OrganizationApprovingEntity';
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
};

export type PageInfo = {
  readonly __typename?: 'PageInfo';
  readonly endCursor: Scalars['Cursor'];
  readonly hasNextPage: Scalars['Boolean'];
  readonly hasPreviousPage: Scalars['Boolean'];
  readonly startCursor: Scalars['Cursor'];
};

export type PaymentNotReceived = PaymentStatus & {
  readonly __typename?: 'PaymentNotReceived';
  readonly isPaid: Scalars['Boolean'];
};

export type PaymentReceived = PaymentStatus & {
  readonly __typename?: 'PaymentReceived';
  readonly isPaid: Scalars['Boolean'];
  readonly paymentType: PaymentType;
};

export type PaymentStatus = {
  readonly isPaid: Scalars['Boolean'];
};

export enum PaymentType {
  Cash = 'CASH',
  Check = 'CHECK',
  Stripe = 'STRIPE'
}

export type PendingGameApprovalStatusEntry = {
  readonly __typename?: 'PendingGameApprovalStatusEntry';
  readonly approvingEntity: GraphQlApprovingEntity;
  readonly status: ApprovalStatus;
};

export type PendingGraphQlGame = GraphQlGame & {
  readonly __typename?: 'PendingGraphQLGame';
  readonly approvalStatus: GameApprovalStatus;
  readonly assignedOfficials: ReadonlyArray<GraphQlOfficial>;
  readonly availableActions: GameAvailableActions;
  readonly awayTeam: SeasonTeam;
  readonly date: ZonedDateTimeParts;
  readonly eventHistory: ReadonlyArray<TeamGraphQlGameEvent>;
  readonly fingerprint: Scalars['NonBlankString'];
  readonly homeTeam: SeasonTeam;
  readonly id: Scalars['ID'];
  readonly venue: GraphQlVenue;
  readonly version: Scalars['Int'];
};


export type PendingGraphQlGameAvailableActionsArgs = {
  teamId: Scalars['ID'];
};

export type PendingTeamStaffMember = {
  readonly __typename?: 'PendingTeamStaffMember';
  readonly id: Scalars['ID'];
  readonly userEmail: Scalars['Email'];
};

export type Player = Node & {
  readonly __typename?: 'Player';
  readonly dateOfBirth: Scalars['Date'];
  readonly displayName: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly lastName: Scalars['String'];
};

export type PlayerEdge = GraphQlEdge & {
  readonly __typename?: 'PlayerEdge';
  readonly cursor: Scalars['Cursor'];
  readonly node: Player;
};

export type Price = {
  readonly __typename?: 'Price';
  readonly amount: Scalars['Int'];
  readonly currency: Scalars['String'];
  readonly formattedString: Scalars['String'];
  readonly id: Scalars['ID'];
};

export type Product = {
  readonly __typename?: 'Product';
  readonly description?: Maybe<Scalars['NonBlankString']>;
  readonly id: Scalars['ID'];
  readonly image?: Maybe<Scalars['Url']>;
  readonly name: Scalars['NonBlankString'];
  readonly price: Price;
};

export type ProductDetails = {
  readonly __typename?: 'ProductDetails';
  readonly description?: Maybe<Scalars['NonBlankString']>;
  readonly image?: Maybe<Scalars['Url']>;
  readonly name: Scalars['NonBlankString'];
};

export type ProposeGameSchedulingChangeDataInput = {
  readonly date: ZonedDateTimePartsInput;
  readonly gameId: Scalars['ID'];
  readonly gameVersion?: Maybe<Scalars['Int']>;
  readonly isEnteringTeamHomeTeam: Scalars['Boolean'];
  readonly opponent: Scalars['ID'];
  readonly venue: Scalars['ID'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly game?: Maybe<GraphQlGame>;
  readonly organization?: Maybe<Organization>;
  readonly ping: Scalars['String'];
  readonly rateLimit: RateLimit;
  readonly seasonTeam?: Maybe<SeasonTeam>;
  readonly team?: Maybe<Team>;
  readonly teamSeasons: ReadonlyArray<UserSeasonEntry>;
  readonly teamStaffMemberInvite?: Maybe<TeamStaffMemberInvite>;
  readonly teams: MyTeamsConnection;
  readonly viewer: LeagueBrainUser;
};


export type QueryGameArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QuerySeasonTeamArgs = {
  id: Scalars['ID'];
};


export type QueryTeamArgs = {
  id: Scalars['ID'];
};


export type QueryTeamStaffMemberInviteArgs = {
  id: Scalars['ID'];
};


export type QueryTeamsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type RateLimit = {
  readonly __typename?: 'RateLimit';
  readonly cost: Scalars['Int'];
  readonly nodeCount: Scalars['Int'];
  readonly remaining: Scalars['Int'];
};

export type RegisterTeamDataInput = {
  readonly divisionId: Scalars['ID'];
  readonly registeringTeamId: Scalars['ID'];
  readonly responsesAsJson: Scalars['String'];
  readonly seasonId: Scalars['ID'];
  readonly teamName: Scalars['String'];
};

export type RejectGameDataInput = {
  readonly gameId: Scalars['ID'];
  readonly gameVersion?: Maybe<Scalars['Int']>;
};

export type RemoveOrderLineItemInput = {
  readonly itemPriceId: Scalars['ID'];
  readonly teamId: Scalars['ID'];
};

export type RemoveOrderLineItemPayload = {
  readonly __typename?: 'RemoveOrderLineItemPayload';
  readonly order?: Maybe<SeasonTeamOrder>;
};

export type RemoveUserFromTeamPayload = {
  readonly __typename?: 'RemoveUserFromTeamPayload';
  readonly success: Scalars['Boolean'];
  readonly userError?: Maybe<UserError>;
};

export type ReportScoreDataInput = {
  readonly awayTeamScore: Scalars['Int'];
  readonly gameId: Scalars['ID'];
  readonly gameVersion?: Maybe<Scalars['Int']>;
  readonly homeTeamScore: Scalars['Int'];
};

export type RequirementCompletionData = {
  readonly completionDate: Scalars['DateTime'];
  readonly submitterIpAddress: Scalars['IpAddress'];
};

export type S3File = UniformResourceLocatable & {
  readonly __typename?: 'S3File';
  readonly id: Scalars['ID'];
  readonly metadata: S3FileMetadata;
  readonly url: Scalars['Url'];
};

export type S3FileMetadata = {
  readonly __typename?: 'S3FileMetadata';
  readonly fileId: Scalars['String'];
  readonly s3Key: Scalars['String'];
  readonly s3VersionId: Scalars['String'];
};

export type ScheduleGamePayload = {
  readonly __typename?: 'ScheduleGamePayload';
  readonly error?: Maybe<Scalars['String']>;
  readonly game?: Maybe<GraphQlGame>;
};

export type Season = {
  readonly __typename?: 'Season';
  readonly collaborativeSchedulingSettings: GraphQlCollaborativeSchedulingSettings;
  readonly divisions: ReadonlyArray<Division>;
  readonly eligibleGameOpponents: ReadonlyArray<SeasonTeam>;
  readonly id: Scalars['ID'];
  readonly isSchedulingOpen: Scalars['Boolean'];
  readonly name: Scalars['NonBlankString'];
  readonly registrationForm?: Maybe<SimpleRegistrationForm>;
  readonly sportType: SportType;
  readonly venues: ReadonlyArray<GraphQlVenue>;
};


export type SeasonEligibleGameOpponentsArgs = {
  seasonTeamId?: Maybe<Scalars['ID']>;
};

export type SeasonTeam = {
  readonly __typename?: 'SeasonTeam';
  readonly activeOrder: SeasonTeamOrder;
  readonly division: Division;
  readonly games: ReadonlyArray<GraphQlGame>;
  readonly hasPaidRegistrationFee: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isCommittedToPlayoffs: Scalars['Boolean'];
  readonly name: Scalars['NonBlankString'];
  readonly organization: Organization;
  readonly paidOrders: ReadonlyArray<SeasonTeamOrder>;
  readonly qualifiedName: Scalars['NonBlankString'];
  readonly registrationFeeCheckoutSession?: Maybe<Scalars['String']>;
  readonly registrationFormResponses: Scalars['String'];
  readonly requirementCompletionStatus: GraphQlRequirementCompletionStatus;
  readonly requirements: ReadonlyArray<GraphQlRequirementStatus>;
  readonly season: Season;
  readonly sourceTeam: Team;
  readonly stripeAccountId: Scalars['String'];
  readonly teamContacts?: Maybe<ReadonlyArray<TeamContactEntry>>;
  readonly viewerRole: TeamStaffMemberRole;
};

export type SeasonTeamApprovingEntity = {
  readonly __typename?: 'SeasonTeamApprovingEntity';
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
};

export type SeasonTeamMutations = {
  readonly __typename?: 'SeasonTeamMutations';
  readonly games: GameMutations;
  readonly updatePlayoffCommitmentStatus: UpdateSeasonTeamPlayoffCommitmentStatusPayload;
};


export type SeasonTeamMutationsUpdatePlayoffCommitmentStatusArgs = {
  status: Scalars['Boolean'];
};

export type SeasonTeamOrder = {
  readonly __typename?: 'SeasonTeamOrder';
  readonly createdAt: Scalars['DateTime'];
  readonly formattedTotal: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly lineItems: ReadonlyArray<SeasonTeamOrderLineItem>;
  readonly paymentStatus: PaymentStatus;
  readonly stripePaymentUrl?: Maybe<StripeCheckoutSession>;
};

export type SeasonTeamOrderLineItem = {
  readonly __typename?: 'SeasonTeamOrderLineItem';
  readonly product: Product;
  readonly quantity: Scalars['Int'];
  readonly summary: Summary;
};

export type SetOrderInput = {
  readonly lineItems: ReadonlyArray<SetOrderInputLineItemInput>;
  readonly teamId: Scalars['ID'];
};

export type SetOrderInputLineItemInput = {
  readonly priceId: Scalars['ID'];
  readonly quantity: Scalars['Int'];
};

export type SetOrderItemQuantityInput = {
  readonly itemPriceId: Scalars['ID'];
  readonly quantity: Scalars['Int'];
  readonly teamId: Scalars['ID'];
};

export type SetOrderItemQuantityPayload = {
  readonly __typename?: 'SetOrderItemQuantityPayload';
  readonly order?: Maybe<SeasonTeamOrder>;
};

export type SetOrderPayload = {
  readonly __typename?: 'SetOrderPayload';
  readonly order?: Maybe<SeasonTeamOrder>;
};

export type SimpleRegistrationForm = {
  readonly __typename?: 'SimpleRegistrationForm';
  readonly availableTeams: AvailableTeamsConnection;
  readonly formDefinition: Scalars['String'];
  readonly isOpen: Scalars['Boolean'];
  readonly name: Scalars['NonBlankString'];
};

export type SpecificationPart = {
  readonly __typename?: 'SpecificationPart';
  readonly descriptor: DivisionDescriptor;
  readonly group: DivisionDescriptorGroup;
};

export enum SportType {
  Baseball = 'BASEBALL'
}

export type StaticFile = UniformResourceLocatable & {
  readonly __typename?: 'StaticFile';
  readonly url: Scalars['Url'];
};

export type StripeCheckoutSession = {
  readonly __typename?: 'StripeCheckoutSession';
  readonly accountId: Scalars['String'];
  readonly id: Scalars['String'];
};

export type SubmitRegistrationResponsePayload = {
  readonly __typename?: 'SubmitRegistrationResponsePayload';
  readonly team?: Maybe<SeasonTeam>;
};

export type SubmitRequirementDataInput = {
  readonly agreement?: Maybe<AgreementRequirementSubmissionDataInput>;
  readonly fileUpload?: Maybe<FileUploadRequirementSubmissionDataInput>;
};

export type SubmitRequirementPayload = {
  readonly __typename?: 'SubmitRequirementPayload';
  readonly requirement: GraphQlRequirementStatus;
  readonly status: GraphQlRequirementCompletionStatus;
};

export type Subscription = {
  readonly __typename?: 'Subscription';
  readonly messages: TestMessage;
};

export type Summary = {
  readonly __typename?: 'Summary';
  readonly formattedTotal: Scalars['String'];
  readonly lastPurchasedAt?: Maybe<LastPurchasedAtItem>;
  readonly productDetails: ProductDetails;
};

export type Team = Node & {
  readonly __typename?: 'Team';
  readonly avatar: Scalars['Url'];
  readonly description?: Maybe<Scalars['NonBlankString']>;
  readonly id: Scalars['ID'];
  readonly isOwner: Scalars['Boolean'];
  readonly isRegisteredForAtLeastOneSeason: Scalars['Boolean'];
  readonly name: Scalars['NonBlankString'];
  readonly owner: LeagueBrainUserProfile;
  readonly pendingStaffMembers: ReadonlyArray<PendingTeamStaffMember>;
  readonly staffMembers: ReadonlyArray<TeamStaffMember>;
  readonly timeCreated: Scalars['DateTime'];
  readonly viewerRole: TeamStaffMemberRole;
};

export type TeamContactEntry = {
  readonly __typename?: 'TeamContactEntry';
  readonly confirmedGameCount: Scalars['Int'];
  readonly distanceInMiles?: Maybe<Scalars['Float']>;
  readonly headCoachCellPhone: Scalars['String'];
  readonly headCoachEmail: Scalars['String'];
  readonly headCoachName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly location?: Maybe<GeoCoords>;
  readonly schedulerCellPhone: Scalars['String'];
  readonly schedulerEmail: Scalars['String'];
  readonly schedulerName: Scalars['String'];
  readonly teamDisplayName: Scalars['String'];
};

export type TeamEdge = GraphQlEdge & {
  readonly __typename?: 'TeamEdge';
  readonly cursor: Scalars['Cursor'];
  readonly node: Team;
};

export type TeamGraphQlGameEvent = {
  readonly __typename?: 'TeamGraphQLGameEvent';
  readonly actor: TeamGraphQlGameSchedulingActor;
  readonly name: Scalars['String'];
  readonly timestamp: Scalars['DateTime'];
};

export type TeamGraphQlGameSchedulingActor = {
  readonly user: LeagueBrainUser;
};

export type TeamOperations = {
  readonly __typename?: 'TeamOperations';
  readonly inviteStaffMember: InviteStaffMemberToTeamPayload;
  readonly removeStaffMember: RemoveUserFromTeamPayload;
  readonly withdrawStaffMemberInvite: DeleteStaffMemberInvitePayload;
};


export type TeamOperationsInviteStaffMemberArgs = {
  email: Scalars['Email'];
};


export type TeamOperationsRemoveStaffMemberArgs = {
  userId: Scalars['ID'];
};


export type TeamOperationsWithdrawStaffMemberInviteArgs = {
  inviteId: Scalars['ID'];
};

export type TeamStaffMember = {
  readonly __typename?: 'TeamStaffMember';
  readonly role: TeamStaffMemberRole;
  readonly userProfile: LeagueBrainUserProfile;
};

export type TeamStaffMemberInvite = {
  readonly __typename?: 'TeamStaffMemberInvite';
  readonly id: Scalars['ID'];
  readonly invitingTeam: Team;
  readonly userEmail: Scalars['Email'];
};

export enum TeamStaffMemberRole {
  Administrator = 'ADMINISTRATOR',
  Owner = 'OWNER'
}

export type TestMessage = {
  readonly __typename?: 'TestMessage';
  readonly from: Scalars['String'];
  readonly message: Scalars['String'];
  readonly userId: Scalars['String'];
};

export type TimeRange = {
  readonly __typename?: 'TimeRange';
  readonly end: Scalars['LocalTime'];
  readonly start: Scalars['LocalTime'];
};


export type UniformResourceLocatable = {
  readonly url: Scalars['Url'];
};

export type UpdateOrderInput = {
  readonly lineItems: ReadonlyArray<UpdateOrderInputLineItemInput>;
  readonly orderId: Scalars['ID'];
  readonly teamId: Scalars['ID'];
};

export type UpdateOrderInputLineItemInput = {
  readonly priceId: Scalars['ID'];
  readonly quantity: Scalars['Int'];
};

export type UpdateOrderPayload = {
  readonly __typename?: 'UpdateOrderPayload';
  readonly order?: Maybe<SeasonTeamOrder>;
};

export type UpdateSeasonTeamPlayoffCommitmentStatusPayload = {
  readonly __typename?: 'UpdateSeasonTeamPlayoffCommitmentStatusPayload';
  readonly team: SeasonTeam;
};

export type UpdateTeamDetailsInput = {
  readonly avatar?: Maybe<Scalars['Upload']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly teamName: Scalars['NonBlankString'];
};

export type UpdateUserAvatarPayload = {
  readonly __typename?: 'UpdateUserAvatarPayload';
  readonly user?: Maybe<LeagueBrainUser>;
};

export type UpdateUserInfoInput = {
  readonly displayName?: Maybe<Scalars['String']>;
  readonly realName?: Maybe<Scalars['String']>;
  readonly username?: Maybe<Scalars['String']>;
};

export type UpdateUserInfoPayload = {
  readonly __typename?: 'UpdateUserInfoPayload';
  readonly user?: Maybe<LeagueBrainUser>;
};



export type UserError = {
  readonly __typename?: 'UserError';
  readonly description: Scalars['String'];
  readonly type: Scalars['String'];
};

export type UserSeasonEntry = {
  readonly __typename?: 'UserSeasonEntry';
  readonly divisionName: Scalars['NonBlankString'];
  readonly organizationName: Scalars['NonBlankString'];
  readonly seasonName: Scalars['NonBlankString'];
  readonly seasonTeamId: Scalars['ID'];
  readonly teamName: Scalars['NonBlankString'];
};

export type ZonedDateTimeParts = {
  readonly __typename?: 'ZonedDateTimeParts';
  readonly timestamp: Scalars['LocalDateTime'];
  readonly timezone: Scalars['TimeZone'];
};

export type ZonedDateTimePartsInput = {
  readonly timestamp: Scalars['LocalDateTime'];
  readonly timezone: Scalars['TimeZone'];
};

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserInfoQuery = { readonly __typename?: 'Query', readonly viewer: (
    { readonly __typename?: 'LeagueBrainUser' }
    & ManageUserProfileFieldsFragment
  ) };

export type ManageUserProfileFieldsFragment = { readonly __typename?: 'LeagueBrainUser', readonly id: string, readonly email: any, readonly realName: any, readonly displayName: any, readonly defaultAvatar: any, readonly avatar: any };


export type ManageUserProfileFieldsFragmentVariables = Exact<{ [key: string]: never; }>;

export type UpdateUserAvatarMutationVariables = Exact<{
  avatar?: Maybe<Scalars['Upload']>;
}>;


export type UpdateUserAvatarMutation = { readonly __typename?: 'Mutation', readonly updateUserAvatar: { readonly __typename?: 'UpdateUserAvatarPayload', readonly user?: Maybe<{ readonly __typename?: 'LeagueBrainUser', readonly id: string, readonly avatar: any }> } };

export type UpdateUserInfoMutationVariables = Exact<{
  input: UpdateUserInfoInput;
}>;


export type UpdateUserInfoMutation = { readonly __typename?: 'Mutation', readonly updateUserInfo: { readonly __typename?: 'UpdateUserInfoPayload', readonly user?: Maybe<{ readonly __typename?: 'LeagueBrainUser', readonly id: string, readonly realName: any, readonly displayName: any }> } };

export type AllTeamSeasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTeamSeasonsQuery = { readonly __typename?: 'Query', readonly teamSeasons: ReadonlyArray<{ readonly __typename?: 'UserSeasonEntry', readonly seasonTeamId: string, readonly teamName: any, readonly organizationName: any, readonly seasonName: any, readonly divisionName: any }> };

export type AllGamesQueryVariables = Exact<{
  seasonTeamId: Scalars['ID'];
}>;


export type AllGamesQuery = { readonly __typename?: 'Query', readonly seasonTeam?: Maybe<(
    { readonly __typename?: 'SeasonTeam', readonly id: string, readonly games: ReadonlyArray<(
      { readonly __typename?: 'CanceledGraphQLGame' }
      & GameDetails_CanceledGraphQlGame_Fragment
    ) | (
      { readonly __typename?: 'ConfirmedGraphQLGame' }
      & GameDetails_ConfirmedGraphQlGame_Fragment
    ) | (
      { readonly __typename?: 'PendingGraphQLGame' }
      & GameDetails_PendingGraphQlGame_Fragment
    )> }
    & GameSchedulingContextFragment
  )> };

export type ApprovalStatusDetailsFragment = { readonly __typename?: 'GameApprovalStatus', readonly approvingEntities: ReadonlyArray<{ readonly __typename?: 'PendingGameApprovalStatusEntry', readonly status: ApprovalStatus, readonly approvingEntity: { readonly __typename?: 'OrganizationApprovingEntity', readonly id: string, readonly name: any } | { readonly __typename?: 'SeasonTeamApprovingEntity', readonly id: string, readonly name: any } }> };


export type ApprovalStatusDetailsFragmentVariables = Exact<{ [key: string]: never; }>;

export type ApproveGameMutationVariables = Exact<{
  seasonTeamId: Scalars['ID'];
  data: ApproveGameDataInput;
}>;


export type ApproveGameMutation = { readonly __typename?: 'Mutation', readonly seasonTeam: { readonly __typename?: 'SeasonTeamMutations', readonly games: { readonly __typename?: 'GameMutations', readonly approveGame: { readonly __typename?: 'GenericGameActionPayload', readonly error?: Maybe<string>, readonly game?: Maybe<(
          { readonly __typename?: 'CanceledGraphQLGame' }
          & GameDetails_CanceledGraphQlGame_Fragment
        ) | (
          { readonly __typename?: 'ConfirmedGraphQLGame' }
          & GameDetails_ConfirmedGraphQlGame_Fragment
        ) | (
          { readonly __typename?: 'PendingGraphQLGame' }
          & GameDetails_PendingGraphQlGame_Fragment
        )> } } } };

export type AvailableGameActions_CanceledGraphQlGame_Fragment = { readonly __typename?: 'CanceledGraphQLGame', readonly availableActions: { readonly __typename?: 'GameAvailableActions', readonly canApprove: boolean, readonly canReject: boolean, readonly canCancel: boolean, readonly canProposeChange: boolean } };

export type AvailableGameActions_ConfirmedGraphQlGame_Fragment = { readonly __typename?: 'ConfirmedGraphQLGame', readonly availableActions: { readonly __typename?: 'GameAvailableActions', readonly canApprove: boolean, readonly canReject: boolean, readonly canCancel: boolean, readonly canProposeChange: boolean } };

export type AvailableGameActions_PendingGraphQlGame_Fragment = { readonly __typename?: 'PendingGraphQLGame', readonly availableActions: { readonly __typename?: 'GameAvailableActions', readonly canApprove: boolean, readonly canReject: boolean, readonly canCancel: boolean, readonly canProposeChange: boolean } };

export type AvailableGameActionsFragment = AvailableGameActions_CanceledGraphQlGame_Fragment | AvailableGameActions_ConfirmedGraphQlGame_Fragment | AvailableGameActions_PendingGraphQlGame_Fragment;


export type AvailableGameActionsFragmentVariables = Exact<{ [key: string]: never; }>;

export type CancelGameMutationVariables = Exact<{
  seasonTeamId: Scalars['ID'];
  data: CancelGameDataInput;
}>;


export type CancelGameMutation = { readonly __typename?: 'Mutation', readonly seasonTeam: { readonly __typename?: 'SeasonTeamMutations', readonly games: { readonly __typename?: 'GameMutations', readonly cancelGame: { readonly __typename?: 'GenericGameActionPayload', readonly error?: Maybe<string>, readonly game?: Maybe<(
          { readonly __typename?: 'CanceledGraphQLGame' }
          & GameDetails_CanceledGraphQlGame_Fragment
        ) | (
          { readonly __typename?: 'ConfirmedGraphQLGame' }
          & GameDetails_ConfirmedGraphQlGame_Fragment
        ) | (
          { readonly __typename?: 'PendingGraphQLGame' }
          & GameDetails_PendingGraphQlGame_Fragment
        )> } } } };

export type CanceledGameDetailsFragment = { readonly __typename?: 'CanceledGraphQLGame', readonly cancelReason?: Maybe<any>, readonly homeTeam: { readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any }, readonly awayTeam: { readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any }, readonly date: { readonly __typename?: 'ZonedDateTimeParts', readonly timestamp: any, readonly timezone: any }, readonly venue: { readonly __typename?: 'GraphQLVenue', readonly id: string, readonly name: any }, readonly assignedOfficials: ReadonlyArray<{ readonly __typename?: 'GraphQLOfficial', readonly id: string, readonly displayName: any, readonly cellPhoneNumber?: Maybe<any> }> };


export type CanceledGameDetailsFragmentVariables = Exact<{ [key: string]: never; }>;

export type ConfirmedGameDetailsFragment = { readonly __typename?: 'ConfirmedGraphQLGame', readonly canReportScore: boolean, readonly homeTeam: { readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any }, readonly awayTeam: { readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any }, readonly date: { readonly __typename?: 'ZonedDateTimeParts', readonly timestamp: any, readonly timezone: any }, readonly venue: { readonly __typename?: 'GraphQLVenue', readonly id: string, readonly name: any }, readonly assignedOfficials: ReadonlyArray<{ readonly __typename?: 'GraphQLOfficial', readonly id: string, readonly displayName: any, readonly cellPhoneNumber?: Maybe<any> }>, readonly reportedScore?: Maybe<{ readonly __typename?: 'GameResultSubmission', readonly result: { readonly __typename?: 'Decision', readonly homeTeamScore: number, readonly awayTeamScore: number } | { readonly __typename?: 'Forfeit', readonly forfeitingTeam?: Maybe<{ readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any }> } }> };


export type ConfirmedGameDetailsFragmentVariables = Exact<{ [key: string]: never; }>;

export type GameDetails_CanceledGraphQlGame_Fragment = (
  { readonly __typename?: 'CanceledGraphQLGame', readonly id: string, readonly version: number }
  & CanceledGameDetailsFragment
  & AvailableGameActions_CanceledGraphQlGame_Fragment
);

export type GameDetails_ConfirmedGraphQlGame_Fragment = (
  { readonly __typename?: 'ConfirmedGraphQLGame', readonly id: string, readonly version: number }
  & ConfirmedGameDetailsFragment
  & AvailableGameActions_ConfirmedGraphQlGame_Fragment
);

export type GameDetails_PendingGraphQlGame_Fragment = (
  { readonly __typename?: 'PendingGraphQLGame', readonly id: string, readonly version: number }
  & PendingGameDetailsFragment
  & AvailableGameActions_PendingGraphQlGame_Fragment
);

export type GameDetailsFragment = GameDetails_CanceledGraphQlGame_Fragment | GameDetails_ConfirmedGraphQlGame_Fragment | GameDetails_PendingGraphQlGame_Fragment;


export type GameDetailsFragmentVariables = Exact<{ [key: string]: never; }>;

export type GetEventsForGameQueryVariables = Exact<{
  gameId: Scalars['ID'];
}>;


export type GetEventsForGameQuery = { readonly __typename?: 'Query', readonly game?: Maybe<{ readonly __typename?: 'CanceledGraphQLGame', readonly id: string, readonly eventHistory: ReadonlyArray<{ readonly __typename?: 'TeamGraphQLGameEvent', readonly name: string, readonly timestamp: any, readonly actor: { readonly __typename?: 'GraphQLGameSchedulingActorOrganizationStaffMember', readonly entity: { readonly __typename?: 'Organization', readonly id: string, readonly name: any } } | { readonly __typename?: 'GraphQLGameSchedulingActorTeamStaffMember', readonly entity: { readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any } } }> } | { readonly __typename?: 'ConfirmedGraphQLGame', readonly id: string, readonly eventHistory: ReadonlyArray<{ readonly __typename?: 'TeamGraphQLGameEvent', readonly name: string, readonly timestamp: any, readonly actor: { readonly __typename?: 'GraphQLGameSchedulingActorOrganizationStaffMember', readonly entity: { readonly __typename?: 'Organization', readonly id: string, readonly name: any } } | { readonly __typename?: 'GraphQLGameSchedulingActorTeamStaffMember', readonly entity: { readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any } } }> } | { readonly __typename?: 'PendingGraphQLGame', readonly id: string, readonly eventHistory: ReadonlyArray<{ readonly __typename?: 'TeamGraphQLGameEvent', readonly name: string, readonly timestamp: any, readonly actor: { readonly __typename?: 'GraphQLGameSchedulingActorOrganizationStaffMember', readonly entity: { readonly __typename?: 'Organization', readonly id: string, readonly name: any } } | { readonly __typename?: 'GraphQLGameSchedulingActorTeamStaffMember', readonly entity: { readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any } } }> }> };

export type PendingGameDetailsFragment = { readonly __typename?: 'PendingGraphQLGame', readonly homeTeam: { readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any }, readonly awayTeam: { readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any }, readonly date: { readonly __typename?: 'ZonedDateTimeParts', readonly timestamp: any, readonly timezone: any }, readonly venue: { readonly __typename?: 'GraphQLVenue', readonly id: string, readonly name: any }, readonly assignedOfficials: ReadonlyArray<{ readonly __typename?: 'GraphQLOfficial', readonly id: string, readonly displayName: any, readonly cellPhoneNumber?: Maybe<any> }>, readonly approvalStatus: (
    { readonly __typename?: 'GameApprovalStatus' }
    & ApprovalStatusDetailsFragment
  ) };


export type PendingGameDetailsFragmentVariables = Exact<{ [key: string]: never; }>;

export type ProposeSchedulingChangeMutationVariables = Exact<{
  seasonTeamId: Scalars['ID'];
  data: ProposeGameSchedulingChangeDataInput;
}>;


export type ProposeSchedulingChangeMutation = { readonly __typename?: 'Mutation', readonly seasonTeam: { readonly __typename?: 'SeasonTeamMutations', readonly games: { readonly __typename?: 'GameMutations', readonly proposeSchedulingChange: { readonly __typename?: 'GenericGameActionPayload', readonly error?: Maybe<string>, readonly game?: Maybe<(
          { readonly __typename?: 'CanceledGraphQLGame' }
          & GameDetails_CanceledGraphQlGame_Fragment
        ) | (
          { readonly __typename?: 'ConfirmedGraphQLGame' }
          & GameDetails_ConfirmedGraphQlGame_Fragment
        ) | (
          { readonly __typename?: 'PendingGraphQLGame' }
          & GameDetails_PendingGraphQlGame_Fragment
        )> } } } };

export type RejectGameMutationVariables = Exact<{
  seasonTeamId: Scalars['ID'];
  data: RejectGameDataInput;
}>;


export type RejectGameMutation = { readonly __typename?: 'Mutation', readonly seasonTeam: { readonly __typename?: 'SeasonTeamMutations', readonly games: { readonly __typename?: 'GameMutations', readonly rejectGame: { readonly __typename?: 'GenericGameActionPayload', readonly error?: Maybe<string>, readonly game?: Maybe<(
          { readonly __typename?: 'CanceledGraphQLGame' }
          & GameDetails_CanceledGraphQlGame_Fragment
        ) | (
          { readonly __typename?: 'ConfirmedGraphQLGame' }
          & GameDetails_ConfirmedGraphQlGame_Fragment
        ) | (
          { readonly __typename?: 'PendingGraphQLGame' }
          & GameDetails_PendingGraphQlGame_Fragment
        )> } } } };

export type ScheduleGameFormContextQueryVariables = Exact<{
  seasonTeamId: Scalars['ID'];
}>;


export type ScheduleGameFormContextQuery = { readonly __typename?: 'Query', readonly seasonTeam?: Maybe<(
    { readonly __typename?: 'SeasonTeam' }
    & GameSchedulingContextFragment
  )> };

export type ScheduleGameMutationVariables = Exact<{
  seasonTeamId: Scalars['ID'];
  data: CreateGameDataInput;
}>;


export type ScheduleGameMutation = { readonly __typename?: 'Mutation', readonly seasonTeam: { readonly __typename?: 'SeasonTeamMutations', readonly games: { readonly __typename?: 'GameMutations', readonly scheduleGame: { readonly __typename?: 'ScheduleGamePayload', readonly error?: Maybe<string>, readonly game?: Maybe<(
          { readonly __typename?: 'CanceledGraphQLGame' }
          & GameDetails_CanceledGraphQlGame_Fragment
        ) | (
          { readonly __typename?: 'ConfirmedGraphQLGame' }
          & GameDetails_ConfirmedGraphQlGame_Fragment
        ) | (
          { readonly __typename?: 'PendingGraphQLGame' }
          & GameDetails_PendingGraphQlGame_Fragment
        )> } } } };

export type GameSchedulingContextFragment = { readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any, readonly division: { readonly __typename?: 'Division', readonly id: string }, readonly season: { readonly __typename?: 'Season', readonly id: string, readonly allTeams: ReadonlyArray<{ readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any, readonly division: { readonly __typename?: 'Division', readonly id: string, readonly name: any } }>, readonly eligibleGameOpponents: ReadonlyArray<{ readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any, readonly division: { readonly __typename?: 'Division', readonly id: string, readonly name: any, readonly sortValue: number } }>, readonly venues: ReadonlyArray<{ readonly __typename?: 'GraphQLVenue', readonly id: string, readonly name: any }>, readonly collaborativeSchedulingSettings: { readonly __typename?: 'GraphQLCollaborativeSchedulingSettings', readonly schedulingGracePeriod: number, readonly allowedDates: { readonly __typename?: 'DateRange', readonly start: any, readonly end: any }, readonly allowedTimes: { readonly __typename?: 'TimeRange', readonly start: any, readonly end: any } } } };


export type GameSchedulingContextFragmentVariables = Exact<{ [key: string]: never; }>;

export type SeasonTeamHomePageDetailsQueryVariables = Exact<{
  seasonTeamId: Scalars['ID'];
}>;


export type SeasonTeamHomePageDetailsQuery = { readonly __typename?: 'Query', readonly seasonTeam?: Maybe<{ readonly __typename?: 'SeasonTeam', readonly id: string, readonly registrationFormResponses: string, readonly isCommittedToPlayoffs: boolean, readonly sourceTeam: { readonly __typename?: 'Team', readonly id: string, readonly owner: { readonly __typename?: 'LeagueBrainUserProfile', readonly id: string, readonly displayName: any, readonly email: any }, readonly staffMembers: ReadonlyArray<{ readonly __typename?: 'TeamStaffMember', readonly userProfile: { readonly __typename?: 'LeagueBrainUserProfile', readonly id: string, readonly displayName: any, readonly email: any } }> } }> };

export type SeasonTeamActiveOrderQueryVariables = Exact<{
  seasonTeamId: Scalars['ID'];
}>;


export type SeasonTeamActiveOrderQuery = { readonly __typename?: 'Query', readonly seasonTeam?: Maybe<{ readonly __typename?: 'SeasonTeam', readonly id: string, readonly activeOrder: { readonly __typename?: 'SeasonTeamOrder', readonly id: string, readonly formattedTotal: string, readonly lineItems: ReadonlyArray<{ readonly __typename?: 'SeasonTeamOrderLineItem', readonly quantity: number, readonly product: (
          { readonly __typename?: 'Product' }
          & ProductDescriptionFragment
        ), readonly summary: { readonly __typename?: 'Summary', readonly lastPurchasedAt?: Maybe<{ readonly __typename?: 'LastPurchasedAtItem', readonly purchaseDate: any, readonly order: { readonly __typename?: 'SeasonTeamOrder', readonly id: string } }> } }> } }> };

export type AddLineItemsMutationVariables = Exact<{
  data: SetOrderInput;
}>;


export type AddLineItemsMutation = { readonly __typename?: 'Mutation', readonly addLineItems: { readonly __typename?: 'SetOrderPayload', readonly order?: Maybe<{ readonly __typename?: 'SeasonTeamOrder', readonly id: string, readonly formattedTotal: string, readonly lineItems: ReadonlyArray<{ readonly __typename?: 'SeasonTeamOrderLineItem', readonly quantity: number, readonly product: (
          { readonly __typename?: 'Product' }
          & ProductDescriptionFragment
        ), readonly summary: { readonly __typename?: 'Summary', readonly lastPurchasedAt?: Maybe<{ readonly __typename?: 'LastPurchasedAtItem', readonly purchaseDate: any, readonly order: { readonly __typename?: 'SeasonTeamOrder', readonly id: string } }> } }> }> } };

export type AvailableProductsQueryVariables = Exact<{
  seasonTeamId: Scalars['ID'];
}>;


export type AvailableProductsQuery = { readonly __typename?: 'Query', readonly seasonTeam?: Maybe<{ readonly __typename?: 'SeasonTeam', readonly id: string, readonly activeOrder: { readonly __typename?: 'SeasonTeamOrder', readonly id: string, readonly formattedTotal: string, readonly lineItems: ReadonlyArray<{ readonly __typename?: 'SeasonTeamOrderLineItem', readonly quantity: number, readonly product: { readonly __typename?: 'Product', readonly price: { readonly __typename?: 'Price', readonly id: string } } }> }, readonly organization: { readonly __typename?: 'Organization', readonly id: string, readonly products: ReadonlyArray<(
        { readonly __typename?: 'Product' }
        & ProductDescriptionFragment
      )> } }> };

export type FinalizeOrderMutationVariables = Exact<{
  data: FinalizeOrderInput;
}>;


export type FinalizeOrderMutation = { readonly __typename?: 'Mutation', readonly finalizeOrder: { readonly __typename?: 'FinalizeOrderPayload', readonly order?: Maybe<{ readonly __typename?: 'SeasonTeamOrder', readonly id: string, readonly formattedTotal: string, readonly stripePaymentUrl?: Maybe<{ readonly __typename?: 'StripeCheckoutSession', readonly id: string, readonly accountId: string }> }> } };

export type SeasonTeamOrderHistoryQueryVariables = Exact<{
  seasonTeamId: Scalars['ID'];
}>;


export type SeasonTeamOrderHistoryQuery = { readonly __typename?: 'Query', readonly seasonTeam?: Maybe<{ readonly __typename?: 'SeasonTeam', readonly id: string, readonly paidOrders: ReadonlyArray<{ readonly __typename?: 'SeasonTeamOrder', readonly id: string, readonly formattedTotal: string, readonly createdAt: any, readonly lineItems: ReadonlyArray<{ readonly __typename?: 'SeasonTeamOrderLineItem', readonly quantity: number, readonly product: (
          { readonly __typename?: 'Product' }
          & ProductDescriptionFragment
        ), readonly summary: { readonly __typename?: 'Summary', readonly lastPurchasedAt?: Maybe<{ readonly __typename?: 'LastPurchasedAtItem', readonly purchaseDate: any, readonly order: { readonly __typename?: 'SeasonTeamOrder', readonly id: string } }> } }> }> }> };

export type ProductDescriptionFragment = { readonly __typename?: 'Product', readonly id: string, readonly name: any, readonly description?: Maybe<any>, readonly image?: Maybe<any>, readonly price: { readonly __typename?: 'Price', readonly id: string, readonly amount: number, readonly formattedString: string } };


export type ProductDescriptionFragmentVariables = Exact<{ [key: string]: never; }>;

export type RemoveLineItemMutationVariables = Exact<{
  data: RemoveOrderLineItemInput;
}>;


export type RemoveLineItemMutation = { readonly __typename?: 'Mutation', readonly removeLineItem: { readonly __typename?: 'RemoveOrderLineItemPayload', readonly order?: Maybe<{ readonly __typename?: 'SeasonTeamOrder', readonly id: string, readonly formattedTotal: string, readonly lineItems: ReadonlyArray<{ readonly __typename?: 'SeasonTeamOrderLineItem', readonly quantity: number, readonly product: (
          { readonly __typename?: 'Product' }
          & ProductDescriptionFragment
        ), readonly summary: { readonly __typename?: 'Summary', readonly lastPurchasedAt?: Maybe<{ readonly __typename?: 'LastPurchasedAtItem', readonly purchaseDate: any, readonly order: { readonly __typename?: 'SeasonTeamOrder', readonly id: string } }> } }> }> } };

export type SeasonTeamViewingContextQueryVariables = Exact<{
  seasonTeamId: Scalars['ID'];
}>;


export type SeasonTeamViewingContextQuery = { readonly __typename?: 'Query', readonly viewer: { readonly __typename?: 'LeagueBrainUser', readonly id: string }, readonly seasonTeam?: Maybe<{ readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any, readonly viewerRole: TeamStaffMemberRole, readonly organization: { readonly __typename?: 'Organization', readonly id: string, readonly name: any }, readonly season: { readonly __typename?: 'Season', readonly id: string, readonly name: any, readonly isSchedulingOpen: boolean }, readonly division: { readonly __typename?: 'Division', readonly id: string, readonly name: any } }> };

export type SetLineItemQuantityMutationVariables = Exact<{
  data: SetOrderItemQuantityInput;
}>;


export type SetLineItemQuantityMutation = { readonly __typename?: 'Mutation', readonly setOrderItemQuantity: { readonly __typename?: 'SetOrderItemQuantityPayload', readonly order?: Maybe<{ readonly __typename?: 'SeasonTeamOrder', readonly id: string, readonly formattedTotal: string, readonly lineItems: ReadonlyArray<{ readonly __typename?: 'SeasonTeamOrderLineItem', readonly quantity: number, readonly product: (
          { readonly __typename?: 'Product' }
          & ProductDescriptionFragment
        ), readonly summary: { readonly __typename?: 'Summary', readonly lastPurchasedAt?: Maybe<{ readonly __typename?: 'LastPurchasedAtItem', readonly purchaseDate: any, readonly order: { readonly __typename?: 'SeasonTeamOrder', readonly id: string } }> } }> }> } };

export type ReportGameScoreMutationVariables = Exact<{
  seasonTeamId: Scalars['ID'];
  data: ReportScoreDataInput;
}>;


export type ReportGameScoreMutation = { readonly __typename?: 'Mutation', readonly seasonTeam: { readonly __typename?: 'SeasonTeamMutations', readonly games: { readonly __typename?: 'GameMutations', readonly reportScore: { readonly __typename?: 'GenericGameActionPayload', readonly error?: Maybe<string>, readonly game?: Maybe<(
          { readonly __typename?: 'CanceledGraphQLGame' }
          & GameDetails_CanceledGraphQlGame_Fragment
        ) | (
          { readonly __typename?: 'ConfirmedGraphQLGame' }
          & GameDetails_ConfirmedGraphQlGame_Fragment
        ) | (
          { readonly __typename?: 'PendingGraphQLGame' }
          & GameDetails_PendingGraphQlGame_Fragment
        )> } } } };

export type SubmitTeamRequirementMutationVariables = Exact<{
  teamId: Scalars['ID'];
  data: SubmitRequirementDataInput;
}>;


export type SubmitTeamRequirementMutation = { readonly __typename?: 'Mutation', readonly submitRequirement: { readonly __typename?: 'SubmitRequirementPayload', readonly status: { readonly __typename?: 'GraphQLRequirementCompletionStatus', readonly isComplete: boolean, readonly completedCount: number, readonly totalCount: number }, readonly requirement: { readonly __typename?: 'GraphQLAgreementRequirementStatus', readonly id: string, readonly isFulfilled: boolean, readonly completionState?: Maybe<{ readonly __typename?: 'AgreementRequirementCompletionData', readonly completionDate: any, readonly submitterIpAddress: any }>, readonly requirementDefinition: { readonly __typename?: 'AgreementRequirement', readonly id: string, readonly name: any, readonly moreInfo?: Maybe<any>, readonly content: { readonly __typename?: 'FileAgreementContent', readonly url: any } | { readonly __typename?: 'MarkdownAgreementContent', readonly content: any } } } | { readonly __typename?: 'GraphQLFileUploadRequirementStatus', readonly id: string, readonly isFulfilled: boolean, readonly completionState?: Maybe<{ readonly __typename?: 'FileUploadRequirementCompletionData', readonly completionDate: any, readonly submitterIpAddress: any }>, readonly requirementDefinition: { readonly __typename?: 'FileUploadRequirement', readonly supportingFile?: Maybe<any>, readonly id: string, readonly name: any, readonly moreInfo?: Maybe<any> } } } };

export type SeasonTeamRequirementsQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type SeasonTeamRequirementsQuery = { readonly __typename?: 'Query', readonly seasonTeam?: Maybe<{ readonly __typename?: 'SeasonTeam', readonly id: string, readonly hasPaidRegistrationFee: boolean, readonly registrationFeeCheckoutSession?: Maybe<string>, readonly stripeAccountId: string, readonly requirementCompletionStatus: { readonly __typename?: 'GraphQLRequirementCompletionStatus', readonly isComplete: boolean, readonly completedCount: number, readonly totalCount: number }, readonly requirements: ReadonlyArray<{ readonly __typename?: 'GraphQLAgreementRequirementStatus', readonly id: string, readonly isFulfilled: boolean, readonly completionState?: Maybe<{ readonly __typename?: 'AgreementRequirementCompletionData', readonly completionDate: any, readonly submitterIpAddress: any }>, readonly requirementDefinition: { readonly __typename?: 'AgreementRequirement', readonly id: string, readonly name: any, readonly moreInfo?: Maybe<any>, readonly content: { readonly __typename?: 'FileAgreementContent', readonly url: any } | { readonly __typename?: 'MarkdownAgreementContent', readonly content: any } } } | { readonly __typename?: 'GraphQLFileUploadRequirementStatus', readonly id: string, readonly isFulfilled: boolean, readonly completionState?: Maybe<{ readonly __typename?: 'FileUploadRequirementCompletionData', readonly completionDate: any, readonly submitterIpAddress: any }>, readonly requirementDefinition: { readonly __typename?: 'FileUploadRequirement', readonly supportingFile?: Maybe<any>, readonly id: string, readonly name: any, readonly moreInfo?: Maybe<any> } }> }> };

export type AllTeamContactsQueryVariables = Exact<{
  seasonTeamId: Scalars['ID'];
}>;


export type AllTeamContactsQuery = { readonly __typename?: 'Query', readonly seasonTeam?: Maybe<{ readonly __typename?: 'SeasonTeam', readonly teamContacts?: Maybe<ReadonlyArray<{ readonly __typename?: 'TeamContactEntry', readonly teamDisplayName: string, readonly headCoachName: string, readonly headCoachEmail: string, readonly headCoachCellPhone: string, readonly schedulerName: string, readonly schedulerEmail: string, readonly schedulerCellPhone: string, readonly distanceInMiles?: Maybe<number>, readonly confirmedGameCount: number }>> }> };

export type UpdateSeasonTeamPlayoffCommitmentMutationVariables = Exact<{
  seasonTeamId: Scalars['ID'];
  status: Scalars['Boolean'];
}>;


export type UpdateSeasonTeamPlayoffCommitmentMutation = { readonly __typename?: 'Mutation', readonly seasonTeam: { readonly __typename?: 'SeasonTeamMutations', readonly updatePlayoffCommitmentStatus: { readonly __typename?: 'UpdateSeasonTeamPlayoffCommitmentStatusPayload', readonly team: { readonly __typename?: 'SeasonTeam', readonly id: string, readonly isCommittedToPlayoffs: boolean } } } };

export type AllTeamsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Cursor']>;
}>;


export type AllTeamsQuery = { readonly __typename?: 'Query', readonly teams: { readonly __typename?: 'MyTeamsConnection', readonly totalCount: number, readonly edges: ReadonlyArray<{ readonly __typename?: 'TeamEdge', readonly cursor: any, readonly node: { readonly __typename?: 'Team', readonly id: string, readonly name: any, readonly isOwner: boolean, readonly avatar: any, readonly description?: Maybe<any>, readonly isRegisteredForAtLeastOneSeason: boolean } }>, readonly pageInfo: { readonly __typename?: 'PageInfo', readonly endCursor: any, readonly hasNextPage: boolean } } };

export type AddTeamMutationVariables = Exact<{
  data: AddTeamInput;
}>;


export type AddTeamMutation = { readonly __typename?: 'Mutation', readonly addTeam: { readonly __typename?: 'AddTeamPayload', readonly team?: Maybe<{ readonly __typename?: 'Team', readonly id: string, readonly name: any, readonly description?: Maybe<any>, readonly avatar: any }> } };

export type DeleteTeamMutationVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type DeleteTeamMutation = { readonly __typename?: 'Mutation', readonly deleteTeam: { readonly __typename?: 'DeleteTeamPayload', readonly team?: Maybe<{ readonly __typename?: 'Team', readonly id: string, readonly name: any }> } };

export type TeamByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamByIdQuery = { readonly __typename?: 'Query', readonly team?: Maybe<{ readonly __typename?: 'Team', readonly id: string, readonly name: any, readonly avatar: any, readonly description?: Maybe<any>, readonly viewerRole: TeamStaffMemberRole }> };

export type UpdateTeamDetailsMutationVariables = Exact<{
  teamId: Scalars['ID'];
  details: UpdateTeamDetailsInput;
}>;


export type UpdateTeamDetailsMutation = { readonly __typename?: 'Mutation', readonly updateTeamDetails: { readonly __typename?: 'Team', readonly id: string, readonly name: any, readonly avatar: any, readonly description?: Maybe<any> } };

export type AcceptTeamStaffMemberInviteMutationVariables = Exact<{
  inviteId: Scalars['ID'];
}>;


export type AcceptTeamStaffMemberInviteMutation = { readonly __typename?: 'Mutation', readonly acceptTeamStaffInvite: { readonly __typename?: 'AcceptTeamStaffInvitePayload', readonly success: boolean, readonly userError?: Maybe<{ readonly __typename?: 'UserError', readonly type: string, readonly description: string }> } };

export type AcceptTeamStaffInviteFragment = { readonly __typename?: 'TeamStaffMemberInvite', readonly id: string, readonly userEmail: any, readonly invitingTeam: { readonly __typename?: 'Team', readonly id: string, readonly name: any } };


export type AcceptTeamStaffInviteFragmentVariables = Exact<{ [key: string]: never; }>;

export type GetTeamStaffMemberInviteByIdQueryVariables = Exact<{
  inviteId: Scalars['ID'];
}>;


export type GetTeamStaffMemberInviteByIdQuery = { readonly __typename?: 'Query', readonly teamStaffMemberInvite?: Maybe<(
    { readonly __typename?: 'TeamStaffMemberInvite' }
    & AcceptTeamStaffInviteFragment
  )> };

export type RegistrationFormAvailableTeamEdgeFragment = { readonly __typename?: 'AvailableTeamEdge', readonly isEligible: boolean, readonly node: { readonly __typename?: 'Team', readonly id: string, readonly avatar: any, readonly name: any } };


export type RegistrationFormAvailableTeamEdgeFragmentVariables = Exact<{ [key: string]: never; }>;

export type RegistrationFormForSeasonQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  seasonId: Scalars['ID'];
}>;


export type RegistrationFormForSeasonQuery = { readonly __typename?: 'Query', readonly organization?: Maybe<{ readonly __typename?: 'Organization', readonly id: string, readonly name: any, readonly season?: Maybe<{ readonly __typename?: 'Season', readonly id: string, readonly name: any, readonly registrationForm?: Maybe<{ readonly __typename?: 'SimpleRegistrationForm', readonly name: any, readonly isOpen: boolean, readonly formDefinition: string, readonly availableTeams: { readonly __typename?: 'AvailableTeamsConnection', readonly edges: ReadonlyArray<(
            { readonly __typename?: 'AvailableTeamEdge' }
            & RegistrationFormAvailableTeamEdgeFragment
          )> } }>, readonly divisions: ReadonlyArray<(
        { readonly __typename?: 'Division' }
        & SimpleDivisionDescriptionFragment
      )> }> }> };

export type SimpleDivisionDescriptionFragment = { readonly __typename?: 'Division', readonly id: string, readonly name: any, readonly specification: ReadonlyArray<{ readonly __typename?: 'SpecificationPart', readonly group: { readonly __typename?: 'DivisionDescriptorGroup', readonly id: string, readonly name: any, readonly description?: Maybe<any> }, readonly descriptor: { readonly __typename?: 'DivisionDescriptor', readonly id: string, readonly name: any, readonly description?: Maybe<any> } }> };


export type SimpleDivisionDescriptionFragmentVariables = Exact<{ [key: string]: never; }>;

export type SubmitRegistrationFormResponseMutationVariables = Exact<{
  data: RegisterTeamDataInput;
}>;


export type SubmitRegistrationFormResponseMutation = { readonly __typename?: 'Mutation', readonly submitRegistrationResponseNEW: { readonly __typename?: 'SubmitRegistrationResponsePayload', readonly team?: Maybe<{ readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any }> } };

export type SubmitRegistrationResponseMutationVariables = Exact<{
  response: Scalars['NonBlankString'];
}>;


export type SubmitRegistrationResponseMutation = { readonly __typename?: 'Mutation', readonly submitRegistrationResponse: { readonly __typename?: 'SubmitRegistrationResponsePayload', readonly team?: Maybe<{ readonly __typename?: 'SeasonTeam', readonly id: string, readonly name: any }> } };

export type DeletePendingTeamStaffMemberInviteMutationVariables = Exact<{
  teamId: Scalars['ID'];
  invitationId: Scalars['ID'];
}>;


export type DeletePendingTeamStaffMemberInviteMutation = { readonly __typename?: 'Mutation', readonly team?: Maybe<{ readonly __typename?: 'TeamOperations', readonly withdrawStaffMemberInvite: { readonly __typename?: 'DeleteStaffMemberInvitePayload', readonly success: boolean, readonly userError?: Maybe<{ readonly __typename?: 'UserError', readonly type: string, readonly description: string }> } }> };

export type TeamInviteStaffMemberMutationVariables = Exact<{
  teamId: Scalars['ID'];
  email: Scalars['Email'];
}>;


export type TeamInviteStaffMemberMutation = { readonly __typename?: 'Mutation', readonly team?: Maybe<{ readonly __typename?: 'TeamOperations', readonly inviteStaffMember: { readonly __typename?: 'InviteStaffMemberToTeamPayload', readonly invite?: Maybe<{ readonly __typename?: 'TeamStaffMemberInvite', readonly id: string, readonly userEmail: any }>, readonly userError?: Maybe<{ readonly __typename?: 'UserError', readonly type: string, readonly description: string }> } }> };

export type TeamPendingStaffMemberListFragment = { readonly __typename?: 'Team', readonly id: string, readonly viewerRole: TeamStaffMemberRole, readonly pendingStaffMembers: ReadonlyArray<{ readonly __typename?: 'PendingTeamStaffMember', readonly id: string, readonly userEmail: any }> };


export type TeamPendingStaffMemberListFragmentVariables = Exact<{ [key: string]: never; }>;

export type RemoveTeamStaffMemberMutationVariables = Exact<{
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type RemoveTeamStaffMemberMutation = { readonly __typename?: 'Mutation', readonly team?: Maybe<{ readonly __typename?: 'TeamOperations', readonly removeStaffMember: { readonly __typename?: 'RemoveUserFromTeamPayload', readonly success: boolean, readonly userError?: Maybe<{ readonly __typename?: 'UserError', readonly type: string, readonly description: string }> } }> };

export type TeamStaffMemberListFragment = { readonly __typename?: 'Team', readonly id: string, readonly viewerRole: TeamStaffMemberRole, readonly owner: { readonly __typename?: 'LeagueBrainUserProfile', readonly id: string, readonly displayName: any, readonly avatar: any }, readonly staffMembers: ReadonlyArray<{ readonly __typename?: 'TeamStaffMember', readonly role: TeamStaffMemberRole, readonly userProfile: { readonly __typename?: 'LeagueBrainUserProfile', readonly id: string, readonly avatar: any, readonly displayName: any } }> };


export type TeamStaffMemberListFragmentVariables = Exact<{ [key: string]: never; }>;

export type StaffMembersByTeamQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type StaffMembersByTeamQuery = { readonly __typename?: 'Query', readonly team?: Maybe<(
    { readonly __typename?: 'Team', readonly name: any }
    & TeamStaffMemberListFragment
    & TeamPendingStaffMemberListFragment
  )> };

export type GetMessagesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetMessagesSubscription = { readonly __typename?: 'Subscription', readonly messages: { readonly __typename?: 'TestMessage', readonly from: string, readonly message: string } };

export type PublishMessageMutationVariables = Exact<{
  message: Scalars['String'];
}>;


export type PublishMessageMutation = { readonly __typename?: 'Mutation', readonly sendTestMessage: boolean };

export const ManageUserProfileFieldsFragmentDoc = gql`
    fragment ManageUserProfileFields on LeagueBrainUser {
  id
  email
  realName
  displayName
  defaultAvatar
  avatar
}
    `;
export const AvailableGameActionsFragmentDoc = gql`
    fragment AvailableGameActions on GraphQLGame {
  availableActions(teamId: $seasonTeamId) {
    canApprove
    canReject
    canCancel
    canProposeChange
  }
}
    `;
export const ApprovalStatusDetailsFragmentDoc = gql`
    fragment ApprovalStatusDetails on GameApprovalStatus {
  approvingEntities {
    status
    approvingEntity {
      ... on OrganizationApprovingEntity {
        id
        name
      }
      ... on SeasonTeamApprovingEntity {
        id
        name
      }
    }
  }
}
    `;
export const PendingGameDetailsFragmentDoc = gql`
    fragment PendingGameDetails on PendingGraphQLGame {
  homeTeam {
    id
    name: qualifiedName
  }
  awayTeam {
    id
    name: qualifiedName
  }
  date {
    timestamp
    timezone
  }
  venue {
    id
    name
  }
  assignedOfficials {
    id
    displayName
    cellPhoneNumber
  }
  approvalStatus {
    ...ApprovalStatusDetails
  }
}
    ${ApprovalStatusDetailsFragmentDoc}`;
export const ConfirmedGameDetailsFragmentDoc = gql`
    fragment ConfirmedGameDetails on ConfirmedGraphQLGame {
  homeTeam {
    id
    name: qualifiedName
  }
  awayTeam {
    id
    name: qualifiedName
  }
  date {
    timestamp
    timezone
  }
  venue {
    id
    name
  }
  assignedOfficials {
    id
    displayName
    cellPhoneNumber
  }
  reportedScore {
    result {
      ... on Decision {
        homeTeamScore
        awayTeamScore
      }
      ... on Forfeit {
        forfeitingTeam {
          id
          name
        }
      }
    }
  }
  canReportScore
}
    `;
export const CanceledGameDetailsFragmentDoc = gql`
    fragment CanceledGameDetails on CanceledGraphQLGame {
  homeTeam {
    id
    name: qualifiedName
  }
  awayTeam {
    id
    name: qualifiedName
  }
  date {
    timestamp
    timezone
  }
  venue {
    id
    name
  }
  assignedOfficials {
    id
    displayName
    cellPhoneNumber
  }
  cancelReason
}
    `;
export const GameDetailsFragmentDoc = gql`
    fragment GameDetails on GraphQLGame {
  id
  version
  ...AvailableGameActions
  ... on PendingGraphQLGame {
    ...PendingGameDetails
  }
  ... on ConfirmedGraphQLGame {
    ...ConfirmedGameDetails
  }
  ... on CanceledGraphQLGame {
    ...CanceledGameDetails
  }
}
    ${AvailableGameActionsFragmentDoc}
${PendingGameDetailsFragmentDoc}
${ConfirmedGameDetailsFragmentDoc}
${CanceledGameDetailsFragmentDoc}`;
export const GameSchedulingContextFragmentDoc = gql`
    fragment GameSchedulingContext on SeasonTeam {
  id
  name
  division {
    id
  }
  season {
    id
    allTeams: eligibleGameOpponents {
      id
      name: qualifiedName
      division {
        id
        name
      }
    }
    eligibleGameOpponents(seasonTeamId: $seasonTeamId) {
      id
      name: qualifiedName
      division {
        id
        name
        sortValue
      }
    }
    venues {
      id
      name
    }
    collaborativeSchedulingSettings {
      allowedDates {
        start
        end
      }
      allowedTimes {
        start
        end
      }
      schedulingGracePeriod
    }
  }
}
    `;
export const ProductDescriptionFragmentDoc = gql`
    fragment ProductDescription on Product {
  id
  name
  description
  image
  price {
    id
    amount
    formattedString
  }
}
    `;
export const AcceptTeamStaffInviteFragmentDoc = gql`
    fragment AcceptTeamStaffInvite on TeamStaffMemberInvite {
  id
  userEmail
  invitingTeam {
    id
    name
  }
}
    `;
export const RegistrationFormAvailableTeamEdgeFragmentDoc = gql`
    fragment RegistrationFormAvailableTeamEdge on AvailableTeamEdge {
  isEligible
  node {
    id
    avatar
    name
  }
}
    `;
export const SimpleDivisionDescriptionFragmentDoc = gql`
    fragment SimpleDivisionDescription on Division {
  id
  name
  specification {
    group {
      id
      name
      description
    }
    descriptor {
      id
      name
      description
    }
  }
}
    `;
export const TeamPendingStaffMemberListFragmentDoc = gql`
    fragment TeamPendingStaffMemberList on Team {
  id
  pendingStaffMembers {
    id
    userEmail
  }
  viewerRole
}
    `;
export const TeamStaffMemberListFragmentDoc = gql`
    fragment TeamStaffMemberList on Team {
  id
  owner {
    id
    displayName
    avatar
  }
  staffMembers {
    userProfile {
      id
      avatar
      displayName
    }
    role
  }
  viewerRole
}
    `;
export const GetUserInfoDocument = gql`
    query GetUserInfo {
  viewer {
    ...ManageUserProfileFields
  }
}
    ${ManageUserProfileFieldsFragmentDoc}`;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
      }
export function useGetUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
        }
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = Apollo.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;
export const UpdateUserAvatarDocument = gql`
    mutation UpdateUserAvatar($avatar: Upload) {
  updateUserAvatar(imageFile: $avatar) {
    user {
      id
      avatar
    }
  }
}
    `;
export type UpdateUserAvatarMutationFn = Apollo.MutationFunction<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>;

/**
 * __useUpdateUserAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAvatarMutation, { data, loading, error }] = useUpdateUserAvatarMutation({
 *   variables: {
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUpdateUserAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>(UpdateUserAvatarDocument, options);
      }
export type UpdateUserAvatarMutationHookResult = ReturnType<typeof useUpdateUserAvatarMutation>;
export type UpdateUserAvatarMutationResult = Apollo.MutationResult<UpdateUserAvatarMutation>;
export type UpdateUserAvatarMutationOptions = Apollo.BaseMutationOptions<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>;
export const UpdateUserInfoDocument = gql`
    mutation UpdateUserInfo($input: UpdateUserInfoInput!) {
  updateUserInfo(input: $input) {
    user {
      id
      realName
      displayName
    }
  }
}
    `;
export type UpdateUserInfoMutationFn = Apollo.MutationFunction<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>(UpdateUserInfoDocument, options);
      }
export type UpdateUserInfoMutationHookResult = ReturnType<typeof useUpdateUserInfoMutation>;
export type UpdateUserInfoMutationResult = Apollo.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = Apollo.BaseMutationOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;
export const AllTeamSeasonsDocument = gql`
    query AllTeamSeasons {
  teamSeasons {
    seasonTeamId
    teamName
    organizationName
    seasonName
    divisionName
  }
}
    `;

/**
 * __useAllTeamSeasonsQuery__
 *
 * To run a query within a React component, call `useAllTeamSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTeamSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTeamSeasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTeamSeasonsQuery(baseOptions?: Apollo.QueryHookOptions<AllTeamSeasonsQuery, AllTeamSeasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTeamSeasonsQuery, AllTeamSeasonsQueryVariables>(AllTeamSeasonsDocument, options);
      }
export function useAllTeamSeasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTeamSeasonsQuery, AllTeamSeasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTeamSeasonsQuery, AllTeamSeasonsQueryVariables>(AllTeamSeasonsDocument, options);
        }
export type AllTeamSeasonsQueryHookResult = ReturnType<typeof useAllTeamSeasonsQuery>;
export type AllTeamSeasonsLazyQueryHookResult = ReturnType<typeof useAllTeamSeasonsLazyQuery>;
export type AllTeamSeasonsQueryResult = Apollo.QueryResult<AllTeamSeasonsQuery, AllTeamSeasonsQueryVariables>;
export const AllGamesDocument = gql`
    query AllGames($seasonTeamId: ID!) {
  seasonTeam(id: $seasonTeamId) {
    id
    ...GameSchedulingContext
    games {
      ...GameDetails
    }
  }
}
    ${GameSchedulingContextFragmentDoc}
${GameDetailsFragmentDoc}`;

/**
 * __useAllGamesQuery__
 *
 * To run a query within a React component, call `useAllGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGamesQuery({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *   },
 * });
 */
export function useAllGamesQuery(baseOptions: Apollo.QueryHookOptions<AllGamesQuery, AllGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllGamesQuery, AllGamesQueryVariables>(AllGamesDocument, options);
      }
export function useAllGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllGamesQuery, AllGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllGamesQuery, AllGamesQueryVariables>(AllGamesDocument, options);
        }
export type AllGamesQueryHookResult = ReturnType<typeof useAllGamesQuery>;
export type AllGamesLazyQueryHookResult = ReturnType<typeof useAllGamesLazyQuery>;
export type AllGamesQueryResult = Apollo.QueryResult<AllGamesQuery, AllGamesQueryVariables>;
export const ApproveGameDocument = gql`
    mutation ApproveGame($seasonTeamId: ID!, $data: ApproveGameDataInput!) {
  seasonTeam(id: $seasonTeamId) {
    games {
      approveGame(data: $data) {
        game {
          ...GameDetails
        }
        error
      }
    }
  }
}
    ${GameDetailsFragmentDoc}`;
export type ApproveGameMutationFn = Apollo.MutationFunction<ApproveGameMutation, ApproveGameMutationVariables>;

/**
 * __useApproveGameMutation__
 *
 * To run a mutation, you first call `useApproveGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveGameMutation, { data, loading, error }] = useApproveGameMutation({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useApproveGameMutation(baseOptions?: Apollo.MutationHookOptions<ApproveGameMutation, ApproveGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveGameMutation, ApproveGameMutationVariables>(ApproveGameDocument, options);
      }
export type ApproveGameMutationHookResult = ReturnType<typeof useApproveGameMutation>;
export type ApproveGameMutationResult = Apollo.MutationResult<ApproveGameMutation>;
export type ApproveGameMutationOptions = Apollo.BaseMutationOptions<ApproveGameMutation, ApproveGameMutationVariables>;
export const CancelGameDocument = gql`
    mutation CancelGame($seasonTeamId: ID!, $data: CancelGameDataInput!) {
  seasonTeam(id: $seasonTeamId) {
    games {
      cancelGame(data: $data) {
        game {
          ...GameDetails
        }
        error
      }
    }
  }
}
    ${GameDetailsFragmentDoc}`;
export type CancelGameMutationFn = Apollo.MutationFunction<CancelGameMutation, CancelGameMutationVariables>;

/**
 * __useCancelGameMutation__
 *
 * To run a mutation, you first call `useCancelGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelGameMutation, { data, loading, error }] = useCancelGameMutation({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCancelGameMutation(baseOptions?: Apollo.MutationHookOptions<CancelGameMutation, CancelGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelGameMutation, CancelGameMutationVariables>(CancelGameDocument, options);
      }
export type CancelGameMutationHookResult = ReturnType<typeof useCancelGameMutation>;
export type CancelGameMutationResult = Apollo.MutationResult<CancelGameMutation>;
export type CancelGameMutationOptions = Apollo.BaseMutationOptions<CancelGameMutation, CancelGameMutationVariables>;
export const GetEventsForGameDocument = gql`
    query GetEventsForGame($gameId: ID!) {
  game(id: $gameId) {
    id
    eventHistory {
      name
      timestamp
      actor {
        ... on GraphQLGameSchedulingActorOrganizationStaffMember {
          entity: organization {
            id
            name
          }
        }
        ... on GraphQLGameSchedulingActorTeamStaffMember {
          entity: seasonTeam {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEventsForGameQuery__
 *
 * To run a query within a React component, call `useGetEventsForGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsForGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsForGameQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useGetEventsForGameQuery(baseOptions: Apollo.QueryHookOptions<GetEventsForGameQuery, GetEventsForGameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsForGameQuery, GetEventsForGameQueryVariables>(GetEventsForGameDocument, options);
      }
export function useGetEventsForGameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsForGameQuery, GetEventsForGameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsForGameQuery, GetEventsForGameQueryVariables>(GetEventsForGameDocument, options);
        }
export type GetEventsForGameQueryHookResult = ReturnType<typeof useGetEventsForGameQuery>;
export type GetEventsForGameLazyQueryHookResult = ReturnType<typeof useGetEventsForGameLazyQuery>;
export type GetEventsForGameQueryResult = Apollo.QueryResult<GetEventsForGameQuery, GetEventsForGameQueryVariables>;
export const ProposeSchedulingChangeDocument = gql`
    mutation ProposeSchedulingChange($seasonTeamId: ID!, $data: ProposeGameSchedulingChangeDataInput!) {
  seasonTeam(id: $seasonTeamId) {
    games {
      proposeSchedulingChange(data: $data) {
        game {
          ...GameDetails
        }
        error
      }
    }
  }
}
    ${GameDetailsFragmentDoc}`;
export type ProposeSchedulingChangeMutationFn = Apollo.MutationFunction<ProposeSchedulingChangeMutation, ProposeSchedulingChangeMutationVariables>;

/**
 * __useProposeSchedulingChangeMutation__
 *
 * To run a mutation, you first call `useProposeSchedulingChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProposeSchedulingChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [proposeSchedulingChangeMutation, { data, loading, error }] = useProposeSchedulingChangeMutation({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProposeSchedulingChangeMutation(baseOptions?: Apollo.MutationHookOptions<ProposeSchedulingChangeMutation, ProposeSchedulingChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProposeSchedulingChangeMutation, ProposeSchedulingChangeMutationVariables>(ProposeSchedulingChangeDocument, options);
      }
export type ProposeSchedulingChangeMutationHookResult = ReturnType<typeof useProposeSchedulingChangeMutation>;
export type ProposeSchedulingChangeMutationResult = Apollo.MutationResult<ProposeSchedulingChangeMutation>;
export type ProposeSchedulingChangeMutationOptions = Apollo.BaseMutationOptions<ProposeSchedulingChangeMutation, ProposeSchedulingChangeMutationVariables>;
export const RejectGameDocument = gql`
    mutation RejectGame($seasonTeamId: ID!, $data: RejectGameDataInput!) {
  seasonTeam(id: $seasonTeamId) {
    games {
      rejectGame(data: $data) {
        game {
          ...GameDetails
        }
        error
      }
    }
  }
}
    ${GameDetailsFragmentDoc}`;
export type RejectGameMutationFn = Apollo.MutationFunction<RejectGameMutation, RejectGameMutationVariables>;

/**
 * __useRejectGameMutation__
 *
 * To run a mutation, you first call `useRejectGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectGameMutation, { data, loading, error }] = useRejectGameMutation({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRejectGameMutation(baseOptions?: Apollo.MutationHookOptions<RejectGameMutation, RejectGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectGameMutation, RejectGameMutationVariables>(RejectGameDocument, options);
      }
export type RejectGameMutationHookResult = ReturnType<typeof useRejectGameMutation>;
export type RejectGameMutationResult = Apollo.MutationResult<RejectGameMutation>;
export type RejectGameMutationOptions = Apollo.BaseMutationOptions<RejectGameMutation, RejectGameMutationVariables>;
export const ScheduleGameFormContextDocument = gql`
    query ScheduleGameFormContext($seasonTeamId: ID!) {
  seasonTeam(id: $seasonTeamId) {
    ...GameSchedulingContext
  }
}
    ${GameSchedulingContextFragmentDoc}`;

/**
 * __useScheduleGameFormContextQuery__
 *
 * To run a query within a React component, call `useScheduleGameFormContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleGameFormContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleGameFormContextQuery({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *   },
 * });
 */
export function useScheduleGameFormContextQuery(baseOptions: Apollo.QueryHookOptions<ScheduleGameFormContextQuery, ScheduleGameFormContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleGameFormContextQuery, ScheduleGameFormContextQueryVariables>(ScheduleGameFormContextDocument, options);
      }
export function useScheduleGameFormContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleGameFormContextQuery, ScheduleGameFormContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleGameFormContextQuery, ScheduleGameFormContextQueryVariables>(ScheduleGameFormContextDocument, options);
        }
export type ScheduleGameFormContextQueryHookResult = ReturnType<typeof useScheduleGameFormContextQuery>;
export type ScheduleGameFormContextLazyQueryHookResult = ReturnType<typeof useScheduleGameFormContextLazyQuery>;
export type ScheduleGameFormContextQueryResult = Apollo.QueryResult<ScheduleGameFormContextQuery, ScheduleGameFormContextQueryVariables>;
export const ScheduleGameDocument = gql`
    mutation ScheduleGame($seasonTeamId: ID!, $data: CreateGameDataInput!) {
  seasonTeam(id: $seasonTeamId) {
    games {
      scheduleGame(data: $data) {
        game {
          ...GameDetails
        }
        error
      }
    }
  }
}
    ${GameDetailsFragmentDoc}`;
export type ScheduleGameMutationFn = Apollo.MutationFunction<ScheduleGameMutation, ScheduleGameMutationVariables>;

/**
 * __useScheduleGameMutation__
 *
 * To run a mutation, you first call `useScheduleGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleGameMutation, { data, loading, error }] = useScheduleGameMutation({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useScheduleGameMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleGameMutation, ScheduleGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleGameMutation, ScheduleGameMutationVariables>(ScheduleGameDocument, options);
      }
export type ScheduleGameMutationHookResult = ReturnType<typeof useScheduleGameMutation>;
export type ScheduleGameMutationResult = Apollo.MutationResult<ScheduleGameMutation>;
export type ScheduleGameMutationOptions = Apollo.BaseMutationOptions<ScheduleGameMutation, ScheduleGameMutationVariables>;
export const SeasonTeamHomePageDetailsDocument = gql`
    query SeasonTeamHomePageDetails($seasonTeamId: ID!) {
  seasonTeam(id: $seasonTeamId) {
    id
    registrationFormResponses
    isCommittedToPlayoffs
    sourceTeam {
      id
      owner {
        id
        displayName
        email
      }
      staffMembers {
        userProfile {
          id
          displayName
          email
        }
      }
    }
  }
}
    `;

/**
 * __useSeasonTeamHomePageDetailsQuery__
 *
 * To run a query within a React component, call `useSeasonTeamHomePageDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonTeamHomePageDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonTeamHomePageDetailsQuery({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *   },
 * });
 */
export function useSeasonTeamHomePageDetailsQuery(baseOptions: Apollo.QueryHookOptions<SeasonTeamHomePageDetailsQuery, SeasonTeamHomePageDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonTeamHomePageDetailsQuery, SeasonTeamHomePageDetailsQueryVariables>(SeasonTeamHomePageDetailsDocument, options);
      }
export function useSeasonTeamHomePageDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonTeamHomePageDetailsQuery, SeasonTeamHomePageDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonTeamHomePageDetailsQuery, SeasonTeamHomePageDetailsQueryVariables>(SeasonTeamHomePageDetailsDocument, options);
        }
export type SeasonTeamHomePageDetailsQueryHookResult = ReturnType<typeof useSeasonTeamHomePageDetailsQuery>;
export type SeasonTeamHomePageDetailsLazyQueryHookResult = ReturnType<typeof useSeasonTeamHomePageDetailsLazyQuery>;
export type SeasonTeamHomePageDetailsQueryResult = Apollo.QueryResult<SeasonTeamHomePageDetailsQuery, SeasonTeamHomePageDetailsQueryVariables>;
export const SeasonTeamActiveOrderDocument = gql`
    query SeasonTeamActiveOrder($seasonTeamId: ID!) {
  seasonTeam(id: $seasonTeamId) {
    id
    activeOrder {
      id
      lineItems {
        quantity
        product {
          ...ProductDescription
        }
        summary {
          lastPurchasedAt {
            purchaseDate
            order {
              id
            }
          }
        }
      }
      formattedTotal
    }
  }
}
    ${ProductDescriptionFragmentDoc}`;

/**
 * __useSeasonTeamActiveOrderQuery__
 *
 * To run a query within a React component, call `useSeasonTeamActiveOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonTeamActiveOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonTeamActiveOrderQuery({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *   },
 * });
 */
export function useSeasonTeamActiveOrderQuery(baseOptions: Apollo.QueryHookOptions<SeasonTeamActiveOrderQuery, SeasonTeamActiveOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonTeamActiveOrderQuery, SeasonTeamActiveOrderQueryVariables>(SeasonTeamActiveOrderDocument, options);
      }
export function useSeasonTeamActiveOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonTeamActiveOrderQuery, SeasonTeamActiveOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonTeamActiveOrderQuery, SeasonTeamActiveOrderQueryVariables>(SeasonTeamActiveOrderDocument, options);
        }
export type SeasonTeamActiveOrderQueryHookResult = ReturnType<typeof useSeasonTeamActiveOrderQuery>;
export type SeasonTeamActiveOrderLazyQueryHookResult = ReturnType<typeof useSeasonTeamActiveOrderLazyQuery>;
export type SeasonTeamActiveOrderQueryResult = Apollo.QueryResult<SeasonTeamActiveOrderQuery, SeasonTeamActiveOrderQueryVariables>;
export const AddLineItemsDocument = gql`
    mutation AddLineItems($data: SetOrderInput!) {
  addLineItems(data: $data) {
    order {
      id
      lineItems {
        quantity
        product {
          ...ProductDescription
        }
        summary {
          lastPurchasedAt {
            purchaseDate
            order {
              id
            }
          }
        }
      }
      formattedTotal
    }
  }
}
    ${ProductDescriptionFragmentDoc}`;
export type AddLineItemsMutationFn = Apollo.MutationFunction<AddLineItemsMutation, AddLineItemsMutationVariables>;

/**
 * __useAddLineItemsMutation__
 *
 * To run a mutation, you first call `useAddLineItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLineItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLineItemsMutation, { data, loading, error }] = useAddLineItemsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddLineItemsMutation(baseOptions?: Apollo.MutationHookOptions<AddLineItemsMutation, AddLineItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLineItemsMutation, AddLineItemsMutationVariables>(AddLineItemsDocument, options);
      }
export type AddLineItemsMutationHookResult = ReturnType<typeof useAddLineItemsMutation>;
export type AddLineItemsMutationResult = Apollo.MutationResult<AddLineItemsMutation>;
export type AddLineItemsMutationOptions = Apollo.BaseMutationOptions<AddLineItemsMutation, AddLineItemsMutationVariables>;
export const AvailableProductsDocument = gql`
    query AvailableProducts($seasonTeamId: ID!) {
  seasonTeam(id: $seasonTeamId) {
    id
    activeOrder {
      id
      formattedTotal
      lineItems {
        product {
          price {
            id
          }
        }
        quantity
      }
    }
    organization {
      id
      products {
        ...ProductDescription
      }
    }
  }
}
    ${ProductDescriptionFragmentDoc}`;

/**
 * __useAvailableProductsQuery__
 *
 * To run a query within a React component, call `useAvailableProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableProductsQuery({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *   },
 * });
 */
export function useAvailableProductsQuery(baseOptions: Apollo.QueryHookOptions<AvailableProductsQuery, AvailableProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableProductsQuery, AvailableProductsQueryVariables>(AvailableProductsDocument, options);
      }
export function useAvailableProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableProductsQuery, AvailableProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableProductsQuery, AvailableProductsQueryVariables>(AvailableProductsDocument, options);
        }
export type AvailableProductsQueryHookResult = ReturnType<typeof useAvailableProductsQuery>;
export type AvailableProductsLazyQueryHookResult = ReturnType<typeof useAvailableProductsLazyQuery>;
export type AvailableProductsQueryResult = Apollo.QueryResult<AvailableProductsQuery, AvailableProductsQueryVariables>;
export const FinalizeOrderDocument = gql`
    mutation FinalizeOrder($data: FinalizeOrderInput!) {
  finalizeOrder(data: $data) {
    order {
      id
      formattedTotal
      stripePaymentUrl {
        id
        accountId
      }
    }
  }
}
    `;
export type FinalizeOrderMutationFn = Apollo.MutationFunction<FinalizeOrderMutation, FinalizeOrderMutationVariables>;

/**
 * __useFinalizeOrderMutation__
 *
 * To run a mutation, you first call `useFinalizeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeOrderMutation, { data, loading, error }] = useFinalizeOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFinalizeOrderMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeOrderMutation, FinalizeOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeOrderMutation, FinalizeOrderMutationVariables>(FinalizeOrderDocument, options);
      }
export type FinalizeOrderMutationHookResult = ReturnType<typeof useFinalizeOrderMutation>;
export type FinalizeOrderMutationResult = Apollo.MutationResult<FinalizeOrderMutation>;
export type FinalizeOrderMutationOptions = Apollo.BaseMutationOptions<FinalizeOrderMutation, FinalizeOrderMutationVariables>;
export const SeasonTeamOrderHistoryDocument = gql`
    query SeasonTeamOrderHistory($seasonTeamId: ID!) {
  seasonTeam(id: $seasonTeamId) {
    id
    paidOrders {
      id
      lineItems {
        quantity
        product {
          ...ProductDescription
        }
        summary {
          lastPurchasedAt {
            purchaseDate
            order {
              id
            }
          }
        }
      }
      formattedTotal
      createdAt
    }
  }
}
    ${ProductDescriptionFragmentDoc}`;

/**
 * __useSeasonTeamOrderHistoryQuery__
 *
 * To run a query within a React component, call `useSeasonTeamOrderHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonTeamOrderHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonTeamOrderHistoryQuery({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *   },
 * });
 */
export function useSeasonTeamOrderHistoryQuery(baseOptions: Apollo.QueryHookOptions<SeasonTeamOrderHistoryQuery, SeasonTeamOrderHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonTeamOrderHistoryQuery, SeasonTeamOrderHistoryQueryVariables>(SeasonTeamOrderHistoryDocument, options);
      }
export function useSeasonTeamOrderHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonTeamOrderHistoryQuery, SeasonTeamOrderHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonTeamOrderHistoryQuery, SeasonTeamOrderHistoryQueryVariables>(SeasonTeamOrderHistoryDocument, options);
        }
export type SeasonTeamOrderHistoryQueryHookResult = ReturnType<typeof useSeasonTeamOrderHistoryQuery>;
export type SeasonTeamOrderHistoryLazyQueryHookResult = ReturnType<typeof useSeasonTeamOrderHistoryLazyQuery>;
export type SeasonTeamOrderHistoryQueryResult = Apollo.QueryResult<SeasonTeamOrderHistoryQuery, SeasonTeamOrderHistoryQueryVariables>;
export const RemoveLineItemDocument = gql`
    mutation RemoveLineItem($data: RemoveOrderLineItemInput!) {
  removeLineItem(data: $data) {
    order {
      id
      lineItems {
        quantity
        product {
          ...ProductDescription
        }
        summary {
          lastPurchasedAt {
            purchaseDate
            order {
              id
            }
          }
        }
      }
      formattedTotal
    }
  }
}
    ${ProductDescriptionFragmentDoc}`;
export type RemoveLineItemMutationFn = Apollo.MutationFunction<RemoveLineItemMutation, RemoveLineItemMutationVariables>;

/**
 * __useRemoveLineItemMutation__
 *
 * To run a mutation, you first call `useRemoveLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLineItemMutation, { data, loading, error }] = useRemoveLineItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveLineItemMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLineItemMutation, RemoveLineItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveLineItemMutation, RemoveLineItemMutationVariables>(RemoveLineItemDocument, options);
      }
export type RemoveLineItemMutationHookResult = ReturnType<typeof useRemoveLineItemMutation>;
export type RemoveLineItemMutationResult = Apollo.MutationResult<RemoveLineItemMutation>;
export type RemoveLineItemMutationOptions = Apollo.BaseMutationOptions<RemoveLineItemMutation, RemoveLineItemMutationVariables>;
export const SeasonTeamViewingContextDocument = gql`
    query SeasonTeamViewingContext($seasonTeamId: ID!) {
  viewer {
    id
  }
  seasonTeam(id: $seasonTeamId) {
    id
    name
    viewerRole
    organization {
      id
      name
    }
    season {
      id
      name
      isSchedulingOpen
    }
    division {
      id
      name
    }
  }
}
    `;

/**
 * __useSeasonTeamViewingContextQuery__
 *
 * To run a query within a React component, call `useSeasonTeamViewingContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonTeamViewingContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonTeamViewingContextQuery({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *   },
 * });
 */
export function useSeasonTeamViewingContextQuery(baseOptions: Apollo.QueryHookOptions<SeasonTeamViewingContextQuery, SeasonTeamViewingContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonTeamViewingContextQuery, SeasonTeamViewingContextQueryVariables>(SeasonTeamViewingContextDocument, options);
      }
export function useSeasonTeamViewingContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonTeamViewingContextQuery, SeasonTeamViewingContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonTeamViewingContextQuery, SeasonTeamViewingContextQueryVariables>(SeasonTeamViewingContextDocument, options);
        }
export type SeasonTeamViewingContextQueryHookResult = ReturnType<typeof useSeasonTeamViewingContextQuery>;
export type SeasonTeamViewingContextLazyQueryHookResult = ReturnType<typeof useSeasonTeamViewingContextLazyQuery>;
export type SeasonTeamViewingContextQueryResult = Apollo.QueryResult<SeasonTeamViewingContextQuery, SeasonTeamViewingContextQueryVariables>;
export const SetLineItemQuantityDocument = gql`
    mutation SetLineItemQuantity($data: SetOrderItemQuantityInput!) {
  setOrderItemQuantity(data: $data) {
    order {
      id
      lineItems {
        quantity
        product {
          ...ProductDescription
        }
        summary {
          lastPurchasedAt {
            purchaseDate
            order {
              id
            }
          }
        }
      }
      formattedTotal
    }
  }
}
    ${ProductDescriptionFragmentDoc}`;
export type SetLineItemQuantityMutationFn = Apollo.MutationFunction<SetLineItemQuantityMutation, SetLineItemQuantityMutationVariables>;

/**
 * __useSetLineItemQuantityMutation__
 *
 * To run a mutation, you first call `useSetLineItemQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLineItemQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLineItemQuantityMutation, { data, loading, error }] = useSetLineItemQuantityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetLineItemQuantityMutation(baseOptions?: Apollo.MutationHookOptions<SetLineItemQuantityMutation, SetLineItemQuantityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLineItemQuantityMutation, SetLineItemQuantityMutationVariables>(SetLineItemQuantityDocument, options);
      }
export type SetLineItemQuantityMutationHookResult = ReturnType<typeof useSetLineItemQuantityMutation>;
export type SetLineItemQuantityMutationResult = Apollo.MutationResult<SetLineItemQuantityMutation>;
export type SetLineItemQuantityMutationOptions = Apollo.BaseMutationOptions<SetLineItemQuantityMutation, SetLineItemQuantityMutationVariables>;
export const ReportGameScoreDocument = gql`
    mutation ReportGameScore($seasonTeamId: ID!, $data: ReportScoreDataInput!) {
  seasonTeam(id: $seasonTeamId) {
    games {
      reportScore(data: $data) {
        game {
          ...GameDetails
        }
        error
      }
    }
  }
}
    ${GameDetailsFragmentDoc}`;
export type ReportGameScoreMutationFn = Apollo.MutationFunction<ReportGameScoreMutation, ReportGameScoreMutationVariables>;

/**
 * __useReportGameScoreMutation__
 *
 * To run a mutation, you first call `useReportGameScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportGameScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportGameScoreMutation, { data, loading, error }] = useReportGameScoreMutation({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReportGameScoreMutation(baseOptions?: Apollo.MutationHookOptions<ReportGameScoreMutation, ReportGameScoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportGameScoreMutation, ReportGameScoreMutationVariables>(ReportGameScoreDocument, options);
      }
export type ReportGameScoreMutationHookResult = ReturnType<typeof useReportGameScoreMutation>;
export type ReportGameScoreMutationResult = Apollo.MutationResult<ReportGameScoreMutation>;
export type ReportGameScoreMutationOptions = Apollo.BaseMutationOptions<ReportGameScoreMutation, ReportGameScoreMutationVariables>;
export const SubmitTeamRequirementDocument = gql`
    mutation SubmitTeamRequirement($teamId: ID!, $data: SubmitRequirementDataInput!) {
  submitRequirement(teamId: $teamId, data: $data) {
    status {
      isComplete
      completedCount
      totalCount
    }
    requirement {
      id
      isFulfilled
      completionState {
        completionDate
        submitterIpAddress
      }
      requirementDefinition {
        id
        name
        moreInfo
        ... on FileUploadRequirement {
          supportingFile
        }
        ... on AgreementRequirement {
          content {
            ... on MarkdownAgreementContent {
              content
            }
            ... on FileAgreementContent {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export type SubmitTeamRequirementMutationFn = Apollo.MutationFunction<SubmitTeamRequirementMutation, SubmitTeamRequirementMutationVariables>;

/**
 * __useSubmitTeamRequirementMutation__
 *
 * To run a mutation, you first call `useSubmitTeamRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitTeamRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitTeamRequirementMutation, { data, loading, error }] = useSubmitTeamRequirementMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitTeamRequirementMutation(baseOptions?: Apollo.MutationHookOptions<SubmitTeamRequirementMutation, SubmitTeamRequirementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitTeamRequirementMutation, SubmitTeamRequirementMutationVariables>(SubmitTeamRequirementDocument, options);
      }
export type SubmitTeamRequirementMutationHookResult = ReturnType<typeof useSubmitTeamRequirementMutation>;
export type SubmitTeamRequirementMutationResult = Apollo.MutationResult<SubmitTeamRequirementMutation>;
export type SubmitTeamRequirementMutationOptions = Apollo.BaseMutationOptions<SubmitTeamRequirementMutation, SubmitTeamRequirementMutationVariables>;
export const SeasonTeamRequirementsDocument = gql`
    query SeasonTeamRequirements($teamId: ID!) {
  seasonTeam(id: $teamId) {
    id
    hasPaidRegistrationFee
    registrationFeeCheckoutSession
    stripeAccountId
    requirementCompletionStatus {
      isComplete
      completedCount
      totalCount
    }
    requirements {
      id
      isFulfilled
      completionState {
        completionDate
        submitterIpAddress
      }
      requirementDefinition {
        id
        name
        moreInfo
        ... on FileUploadRequirement {
          supportingFile
        }
        ... on AgreementRequirement {
          content {
            ... on MarkdownAgreementContent {
              content
            }
            ... on FileAgreementContent {
              url
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSeasonTeamRequirementsQuery__
 *
 * To run a query within a React component, call `useSeasonTeamRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonTeamRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonTeamRequirementsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useSeasonTeamRequirementsQuery(baseOptions: Apollo.QueryHookOptions<SeasonTeamRequirementsQuery, SeasonTeamRequirementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonTeamRequirementsQuery, SeasonTeamRequirementsQueryVariables>(SeasonTeamRequirementsDocument, options);
      }
export function useSeasonTeamRequirementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonTeamRequirementsQuery, SeasonTeamRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonTeamRequirementsQuery, SeasonTeamRequirementsQueryVariables>(SeasonTeamRequirementsDocument, options);
        }
export type SeasonTeamRequirementsQueryHookResult = ReturnType<typeof useSeasonTeamRequirementsQuery>;
export type SeasonTeamRequirementsLazyQueryHookResult = ReturnType<typeof useSeasonTeamRequirementsLazyQuery>;
export type SeasonTeamRequirementsQueryResult = Apollo.QueryResult<SeasonTeamRequirementsQuery, SeasonTeamRequirementsQueryVariables>;
export const AllTeamContactsDocument = gql`
    query AllTeamContacts($seasonTeamId: ID!) {
  seasonTeam(id: $seasonTeamId) {
    teamContacts {
      teamDisplayName
      headCoachName
      headCoachEmail
      headCoachCellPhone
      schedulerName
      schedulerEmail
      schedulerCellPhone
      distanceInMiles
      confirmedGameCount
    }
  }
}
    `;

/**
 * __useAllTeamContactsQuery__
 *
 * To run a query within a React component, call `useAllTeamContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTeamContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTeamContactsQuery({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *   },
 * });
 */
export function useAllTeamContactsQuery(baseOptions: Apollo.QueryHookOptions<AllTeamContactsQuery, AllTeamContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTeamContactsQuery, AllTeamContactsQueryVariables>(AllTeamContactsDocument, options);
      }
export function useAllTeamContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTeamContactsQuery, AllTeamContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTeamContactsQuery, AllTeamContactsQueryVariables>(AllTeamContactsDocument, options);
        }
export type AllTeamContactsQueryHookResult = ReturnType<typeof useAllTeamContactsQuery>;
export type AllTeamContactsLazyQueryHookResult = ReturnType<typeof useAllTeamContactsLazyQuery>;
export type AllTeamContactsQueryResult = Apollo.QueryResult<AllTeamContactsQuery, AllTeamContactsQueryVariables>;
export const UpdateSeasonTeamPlayoffCommitmentDocument = gql`
    mutation UpdateSeasonTeamPlayoffCommitment($seasonTeamId: ID!, $status: Boolean!) {
  seasonTeam(id: $seasonTeamId) {
    updatePlayoffCommitmentStatus(status: $status) {
      team {
        id
        isCommittedToPlayoffs
      }
    }
  }
}
    `;
export type UpdateSeasonTeamPlayoffCommitmentMutationFn = Apollo.MutationFunction<UpdateSeasonTeamPlayoffCommitmentMutation, UpdateSeasonTeamPlayoffCommitmentMutationVariables>;

/**
 * __useUpdateSeasonTeamPlayoffCommitmentMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonTeamPlayoffCommitmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonTeamPlayoffCommitmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonTeamPlayoffCommitmentMutation, { data, loading, error }] = useUpdateSeasonTeamPlayoffCommitmentMutation({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateSeasonTeamPlayoffCommitmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonTeamPlayoffCommitmentMutation, UpdateSeasonTeamPlayoffCommitmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonTeamPlayoffCommitmentMutation, UpdateSeasonTeamPlayoffCommitmentMutationVariables>(UpdateSeasonTeamPlayoffCommitmentDocument, options);
      }
export type UpdateSeasonTeamPlayoffCommitmentMutationHookResult = ReturnType<typeof useUpdateSeasonTeamPlayoffCommitmentMutation>;
export type UpdateSeasonTeamPlayoffCommitmentMutationResult = Apollo.MutationResult<UpdateSeasonTeamPlayoffCommitmentMutation>;
export type UpdateSeasonTeamPlayoffCommitmentMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonTeamPlayoffCommitmentMutation, UpdateSeasonTeamPlayoffCommitmentMutationVariables>;
export const AllTeamsDocument = gql`
    query AllTeams($cursor: Cursor) {
  teams(first: 25, after: $cursor) {
    totalCount
    edges {
      cursor
      node {
        id
        name
        isOwner
        avatar
        description
        isRegisteredForAtLeastOneSeason
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useAllTeamsQuery__
 *
 * To run a query within a React component, call `useAllTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTeamsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useAllTeamsQuery(baseOptions?: Apollo.QueryHookOptions<AllTeamsQuery, AllTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTeamsQuery, AllTeamsQueryVariables>(AllTeamsDocument, options);
      }
export function useAllTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTeamsQuery, AllTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTeamsQuery, AllTeamsQueryVariables>(AllTeamsDocument, options);
        }
export type AllTeamsQueryHookResult = ReturnType<typeof useAllTeamsQuery>;
export type AllTeamsLazyQueryHookResult = ReturnType<typeof useAllTeamsLazyQuery>;
export type AllTeamsQueryResult = Apollo.QueryResult<AllTeamsQuery, AllTeamsQueryVariables>;
export const AddTeamDocument = gql`
    mutation AddTeam($data: AddTeamInput!) {
  addTeam(data: $data) {
    team {
      id
      name
      description
      avatar
    }
  }
}
    `;
export type AddTeamMutationFn = Apollo.MutationFunction<AddTeamMutation, AddTeamMutationVariables>;

/**
 * __useAddTeamMutation__
 *
 * To run a mutation, you first call `useAddTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeamMutation, { data, loading, error }] = useAddTeamMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddTeamMutation(baseOptions?: Apollo.MutationHookOptions<AddTeamMutation, AddTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTeamMutation, AddTeamMutationVariables>(AddTeamDocument, options);
      }
export type AddTeamMutationHookResult = ReturnType<typeof useAddTeamMutation>;
export type AddTeamMutationResult = Apollo.MutationResult<AddTeamMutation>;
export type AddTeamMutationOptions = Apollo.BaseMutationOptions<AddTeamMutation, AddTeamMutationVariables>;
export const DeleteTeamDocument = gql`
    mutation DeleteTeam($teamId: ID!) {
  deleteTeam(id: $teamId) {
    team {
      id
      name
    }
  }
}
    `;
export type DeleteTeamMutationFn = Apollo.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteTeamMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(DeleteTeamDocument, options);
      }
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const TeamByIdDocument = gql`
    query TeamById($id: ID!) {
  team(id: $id) {
    id
    name
    avatar
    description
    viewerRole
  }
}
    `;

/**
 * __useTeamByIdQuery__
 *
 * To run a query within a React component, call `useTeamByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamByIdQuery(baseOptions: Apollo.QueryHookOptions<TeamByIdQuery, TeamByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamByIdQuery, TeamByIdQueryVariables>(TeamByIdDocument, options);
      }
export function useTeamByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamByIdQuery, TeamByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamByIdQuery, TeamByIdQueryVariables>(TeamByIdDocument, options);
        }
export type TeamByIdQueryHookResult = ReturnType<typeof useTeamByIdQuery>;
export type TeamByIdLazyQueryHookResult = ReturnType<typeof useTeamByIdLazyQuery>;
export type TeamByIdQueryResult = Apollo.QueryResult<TeamByIdQuery, TeamByIdQueryVariables>;
export const UpdateTeamDetailsDocument = gql`
    mutation UpdateTeamDetails($teamId: ID!, $details: UpdateTeamDetailsInput!) {
  updateTeamDetails(id: $teamId, details: $details) {
    id
    name
    avatar
    description
  }
}
    `;
export type UpdateTeamDetailsMutationFn = Apollo.MutationFunction<UpdateTeamDetailsMutation, UpdateTeamDetailsMutationVariables>;

/**
 * __useUpdateTeamDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateTeamDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamDetailsMutation, { data, loading, error }] = useUpdateTeamDetailsMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useUpdateTeamDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamDetailsMutation, UpdateTeamDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamDetailsMutation, UpdateTeamDetailsMutationVariables>(UpdateTeamDetailsDocument, options);
      }
export type UpdateTeamDetailsMutationHookResult = ReturnType<typeof useUpdateTeamDetailsMutation>;
export type UpdateTeamDetailsMutationResult = Apollo.MutationResult<UpdateTeamDetailsMutation>;
export type UpdateTeamDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateTeamDetailsMutation, UpdateTeamDetailsMutationVariables>;
export const AcceptTeamStaffMemberInviteDocument = gql`
    mutation AcceptTeamStaffMemberInvite($inviteId: ID!) {
  acceptTeamStaffInvite(inviteId: $inviteId) {
    success
    userError {
      type
      description
    }
  }
}
    `;
export type AcceptTeamStaffMemberInviteMutationFn = Apollo.MutationFunction<AcceptTeamStaffMemberInviteMutation, AcceptTeamStaffMemberInviteMutationVariables>;

/**
 * __useAcceptTeamStaffMemberInviteMutation__
 *
 * To run a mutation, you first call `useAcceptTeamStaffMemberInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTeamStaffMemberInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTeamStaffMemberInviteMutation, { data, loading, error }] = useAcceptTeamStaffMemberInviteMutation({
 *   variables: {
 *      inviteId: // value for 'inviteId'
 *   },
 * });
 */
export function useAcceptTeamStaffMemberInviteMutation(baseOptions?: Apollo.MutationHookOptions<AcceptTeamStaffMemberInviteMutation, AcceptTeamStaffMemberInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptTeamStaffMemberInviteMutation, AcceptTeamStaffMemberInviteMutationVariables>(AcceptTeamStaffMemberInviteDocument, options);
      }
export type AcceptTeamStaffMemberInviteMutationHookResult = ReturnType<typeof useAcceptTeamStaffMemberInviteMutation>;
export type AcceptTeamStaffMemberInviteMutationResult = Apollo.MutationResult<AcceptTeamStaffMemberInviteMutation>;
export type AcceptTeamStaffMemberInviteMutationOptions = Apollo.BaseMutationOptions<AcceptTeamStaffMemberInviteMutation, AcceptTeamStaffMemberInviteMutationVariables>;
export const GetTeamStaffMemberInviteByIdDocument = gql`
    query GetTeamStaffMemberInviteById($inviteId: ID!) {
  teamStaffMemberInvite(id: $inviteId) {
    ...AcceptTeamStaffInvite
  }
}
    ${AcceptTeamStaffInviteFragmentDoc}`;

/**
 * __useGetTeamStaffMemberInviteByIdQuery__
 *
 * To run a query within a React component, call `useGetTeamStaffMemberInviteByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamStaffMemberInviteByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamStaffMemberInviteByIdQuery({
 *   variables: {
 *      inviteId: // value for 'inviteId'
 *   },
 * });
 */
export function useGetTeamStaffMemberInviteByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTeamStaffMemberInviteByIdQuery, GetTeamStaffMemberInviteByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamStaffMemberInviteByIdQuery, GetTeamStaffMemberInviteByIdQueryVariables>(GetTeamStaffMemberInviteByIdDocument, options);
      }
export function useGetTeamStaffMemberInviteByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamStaffMemberInviteByIdQuery, GetTeamStaffMemberInviteByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamStaffMemberInviteByIdQuery, GetTeamStaffMemberInviteByIdQueryVariables>(GetTeamStaffMemberInviteByIdDocument, options);
        }
export type GetTeamStaffMemberInviteByIdQueryHookResult = ReturnType<typeof useGetTeamStaffMemberInviteByIdQuery>;
export type GetTeamStaffMemberInviteByIdLazyQueryHookResult = ReturnType<typeof useGetTeamStaffMemberInviteByIdLazyQuery>;
export type GetTeamStaffMemberInviteByIdQueryResult = Apollo.QueryResult<GetTeamStaffMemberInviteByIdQuery, GetTeamStaffMemberInviteByIdQueryVariables>;
export const RegistrationFormForSeasonDocument = gql`
    query RegistrationFormForSeason($organizationId: ID!, $seasonId: ID!) {
  organization(id: $organizationId) {
    id
    name
    season(id: $seasonId) {
      id
      name
      registrationForm {
        name
        isOpen
        formDefinition
        availableTeams {
          edges {
            ...RegistrationFormAvailableTeamEdge
          }
        }
      }
      divisions {
        ...SimpleDivisionDescription
      }
    }
  }
}
    ${RegistrationFormAvailableTeamEdgeFragmentDoc}
${SimpleDivisionDescriptionFragmentDoc}`;

/**
 * __useRegistrationFormForSeasonQuery__
 *
 * To run a query within a React component, call `useRegistrationFormForSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationFormForSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationFormForSeasonQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useRegistrationFormForSeasonQuery(baseOptions: Apollo.QueryHookOptions<RegistrationFormForSeasonQuery, RegistrationFormForSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegistrationFormForSeasonQuery, RegistrationFormForSeasonQueryVariables>(RegistrationFormForSeasonDocument, options);
      }
export function useRegistrationFormForSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegistrationFormForSeasonQuery, RegistrationFormForSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegistrationFormForSeasonQuery, RegistrationFormForSeasonQueryVariables>(RegistrationFormForSeasonDocument, options);
        }
export type RegistrationFormForSeasonQueryHookResult = ReturnType<typeof useRegistrationFormForSeasonQuery>;
export type RegistrationFormForSeasonLazyQueryHookResult = ReturnType<typeof useRegistrationFormForSeasonLazyQuery>;
export type RegistrationFormForSeasonQueryResult = Apollo.QueryResult<RegistrationFormForSeasonQuery, RegistrationFormForSeasonQueryVariables>;
export const SubmitRegistrationFormResponseDocument = gql`
    mutation SubmitRegistrationFormResponse($data: RegisterTeamDataInput!) {
  submitRegistrationResponseNEW(response: $data) {
    team {
      id
      name
    }
  }
}
    `;
export type SubmitRegistrationFormResponseMutationFn = Apollo.MutationFunction<SubmitRegistrationFormResponseMutation, SubmitRegistrationFormResponseMutationVariables>;

/**
 * __useSubmitRegistrationFormResponseMutation__
 *
 * To run a mutation, you first call `useSubmitRegistrationFormResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRegistrationFormResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRegistrationFormResponseMutation, { data, loading, error }] = useSubmitRegistrationFormResponseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitRegistrationFormResponseMutation(baseOptions?: Apollo.MutationHookOptions<SubmitRegistrationFormResponseMutation, SubmitRegistrationFormResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitRegistrationFormResponseMutation, SubmitRegistrationFormResponseMutationVariables>(SubmitRegistrationFormResponseDocument, options);
      }
export type SubmitRegistrationFormResponseMutationHookResult = ReturnType<typeof useSubmitRegistrationFormResponseMutation>;
export type SubmitRegistrationFormResponseMutationResult = Apollo.MutationResult<SubmitRegistrationFormResponseMutation>;
export type SubmitRegistrationFormResponseMutationOptions = Apollo.BaseMutationOptions<SubmitRegistrationFormResponseMutation, SubmitRegistrationFormResponseMutationVariables>;
export const SubmitRegistrationResponseDocument = gql`
    mutation SubmitRegistrationResponse($response: NonBlankString!) {
  submitRegistrationResponse(response: $response) {
    team {
      id
      name
    }
  }
}
    `;
export type SubmitRegistrationResponseMutationFn = Apollo.MutationFunction<SubmitRegistrationResponseMutation, SubmitRegistrationResponseMutationVariables>;

/**
 * __useSubmitRegistrationResponseMutation__
 *
 * To run a mutation, you first call `useSubmitRegistrationResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRegistrationResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRegistrationResponseMutation, { data, loading, error }] = useSubmitRegistrationResponseMutation({
 *   variables: {
 *      response: // value for 'response'
 *   },
 * });
 */
export function useSubmitRegistrationResponseMutation(baseOptions?: Apollo.MutationHookOptions<SubmitRegistrationResponseMutation, SubmitRegistrationResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitRegistrationResponseMutation, SubmitRegistrationResponseMutationVariables>(SubmitRegistrationResponseDocument, options);
      }
export type SubmitRegistrationResponseMutationHookResult = ReturnType<typeof useSubmitRegistrationResponseMutation>;
export type SubmitRegistrationResponseMutationResult = Apollo.MutationResult<SubmitRegistrationResponseMutation>;
export type SubmitRegistrationResponseMutationOptions = Apollo.BaseMutationOptions<SubmitRegistrationResponseMutation, SubmitRegistrationResponseMutationVariables>;
export const DeletePendingTeamStaffMemberInviteDocument = gql`
    mutation DeletePendingTeamStaffMemberInvite($teamId: ID!, $invitationId: ID!) {
  team(id: $teamId) {
    withdrawStaffMemberInvite(inviteId: $invitationId) {
      success
      userError {
        type
        description
      }
    }
  }
}
    `;
export type DeletePendingTeamStaffMemberInviteMutationFn = Apollo.MutationFunction<DeletePendingTeamStaffMemberInviteMutation, DeletePendingTeamStaffMemberInviteMutationVariables>;

/**
 * __useDeletePendingTeamStaffMemberInviteMutation__
 *
 * To run a mutation, you first call `useDeletePendingTeamStaffMemberInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePendingTeamStaffMemberInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePendingTeamStaffMemberInviteMutation, { data, loading, error }] = useDeletePendingTeamStaffMemberInviteMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useDeletePendingTeamStaffMemberInviteMutation(baseOptions?: Apollo.MutationHookOptions<DeletePendingTeamStaffMemberInviteMutation, DeletePendingTeamStaffMemberInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePendingTeamStaffMemberInviteMutation, DeletePendingTeamStaffMemberInviteMutationVariables>(DeletePendingTeamStaffMemberInviteDocument, options);
      }
export type DeletePendingTeamStaffMemberInviteMutationHookResult = ReturnType<typeof useDeletePendingTeamStaffMemberInviteMutation>;
export type DeletePendingTeamStaffMemberInviteMutationResult = Apollo.MutationResult<DeletePendingTeamStaffMemberInviteMutation>;
export type DeletePendingTeamStaffMemberInviteMutationOptions = Apollo.BaseMutationOptions<DeletePendingTeamStaffMemberInviteMutation, DeletePendingTeamStaffMemberInviteMutationVariables>;
export const TeamInviteStaffMemberDocument = gql`
    mutation TeamInviteStaffMember($teamId: ID!, $email: Email!) {
  team(id: $teamId) {
    inviteStaffMember(email: $email) {
      invite {
        id
        userEmail
      }
      userError {
        type
        description
      }
    }
  }
}
    `;
export type TeamInviteStaffMemberMutationFn = Apollo.MutationFunction<TeamInviteStaffMemberMutation, TeamInviteStaffMemberMutationVariables>;

/**
 * __useTeamInviteStaffMemberMutation__
 *
 * To run a mutation, you first call `useTeamInviteStaffMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamInviteStaffMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamInviteStaffMemberMutation, { data, loading, error }] = useTeamInviteStaffMemberMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useTeamInviteStaffMemberMutation(baseOptions?: Apollo.MutationHookOptions<TeamInviteStaffMemberMutation, TeamInviteStaffMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamInviteStaffMemberMutation, TeamInviteStaffMemberMutationVariables>(TeamInviteStaffMemberDocument, options);
      }
export type TeamInviteStaffMemberMutationHookResult = ReturnType<typeof useTeamInviteStaffMemberMutation>;
export type TeamInviteStaffMemberMutationResult = Apollo.MutationResult<TeamInviteStaffMemberMutation>;
export type TeamInviteStaffMemberMutationOptions = Apollo.BaseMutationOptions<TeamInviteStaffMemberMutation, TeamInviteStaffMemberMutationVariables>;
export const RemoveTeamStaffMemberDocument = gql`
    mutation RemoveTeamStaffMember($teamId: ID!, $userId: ID!) {
  team(id: $teamId) {
    removeStaffMember(userId: $userId) {
      success
      userError {
        type
        description
      }
    }
  }
}
    `;
export type RemoveTeamStaffMemberMutationFn = Apollo.MutationFunction<RemoveTeamStaffMemberMutation, RemoveTeamStaffMemberMutationVariables>;

/**
 * __useRemoveTeamStaffMemberMutation__
 *
 * To run a mutation, you first call `useRemoveTeamStaffMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamStaffMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeamStaffMemberMutation, { data, loading, error }] = useRemoveTeamStaffMemberMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveTeamStaffMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTeamStaffMemberMutation, RemoveTeamStaffMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTeamStaffMemberMutation, RemoveTeamStaffMemberMutationVariables>(RemoveTeamStaffMemberDocument, options);
      }
export type RemoveTeamStaffMemberMutationHookResult = ReturnType<typeof useRemoveTeamStaffMemberMutation>;
export type RemoveTeamStaffMemberMutationResult = Apollo.MutationResult<RemoveTeamStaffMemberMutation>;
export type RemoveTeamStaffMemberMutationOptions = Apollo.BaseMutationOptions<RemoveTeamStaffMemberMutation, RemoveTeamStaffMemberMutationVariables>;
export const StaffMembersByTeamDocument = gql`
    query StaffMembersByTeam($teamId: ID!) {
  team(id: $teamId) {
    ...TeamStaffMemberList
    ...TeamPendingStaffMemberList
    name
  }
}
    ${TeamStaffMemberListFragmentDoc}
${TeamPendingStaffMemberListFragmentDoc}`;

/**
 * __useStaffMembersByTeamQuery__
 *
 * To run a query within a React component, call `useStaffMembersByTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffMembersByTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffMembersByTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useStaffMembersByTeamQuery(baseOptions: Apollo.QueryHookOptions<StaffMembersByTeamQuery, StaffMembersByTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffMembersByTeamQuery, StaffMembersByTeamQueryVariables>(StaffMembersByTeamDocument, options);
      }
export function useStaffMembersByTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffMembersByTeamQuery, StaffMembersByTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffMembersByTeamQuery, StaffMembersByTeamQueryVariables>(StaffMembersByTeamDocument, options);
        }
export type StaffMembersByTeamQueryHookResult = ReturnType<typeof useStaffMembersByTeamQuery>;
export type StaffMembersByTeamLazyQueryHookResult = ReturnType<typeof useStaffMembersByTeamLazyQuery>;
export type StaffMembersByTeamQueryResult = Apollo.QueryResult<StaffMembersByTeamQuery, StaffMembersByTeamQueryVariables>;
export const GetMessagesDocument = gql`
    subscription GetMessages {
  messages {
    from
    message
  }
}
    `;

/**
 * __useGetMessagesSubscription__
 *
 * To run a query within a React component, call `useGetMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetMessagesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetMessagesSubscription, GetMessagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetMessagesSubscription, GetMessagesSubscriptionVariables>(GetMessagesDocument, options);
      }
export type GetMessagesSubscriptionHookResult = ReturnType<typeof useGetMessagesSubscription>;
export type GetMessagesSubscriptionResult = Apollo.SubscriptionResult<GetMessagesSubscription>;
export const PublishMessageDocument = gql`
    mutation PublishMessage($message: String!) {
  sendTestMessage(message: $message)
}
    `;
export type PublishMessageMutationFn = Apollo.MutationFunction<PublishMessageMutation, PublishMessageMutationVariables>;

/**
 * __usePublishMessageMutation__
 *
 * To run a mutation, you first call `usePublishMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMessageMutation, { data, loading, error }] = usePublishMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function usePublishMessageMutation(baseOptions?: Apollo.MutationHookOptions<PublishMessageMutation, PublishMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishMessageMutation, PublishMessageMutationVariables>(PublishMessageDocument, options);
      }
export type PublishMessageMutationHookResult = ReturnType<typeof usePublishMessageMutation>;
export type PublishMessageMutationResult = Apollo.MutationResult<PublishMessageMutation>;
export type PublishMessageMutationOptions = Apollo.BaseMutationOptions<PublishMessageMutation, PublishMessageMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Actor": [
      "LeagueBrainUser",
      "LeagueBrainUserProfile"
    ],
    "AgreementContent": [
      "FileAgreementContent",
      "MarkdownAgreementContent"
    ],
    "GameResult": [
      "Decision",
      "Forfeit"
    ],
    "GraphQLApprovingEntity": [
      "OrganizationApprovingEntity",
      "SeasonTeamApprovingEntity"
    ],
    "GraphQLEdge": [
      "AvailableTeamEdge",
      "PlayerEdge",
      "TeamEdge"
    ],
    "GraphQLGame": [
      "CanceledGraphQLGame",
      "ConfirmedGraphQLGame",
      "PendingGraphQLGame"
    ],
    "GraphQLOrganizationOrSeasonTeam": [
      "Organization",
      "SeasonTeam"
    ],
    "GraphQLRequirement": [
      "AgreementRequirement",
      "FileUploadRequirement"
    ],
    "GraphQLRequirementStatus": [
      "GraphQLAgreementRequirementStatus",
      "GraphQLFileUploadRequirementStatus"
    ],
    "Node": [
      "LeagueBrainUser",
      "LeagueBrainUserProfile",
      "Player",
      "Team"
    ],
    "PaymentStatus": [
      "PaymentNotReceived",
      "PaymentReceived"
    ],
    "RequirementCompletionData": [
      "AgreementRequirementCompletionData",
      "FileUploadRequirementCompletionData"
    ],
    "TeamGraphQLGameSchedulingActor": [
      "GraphQLGameSchedulingActorOrganizationStaffMember",
      "GraphQLGameSchedulingActorTeamStaffMember"
    ],
    "UniformResourceLocatable": [
      "S3File",
      "StaticFile"
    ]
  }
};
      export default result;
    