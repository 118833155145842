import React from 'react';
import {
  ActionIcon,
  Box,
  Burger,
  createStyles,
  Group,
  Header,
  MediaQuery,
  Title,
  Tooltip,
  Transition
} from "@mantine/core";
import {Link, resolvePath, useLocation, useMatch, useResolvedPath} from "react-router-dom";
import {Icon} from "../../../../../components/FontAwesomeIcon";
import {faArrowLeft, faPersonToPortal, faRightFromBracket} from "@fortawesome/pro-solid-svg-icons";
import {usePageNameContext} from "../page-name/PageNameContext";
import {UserProfileMenu} from "./UserProfileMenu";
import {useHover} from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    borderBottom: 'none',
  },

}));

export interface ApplicationHeaderProps {
  isOpen: boolean;
  toggleOpen(): void
  rightContent?: React.ReactNode
}

export function ApplicationHeader(props: ApplicationHeaderProps) {

  const { classes, theme } = useStyles()

  const location = useLocation()

  const resolved = useResolvedPath('./');
  const canGoBack = !useMatch({ path: resolved.pathname, end: true })
  const backUrl = resolvePath('../', location.pathname)

  const pageNameContext = usePageNameContext()


  return (
    <Header className={classes.header} height={60}>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
        }}
      >
        <MediaQuery smallerThan={'md'} styles={{ display: 'none !important' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              backgroundColor: `${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
            }}
          >
            <Group
              sx={(theme) => ({
                position: 'relative',
                height: '100%',
                flexBasis: '300px',
                flexGrow: 0,
                flexShrink: 0,
                borderRight: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
              })}
              px={'md'}
              align={'center'}
            >
              <Box
                sx={(theme) => ({
                  position: 'absolute',
                  bottom: 0,
                  left: '5%',
                  right: '5%',
                  borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`
                })}
              />
              <Title
                order={2}
                sx={(theme) => ({
                  fontFamily: 'Greycliff CF, sans-serif',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  backgroundImage: theme.colorScheme === 'dark' ?
                    theme.fn.linearGradient(45, theme.colors.indigo[5], theme.colors.cyan[5]) :
                    theme.fn.linearGradient(45, theme.colors.indigo[6], theme.colors.cyan[6]),
                })}
              >
                LeagueBrain
              </Title>
            </Group>

            <Group
              spacing={0}
              sx={{
                flexGrow: 1,
                maxHeight: '100%',
                borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`
              }}
            >
              <ExitSeasonButton />

              <Box
                sx={{
                  flexGrow: 1,
                }}
              />

              {props.rightContent}

              <Box pr={'xl'}>
                <UserProfileMenu />
              </Box>
            </Group>

          </Box>
        </MediaQuery>

        <MediaQuery largerThan="md" styles={{ display: 'none !important' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`
            }}
          >
            <Box px={'md'} sx={{ minWidth: '60px' }}>
              {canGoBack &&
                <ActionIcon<typeof Link>
                  component={Link}
                  to={backUrl}
                >
                  <Icon icon={faArrowLeft} size={'lg'} />
                </ActionIcon>
              }
            </Box>

            <Title
              order={4}
              mx={'xl'}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {pageNameContext.pageName !== '' ? pageNameContext.pageName : 'LeagueBrain'}
            </Title>

            <Burger
              opened={props.isOpen}
              onClick={props.toggleOpen}
              size="sm"
              color={theme.colors.gray[6]}
              mr="xl"
            />
          </Box>
        </MediaQuery>
      </Box>
    </Header>
  )
}

export function getLeadingCharacters(text: string): string {
  return text
    .split(' ')
    .map(w => w.trimStart())
    .filter(w => w.length > 0)
    .map(w => w.slice(0, 1))
    .join('')
}

function ExitSeasonButton() {

  const { hovered, ref } = useHover();

  return (
    <Tooltip
      ref={ref}
      m={'sm'}
      position={'right'}
      placement={'center'}
      label={'Back to Organization'}
      withArrow
    >
      <ActionIcon
        p={'md'}
        sx={{ opacity: 0 }}
        disabled
      >
        <Icon
          icon={faRightFromBracket}
          size={'lg'}
        />
      </ActionIcon>

      <Transition mounted={!hovered} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <ActionIcon
            p={'md'}
            style={styles}
            sx={(theme) => ({
              ...theme.fn.cover()
            })}
          >
            <Icon
              icon={faRightFromBracket}
              size={'lg'}
              style={{
                transform: 'scaleX(-1)'
              }}
            />
          </ActionIcon>
        )}
      </Transition>
      <Transition mounted={hovered} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <ActionIcon<typeof Link>
            component={Link}
            to={'../../'}
            p={'md'}
            style={styles}
            sx={(theme) => ({
              ...theme.fn.cover()
            })}
          >
            <Icon
              icon={faPersonToPortal}
              size={'lg'}
              style={{
                transform: 'scaleX(-1) translateX(2px)'
              }}
            />
          </ActionIcon>
        )}
      </Transition>
    </Tooltip>
  )
}
