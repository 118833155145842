import {ApolloLink, FetchResult, Observable, Operation,} from '@apollo/client/core';
import {GraphQLError, print} from 'graphql';
import {Client, ClientOptions, createClient} from 'graphql-ws';

export class WebSocketLink extends ApolloLink {
  private client: RestartableClient;
  private socket: any;

  constructor(options: ClientOptions) {
    super();

    const handler = () => {
      if (document.visibilityState === 'visible' && this.socket?.readyState !== WebSocket.OPEN) {
        this.client.restart()
      }
    }

    this.client = createRestartableClient({
      ...options,
      // webSocketImpl: ReconnectingWebSocket,
      keepAlive: 10_000, // ping server every 10 seconds,
      on: {
        opened: (socket) => {
          this.socket = socket
          document.addEventListener('visibilitychange', handler, false)
        },
        closed: () => {
          this.socket = null
          document.removeEventListener('visibilitychange', handler, false)
        }
      }
    });
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (err instanceof Error) {
              return sink.error(err);
            }

            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`,
                ),
              );
            }

            return sink.error(
              new Error(
                (err as GraphQLError[])
                  .map(({ message }) => message)
                  .join(', '),
              ),
            );
          },
        },
      );
    });
  }
}

interface RestartableClient extends Client {
  restart(): void;
}

function createRestartableClient(options: ClientOptions): RestartableClient {
  let restartRequested = false;
  let restart = () => {
    restartRequested = true;
  };

  const client = createClient({
    ...options,
    on: {
      ...options.on,
      opened: (socket) => {
        options.on?.opened?.(socket);

        restart = () => {
          if (socket.readyState === WebSocket.OPEN) {
            // if the socket is still open for the restart, do the restart
            socket.close(4205, 'Client Restart');
          } else {
            // otherwise the socket might've closed, indicate that you want
            // a restart on the next opened event
            restartRequested = true;
          }
        };

        // just in case you were eager to restart
        if (restartRequested) {
          restartRequested = false;
          restart();
        }
      },
    },
  });

  return {
    ...client,
    restart: () => restart(),
  };
}
