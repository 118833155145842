import React from 'react'
import {ManageProfileForm} from "./ManageProfileForm";
import {useGetUserInfoQuery} from "../graphql";

export interface ManageProfilePageProps {

}

export function ManageProfilePage(props: ManageProfilePageProps) {

  const {

  } = props

  const { data } = useGetUserInfoQuery()

  const userInfo = data?.viewer

  if (!userInfo) return null

  return (
      <ManageProfileForm defaultValues={userInfo} />
  )
}
