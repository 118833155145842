import React from 'react';
import {Global} from '@mantine/core';
// @ts-ignore
import bold from './GreycliffCF-Bold.woff2';
// @ts-ignore
import heavy from './GreycliffCF-Heavy.woff2';

export function GreycliffCF() {
  return (
    <Global
      // @ts-ignore
      styles={(theme) => ([
        {
          '@font-face': {
            fontFamily: 'Greycliff CF',
            src: `url('${bold}') format("woff2")`,
            fontWeight: 700,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Greycliff CF',
            src: `url('${heavy}') format("woff2")`,
            fontWeight: 900,
            fontStyle: 'normal',
          }
        }
      ])}
    />
  );
}

