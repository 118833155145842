import React from 'react';
import {useAllDivisionsInSeasonQuery} from '../../graphql';
import {Select} from "@mantine/core";

export interface DivisionSelectorProps {
  seasonId: string
  value: string | null
  onChange(divisionId: string | null): void
}

export function DivisionSelector(props: DivisionSelectorProps) {

  const {
    seasonId,
    value,
    onChange
  } = props

  const { data } = useAllDivisionsInSeasonQuery({
    variables: {
      seasonId: seasonId
    }
  })

  const divisionData = (data?.season?.divisions ?? []).filter(div => div.teamCount > 0).map(division => ({
    value: division.id,
    label: division.name
  }))

  return (
    <Select
      sx={(theme) => ({
        width: '320px',
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
          width: '100%',
        },
      })}
      label=""
      placeholder="Select Division"
      data={divisionData}
      searchable
      clearable
      value={value}
      onChange={onChange}
    />
  )
}
