import React from "react";
import {Select} from "@mantine/core";
import {getHotkeyHandler} from "@mantine/hooks";
import {CellRenderProps} from "./CellRenderProps";
import {TableContext} from "../TableContext";

export const EditableSelectCell = (props: CellRenderProps<any> & { options: { label: string, value: string }[] }) => {

  const {
    value: initialValue,
    row: {index, original: {id: rowId}},
    column: {id},
    updateMyData, // This is a custom function that we supplied to our table instance
    options,
    ...rest
  } = props

  const tableContext = React.useContext(TableContext)

  const inputRef = React.useRef(null)

  React.useEffect(() => {
    inputRef?.current?.focus()
  }, [inputRef])

  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(() => {
    return options.find(o => o.label === initialValue)?.value ?? ''
  })

  const onChange = (e: string) => {
    setValue(e)
    updateMyData(rowId, id, e)
    tableContext.endEditing()
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    // updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(options.find(o => o.label === initialValue)?.value ?? '')
  }, [id])

  return (
    <Select
      ref={inputRef}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      variant={'unstyled'}
      initiallyOpened={true}
      searchable
      sx={{width: '100%'}}
      styles={{
        input: {
          height: '100%'
        }
      }}
      data={options}
      onKeyDown={getHotkeyHandler([
        ['Escape', () => {
          tableContext.endEditing()
        }],
      ])}
    />
  )
}
