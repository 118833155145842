import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_ENDPOINT,
  realm: process.env.REACT_APP_KEYCLOAK_REALM!!,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!!
});

// const keycloak = Keycloak('https://auth.leaguebrain-staging.com/team-management-console/keycloak.json')

export default keycloak;
