import React from 'react';
import {useAllTeamsInSeasonQuery} from "../../graphql";
import {Select} from '@mantine/core';

export interface TeamSelectorProps {
  seasonId: string
  value: string | null
  onChange(seasonTeamId: string | null): void
}

export function TeamSelector(props: TeamSelectorProps) {

  const {
    seasonId,
    value,
    onChange
  } = props

  const { data } = useAllTeamsInSeasonQuery({
    variables: {
      seasonId: seasonId
    }
  })

  const teamData = (data?.teamsInSeason ?? []).map(team => ({
    value: team.id,
    group: team.division.name,
    label: team.division.name + ' ' + team.name
  }))

  return (
    <Select
      sx={(theme) => ({
        width: '320px',
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
          width: '100%',
        },
      })}
      label=""
      placeholder="Select Team"
      data={teamData}
      searchable
      clearable
      value={value}
      onChange={onChange}
    />
  )
}
