import React, {useEffect} from "react";
import {useSmartFormContext} from "../context";
import {FormControl, FormHelperText, FormLabel, Stack} from "@chakra-ui/react";
import {Controller} from "react-hook-form";
import {UserAvatarImageUpload} from "../../../pages/user/manage-profile/SingleFileInput";

export interface SmartFileUploadSingleImageProps {
  label: string
  name: string
  isRequired?: boolean
  helperText?: string
}

export function SmartUserAvatarFileUpload(props: SmartFileUploadSingleImageProps) {

  const {
    label,
    name,
    isRequired = false,
    helperText
  } = props

  const { useFormReturn: { control, watch, getValues, setValue } } = useSmartFormContext();

  useEffect(() => {
    if (!getValues(name)) {
      setValue(name, null)
    }
  }, [watch(name)])

  return (
    <FormControl as={Stack} width="full">

      <FormLabel>{label}</FormLabel>

      <Controller
        name={name}
        control={control}
        rules={{ required: isRequired }}
        render={({ field }) => (
          <UserAvatarImageUpload {...field} />
        )}
      />

      {helperText && <FormHelperText pt={2}>{helperText}</FormHelperText>}
    </FormControl>
  )
}
