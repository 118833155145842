import React from "react";
import {RouteDefinition} from "../../../../../../components/router-traversal/route-visitor";
import {Text} from "@mantine/core";
import {Outlet} from "react-router-dom";

export const gameRoutes: RouteDefinition[] = [
  {
    path: 'master',
    element: <>Master</>
  },
  {
    path: ':gameId',
    element: <>
      <Text>Current Game</Text>
      <Outlet />
    </>
  }
]
