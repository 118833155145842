import React, {PropsWithChildren} from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem, BreadcrumbLink,
  Heading,
  Link,
  LinkProps,
  SimpleGrid,
  Stack,
  StackProps,
  Text,
  VStack
} from "@chakra-ui/react";
import {Link as ReactRouterLink, Route, Routes, useLocation, useMatch} from "react-router-dom";
import {ConnectToStripePage} from "./stripe";
import {Icon} from "../../../../components/FontAwesomeIcon";
import {faChevronRight} from "@fortawesome/pro-solid-svg-icons";

export function OrganizationSettingsPage() {

  const { pathname: url } = useMatch()!!

  const isExact = useLocation()?.pathname === url

  if (isExact) {
    return <OrganizationSettingsPageContent />
  }

  return (
    <Box>

      <Routes>
        <Route path={`${url}/organization/payments`}>
          <OrganizationBreadcrumbs title={'Payments'} settingsPath={url} />
          <ConnectToStripePage />
        </Route>

      </Routes>
    </Box>
  )
}

function OrganizationSettingsPageContent() {

  const { pathname: url } = useMatch()

  return (
    <Box mt={4}>
      <Heading size={'lg'}>Business Settings</Heading>

      <SimpleGrid columns={3} spacing={0} mt={4}>
        <SettingsBox title={'Your Organization'}>
          <SettingsBoxLink to={`${url}/organization`}>Account details</SettingsBoxLink>
          <SettingsBoxLink to={`${url}/organization/branding`}>Branding</SettingsBoxLink>
          <SettingsBoxLink to={`${url}/organization/billing`}>Billing</SettingsBoxLink>
          <SettingsBoxLink to={`${url}/organization/payments`}>Connect to Stripe</SettingsBoxLink>
        </SettingsBox>

        <SettingsBox title={'Staff Members'}>
          <SettingsBoxLink to={`${url}/staff/manage`}>Manage staff members</SettingsBoxLink>
        </SettingsBox>
      </SimpleGrid>
    </Box>
  )
}

interface FieldGroupProps extends StackProps {
  title?: string
}

export const FieldGroup = (props: FieldGroupProps) => {
  const { title, children, ...flexProps } = props
  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing="6" py="4" {...flexProps}>
      <Box minW="3xs">
        {title && (
          <Heading fontWeight="semibold" fontSize="lg" flexShrink={0}>
            {title}
          </Heading>
        )}
      </Box>
      {children}
    </Stack>
  )
}

interface SettingsBoxProps {
  title: string
  description?: string
}

function SettingsBox(props: PropsWithChildren<SettingsBoxProps>) {

  const {
    title,
    description
  } = props

  return (
    <Box
      height={'140px'}
      borderTop={'1px solid'}
      borderColor={'gray.200'}
      _notLast={{
        borderRight: '1px solid',
        borderColor: 'gray.200'
      }}
      p={4}
    >
      <Heading
        fontSize={'md'}
        fontWeight={'semibold'}
        color={'gray.700'}
      >
        {title}
      </Heading>

      <Text mt={1} fontSize={'sm'}>{description}</Text>

      <VStack align={'left'} spacing={0} pt={1}>
        {props.children}
      </VStack>

    </Box>
  )
}

function SettingsBoxLink(props: PropsWithChildren<LinkProps & { to: string }>) {
  return (
    <Link
      as={ReactRouterLink}
      to={props.to}
      fontSize={'sm'}
      fontWeight={'500'}
      color={'purple.500'}
    >
      {props.children}
    </Link>
  )
}

type OrganizationBreadcrumbsProps = {
  title: string
  settingsPath: string
}

function OrganizationBreadcrumbs(props: OrganizationBreadcrumbsProps) {
  return (
    <Breadcrumb py={6} color={'gray.700'} spacing={2} separator={<Icon size={'sm'} icon={faChevronRight} color={'gray.500'} />}>
      <BreadcrumbItem fontWeight={'500'}>
        <BreadcrumbLink
          as={ReactRouterLink}
          to={props.settingsPath}
        >
          Settings
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink fontWeight={'semibold'} color={'purple.600'}>{props.title}</BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  )
}

/*
<Box px={{ base: '4', md: '10' }} py='4' maxWidth="3xl" mx="auto">
      <forms
        id="settings-forms"
        onSubmit={(e) => {
          e.preventDefault()
          // forms submit logic
        }}
      >
        <Stack spacing="4" divider={<StackDivider />}>
          <Heading size="lg" as="h1" paddingBottom="4">
            Account Settings
          </Heading>
          <FieldGroup title="Basic Info">
            <VStack width="full" spacing="6">
              <FormControl id="name">
                <FormLabel>Organization Name</FormLabel>
                <Input type="text" maxLength={255} defaultValue={'The Greater Hudson Valley Baseball League'} />
              </FormControl>

              <FormControl id="email">
                <FormLabel>Support Email</FormLabel>
                <Input type="email" defaultValue="theghvbl@gmail.com" />
              </FormControl>

              <FormControl id="website">
                <FormLabel>Website</FormLabel>
                <Input type="text" defaultValue="https://www.ghvbl.com" />
              </FormControl>
            </VStack>
          </FieldGroup>
          <FieldGroup title="Connected Accounts">
            <Stack as="section" spacing="6">
              <Stack spacing="5">
                <SocialAccount provider="Stripe" icon={faStripe} username="theghvbl@gmail.com" />
                <SocialAccount provider="Mailchimp" icon={faMailchimp} />
              </Stack>
            </Stack>
          </FieldGroup>
        </Stack>
        <FieldGroup mt="8">
          <HStack width="full">
            <Button type="submit" colorScheme="blue">
              Save Changes
            </Button>
            <Button variant="outline">Cancel</Button>
          </HStack>
        </FieldGroup>
      </forms>
    </Box>
 */
