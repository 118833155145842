import {ActionIcon, TextInput} from "@mantine/core";
import React from "react";
import {faTimes} from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "../../../../../../../../components/FontAwesomeIcon";

export function DefaultColumnFilter({
                                      column: {filterValue, preFilteredRows, setFilter},
                                    }) {
  const count = preFilteredRows.length

  return (
    <TextInput
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
      mx={'sm'}
      size={'xs'}
      rightSection={
        <>
          {!!filterValue && (
            <ActionIcon size={'xs'} onClick={() => setFilter(undefined)}>
              <Icon color={'gray'} icon={faTimes} />
            </ActionIcon>
          )}
        </>
      }
    />
  )
}
