import {Icon} from "../../../../../../../components/FontAwesomeIcon";
import {faSearch, faTimes} from "@fortawesome/pro-solid-svg-icons";
import {ActionIcon, Center, Loader, TextInput} from "@mantine/core";
import React from "react";
import {TextInputProps} from "@mantine/core/lib/components/TextInput/TextInput";

export interface SearchBarProps extends Omit<TextInputProps, 'onChange'> {
  onChange?(value: string): void
  loading?: boolean
}

export function SearchBar(props: SearchBarProps) {

  const {
    value = '',
    loading = false,
    onChange = () => {},
    ...rest
  } = props

  return (
    <TextInput
      placeholder={'Search...'}
      icon={<Icon icon={faSearch} />}
      radius={'xl'}
      rightSectionWidth={40}
      rightSection={
        <Center>
          {loading ?
            <Loader variant={'dots'} size="xs" />
            :
            value ?
              <ActionIcon
                radius={'xl'}
                onClick={() => onChange('')}
              >
                <Icon icon={faTimes} />
              </ActionIcon>
              : null
          }
        </Center>
      }
      {...rest}
      value={props.value}
      onChange={e => onChange(e.target.value)}
    />
  )
}
