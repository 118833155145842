import React from "react";

export interface CellCoordinates {
  row: string
  column: string
}

export interface TableContextType {
  selectedCell: CellCoordinates | null
  editingCell: CellCoordinates | null

  selectCell(coordinates: CellCoordinates): void
  clearSelection(): void

  startEditing(coordinates: CellCoordinates): void
  endEditing(): void
}


export const TableContext = React.createContext<TableContextType>({
  selectedCell: null,
  editingCell: null,
  selectCell(coordinates: CellCoordinates) {},
  clearSelection() {},
  startEditing(coordinates: CellCoordinates) {},
  endEditing() {}
})
