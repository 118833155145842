import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A representation of a datetime without a time zone */
  LocalDateTime: any;
  /** A representation of a non blank String value */
  NonBlankString: any;
  /** A representation of an Olson time zone */
  TimeZone: any;
};

export type Decision = {
  readonly __typename?: 'Decision';
  readonly awayTeamScore: Scalars['Int'];
  readonly homeTeamScore: Scalars['Int'];
};

export type Division = {
  readonly __typename?: 'Division';
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
  readonly standings: ReadonlyArray<GraphQlSeasonTeam>;
  readonly teamCount: Scalars['Int'];
  readonly teams: ReadonlyArray<GraphQlSeasonTeam>;
};

export type Forfeit = {
  readonly __typename?: 'Forfeit';
  readonly forfeitingTeam: GraphQlSeasonTeam;
};

export type Game = {
  readonly __typename?: 'Game';
  readonly awayTeam: GraphQlSeasonTeam;
  readonly date: ZonedDateTimeParts;
  readonly homeTeam: GraphQlSeasonTeam;
  readonly id: Scalars['ID'];
  readonly officials: ReadonlyArray<Scalars['String']>;
  readonly reportedScore?: Maybe<GameResult>;
  readonly venue: Venue;
};

export type GameResult = Decision | Forfeit;

export type GraphQlSeasonTeam = {
  readonly __typename?: 'GraphQLSeasonTeam';
  readonly division: Division;
  readonly id: Scalars['ID'];
  readonly isCommittedToPlayoffs: Scalars['Boolean'];
  readonly name: Scalars['NonBlankString'];
  readonly statistics: TeamStatistics;
};



export type Query = {
  readonly __typename?: 'Query';
  readonly division?: Maybe<Division>;
  readonly gamesForTeam: ReadonlyArray<Game>;
  readonly ping: Scalars['String'];
  readonly rateLimit: RateLimit;
  readonly season?: Maybe<Season>;
  readonly teamsInSeason: ReadonlyArray<GraphQlSeasonTeam>;
};


export type QueryDivisionArgs = {
  id: Scalars['ID'];
};


export type QueryGamesForTeamArgs = {
  id: Scalars['ID'];
};


export type QuerySeasonArgs = {
  id: Scalars['ID'];
};


export type QueryTeamsInSeasonArgs = {
  id: Scalars['ID'];
};

export type RateLimit = {
  readonly __typename?: 'RateLimit';
  readonly cost: Scalars['Int'];
  readonly nodeCount: Scalars['Int'];
  readonly remaining: Scalars['Int'];
};

export type Season = {
  readonly __typename?: 'Season';
  readonly divisions: ReadonlyArray<Division>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
  readonly todaysGames: ReadonlyArray<Game>;
};

export type TeamRecord = {
  readonly __typename?: 'TeamRecord';
  readonly losses: Scalars['Int'];
  readonly ties: Scalars['Int'];
  readonly wins: Scalars['Int'];
};

export type TeamStatistics = {
  readonly __typename?: 'TeamStatistics';
  readonly currentActiveStreak: Scalars['String'];
  readonly expectedWinLoss: TeamRecord;
  readonly points: Scalars['Int'];
  readonly record: TeamRecord;
  readonly recordOverLastFive: TeamRecord;
  readonly scoreDifferential: Scalars['Int'];
  readonly scoresAgainst: Scalars['Int'];
  readonly scoresAgainstAverage: Scalars['Float'];
  readonly scoresFor: Scalars['Int'];
  readonly winPercentage: Scalars['Float'];
};


export type Venue = {
  readonly __typename?: 'Venue';
  readonly id: Scalars['ID'];
  readonly name: Scalars['NonBlankString'];
};

export type ZonedDateTimeParts = {
  readonly __typename?: 'ZonedDateTimeParts';
  readonly timestamp: Scalars['LocalDateTime'];
  readonly timezone: Scalars['TimeZone'];
};

export type GamesForTeamQueryVariables = Exact<{
  seasonTeamId: Scalars['ID'];
}>;


export type GamesForTeamQuery = { readonly __typename?: 'Query', readonly gamesForTeam: ReadonlyArray<{ readonly __typename?: 'Game', readonly id: string, readonly date: { readonly __typename?: 'ZonedDateTimeParts', readonly timestamp: any, readonly timezone: any }, readonly homeTeam: { readonly __typename?: 'GraphQLSeasonTeam', readonly id: string, readonly name: any, readonly division: { readonly __typename?: 'Division', readonly id: string, readonly name: any } }, readonly awayTeam: { readonly __typename?: 'GraphQLSeasonTeam', readonly id: string, readonly name: any, readonly division: { readonly __typename?: 'Division', readonly id: string, readonly name: any } }, readonly venue: { readonly __typename?: 'Venue', readonly id: string, readonly name: any }, readonly reportedScore?: Maybe<{ readonly __typename?: 'Decision', readonly homeTeamScore: number, readonly awayTeamScore: number } | { readonly __typename?: 'Forfeit', readonly forfeitingTeam: { readonly __typename?: 'GraphQLSeasonTeam', readonly id: string, readonly name: any } }> }> };

export type SeasonByIdQueryVariables = Exact<{
  seasonId: Scalars['ID'];
}>;


export type SeasonByIdQuery = { readonly __typename?: 'Query', readonly season?: Maybe<{ readonly __typename?: 'Season', readonly id: string, readonly name: any }> };

export type AllTeamsInSeasonQueryVariables = Exact<{
  seasonId: Scalars['ID'];
}>;


export type AllTeamsInSeasonQuery = { readonly __typename?: 'Query', readonly teamsInSeason: ReadonlyArray<{ readonly __typename?: 'GraphQLSeasonTeam', readonly id: string, readonly name: any, readonly division: { readonly __typename?: 'Division', readonly id: string, readonly name: any } }> };

export type AllDivisionsInSeasonQueryVariables = Exact<{
  seasonId: Scalars['ID'];
}>;


export type AllDivisionsInSeasonQuery = { readonly __typename?: 'Query', readonly season?: Maybe<{ readonly __typename?: 'Season', readonly id: string, readonly divisions: ReadonlyArray<{ readonly __typename?: 'Division', readonly id: string, readonly name: any, readonly teamCount: number }> }> };

export type TeamsInDivisionStatisticsQueryVariables = Exact<{
  divisionId: Scalars['ID'];
}>;


export type TeamsInDivisionStatisticsQuery = { readonly __typename?: 'Query', readonly division?: Maybe<{ readonly __typename?: 'Division', readonly teams: ReadonlyArray<{ readonly __typename?: 'GraphQLSeasonTeam', readonly id: string, readonly name: any, readonly isCommittedToPlayoffs: boolean, readonly statistics: { readonly __typename?: 'TeamStatistics', readonly winPercentage: number, readonly currentActiveStreak: string, readonly scoresFor: number, readonly scoresAgainst: number, readonly scoreDifferential: number, readonly scoresAgainstAverage: number, readonly points: number, readonly record: { readonly __typename?: 'TeamRecord', readonly wins: number, readonly losses: number, readonly ties: number }, readonly recordOverLastFive: { readonly __typename?: 'TeamRecord', readonly wins: number, readonly losses: number, readonly ties: number }, readonly expectedWinLoss: { readonly __typename?: 'TeamRecord', readonly wins: number, readonly losses: number, readonly ties: number } } }> }> };

export type TeamsInSeasonQueryVariables = Exact<{
  seasonId: Scalars['ID'];
}>;


export type TeamsInSeasonQuery = { readonly __typename?: 'Query', readonly teamsInSeason: ReadonlyArray<{ readonly __typename?: 'GraphQLSeasonTeam', readonly id: string, readonly name: any }> };

export type TodaysGamesBySeasonQueryVariables = Exact<{
  seasonId: Scalars['ID'];
}>;


export type TodaysGamesBySeasonQuery = { readonly __typename?: 'Query', readonly season?: Maybe<{ readonly __typename?: 'Season', readonly name: any, readonly todaysGames: ReadonlyArray<{ readonly __typename?: 'Game', readonly id: string, readonly officials: ReadonlyArray<string>, readonly date: { readonly __typename?: 'ZonedDateTimeParts', readonly timestamp: any, readonly timezone: any }, readonly homeTeam: { readonly __typename?: 'GraphQLSeasonTeam', readonly id: string, readonly name: any, readonly division: { readonly __typename?: 'Division', readonly id: string, readonly name: any } }, readonly awayTeam: { readonly __typename?: 'GraphQLSeasonTeam', readonly id: string, readonly name: any, readonly division: { readonly __typename?: 'Division', readonly id: string, readonly name: any } }, readonly venue: { readonly __typename?: 'Venue', readonly id: string, readonly name: any } }> }> };


export const GamesForTeamDocument = gql`
    query GamesForTeam($seasonTeamId: ID!) {
  gamesForTeam(id: $seasonTeamId) {
    id
    date {
      timestamp
      timezone
    }
    homeTeam {
      id
      name
      division {
        id
        name
      }
    }
    awayTeam {
      id
      name
      division {
        id
        name
      }
    }
    venue {
      id
      name
    }
    reportedScore {
      ... on Decision {
        homeTeamScore
        awayTeamScore
      }
      ... on Forfeit {
        forfeitingTeam {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGamesForTeamQuery__
 *
 * To run a query within a React component, call `useGamesForTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamesForTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamesForTeamQuery({
 *   variables: {
 *      seasonTeamId: // value for 'seasonTeamId'
 *   },
 * });
 */
export function useGamesForTeamQuery(baseOptions: Apollo.QueryHookOptions<GamesForTeamQuery, GamesForTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GamesForTeamQuery, GamesForTeamQueryVariables>(GamesForTeamDocument, options);
      }
export function useGamesForTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GamesForTeamQuery, GamesForTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GamesForTeamQuery, GamesForTeamQueryVariables>(GamesForTeamDocument, options);
        }
export type GamesForTeamQueryHookResult = ReturnType<typeof useGamesForTeamQuery>;
export type GamesForTeamLazyQueryHookResult = ReturnType<typeof useGamesForTeamLazyQuery>;
export type GamesForTeamQueryResult = Apollo.QueryResult<GamesForTeamQuery, GamesForTeamQueryVariables>;
export const SeasonByIdDocument = gql`
    query SeasonById($seasonId: ID!) {
  season(id: $seasonId) {
    id
    name
  }
}
    `;

/**
 * __useSeasonByIdQuery__
 *
 * To run a query within a React component, call `useSeasonByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonByIdQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useSeasonByIdQuery(baseOptions: Apollo.QueryHookOptions<SeasonByIdQuery, SeasonByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonByIdQuery, SeasonByIdQueryVariables>(SeasonByIdDocument, options);
      }
export function useSeasonByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonByIdQuery, SeasonByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonByIdQuery, SeasonByIdQueryVariables>(SeasonByIdDocument, options);
        }
export type SeasonByIdQueryHookResult = ReturnType<typeof useSeasonByIdQuery>;
export type SeasonByIdLazyQueryHookResult = ReturnType<typeof useSeasonByIdLazyQuery>;
export type SeasonByIdQueryResult = Apollo.QueryResult<SeasonByIdQuery, SeasonByIdQueryVariables>;
export const AllTeamsInSeasonDocument = gql`
    query AllTeamsInSeason($seasonId: ID!) {
  teamsInSeason(id: $seasonId) {
    id
    name
    division {
      id
      name
    }
  }
}
    `;

/**
 * __useAllTeamsInSeasonQuery__
 *
 * To run a query within a React component, call `useAllTeamsInSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTeamsInSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTeamsInSeasonQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useAllTeamsInSeasonQuery(baseOptions: Apollo.QueryHookOptions<AllTeamsInSeasonQuery, AllTeamsInSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTeamsInSeasonQuery, AllTeamsInSeasonQueryVariables>(AllTeamsInSeasonDocument, options);
      }
export function useAllTeamsInSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTeamsInSeasonQuery, AllTeamsInSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTeamsInSeasonQuery, AllTeamsInSeasonQueryVariables>(AllTeamsInSeasonDocument, options);
        }
export type AllTeamsInSeasonQueryHookResult = ReturnType<typeof useAllTeamsInSeasonQuery>;
export type AllTeamsInSeasonLazyQueryHookResult = ReturnType<typeof useAllTeamsInSeasonLazyQuery>;
export type AllTeamsInSeasonQueryResult = Apollo.QueryResult<AllTeamsInSeasonQuery, AllTeamsInSeasonQueryVariables>;
export const AllDivisionsInSeasonDocument = gql`
    query AllDivisionsInSeason($seasonId: ID!) {
  season(id: $seasonId) {
    id
    divisions {
      id
      name
      teamCount
    }
  }
}
    `;

/**
 * __useAllDivisionsInSeasonQuery__
 *
 * To run a query within a React component, call `useAllDivisionsInSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDivisionsInSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDivisionsInSeasonQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useAllDivisionsInSeasonQuery(baseOptions: Apollo.QueryHookOptions<AllDivisionsInSeasonQuery, AllDivisionsInSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDivisionsInSeasonQuery, AllDivisionsInSeasonQueryVariables>(AllDivisionsInSeasonDocument, options);
      }
export function useAllDivisionsInSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDivisionsInSeasonQuery, AllDivisionsInSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDivisionsInSeasonQuery, AllDivisionsInSeasonQueryVariables>(AllDivisionsInSeasonDocument, options);
        }
export type AllDivisionsInSeasonQueryHookResult = ReturnType<typeof useAllDivisionsInSeasonQuery>;
export type AllDivisionsInSeasonLazyQueryHookResult = ReturnType<typeof useAllDivisionsInSeasonLazyQuery>;
export type AllDivisionsInSeasonQueryResult = Apollo.QueryResult<AllDivisionsInSeasonQuery, AllDivisionsInSeasonQueryVariables>;
export const TeamsInDivisionStatisticsDocument = gql`
    query TeamsInDivisionStatistics($divisionId: ID!) {
  division(id: $divisionId) {
    teams: standings {
      id
      name
      isCommittedToPlayoffs
      statistics {
        record {
          wins
          losses
          ties
        }
        recordOverLastFive {
          wins
          losses
          ties
        }
        winPercentage
        currentActiveStreak
        scoresFor
        scoresAgainst
        scoreDifferential
        scoresAgainstAverage
        expectedWinLoss {
          wins
          losses
          ties
        }
        points
      }
    }
  }
}
    `;

/**
 * __useTeamsInDivisionStatisticsQuery__
 *
 * To run a query within a React component, call `useTeamsInDivisionStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsInDivisionStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsInDivisionStatisticsQuery({
 *   variables: {
 *      divisionId: // value for 'divisionId'
 *   },
 * });
 */
export function useTeamsInDivisionStatisticsQuery(baseOptions: Apollo.QueryHookOptions<TeamsInDivisionStatisticsQuery, TeamsInDivisionStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamsInDivisionStatisticsQuery, TeamsInDivisionStatisticsQueryVariables>(TeamsInDivisionStatisticsDocument, options);
      }
export function useTeamsInDivisionStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsInDivisionStatisticsQuery, TeamsInDivisionStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamsInDivisionStatisticsQuery, TeamsInDivisionStatisticsQueryVariables>(TeamsInDivisionStatisticsDocument, options);
        }
export type TeamsInDivisionStatisticsQueryHookResult = ReturnType<typeof useTeamsInDivisionStatisticsQuery>;
export type TeamsInDivisionStatisticsLazyQueryHookResult = ReturnType<typeof useTeamsInDivisionStatisticsLazyQuery>;
export type TeamsInDivisionStatisticsQueryResult = Apollo.QueryResult<TeamsInDivisionStatisticsQuery, TeamsInDivisionStatisticsQueryVariables>;
export const TeamsInSeasonDocument = gql`
    query TeamsInSeason($seasonId: ID!) {
  teamsInSeason(id: $seasonId) {
    id
    name
  }
}
    `;

/**
 * __useTeamsInSeasonQuery__
 *
 * To run a query within a React component, call `useTeamsInSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsInSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsInSeasonQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useTeamsInSeasonQuery(baseOptions: Apollo.QueryHookOptions<TeamsInSeasonQuery, TeamsInSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamsInSeasonQuery, TeamsInSeasonQueryVariables>(TeamsInSeasonDocument, options);
      }
export function useTeamsInSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsInSeasonQuery, TeamsInSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamsInSeasonQuery, TeamsInSeasonQueryVariables>(TeamsInSeasonDocument, options);
        }
export type TeamsInSeasonQueryHookResult = ReturnType<typeof useTeamsInSeasonQuery>;
export type TeamsInSeasonLazyQueryHookResult = ReturnType<typeof useTeamsInSeasonLazyQuery>;
export type TeamsInSeasonQueryResult = Apollo.QueryResult<TeamsInSeasonQuery, TeamsInSeasonQueryVariables>;
export const TodaysGamesBySeasonDocument = gql`
    query TodaysGamesBySeason($seasonId: ID!) {
  season(id: $seasonId) {
    name
    todaysGames {
      id
      date {
        timestamp
        timezone
      }
      homeTeam {
        id
        name
        division {
          id
          name
        }
      }
      awayTeam {
        id
        name
        division {
          id
          name
        }
      }
      venue {
        id
        name
      }
      officials
    }
  }
}
    `;

/**
 * __useTodaysGamesBySeasonQuery__
 *
 * To run a query within a React component, call `useTodaysGamesBySeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodaysGamesBySeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodaysGamesBySeasonQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useTodaysGamesBySeasonQuery(baseOptions: Apollo.QueryHookOptions<TodaysGamesBySeasonQuery, TodaysGamesBySeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TodaysGamesBySeasonQuery, TodaysGamesBySeasonQueryVariables>(TodaysGamesBySeasonDocument, options);
      }
export function useTodaysGamesBySeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TodaysGamesBySeasonQuery, TodaysGamesBySeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TodaysGamesBySeasonQuery, TodaysGamesBySeasonQueryVariables>(TodaysGamesBySeasonDocument, options);
        }
export type TodaysGamesBySeasonQueryHookResult = ReturnType<typeof useTodaysGamesBySeasonQuery>;
export type TodaysGamesBySeasonLazyQueryHookResult = ReturnType<typeof useTodaysGamesBySeasonLazyQuery>;
export type TodaysGamesBySeasonQueryResult = Apollo.QueryResult<TodaysGamesBySeasonQuery, TodaysGamesBySeasonQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GameResult": [
      "Decision",
      "Forfeit"
    ]
  }
};
      export default result;
    