import React from "react";
import {Box, Paper, useMantineTheme} from "@mantine/core";
import {Outlet, useOutlet} from "react-router-dom";
import {useViewportSize} from "@mantine/hooks";

export interface ListViewWithDetailProps {
  header?: React.ReactNode
  list?: React.ReactNode
  emptyContent?: React.ReactNode
}

export function ListViewWithDetail(props: ListViewWithDetailProps) {

  const theme = useMantineTheme()
  const hasDetail = useOutlet() !== null

  const { width } = useViewportSize();
  const isSmallerScreen = width < theme.breakpoints['md'];


  return (
    <Box
      sx={theme => ({
        ...theme.fn.cover(),
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'stretch',
        height: '100%',
      })}
    >

      {/* Header */}
      <Box
        sx={{
          position: 'relative',
          display: isSmallerScreen && hasDetail ? 'none' : undefined
        }}
      >
        {props.header}
      </Box>

      {/* List */}
      <Box
        mb={theme.spacing['md']}
        sx={{
          position: 'relative',
          flexBasis: `150px`,
          flexGrow: 1,
          display: 'flex',
          width: `100%`,
          overflow: 'hidden',
          flexWrap: 'nowrap',
          gap: theme.spacing['sm'],
        }}
      >
        {props.list}

        {(!isSmallerScreen || hasDetail) && (
          <Paper
            withBorder
            p={'md'}
            sx={theme => ({
              position: 'relative',
              flexBasis: '100px',
              flexGrow: 1,
            })}
          >
            {hasDetail ? <Outlet /> : props.emptyContent}
          </Paper>
        )}
      </Box>

    </Box>
  )
}
