import React from 'react';
import {Box, Collapse, createStyles, Group, Text, ThemeIcon} from '@mantine/core';
import {Icon} from '../../../../components/FontAwesomeIcon';
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {Link, matchPath, resolvePath, useLocation, useMatch, useResolvedPath} from "react-router-dom";

const useStyles = createStyles((theme) => ({
  control: {
    position: 'relative',
    fontWeight: 500,
    display: 'block',
    width: '100%',
    padding: `4px ${theme.spacing.md}px`,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,
    borderRadius: `${theme.radius.sm}px`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },

    margin: '2px 0'
  },

  link: {
    position: 'relative',
    fontWeight: 500,
    display: 'block',
    textDecoration: 'none',
    padding: `8px ${theme.spacing.xs}px`,
    paddingLeft: 31,
    marginLeft: 30,
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    borderLeft: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    borderRadius: `${theme.radius.sm}px`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  activeLink: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1],
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },

  activeLinkIcon: {
    '&::before': {
      content: '""',
      width: '4px',
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.indigo[6] : theme.colors.indigo[5],
      position: 'absolute',
      top: '10%', bottom: '10%', left: 0,
      transform: 'translateX(100%)',
      borderRadius: '4px'
    }
  },

  activeNestedLink: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },
}));

interface LinksGroupProps {
  icon: IconDefinition;
  label: string;
  initiallyOpened?: boolean;
  link: string;
  links?: { label: string; link: string }[];
}

export function LinksGroup({ icon, label, link, links }: LinksGroupProps) {
  const { classes, cx } = useStyles();

  const location = useLocation()

  let resolved = useResolvedPath(link);

  const hasLinks = Array.isArray(links);

  const opened = !!useMatch({ path: resolved.pathname, end: link === '' })

  const items = (hasLinks ? (links ?? []) : []).map((nestedLink) => {

    const rresolved = resolvePath(nestedLink.link, resolved.pathname)
    const match = matchPath({ path: rresolved.pathname, end: true }, location.pathname)

    return (
      <Box key={nestedLink.label} px={'xs'} py={2}>
        <Text<typeof Link>
          component={Link}
          to={[link, nestedLink.link].join('/')}
          className={cx(classes.link, {[classes.activeNestedLink]: match, [classes.activeLinkIcon]: match})}
        >
          {nestedLink.label}
        </Text>
      </Box>
    )
  });

  const containsSubMatch = (hasLinks ? (links ?? []) : []).map((nestedLink) => {
    const rresolved = resolvePath(nestedLink.link, resolved.pathname)
    return matchPath({ path: rresolved.pathname, end: true }, location.pathname)
  })
    .reduce((acc, curr) => acc || !!curr, false);

  return (
    <>
      <Box px={'xs'} py={4}>
        <Text<typeof Link>
          component={Link}
          to={link}
          className={cx(classes.control, { [classes.activeLink]: opened && !containsSubMatch, [classes.activeLinkIcon]: opened && !containsSubMatch })}
        >
          <Group position="apart" spacing={0}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ThemeIcon variant="light" size={30}>
                {<Icon icon={icon} />}
              </ThemeIcon>
              <Box ml="md">{label}</Box>
            </Box>
          </Group>
        </Text>
      </Box>

      {hasLinks ? (
        <Collapse in={opened}>
          <Group direction={'column'} align={'stretch'} spacing={2}>
            {items}
          </Group>
        </Collapse>
      ) : null}
    </>
  );
}

