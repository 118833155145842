import React from 'react';
import {Box, Divider, Flex, Heading, HStack, useColorModeValue as mode} from "@chakra-ui/react";

export const SearchPage = () => {

  return (
    <Box flexGrow={1} w="full" overflow={'auto'}>
      <Flex direction="column" align="stretch">
        <Box bg={mode('gray.50', 'gray.800')} px="8" pt="8">
          <Flex
            direction={{ base: 'row', md: 'row' }}
            justify="space-between"
            align="flex-start"
            mb={{base: '4', md: '10'}}
          >
            <HStack mb={{ base: '4', md: '0' }}>
              <Heading size="lg">Search Results</Heading>
            </HStack>
          </Flex>
        </Box>

        <Box pos="relative" zIndex={0}>
          <Divider borderBottomWidth="2px" opacity={1} borderColor={mode('gray.100', 'gray.700')} />
        </Box>

        <Box flex="1">
          Results...
        </Box>
      </Flex>
    </Box>
  )
}
