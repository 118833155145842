import React from 'react';


export type PageNameContextType = {
  pageName: string
  setPageName(name: string): void
}

export const PageNameContext = React.createContext<PageNameContextType>({
  pageName: '',
  setPageName(name: string) {}
})

export function usePageNameContext(): PageNameContextType {
  return React.useContext(PageNameContext)
}
