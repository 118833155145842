import React, {useContext} from 'react';
import {
  Box,
  Center,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  useToken,
  VStack
} from "@chakra-ui/react";
import {Icon} from "../../../../components/FontAwesomeIcon";
import {faBell, faCog, faUser} from "@fortawesome/pro-solid-svg-icons";
import {Link as ReactRouterLink, useMatch, useLocation} from "react-router-dom";
import {OrganizationViewingContext} from "../../index";

export interface OrganizationHeaderProps {
  leftElement?: React.ReactNode
}

export function Header(props: OrganizationHeaderProps) {

  const {
    leftElement
  } = props

  return (
      <SimpleGrid
        h={'48px'}
        bgColor={'gray.100'}
        borderBottom={'1px solid'}
        borderColor={'gray.200'}
        columns={3}
        spacing={4}
        p={1}
        px={24}
      >
        <VStack align={'start'} justifyContent={'center'}>
          {leftElement}
        </VStack>

        <Center>
          <InputGroup>
            <Input
              h={'36px'}
              bgColor={'white'}
              placeholder={'Search...'}
            />
          </InputGroup>
        </Center>

        <UserProfile />
      </SimpleGrid>
  )
}

function UserProfile() {

  const [grayColor] = useToken('colors', ['gray.600'])

  const { pathname: url } = useLocation()
  const organizationViewingContext = useContext(OrganizationViewingContext)

  return (
    <HStack
      justifyContent={'flex-end'}
      alignItems={'center'}
      spacing={4}
    >
      <IconButton
        // as={ReactRouterLink}
        // to={`${url}/notifications`}
        aria-label={'Notifications'}
        icon={<Icon size={'lg'} color={grayColor} icon={faBell} />}
      />

      <IconButton
        as={ReactRouterLink}
        to={`${url}/settings`}
        aria-label={'Settings'}
        icon={<Icon size={'lg'} color={grayColor} icon={faCog} />}
      />

      <Menu>
        <MenuButton
          as={IconButton}
          aria-label={'User Profile'}
          icon={<Icon size={'lg'} color={grayColor} icon={faUser} />}
        />

        <MenuList pt={0}>
          <VStack
            spacing={1}
            px={4}
            py={2}
            mb={2}
            align={'left'}
            borderBottom={'1px solid lightgray'}
          >
            <Heading size={'sm'} fontWeight={'semibold'}>{organizationViewingContext.displayName}</Heading>
            <Text fontSize={'xs'}>{organizationViewingContext.viewerRole}</Text>
          </VStack>

          <MenuItem>Profile</MenuItem>
          <MenuItem>Sign Out</MenuItem>
        </MenuList>
      </Menu>

    </HStack>
  )
}
