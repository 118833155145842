import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import {HiOutlineMenu} from 'react-icons/hi'
import {Sidebar} from '../Sidebar'
import {useMobileMenuState} from './useMobileMenuState'
import {ProfileDropdown} from "./ProfileDropdown";

export const MobileTopBar = () => {
  const { isOpen, onClose, onOpen } = useMobileMenuState()
  return (
    <Flex
      align="center"
      justify="space-between"
      py="2"
      px="4"
      bg={mode('gray.50', 'gray.800')}
      display={{ base: 'flex', md: 'none' }}
      borderBottomWidth="1px"
    >

      <IconButton
        onClick={onOpen}
        variant="unstyled"
        display="flex"
        cursor="pointer"
        aria-label="Menu"
        icon={<HiOutlineMenu fontSize="1.5rem" />}
      />

      <Heading fontSize={'lg'}>LeagueBrain</Heading>

      <ProfileDropdown size={'sm'} />

      {/*
      <BaseSearchBar
        inputVariant={'filled'}
        inputLeftElement={
          <IconButton
            onClick={onOpen}
            variant="unstyled"
            display="flex"
            cursor="pointer"
            aria-label="Menu"
            icon={<HiOutlineMenu fontSize="1.5rem" />}
          />
        }
        inputRightElement={
          <ProfileDropdown size={'sm'} />
        }
      />
      */}
      {/*<SearchBar
        variant={'filled'}
        leftElement={
          <IconButton
            onClick={onOpen}
            variant="unstyled"
            display="flex"
            cursor="pointer"
            aria-label="Menu"
            icon={<HiOutlineMenu fontSize="1.5rem" />}
          />
        }
        rightElement={<ProfileDropdown size={'sm'} />}
      />*/}

      <Drawer
        size="xs"
        placement="left"
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent onClick={() => setTimeout(onClose, 150)} bg={mode('white', 'gray.800')} shadow="none" position="relative" maxW="64">
          <Sidebar width="full" height="full" border="0" />
          <DrawerCloseButton
            bg="blue.500"
            _hover={{ bg: 'blue.600' }}
            _active={{ bg: 'blue.700' }}
            rounded="0"
            position="absolute"
            color="white"
            right="-8"
            top="0"
          />
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}
