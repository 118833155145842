import * as React from 'react'

export interface Link {
  label: string
  href?: string
  external?: boolean
  children?: Array<{
    label: string
    description?: string
    href: string
    icon?: React.ReactElement
  }>
}

export function organizationPageLinks(
  organizationId: string
): Link[] {
  return [
    { label: 'Home', external: true, href: `${process.env.REACT_APP_REFRESHED_ADMIN_BASE}/o/${organizationId}/` },
    { label: 'Seasons', external: true, href: `${process.env.REACT_APP_REFRESHED_ADMIN_BASE}/o/${organizationId}/seasons` },
    { label: 'Venues', href: 'venues' },
    { label: 'Officials', external: true, href: `${process.env.REACT_APP_REFRESHED_ADMIN_BASE}/o/${organizationId}/officials` },
  ]
}

export function seasonPageLinks(
  organizationId: string,
  seasonId: string
): Link[] {
  return [
    { label: 'Home', external: true, href: `${process.env.REACT_APP_REFRESHED_ADMIN_BASE}/o/${organizationId}/s/${seasonId}/` },
    { label: 'Teams', external: true, href: `${process.env.REACT_APP_REFRESHED_ADMIN_BASE}/o/${organizationId}/s/${seasonId}/teams` },
    { label: 'Games', href: 'games' },
  ]
}
