import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  BoxProps,
  Center,
  CenterProps,
  useColorModeValue as mode,
  VStack
} from "@chakra-ui/react";
import {Icon} from "../FontAwesomeIcon";
import {IconDefinition} from "@fortawesome/pro-duotone-svg-icons";

export function SimpleList(props: React.PropsWithChildren<BoxProps>) {

  const childrenNodes = React.Children.toArray(props.children)
  const whenEmptyNode = childrenNodes.filter(node => node.type === WhenEmpty)[0] ?? <div />
  const listItems = childrenNodes.filter(node => node.type !== WhenEmpty)

  const bgColor = mode('white', 'gray.800')

  return (
    <Box
      p={{ base: 0, md: 1 }}
    >
      {listItems.length === 0 ? (
        <>{whenEmptyNode}</>
      ) : (
        <VStack
          bgColor={bgColor}
          align="stretch"
          spacing={4}
        >
          {listItems}
        </VStack>
      )}
    </Box>
  )
}

export function WhenEmpty(props: React.PropsWithChildren<CenterProps>) {
  return (
    <>{props.children}</>
  )
}

export interface WhenEmptyCallToActionProps {
  icon: IconDefinition
  title: string
  description: string
}

export function CallToAction(props: React.PropsWithChildren<WhenEmptyCallToActionProps>) {
  return (
    <Center h={'400px'}>
      <Alert
        bgColor={'transparent'}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Icon icon={props.icon} size={'5x'} color={'gray.400'} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {props.title}
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          {props.description}
        </AlertDescription>
      </Alert>
    </Center>
  )
}
