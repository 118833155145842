import React from 'react'
import {useBlockLayout, useFilters, useResizeColumns, useTable} from 'react-table'
import {ActionIcon, Box, ScrollArea, Tooltip} from "@mantine/core";
import {useVirtual} from "react-virtual";
import {useClickOutside, useClipboard, useHotkeys} from "@mantine/hooks";
import _ from 'lodash';
import {DefaultTextCell} from "./table/cells/DefaultTextCell";
import {DefaultColumnFilter} from "./table/DefaultColumnFilter";
import {CellCoordinates, TableContext} from "./table/TableContext";
import {faFileExport, faLockKeyhole} from "@fortawesome/pro-solid-svg-icons";
import {useExportData} from 'react-table-plugins';
import {getExportFileBlob} from './table/getExportFileBlob';
import {Icon} from '../../../../../../../components/FontAwesomeIcon';
import {MantineTeamActionsMenu} from "../../../../../../organization/season/teams/TeamActionsMenu";

interface TableSchema {
  type: string
  columns: {
    title: string
    accessor: string
  }[]
}

export type TableCellType = {
  type: 'text'
} | {
  type: 'number'
} | {
  type: 'date'
} | {
  type: 'select'
  options: {
    value: string
    label: string
    group: string | null
  }[]
}

export interface TableData {
  schema: {
    path: string
    name: string
    isEditable: boolean
    type: TableCellType
  }[]
  data: {
    _id: string
    [key: string]: string
  }[]
}

export interface TeamListTableProps {
  tableData: TableData
  updateValue(rowId: string, columnId: string, value: string): Promise<boolean>
  refreshData(ids: string[]): Promise<TableData['data']>
}

const SCROLLBAR_WIDTH = 12

export function TeamListTable(props: TeamListTableProps) {

  const clipboard = useClipboard({ timeout: 500 });

  const _data = React.useMemo(
    () => props.tableData.data.map(r => {
      return {
        ...r,
        id: r['_id']
      }
    }),
    []
  )

  const columns = React.useMemo(
    () => [
      {
        id: 'actions',
        Cell: (cellProps) => {
          const teamId = cellProps.row.id
          return (
            <MantineTeamActionsMenu
              teamId={teamId}
              isCommittedToPlayoffs={cellProps.row.original['team.is_committed_to_playoffs'] === 'true'}
              row={cellProps.row}
              refetch={() => {
                props.refreshData([teamId])
                  .then(res => {
                  setSkipPageReset(true)
                  setData(old =>
                    old.map((row, index) => {
                      if (row.id === teamId) {
                        return {
                          ...res.data[0],
                          id: teamId
                        }
                      }

                      return row
                    })
                  )
              })}}
            />
          )
        },
        width: 36,
      },
      ...props.tableData.schema.map(s => ({
        Header: s.name,
        id: s.path,
        isEditable: s.isEditable,
        type: s.type,
        accessor: (row: any) => {
          switch (s.type.type) {
            case 'text':
              return row[s.path]
            case 'number':
              return row[s.path]
            case 'date':
              return row[s.path]
            case 'select':
              return s.type.options.find(o => o.value === row[s.path])?.label ?? row[s.path]
            default:
              return row[s.path]
          }
        }
      }
    ))], [])

  const getRowId = React.useCallback((row: any) => {
    return row['_id']
  }, [])

  const tableRef = useClickOutside(() => {
    setEditingCell(null)
  });

  const DEFAULT_COLUMN_WIDTH = React.useMemo(() => {
    if (tableRef.current) {
      return (tableRef.current.getBoundingClientRect().width) / columns.length
    }

    return 200
  }, [tableRef])

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      Cell: DefaultTextCell,
      width: DEFAULT_COLUMN_WIDTH,
      minWidth: 24
    }),
    []
  )

  const [data, setData] = React.useState(() => _data)
  const [skipPageReset, setSkipPageReset] = React.useState(false)

  const updateMyData = React.useCallback(  async (rowIndex, columnId, value) => {
    const oldValue = (data.find(t => t._id === rowIndex) ?? {})[columnId]

    if (oldValue === value) {
      return
    }

    // We also turn on the flag to not reset the page
    setSkipPageReset(true)

    setData(old =>
      old.map((row, index) => {
        if (row._id === rowIndex) {
          const oldRow = _.cloneDeep(row)
          return _.set(oldRow, columnId, value)
        }
        return row
      })
    )

    props.updateValue(data.find(t => t._id === rowIndex)!!._id!!, columnId, value)
      .then(res => {
        if (!res) {
          throw Error("Error updating value.")
        }
      })
      .catch(err => {
        setSkipPageReset(true)
        setData(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              const oldRow = _.cloneDeep(row)
              return _.set(oldRow, columnId, oldValue)
            }
            return row
          })
        )
      })

  }, [data, props])

  React.useEffect(() => {
    setSkipPageReset(false)
  }, [data])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    totalColumnsWidth,
    exportData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      getRowId,
      // manualFilters: true,
      autoResetPage: !skipPageReset,
      autoResetFilters: !skipPageReset,
      updateMyData,
      getExportFileName: ({ fileType, all }) => 'Teams',
      getExportFileBlob,
    },
    useResizeColumns,
    useBlockLayout,
    useFilters,
    useExportData,
  )

  const parentRef = React.useRef(null)

  const rowVirtualizer = useVirtual({
    size: rows.length,
    parentRef,
    estimateSize: React.useCallback(() => 36, []),
    overscan: 10,
    keyExtractor(idx): string {
      return rows[idx].original.id
    }
  })

  const columnVirtualizer = useVirtual({
    horizontal: true,
    size: columns.length,
    parentRef,
    estimateSize: React.useCallback((index: number) => {
      const col = columns[index]
      return state.columnResizing.columnWidths[col.id] ?? col.width ?? DEFAULT_COLUMN_WIDTH
    }, [JSON.stringify(state)]),
    overscan: 1,
    keyExtractor(idx): string {
      return columns[idx].id
    }
  })

  const [selectedCell, setSelectedCell] = React.useState<CellCoordinates | null>(null);
  const [editingCell, setEditingCell] = React.useState<CellCoordinates | null>(null);

  React.useEffect(() => {
    rowVirtualizer.scrollToIndex(rows.findIndex(r => r.original.id === selectedCell?.row))
    columnVirtualizer.scrollToIndex(columns.findIndex(c => c.id === selectedCell?.column))
  }, [selectedCell])

  React.useEffect(() => {
    if (!editingCell) return

    const isSelectedRowVisible = rowVirtualizer.virtualItems.findIndex(vi => vi.key === editingCell?.row) !== -1
    const isColumnVisible = columnVirtualizer.virtualItems.findIndex(ci => ci.key === editingCell?.column) !== -1

    if (!isSelectedRowVisible || !isColumnVisible) {
      setEditingCell(null)
    }
  }, [rowVirtualizer.virtualItems, columnVirtualizer.virtualItems, rows, columns])

  const hotkeyActions = [
    ['ArrowLeft', () => {
      if (editingCell || !selectedCell) return
      const currCol = columns.findIndex(c => c.id === selectedCell?.column)

      if (columns[currCol > 0 ? currCol - 1 : currCol].id === 'actions') return

      setSelectedCell({
        ...selectedCell,
        column: columns[currCol > 0 ? currCol - 1 : currCol].id
      })
    }],
    ['ArrowRight', () => {
      if (editingCell || !selectedCell) return
      const currCol = columns.findIndex(c => c.id === selectedCell?.column)

      if (columns[currCol < columns.length - 1 ? currCol + 1 : currCol].id === 'actions') return

      setSelectedCell({
        ...selectedCell,
        column: columns[currCol < columns.length - 1 ? currCol + 1 : currCol].id
      })
    }],
    ['ArrowUp', () => {
      if (editingCell || !selectedCell) return
      const currRow = rows.findIndex(r => r.original.id === selectedCell.row)
      setSelectedCell({
        ...selectedCell,
        row: rows[currRow > 0 ? currRow - 1 : currRow].original.id
      })
    }],
    ['ArrowDown', () => {
      if (editingCell || !selectedCell) return
      const currRow = rows.findIndex(r => r.original.id === selectedCell.row)
      setSelectedCell({
        ...selectedCell,
        row: rows[currRow < rows.length - 1 ? currRow + 1 : currRow].original.id
      })
    }],
    ['Escape', () => {
      setEditingCell(null)
    }],
    ['mod+C', () => {
      const currCol = columns.find(c => c.id === selectedCell?.column)
      const currRow = rows.find(r => r.original.id === selectedCell?.row)

      const r = currRow.cells.find(c => c.column.id === currCol.id)

      clipboard.copy(r.value)
    }],
    ['mod+S', () => {
      exportData('csv')
    }],
  ]

  if (!editingCell) {
    hotkeyActions.push(['Enter', () => {
      if (selectedCell) {
        const currCol = columns.find(c => c.id === selectedCell.column)
        if (currCol.isEditable) {
          const isSelectedRowVisible = rowVirtualizer.virtualItems.findIndex(vi => vi.key === selectedCell?.row) !== -1
          const isColumnVisible = columnVirtualizer.virtualItems.findIndex(ci => ci.key === selectedCell?.column) !== -1

          if (isSelectedRowVisible) {
            setEditingCell(selectedCell)
          }
        }
      }
    }])
  }

  useHotkeys(hotkeyActions);

  const headerRef = React.useRef(null)

  React.useEffect(() => {
    if (headerRef.current?.style?.left && parentRef.current) {
      const hasScrollableContent = parentRef.current.scrollHeight > parentRef.current.clientHeight;

      if (hasScrollableContent) {
        parentRef.current.scrollLeft = -1 * parseFloat(headerRef.current.style.left)
      }
    }
  }, [headerRef.current?.style?.left, parentRef.current])

  return (
    <TableContext.Provider
      value={{
        selectedCell: selectedCell,
        editingCell: editingCell,
        selectCell(coordinates: CellCoordinates) {
          setSelectedCell(coordinates)
        },
        clearSelection() {
          setSelectedCell(null)
        },
        startEditing(coordinates: CellCoordinates) {
          setEditingCell(coordinates)
        },
        endEditing() {
          setEditingCell(null)
        }
      }}
    >
    <Box
      ref={tableRef}
      {...getTableProps()}
      sx={(theme) => ({
        position: 'relative',
        height: 'calc(100vh - 80px)',
        cursor: 'default',
        border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
        overflow: 'hidden'
      })}
    >
      <Box
        ref={headerRef}
        sx={(theme) => ({
          position: 'absolute',
          width: `${totalColumnsWidth}px`,
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[1],
          borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4]}`,
          zIndex: 10,
        })}
      >
      {headerGroups.map(headerGroup => (
        <Box
          {...headerGroup.getHeaderGroupProps()}
          sx={{
            position: 'relative',
          }}
        >
          {headerGroup.headers.map(column => (
            <Box
              {...column.getHeaderProps()}
              py={4}
              align={'center'}
              sx={{
                userSelect: 'none'
              }}
            >
              {column.render('Header')}
              {column.id === 'actions' && (
                <ActionIcon onClick={() => exportData('csv')}>
                  <Icon icon={faFileExport} />
                </ActionIcon>
              )}

              {!column.isEditable && (
                <Tooltip
                  label={`This column cannot be edited`}
                  sx={{
                    position: 'absolute',
                    top: -4,
                    left: 4
                  }}
                >
                  <Icon
                    icon={faLockKeyhole}
                    size={'xs'}
                    opacity={0.5}
                  />
                </Tooltip>
              )}

              <div>{column.canFilter ? column.render('Filter') : null}</div>

              <Box
                {...column.getResizerProps()}
                sx={(theme) => ({
                  display: 'inline-block',
                  width: '6px',
                  height: '100%',
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  zIndex: 10,
                  transform: 'translateX(50%)'
                })}
              >
                <Box
                  sx={(theme) => ({
                    height: '100%',
                    margin: '0 auto',
                    width: '1px',
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
                  })}
                />
              </Box>
            </Box>
          ))}
        </Box>
      ))}
      </Box>
      <ScrollArea
        key={totalColumnsWidth}
        viewportRef={parentRef}
        {...getTableBodyProps()}
        pl={0.5}
        sx={{
          position: 'relative',
          height: `calc(100% - ${headerRef?.current?.getBoundingClientRect().height}px)`,
          width: '100%',
          marginTop: `${headerRef?.current?.getBoundingClientRect().height}px`,
        }}
        onScrollPositionChange={({x, y}) => {
          if (headerRef.current) {
            headerRef.current.style.left = `-${x}px`
          }
        }}
      >
        <Box
          sx={{
            position: 'relative',
            height: `${rowVirtualizer.totalSize + SCROLLBAR_WIDTH}px`,
            width: `${columnVirtualizer.totalSize}px`,
          }}
        >
          {rowVirtualizer.virtualItems.map(item => {
            const row = rows[item.index]
            prepareRow(row)

            return (
                <Box
                  {...row.getRowProps()}
                  sx={(theme) => ({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: `${item.size}px`,
                    transform: `translateY(${item.start}px)`,
                    '& .table-cell': {
                      position: 'absolute',
                      borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4]}`,
                      borderRight: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4]}`,
                      userSelect: 'none',
                      lineHeight: '32px',
                      verticalAlign: 'middle',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      paddingLeft: '4px',
                      height: '100%',
                      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    }
                })}
              >
                {columnVirtualizer.virtualItems.map(citem => {
                  const cell = row.cells[citem.index]

                  const isSelected = selectedCell?.row === cell.row.original.id && selectedCell?.column === cell.column.id
                  const isEditing = editingCell?.row === cell.row.original.id && editingCell?.column === cell.column.id

                  return (
                    <Box
                      className={'table-cell'}
                      {...cell.getCellProps()}
                      sx={{
                        overflow: isEditing ? 'visible !important' : undefined,
                        paddingLeft: (isSelected || isEditing) ? '0px !important' : undefined,
                        width: `${citem.size}px`,
                        transform: `translateX(${citem.start}px)`,
                        zIndex: isEditing ? '10 !important' : undefined,
                      }}
                      onClick={e => {
                        if (cell.column.id === 'actions') return
                        if (cell.row.original.id === editingCell?.row && cell.column.id === editingCell?.column) {
                          return
                        }

                        if (e.detail === 1) {
                          setSelectedCell({
                            row: cell.row.original.id,
                            column: cell.column.id
                          })
                          setEditingCell(null)
                        } else if (e.detail === 2) {
                          if (cell.column.isEditable) {
                            setEditingCell({
                              row: cell.row.original.id,
                              column: cell.column.id
                            })
                          }
                        }
                      }}
                    >
                      {cell.render('Cell', {
                        type: cell.column.type,
                        isSelected,
                        isEditing
                      })}
                    </Box>
                  )
                })}
              </Box>
            )
          })}
        </Box>
      </ScrollArea>
    </Box>
    </TableContext.Provider>
  )
}
