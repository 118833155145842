import * as React from "react"
import {useEffect} from "react"
import {ChakraProvider, extendTheme, useColorModePreference} from "@chakra-ui/react"
import {ReactKeycloakProvider, useKeycloak} from "@react-keycloak/web";
import {RequireAuth} from "./keycloak/utils";
import LoginPage from "./keycloak/Login";
import keycloak from "./keycloak/keycloak";
import {FullScreenLoader} from "./keycloak/FullScreenLoader";
import './App.css'
import {ApolloProvider} from "./ApolloProvider";
import {SmartFormStyling} from "./components/smart-forms";
import "focus-visible/dist/focus-visible"
import {css, Global} from '@emotion/react'
import {OrganizationRoot} from "./pages/organization";
import {UserHomePageRouter} from "./pages/user";
import {SeasonTeamHomePageRouter} from "./pages/user/seasonteamportal";
import '@stripe/stripe-js';
import {ModalsProvider} from '@mantine/modals';
import {PublicRegistrationForm} from "./forms/public-registration-form";
import {NotificationsProvider} from "@mantine/notifications";
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  useMantineColorScheme,
  Title,
  Center,
  Group
} from '@mantine/core';
import {PublicPagesRouter} from "./pages/public";
import {BrowserRouter, Navigate, Outlet, Route, Routes} from "react-router-dom";
import {LeagueBrainOrganizationRoot} from "./pages/leaguebrain/organization/routes";
import {SpotlightProvider} from "@mantine/spotlight";
import {LeagueBrainOrganizationSeasonRoot} from "./pages/leaguebrain/organization/season";
import {GreycliffCF} from "./fonts/GreycliffCF";
import {useLocalStorage} from "@mantine/hooks";

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
     outline: none;
     box-shadow: none;
   }
`;

const theme = extendTheme({
  config: {},
  fonts: {
    heading: "Greycliff CF, sans-serif",
    body: "Greycliff CF, sans-serif"
  },
  colors: {
    primary: {
      /*
      50: '#F5F8FF',
      100: '#EBF0FF',
      200: '#CCD9FF',
      300: '#ACC1FF',
      400: '#7194FF',
      500: '#3366FF',
      600: '#2E5BE3',
      700: '#1F3E99',
      800: '#172E73',
      900: '#0F1E4A',*/
      "50": "#EAF3FA",
      "100": "#C5DCF1",
      "200": "#A0C6E8",
      "300": "#7BAFE0",
      "400": "#5698D7",
      "500": "#3182CE",
      "600": "#2768A5",
      "700": "#1D4E7C",
      "800": "#143452",
      "900": "#0A1A29"
    },
    secondary: {
      50: '#FCF5FD',
      100: '#F8EBFB',
      200: '#EECBF4',
      300: '#E3AAED',
      400: '#CE6DDF',
      500: '#B92ED1',
      600: '#A529BB',
      700: '#6F1C7E',
      800: '#54155F',
      900: '#360E3D'
    },
    success: {
      50: '#F7FCFA',
      100: '#EEF9F5',
      200: '#D4EFE5',
      300: '#B9E4D4',
      400: '#86D1B5',
      500: '#52BD95',
      600: '#49A985',
      700: '#32725A',
      800: '#255644',
      900: '#18372C'
    },
    warning: {
      "50": "#FEF5E7",
      "100": "#FCE3BB",
      "200": "#F9D08F",
      "300": "#F7BE64",
      "400": "#F5AC38",
      "500": "#F39A0C",
      "600": "#C27B0A",
      "700": "#925C07",
      "800": "#613D05",
      "900": "#311F02"
    },
    danger: {
      50: '#FDF5F3',
      100: '#FBEBE6',
      200: '#F4CBC0',
      300: '#EDAA98',
      400: '#E16E4E',
      500: '#D32F02',
      600: '#BC2A02',
      700: '#7F1D02',
      800: '#5F1601',
      900: '#3E0E01'
    }
  },
  styles: {
    global: {
      'html, body': {
        padding: 0
      }
    }
  },
  components: {
    Heading: {
      defaultProps: {
        fontWeight: 'normal'
      }
    },
    Button: {
      baseStyle: {
        borderRadius: '4px',
        fontWeight: '500'
      },
      defaultProps: {
        colorScheme: 'gray',
        size: 'sm'
      }
    },
    Input: {
      defaultProps: {
        focusBorderColor: 'primary.400',
        errorBorderColor: 'danger.400'
      }
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: 'primary.400',
        errorBorderColor: 'danger.400'
      }
    },
    NumberInput: {
      defaultProps: {
        focusBorderColor: 'primary.400',
        errorBorderColor: 'danger.400'
      }
    },
    Radio: {
      defaultProps: {
        colorScheme: 'primary'
      }
    },
    Checkbox: {
      defaultProps: {
        colorScheme: 'primary'
      }
    },
    Slider: {
      defaultProps: {
        colorScheme: 'primary'
      }
    },
    Tabs: {
      defaultProps: {
        colorScheme: 'primary'
      }
    },
    Switch: {
      defaultProps: {
        colorScheme: 'primary'
      }
    },
    Alert: {
      defaultProps: {
        colorScheme: 'primary'
      }
    },
    SmartForm: SmartFormStyling
  }
})

const tokenLogger = (tokens: unknown) => {
  // console.log('onKeycloakTokens', tokens)
}



export const App = () => {

  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: useColorModePreference() ?? 'light',
    getInitialValueInEffect: true,
  });

  React.useEffect(() => {
    setColorScheme('light')
  }, [])

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  useEffect(() => {
    document.body.classList.add('is-scrollLocked')
    document.body.classList.add('is-momentumScrollable')

    return () => {
      document.body.classList.remove('is-scrollLocked')
      document.body.classList.remove('is-momentumScrollable')
    }
  }, [])

  return (
    <ChakraProvider theme={theme}>
      <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
        <MantineProvider withGlobalStyles withNormalizeCSS theme={{ colorScheme: colorScheme }}>
          <SpotlightProvider
            actions={[]}
            highlightQuery
            searchPlaceholder="Search..."
            shortcut="mod + k"
            nothingFoundMessage="Nothing found..."
          >
            <ModalsProvider>
              <NotificationsProvider>
                <Global styles={GlobalStyles} />
                <ReactKeycloakProvider
                  authClient={keycloak}
                  initOptions={{
                    checkLoginIframe: false,
                    // onLoad: 'check-sso'
                    pkceMethod: 'S256'
                  }}
                  onTokens={tokenLogger}
                >
                  <AppRouter />
                </ReactKeycloakProvider>
              </NotificationsProvider>
            </ModalsProvider>
          </SpotlightProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </ChakraProvider>
  )
}

function AppRouter() {
  const keycloak = useKeycloak()

  const { colorScheme } = useMantineColorScheme();

  if (!keycloak.initialized) {
    return <FullScreenLoader />
  }

  return (
    <BrowserRouter>
      <Routes>
        {/*
        <Redirect from="/:url*(/+)" to={window.location.pathname.replace(/\/+$/, window.location.search)} />
        */}

        <Route
          path={'/lb/o/:organizationId/*'}
          element={
            <MantineProvider
              withGlobalStyles
              withNormalizeCSS
              theme={{
                colorScheme: colorScheme,
                headings: {
                  fontFamily: 'Greycliff CF, sans-serif'
                },
                loader: 'bars'
              }}
            >
              <GreycliffCF />
              <RequireAuth>
                <Outlet />
              </RequireAuth>
            </MantineProvider>
          }
        >
          <Route
            path={'*'}
            element={<LeagueBrainOrganizationRoot />}
          />

          <Route
            path={'s/:seasonId/*'}
            element={<LeagueBrainOrganizationSeasonRoot />}
          />
        </Route>

        <Route
          path={'/o/:organizationId/*'}
          element={
            <RequireAuth>
              <OrganizationRoot />
            </RequireAuth>
          }
        />

        <Route
          path={"/public/*"}
          element={<PublicPagesRouter />}
        />

        <Route
          path={'/u/*'}
          element={
            <ApolloProvider>
              <RequireAuth>
                <UserHomePageRouter />
              </RequireAuth>
            </ApolloProvider>
          }
        />

        <Route
          path={'/st/:seasonTeamId/*'}
          element={
            <ApolloProvider>
              <RequireAuth>
                <SeasonTeamHomePageRouter />
              </RequireAuth>
            </ApolloProvider>
          }
        />

        <Route
          path={'login'}
          element={<LoginPage />}
        />

        <Route
          path={'org/:organizationId/season/:seasonId/*'}
          element={
            <ApolloProvider>
              <PublicRegistrationForm />
            </ApolloProvider>
          }
        />

        <Route path={'*'} element={<Navigate to={'/u'} replace={true} />} />

      </Routes>
    </BrowserRouter>
  )
}
