import {useNavigate, useParams, useMatch} from "react-router-dom";
import React from "react";
import {
  StaffMembersByTeamDocument,
  TeamPendingStaffMemberListFragment,
  TeamStaffMemberListFragment,
  useDeletePendingTeamStaffMemberInviteMutation,
  useGetUserInfoQuery,
  useRemoveTeamStaffMemberMutation,
  useStaffMembersByTeamQuery,
  useTeamInviteStaffMemberMutation
} from "../../graphql";
import {
  Avatar,
  Box,
  Button,
  chakra,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {Icon} from "../../../../components/FontAwesomeIcon";
import {faPaperPlane, faPlus, faTrashAlt} from "@fortawesome/pro-solid-svg-icons";
import {faUserCog as faUserCogRegular} from "@fortawesome/pro-regular-svg-icons";
import {SmartForm, SmartFormSubmitButton} from "../../../../components/smart-forms";
import {SmartFormTextInput} from "../../../../components/smart-forms/inputs/TextInput";
import {SegmentedControl, SegmentedControlOption} from "../../../../components/segmented-control/updated";
import {useActionSuccessToast, useUserErrorToast} from "../../../../components/user-error-toast";
import {ActionItem, CollapsableActionItemMenu} from "../../../../components/collapsable-action-item-menu";
import {CallToAction, SimpleList, WhenEmpty} from "../../../../components/entity-list-page/new";

interface InviteStaffMemberFormData {
  email: string
  name?: string
}

export function TeamStaffMembersPanel() {

  const { teamId } = useParams()
  const navigate = useNavigate()
  const userErrorToast = useUserErrorToast()
  const toast = useToast()

  const [radioSelection, setRadioSelection] = React.useState('1');

  const { data, refetch } = useStaffMembersByTeamQuery({
    variables: {
      teamId: teamId!!
    }
  })

  const [inviteStaffMemberToTeamMutation] = useTeamInviteStaffMemberMutation()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const drawerProps = useBreakpointValue<Pick<DrawerProps, 'size' | 'placement'>>({
    base: {
      size: 'full',
      placement: 'bottom'
    },
    md: {
      size: 'sm',
      placement: 'right'
    }
  })

  if (!data) {
    return <div />
  }

  return (
    <Box h={'full'} p={2} overflowY={'auto'}>

      {/*
      <Button
        color={'blue.600'}
        fontSize="sm"
        fontWeight="semibold"
        variant={'ghost'}
        mb="4"
        rounded="lg"
        px="2"
        py="1"
        onClick={() => history.goBack()}
      >
        <Box as={BsCaretLeftFill} fontSize="xs" marginEnd="1" />
        Back to {data.team.name}
      </Button>
      */}


      <Box p={2}>
        <Flex justify={'space-between'}>
          <Heading fontSize={'2xl'}>Staff Members</Heading>
          {data?.team?.viewerRole === 'OWNER' &&
            <Button leftIcon={<Icon icon={faPlus}/>} colorScheme={'blue'} onClick={onOpen}>Invite Member</Button>
          }
        </Flex>

        <Drawer
          size={drawerProps?.size}
          isOpen={isOpen}
          placement={drawerProps?.placement}
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Invite Staff Member</DrawerHeader>

            <SmartForm<InviteStaffMemberFormData>
              onSubmit={(data: InviteStaffMemberFormData) => {
                inviteStaffMemberToTeamMutation({
                  variables: {
                    teamId: teamId!!,
                    email: data.email
                  }
                }).then((response) => {
                  if (response.data?.team?.inviteStaffMember.userError) {
                    userErrorToast(response.data?.team.inviteStaffMember.userError)
                  } else {
                    toast({
                      status: 'success',
                      position: 'top',
                      title: "Invite sent!",
                      description: `We've sent an invite to ${response.data?.team?.inviteStaffMember.invite?.userEmail}`
                    })
                  }
                }).finally(refetch)

                onClose()
              }}
            >
              <DrawerBody>
                <SmartFormTextInput type={'email'} name={'email'} label={'Email Address'} />
                {/*
                <Box h={6} />
                <SmartFormTextInput name={'name'} label={'Name'} />
                */}
              </DrawerBody>

              <DrawerFooter>
                <Button variant="outline" mr={3} onClick={onClose}>Cancel</Button>
                <SmartFormSubmitButton>Send Invite</SmartFormSubmitButton>
              </DrawerFooter>
            </SmartForm>
          </DrawerContent>
        </Drawer>

        <Flex w={'full'} justify={'space-between'} align={'center'} mt={2} pb={2} borderBottom={'1px solid #efefef'}>
          <SegmentedControl defaultValue={radioSelection} onChange={setRadioSelection}>
            <SegmentedControlOption value={'1'} color={'green.500'}>Accepted</SegmentedControlOption>
            <SegmentedControlOption value={'2'} color={'orange.500'}>Pending</SegmentedControlOption>
          </SegmentedControl>
        </Flex>
      </Box>

      {data.team?.viewerRole &&
        <>
          {radioSelection === '1' &&
            <StaffMembersList data={data.team!!} />
          }

          {radioSelection === '2' &&
            <PendingStaffMembersList data={data.team!!} />
          }
        </>
      }
    </Box>
  )
}

interface StaffMembersListProps {
  data: TeamStaffMemberListFragment
}

function StaffMembersList(props: StaffMembersListProps) {

  const {
    data
  } = props

  const {
    owner,
    staffMembers
  } = data

  const userErrorToast = useUserErrorToast()
  const successToast = useActionSuccessToast()

  const { data: userData } = useGetUserInfoQuery()

  const [removeStaffMemberMutation] = useRemoveTeamStaffMemberMutation()

  return (
    <SimpleList>
      <Flex key={owner.id} w={'full'} align={'center'}>
        <Avatar src={owner.avatar} />

        <Flex direction={'column'} ml={4}>
          <Heading fontSize={'lg'} fontWeight={'semibold'}>
            {owner.displayName}
          </Heading>

          <Text color={'gray.400'} fontWeight={'semibold'}>Team Owner</Text>
        </Flex>
      </Flex>

      {staffMembers.map((staffMember) => (
        <Flex
          key={staffMember.userProfile.id}
          w={'full'}
        >
          <Flex w={'full'} align={'center'}>
            <Avatar size={'md'} src={staffMember.userProfile.avatar.url} />

            <Flex direction={'column'} ml={4}>
              <Heading fontSize={'lg'} fontWeight={'semibold'}>
                {staffMember.userProfile.displayName}
              </Heading>
              <Text color={'gray.400'} fontWeight={'semibold'}>{staffMember.role}</Text>
            </Flex>
          </Flex>

          <CollapsableActionItemMenu>
            {owner.id === userData?.viewer?.id &&
            <ActionItem
              label={'Remove User'}
              colorScheme={'red'}
              icon={faTrashAlt}
              confirmActionButtonProps={{
                title: 'Remove User',
                description: "Are you sure you want to remove this user from your team? This action cannot be undone.",
                colorScheme: 'red',
                confirmLabel: 'Remove User'
              }}
              onClick={() => {
                removeStaffMemberMutation({
                  variables: {
                    teamId: data.id,
                    userId: staffMember.userProfile.id
                  },
                  refetchQueries: [{
                    query: StaffMembersByTeamDocument,
                    variables: {
                      teamId: data.id
                    }
                  }]
                }).then(response => {
                  if (response.data?.team?.removeStaffMember.success) {
                    successToast({
                      title: 'User Removed',
                      description: 'The user has been removed from your team'
                    })
                  } else {
                    userErrorToast(response.data?.team?.removeStaffMember.userError)
                  }
                })
              }}
            />
            }
          </CollapsableActionItemMenu>
        </Flex>
      ))}
    </SimpleList>
  )
}

interface PendingStaffMembersListProps {
  data: TeamPendingStaffMemberListFragment
}

function PendingStaffMembersList(props: PendingStaffMembersListProps) {

  const {
    data
  } = props

  const {
    id: teamId,
    pendingStaffMembers
  } = data

  const toast = useToast()

  const [inviteStaffMemberToTeamMutation] = useTeamInviteStaffMemberMutation()
  const [deleteStaffMemberInviteMutation] = useDeletePendingTeamStaffMemberInviteMutation()

  return (
    <SimpleList>
      <WhenEmpty>
        <CallToAction
          icon={faUserCogRegular}
          title={'Invite Your First Staff Member'}
          description={'Invite a new staff member to get started'}
        />
      </WhenEmpty>

      {pendingStaffMembers.map((pendingStaffMember) => (
        <Flex
          key={pendingStaffMember.userEmail}
          w={'full'}
          align={'center'}
          justify={'space-between'}
        >
          <Text fontSize={'lg'} fontWeight={'semibold'}>{pendingStaffMember.userEmail}</Text>

          {data.viewerRole === 'OWNER' &&
            <CollapsableActionItemMenu>
              <ActionItem
                label={'Resend Invite'}
                colorScheme={'gray'}
                icon={faPaperPlane}
                confirmActionButtonProps={{
                  colorScheme: 'green',
                  title: 'Resend Invite',
                  confirmLabel: 'Resend Invite',
                  description: (
                    <Text>
                      Are you sure you want to resend an invite to <chakra.span fontWeight={'semibold'}>{pendingStaffMember.userEmail}</chakra.span>?
                    </Text>
                  )
                }}
                onClick={() => {
                  inviteStaffMemberToTeamMutation({
                    variables: {
                      teamId: teamId,
                      email: pendingStaffMember.userEmail
                    }
                  }).then(res => {
                    toast({
                      status: 'success',
                      position: 'top',
                      title: "Invite sent!",
                      description: `We've resent your invite to ${pendingStaffMember.userEmail}`
                    })
                  })
                }}
              />

              <ActionItem
                label={'Remove User'}
                colorScheme={'red'}
                icon={faTrashAlt}
                confirmActionButtonProps={{
                  colorScheme: 'red',
                  title: 'Delete Invite',
                  confirmLabel: 'Delete Invite',
                  description: 'Are you sure you want to delete this invite? This action cannot be undone.'
                }}
                onClick={() => {
                  deleteStaffMemberInviteMutation({
                    variables: {
                      teamId: teamId,
                      invitationId: pendingStaffMember.id,
                    },
                    refetchQueries: [{
                      query: StaffMembersByTeamDocument,
                      variables: {
                        teamId: teamId
                      }
                    }]
                  })
                }}
              />
            </CollapsableActionItemMenu>
          }
        </Flex>
      ))}
    </SimpleList>
  )
}
