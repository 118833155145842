import React from "react";
import {Box} from "@mantine/core";
import {EditableSelectCell} from "./EditableSelectCell";
import {EditableDatePickerCell} from "./EditableDatePickerCell";
import {EditableNumberCell} from "./EditableNumberCell";
import {EditableTextCell} from "./EditableTextCell";
import {CellRenderProps} from "./CellRenderProps";

export const DefaultTextCell = (props: CellRenderProps<{ id: string, updateMyData: any }>) => {

  const {
    value: initialValue = '',
    row: {index, original: {id: rowId}},
    column: { id },
    state,
    type,
    isSelected,
    isEditing,
  } = props

  if (!isSelected) {
    return initialValue
  }

  if (!isEditing) {
    return (
      <Box
        sx={(theme) => ({
          position: 'relative',
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          border: `2px solid ${theme.colors.blue[5]}`,
          flexDirection: 'column',
          justifyContent: 'center',
          paddingLeft: '2px'
        })}
      >
        {initialValue}
      </Box>
    )
  }

  let result = null

  switch (type.type) {
    case 'text':
      result = <EditableTextCell {...{...props, isSelected}} />
      break;
    case 'number':
      result = <EditableNumberCell {...{...props, isSelected}} />
      break;
    case 'select':
      result = <EditableSelectCell {...{...props, isSelected, options: type.options}} />
      break;
    case 'date':
      result = <EditableDatePickerCell {...{...props, isSelected}} />
      break;
    default:
      break;
  }

  const requiredMinWidth = (initialValue?.length ?? 0) * 12

  let computedWidth: number = 0

  const startCol = props.columns.findIndex(c => c.id === props.column.id)

  for (let i = startCol; i < props.columns.length; i++) {
    const column = props.columns[i]

    const colWidth: number = parseInt(state.columnResizing.columnWidths[column.id] ?? column.totalWidth)

    computedWidth += colWidth

    if (computedWidth >= requiredMinWidth) {
      break;
    }
  }

  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        height: '100%',
        border: `2px solid ${theme.colors.blue[5]}`,
        boxShadow: '1px 10px 19px -10px rgba(0,0,0,0.5)',
        width: `${computedWidth}px`,
        zIndex: 100,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white
      })}
    >
      {result}
    </Box>
  )
}
