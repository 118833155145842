import {Flex, Heading, HTMLChakraProps, useToken} from '@chakra-ui/react'
import * as React from 'react'
import {Icon} from "../components/FontAwesomeIcon";
import {faBrain} from "@fortawesome/pro-duotone-svg-icons";

interface LogoProps extends HTMLChakraProps<'svg'> {
  iconColor?: string
  isTextVisible?: boolean
}

export const Logo = (props: LogoProps) => {
  const { iconColor = 'currentColor', isTextVisible = true, ...rest } = props
  const color = useToken('colors', iconColor)
  return (
    <Flex justifyContent={'flex-start'} alignItems={'center'}>
      {/*<Icon icon={faBrain} size={'lg'} mr={2} />*/}

      <Heading size={'md'} m={0} p={0} display={isTextVisible ? 'block' : 'none'}>
        LeagueBrain
      </Heading>
    </Flex>
  )
}
