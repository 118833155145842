import React from 'react';
import {FormDefinition} from "./formDefinition";
import {FormProvider, useForm} from "react-hook-form";
import {FormSection} from "./FormSection";
import {Box, Button, Group, Modal, Stack, Text, Title} from '@mantine/core';
import {useRegistrationFormForSeasonQuery, useSubmitRegistrationFormResponseMutation} from "../../pages/user/graphql";
import {CommonRegistrationQuestions} from "../index";
import {Link as ReactRouterLink, useParams} from "react-router-dom";
import {useTimeout, useWindowSize} from "react-use";
import Confetti from "react-confetti";
import {usePrefersReducedMotion} from "@chakra-ui/react";

export interface RegistrationFormProps {

}

export function RegistrationForm(props: RegistrationFormProps) {

  const {
    organizationId,
    seasonId
  } = useParams()

  const { loading, data, refetch } = useRegistrationFormForSeasonQuery({
    variables: {
      organizationId,
      seasonId
    }
  })

  const prefersReducedMotion = usePrefersReducedMotion()

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [registeredSeasonTeamId, setRegisteredSeasonTeamId] = React.useState<string | null>(null)

  const [submitRegistrationFormMutation] = useSubmitRegistrationFormResponseMutation()

  const methods = useForm();

  if (!data?.organization?.season?.registrationForm) {
    return <>loading...</>
  }

  const formDefinition = JSON.parse(data.organization.season.registrationForm?.formDefinition) as FormDefinition
  const divisions = data.organization.season.divisions
  const teams = data.organization.season.registrationForm.availableTeams.edges

  function onSubmit(data: any) {
    console.log(data)

    submitRegistrationFormMutation({
      variables: {
        data: {
          seasonId: seasonId!!,
          divisionId: data.common.division.id,
          registeringTeamId: data.common.team.value,
          teamName: data.common.team.label,
          responsesAsJson: JSON.stringify(data.questions)
        }
      }
    }).then(res => {
      setIsSubmitted(true)
      setRegisteredSeasonTeamId(res.data?.submitRegistrationResponseNEW?.team?.id || null)
    })
  }

  return (
    <Stack
      align={'center'}
      sx={{
        width: '100%',
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Box
        component={'form'}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack
          sx={(theme) => ({
            width: '100%',
            minWidth: '400px',
            maxWidth: '800px',
            borderRadius: `${theme.radius.sm}px`,
            boxShadow: theme.shadows.sm
          })}
          align={'stretch'}
          px={'md'}
          py={'md'}
          mb={8}
        >
          {isSubmitted && !prefersReducedMotion && <ConfettiCannon />}
          <Title>{data.organization.season.name} Registration</Title>

          <FormProvider {...methods}>
            <CommonRegistrationQuestions orgId={organizationId} seasonId={seasonId} divisions={divisions} teams={teams} />

            <Box sx={{ height: '4px' }} />

            {formDefinition.sections.map((section) => (
              <FormSection key={section.key} definition={section} />
            ))}
          </FormProvider>

          <Box sx={{ height: '16px' }} />

          <Button type={'submit'}>Submit</Button>
        </Stack>
      </Box>

      {registeredSeasonTeamId &&
        <RegistrationConfirmationPopup teamId={registeredSeasonTeamId} />
      }
    </Stack>
  )
}

type RegistrationConfirmationPopupProps = {
  teamId: string
}

function RegistrationConfirmationPopup(props: RegistrationConfirmationPopupProps) {

  const {
    teamId
  } = props

  return (
    <Modal
      opened={true}
      onClose={() => {}}
      title={<Title order={4}>Registration Submitted</Title>}
      withCloseButton={false}
    >
      <Text>You have successfully registered.</Text>

      <Group mt={'xl'} position={'right'}>
        <Button
          variant={'subtle'}
          onClick={() => window.location.reload()}
          mr={2}
        >
          Register another team
        </Button>

        <Button
          component={ReactRouterLink}
          to={`/st/${teamId}`}
          color={'green'}
        >
          Go to team portal
        </Button>
      </Group>
    </Modal>
  )
}

function ConfettiCannon() {
  const { width, height } = useWindowSize()
  const [isComplete] = useTimeout(4000);

  return (
    <Confetti
      width={width}
      height={height}
      recycle={!isComplete()}
    />
  )
}
