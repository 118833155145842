import React from 'react';
import {Box} from "@chakra-ui/react";
import {useParams} from "react-router-dom";

export function PublicUserProfile() {

  const { userId } = useParams()

  return (
    <Box>
      {userId}
    </Box>
  )
}
