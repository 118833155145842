import { Box, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import { NavContent } from './NavContent'
import { Link } from './_data'

export type NavBarProps = {
  links: Link[]
}

export const NavBar = (props: NavBarProps) => {
  return (
    <Box>
      <Box as="header" bg={mode('white', 'gray.800')} position="relative">
        <Box as="nav" aria-label="Main navigation" mx="auto" px={{ base: '0', md: '0' }}>
          {/*
          <NavContent.Mobile display={{ base: 'flex', lg: 'none' }} />
          <NavContent.Desktop display={{ base: 'none', lg: 'flex' }} />
          */}
          <NavContent.Desktop links={props.links} />
        </Box>
      </Box>
    </Box>
  )
}
