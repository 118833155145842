import React from 'react';
import {Button, Group, Popover, Select, UnstyledButton} from '@mantine/core';
import {faAnglesRight} from '@fortawesome/pro-duotone-svg-icons';
import {Icon} from '../../../../../components/FontAwesomeIcon';
import {useAllSeasonsQuery} from "../../graphql";
import {generatePath, useNavigate} from "react-router-dom";
import {useOrganization} from "../../routes";

export interface SeasonSelectorProps {
  target: React.ReactNode
}

export function SeasonSelector(props: SeasonSelectorProps) {

  const organizationId = useOrganization().organizationId

  const [opened, setOpened] = React.useState(false);

  const navigate = useNavigate()
  const { data } = useAllSeasonsQuery()

  const [selectedSeason, setSelectedSeason] = React.useState<string | null>(null);

  const seasonOptions = (data?.organization?.seasons ?? []).map(season => ({
    value: season.id,
    label: season.name
  }))

  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      width={320}
      position="bottom"
      placement="end"
      gutter={0}
      target={
        <UnstyledButton
          onClick={() => setOpened(o => !o)}
          mr={0}
          px={'md'}
          py={4}
          sx={theme => ({
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
            },
          })}
        >
          {props.target}
        </UnstyledButton>
      }
    >
      <Group direction={'column'} align={'stretch'}>
        <Select
          label="Select a Season"
          placeholder="Choose a Season"
          initiallyOpened={false}
          withinPortal={false}
          searchable
          clearable
          data={seasonOptions}
          value={selectedSeason}
          onChange={setSelectedSeason}
        />

        <Group>
          <Button
            sx={{ flexGrow: 1 }}
            disabled={!selectedSeason}
            rightIcon={<Icon icon={faAnglesRight} />}
            onClick={() => {
              if (selectedSeason) {
                const dest = generatePath('/lb/o/:organizationId/s/:seasonId', {
                  organizationId,
                  seasonId: selectedSeason
                })
                navigate(dest)
                setOpened(false)
              }
            }}
          >
            GO
          </Button>
        </Group>
      </Group>
    </Popover>
  );
}
