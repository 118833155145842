import React from 'react';
import {generatePath, Link as ReactRouterLink, useNavigate, useParams, useMatch} from "react-router-dom";
import {
  AlertDescription,
  AlertTitle, Avatar,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  IconButton,
  Img,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text
} from "@chakra-ui/react";
import {useTeamByIdQuery} from "../../graphql";
import {faBaseball, faEllipsisH} from "@fortawesome/pro-regular-svg-icons";
import {Icon} from "../../../../components/FontAwesomeIcon";
import {EntityListPage, EntityListRow} from "../../../../components/entity-list-page";
import {faArrowRight, faPaperPlane, faPencil} from "@fortawesome/pro-solid-svg-icons";
import {motion} from 'framer-motion';
import {TeamStaffMembersPanel} from "./staff-members";

interface UseRoutedTabsProps {
  routeConfig: {
    [action: string]: number
  }
}

interface UseRoutedTabsReturn {
  tabProps: any
}

function useRoutedTabs(props: UseRoutedTabsProps): UseRoutedTabsReturn {
  const { pathname: url, pathnameBase: path } = useMatch();
  const history = useNavigate()
  const params = useParams()

  const { routeConfig } = props

  const [selectedTab, setSelectedTab] = React.useState<number>(routeConfig[params.panel]);

  React.useEffect(() => {
    const routeConfigKeys = Object.keys(routeConfig)
    let panel = routeConfigKeys[0]

    for (let i = 0; i < routeConfigKeys.length; i++) {
      if (routeConfig.hasOwnProperty(routeConfigKeys[i])) {
        if (routeConfig[routeConfigKeys[i]] === selectedTab) {
          panel = routeConfigKeys[i]
        }
      }
    }

    const newPath = generatePath(path, { ...params, panel: panel })

    if (url === newPath) return

    history.replace(newPath)
  }, [selectedTab])

  React.useEffect(() => {
    if (selectedTab !== routeConfig[params.panel]) {
      setSelectedTab(routeConfig[params.panel])
    }
  }, [url, JSON.stringify(params)])

  return {
    tabProps: {
      index: selectedTab,
      onChange(index: number) { setSelectedTab(index) }
    }
  }
}

interface ActionCardProps {
  color?: string
  title: string
  to?: string
}

const MotionBox = motion(Box)

function ActionCard(props: React.PropsWithChildren<ActionCardProps>) {

  const {
    to = '#',
    color = 'blue'
  } = props

  return (
    <LinkBox
      as={MotionBox}
      p={4}
      h="120px"
      bg={`${color}.400`}
      color={"white"}
      borderRadius={'lg'}
      boxShadow={'md'}
      cursor={'pointer'}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <Flex align={'center'} justify={'space-between'}>
        <LinkOverlay as={ReactRouterLink} to={to}>
          <Heading fontSize={'xl'}>{props.title}</Heading>
        </LinkOverlay>
        <Icon icon={faArrowRight} />
      </Flex>

      {props.children}
    </LinkBox>
  )
}

export function ViewTeamPage() {

  const { teamId } = useParams()

  const { data } = useTeamByIdQuery({
    variables: {
      id: teamId!!
    }
  })

  if (data && !data.team) {
    return (
      <Center py={8}>
        <Heading>404</Heading>
      </Center>
    )
  }

  return (
    <Box flexGrow={1} w="full" overflow={'auto'}>
      <Box px="6" py="4" borderBottom={'2px solid #efefef'}>
        <Flex align={'center'} justify={'space-between'}>
          <HStack spacing={'4'}>
            <Avatar icon={<Icon icon={faBaseball}/>} src={data?.team?.avatar || undefined} size={'md'} bg={'gray.100'}/>

            <Box w={{ base: 'full', md: 'auto' }}>
              <Heading size="md" mb="2" mt={{  lg: '3' }}>
                {data?.team?.name}
              </Heading>
              <Text>{data?.team?.description}</Text>
            </Box>
          </HStack>

          <Flex alignItems={'center'}>
            {data?.team?.viewerRole === 'OWNER' &&
              <Button
                as={ReactRouterLink}
                to={generatePath('/u/teams/:teamId/edit', {teamId})}
                leftIcon={<Icon icon={faPencil}/>}
                colorScheme={'blue'}
              >
                Edit Team
              </Button>
            }
          </Flex>
        </Flex>
      </Box>

      <TeamStaffMembersPanel />

      {/*
      <SimpleGrid p={4} columns={1} spacing={'15px'}>

        <ActionCard
          color={'blue'}
          to={generatePath('/u/teams/:teamId/roster', { teamId })}
          title={'Roster'}
        >
          Manage the players on your team
        </ActionCard>

        <ActionCard
          color={'green'}
          to={generatePath('/u/teams/:teamId/staff', { teamId })}
          title={'Staff Members'}
        >
          Manage the staff members on your team
        </ActionCard>
      </SimpleGrid>
      */}

      {/*
      <Tabs mt={4} {...tabProps}>
        <TabList>
          <Tab>Home</Tab>
          <Tab>Roster</Tab>
          <Tab>Staff</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            Home
          </TabPanel>

          <TabPanel p={0}>
            // <TeamRosterPanel />
          </TabPanel>

          <TabPanel p={0}>
            <TeamStaffMembersPanel />
          </TabPanel>
        </TabPanels>
      </Tabs>
      */}
    </Box>
  )
}
