import {Box, Divider, Flex, FlexProps, Spacer, Stack, useColorMode, useColorModeValue as mode} from '@chakra-ui/react'
import * as React from 'react'
import {useEffect} from 'react'
import {Logo} from './Logo'
import {NavLink} from './NavLink'
import {UserProfile} from './UserProfile'
import {useKeycloak} from "@react-keycloak/web";
import {KeycloakProfile} from "keycloak-js";
import {
  faBell,
  faCogs,
  faHome,
  faMoonStars,
  faNetworkWired,
  faSignOut,
  faSun,
  faUsers,
  faUsersClass,
  faPortalEnter
} from "@fortawesome/pro-duotone-svg-icons";
import {useLocation, useMatch} from "react-router-dom";
import {useGetUserInfoQuery} from "../pages/user/graphql";

export const Sidebar = (props: FlexProps) => {

  const { keycloak } = useKeycloak()

  let { pathname: url } = useLocation()!!;

  const { data } = useGetUserInfoQuery()

  const [profileInfo, setProfileInfo] = React.useState<KeycloakProfile | null>(null);

  const { toggleColorMode } = useColorMode()
  const switchIcon = mode(faMoonStars, faSun)

  useEffect(() => {
    keycloak.loadUserProfile().then(profile => setProfileInfo(profile))
  }, [keycloak])

  return (
    <Flex
      bg={mode({ base: 'white', md: '#495057' }, 'gray.800')}
      direction="column"
      borderRightWidth="1px"
      width="64"
      color={mode({ base: 'base', md: 'white' }, 'gray.50')}
      {...props}
    >
      <Flex direction="column" flex="1" pt="5" pb="4" overflowY="auto" px="4">
        <Box mb="6">
          <Logo color={mode('blue.600', 'blue.400')} h="6" />
        </Box>

        <Stack spacing="4" as="nav" aria-label="Sidebar Navigation">
          {/*
          <Stack spacing="1">
            <NavLink label="Home" path={`${url}/home`} icon={faHome} />
          </Stack>

          <Divider borderColor={'gray.500'} />
          */}

          <Stack spacing="1">
            {/*
            <NavLink label="Teams" path={`${url}/teams`} icon={faUsersClass} />
            */}

            <NavLink label="Team Portals" path={`seasons`} icon={faPortalEnter} />

            {/*
            <NavLink label="Players" path={`${url}/players`} icon={faUsers} />
            */}
          </Stack>

          {/*
          <Divider borderColor={'gray.500'} />

          <Stack spacing={1}>
            <NavLink label="Notifications" path={`${url}/notifications`} icon={faBell} />
          </Stack>
          */}
        </Stack>

        <Spacer />

        <Stack display={{ base: 'flex', md: 'none' }}>
          {/*
          <NavLink
            icon={switchIcon}
            label={'Toggle Theme'}
            onClick={toggleColorMode}
          />
          */}

          <NavLink
            icon={faCogs}
            label={'Account Settings'}
            path={`profile`}
          />

          <NavLink
            icon={faSignOut}
            color={mode('red.500', 'red.300')}
            label={'Sign Out'}
            href={keycloak?.createLogoutUrl()}
          />
        </Stack>
      </Flex>

      <UserProfile
        display={{ base: 'flex', md: 'none' }}
        image={data?.viewer?.avatar?.url || data?.viewer?.defaultAvatar?.url}
        email={profileInfo?.email + ''}
      />
    </Flex>
  )
}
