import {chakra, HTMLChakraProps, useColorModeValue as mode} from '@chakra-ui/react'
import * as React from 'react'
import {Link as ReactRouterLink, useLocation, useMatch, useParams} from 'react-router-dom';

interface NavLinkProps extends HTMLChakraProps<'a'> {
  active?: boolean
  to?: string
  external?: boolean
}

const DesktopNavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  const { active, to, external, ...rest } = props
  const { pathname: url } = useLocation()!!
  const location = useLocation()

  const isActive = (to === '') || location.pathname.includes(to ?? 'test')

  return (
    <chakra.a
      as={!external ? ReactRouterLink : 'a'}
      href={external ? props.to : undefined}
      to={`${to}`}
      ref={ref}
      display="inline-block"
      px="2"
      py={'2px'}
      fontSize={'sm'}
      mx={2}
      my={2}
      fontWeight="500"
      aria-current={active ? 'page' : undefined}
      color={mode(isActive ? 'white' : 'gray.800', 'gray.400')}
      bgColor={isActive ? 'purple.500' : 'white'}
      borderRadius={'full'}
      transition="all 0.1s"
      {...rest}
      _hover={{
        bgColor: isActive ? 'purple.500' : 'gray.100'
      }}
    />
  )
})
DesktopNavLink.displayName = 'DesktopNavLink'

export const MobileNavLink = (props: NavLinkProps) => {
  const { active, ...rest } = props
  return (
    <chakra.a
      aria-current={active ? 'page' : undefined}
      w="full"
      display="flex"
      alignItems="center"
      height="14"
      fontWeight="semibold"
      borderBottomWidth="1px"
      {...rest}
    />
  )
}

export const NavLink = {
  Mobile: MobileNavLink,
  Desktop: DesktopNavLink,
}
