import React from 'react';
import {Route, Routes, useMatch} from 'react-router-dom';
import {EditTeamPage} from "./edit";
import {ViewTeamPage} from "./view";
import {TeamStaffMembersPanel} from "./view/staff-members";


export function ViewTeamPageRouter() {

  return (
    <Routes>

      <Route
        index
        element={<ViewTeamPage />}
      />

      <Route
        path={`edit`}
        element={<EditTeamPage />}
      />

      <Route
        path={`staff`}
        element={<TeamStaffMembersPanel />}
      />
    </Routes>
  )
}
