import React from 'react';
import {Outlet, useParams, useRoutes} from "react-router-dom";
import {Dashboard} from '../../components/app-shell';
import {ApolloProviderOrganization} from "../../../../ApolloProviderOrganization";
import {LoadingOverlay, Text} from '@mantine/core';
import {NothingFoundBackground} from "../../components/app-shell/404/404-page";
import {faCalendarRange, faGear, faHome, faLocationDot, faWhistle} from "@fortawesome/pro-duotone-svg-icons";
import {OrganizationViewingContext, OrganizationViewingContextType} from "../../../organization";
import {useOrganizationContextInfoQuery} from "../../../organization/graphql";
import {PageNameContextProvider, PageNameSynchronizer} from "../../components/app-shell/page-name";
import {RouteDefinition} from "../../../../components/router-traversal/route-visitor";
import {useSpotlightSearch} from "../../../../components/router-traversal";
import {seasonRoutes, SeasonsListPage} from "./seasons";
import {venueRoutes} from "./venues";
import {officialRoutes} from "./officials";
import {UserContext} from '../../components/app-shell/user-context';

const organizationNavigation = [
  {
    label: 'Dashboard',
    icon: faHome,
    link: ''
  },
  {
    label: 'Seasons',
    icon: faCalendarRange,
    link: 'seasons',
  },
  {
    label: 'Venues',
    icon: faLocationDot,
    link: 'venues'
  },
  {
    label: 'Officials',
    icon: faWhistle,
    link: 'officials'
  },
  {
    label: 'Settings',
    icon: faGear,
    link: 'settings'
  },
];

const routes: RouteDefinition[] = [
  {
    path: '/',
    element: <Dashboard navigationDefinition={organizationNavigation} />,
    extras: {
      details: {
        pageName: 'Dashboard'
      },
      spotlight: {
        title: 'Dashboard',
        description: 'Go to organization dashboard'
      },
    },
    children: [
      {
        index: true,
        element: <Text>
          Dashboard
        </Text>
      },
      {
        path: 'seasons',
        element: <SeasonsListPage />,
        children: seasonRoutes,
        extras: {
          details: {
            pageName: 'Seasons'
          },
          spotlight: {
            title: 'Seasons',
            description: 'View all seasons in this organization'
          },
        }
      },
      {
        path: 'venues',
        element: <>
          <Text>Venues</Text>
          <Outlet />
        </>,
        children: venueRoutes,
        extras: {
          details: {
            pageName: 'Venues'
          },
          spotlight: {
            title: 'Venues',
            description: 'Manage your venues'
          },
        }
      },
      {
        path: 'officials',
        element: <>Officials</>,
        children: officialRoutes,
        extras: {
          details: {
            pageName: 'Officials'
          },
          spotlight: {
            title: 'Officials',
            description: 'Manage your officials'
          }
        }
      },
      {
        path: 'settings',
        element: <>Settings</>,
        extras: {
          details: {
            pageName: 'Settings'
          },
          spotlight: {
            title: 'Settings',
            description: 'Visit organization settings page'
          }
        },
      },
      {
        path: '*',
        element: <NothingFoundBackground backButtonLabel={'Back to Organization Dashboard'} />
      },
    ]
  },
]


export function LeagueBrainOrganizationRoot() {

  const { organizationId } = useParams()

  return (
    <ApolloProviderOrganization organizationId={organizationId}>
      <PageNameContextProvider>
        <RealRoot />
      </PageNameContextProvider>
    </ApolloProviderOrganization>
  )
}

function RealRoot() {
  const { loading, data } = useOrganizationContextInfoQuery()

  let contextValue: any = {
    id: '',
    firstName: '',
    lastName: '',
    displayName: '',
    avatar: '',

    organizationId: '',
    organizationName: '',
    organizationAvatar: '',
    viewerId: '',
    viewerRole: ''
  }

  if (data) {
    contextValue = {
      id: data.viewer.id,
      firstName: '',
      lastName: '',
      displayName: data.viewer.displayName,
      avatar: null,

      organizationId: data.organization.id,
      organizationName: data.organization.name,
      organizationAvatar: "https://www.ghvbl.com/wp-content/uploads/2020/11/cropped-2017-ghvbl-logo.png",
      viewerId: data.viewer.id,
      viewerRole: data.viewer.role
    }
  }

  useSpotlightSearch(routes)

  return (
    <UserContext.Provider value={contextValue}>
      <OrganizationViewingContext.Provider value={contextValue}>
        <PageNameSynchronizer routes={routes} />
        <LoadingOverlay visible={loading} />
        {useRoutes(routes)}
      </OrganizationViewingContext.Provider>
    </UserContext.Provider>
  )
}

export function useOrganization() {
  return React.useContext<OrganizationViewingContextType>(OrganizationViewingContext);
}
