import React from 'react';
import {
  Box,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
  Breadcrumb,
  BreadcrumbItem,
  LinkBox,
  LinkOverlay
} from "@chakra-ui/react";
import {useAllTeamSeasonsQuery} from "../graphql";
import {Icon} from "../../../components/FontAwesomeIcon";
import {faArrowRight} from "@fortawesome/pro-solid-svg-icons";
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { Link as ReactRouterLink } from "react-router-dom";

export function SeasonsPage() {

  const { data } = useAllTeamSeasonsQuery()

  if (!data?.teamSeasons) {
    return <>loading...</>
  }

  return (
    <>
      <Box bg={'gray.50'} px="8" pt="8" borderBottom={'2px solid'} borderColor={'gray.100'}>
        <Flex
          direction={{ base: 'column', md: 'column' }}
          justify="space-between"
          align="flex-start"
          mb={{base: '4', md: '6'}}
        >
          <HStack mb={'0'}>
            <Heading size="lg">Team Portals</Heading>
            <Text color={'gray.500'} fontSize="sm">
              ({data?.teamSeasons?.length ?? '~'} Teams)
            </Text>
          </HStack>

          <Text size={'sm'} mt={1}><i>Successfully registered teams</i></Text>
        </Flex>
      </Box>

      <VStack align={'stretch'} spacing={2} overflowY={'auto'}>
        {data.teamSeasons.map(entry => {
          return (
            <LinkBox key={entry.seasonTeamId}>
              <Flex
                px={2}
                py={1}
                direction={'column'}
                // borderBottom={'1px solid lightgrey'}
                sx={{
                  '&:hover': {
                    bgColor: 'gray.100'
                  }
                }}
              >
                <LinkOverlay as={ReactRouterLink} to={`/st/${entry.seasonTeamId}`}>
                  <Breadcrumb spacing='8px' separator={<Icon icon={faChevronRight} size={'xs'} color='gray.500' />}>
                    <BreadcrumbItem>
                      <Heading size={'sm'}>{entry.organizationName}</Heading>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Heading size={'sm'}>{entry.seasonName}</Heading>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </LinkOverlay>

                <Text>{entry.divisionName} {entry.teamName}</Text>
              </Flex>
            </LinkBox>
          )
        })}
      </VStack>
    </>
  )
}
