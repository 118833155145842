import React, {useState} from 'react';
import {AnimateSharedLayout, motion} from 'framer-motion';
import {Box, ButtonProps, chakra, Flex, useColorModeValue, useToken} from '@chakra-ui/react';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

interface SegmentedControlContextData {
  value: string
  onChange(value: string): void
  formerColor: string
  setFormerColor(value: string): void
}

const SegmentedControlContext = React.createContext<SegmentedControlContextData>({
  value: '1',
  onChange(value: string){},
  formerColor: '',
  setFormerColor(value: string){}
})

export interface SegmentedControlProps {
  defaultValue?: string
  onChange?(value: string): void
}

export function SegmentedControl(props: React.PropsWithChildren<SegmentedControlProps>) {

  const {
    onChange = () => {},
    defaultValue = ''
  } = props

  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => {
    onChange(value)
  }, [value, onChange])


  const [formerColor, setFormerColor] = useState(React.Children.toArray(props.children)[0].color)

  return (
    <SegmentedControlContext.Provider
      value={{
        value: value,
        onChange: setValue,
        formerColor: formerColor,
        setFormerColor: setFormerColor
      }}
    >
      <MotionFlex
        borderRadius={'md'}
        backgroundColor={useColorModeValue('gray.100', 'rgba(181,190,255,0.32)')}
        p={1}
        alignContent={"flex-start"}
        alignItems={'start'}
        justifyContent={'start'}
      >
        <AnimateSharedLayout>
        {React.Children.map(props.children, (node, idx) => (
           <SegmentedControlOptionContext.Provider value={node.props.value}>
             {node}
           </SegmentedControlOptionContext.Provider>
        ))}
        </AnimateSharedLayout>
      </MotionFlex>
    </SegmentedControlContext.Provider>
  )
}

export interface SegmentedControlOptionProps {
  value: string
  color?: string
}

const SegmentedControlOptionContext = React.createContext('')

export function SegmentedControlOption(props: React.PropsWithChildren<SegmentedControlOptionProps & ButtonProps>) {

  const { value: selectionValue, color = 'blue.500' } = props

  const cssColor = useToken('colors', color, '#00c')

  const i = React.useContext(SegmentedControlOptionContext)

  const { value, onChange, formerColor, setFormerColor } = React.useContext(SegmentedControlContext)

  const selectedTextColor = useColorModeValue('#fff', '#fff')
  const unselectedTextColor = useColorModeValue('#222', '#ddd')

  const selected = (value + '')

  return (
    <MotionBox
      key={selectionValue}
      position={'relative'}
      height={'30px'}
      p={'0 15px'}
      m={0}
      fontSize={'20px'}
      fontWeight={700}
      color={"#222"}
      cursor={'pointer'}
      zIndex={0}
      display={'flex'}
      alignItems={'center'}
      initial={{
        color: i === selected ? selectedTextColor : unselectedTextColor,
      }}
      animate={{
        color: i === selected ? selectedTextColor : unselectedTextColor,
      }}
      onTap={() => {
        setFormerColor(cssColor)
        onChange(selectionValue)
      }}
    >
      <chakra.span fontSize={'md'} userSelect={'none'}>{props.children}</chakra.span>
      {i === selected && (
        <MotionBox
          position={"absolute"}
          top={0}
          left={0}
          w={'full'}
          h={'full'}
          borderRadius={'md'}
          zIndex={-1}
          layoutId={"selected"}
          initial={{ backgroundColor: formerColor }}
          animate={{ backgroundColor: cssColor }}
        />
      )}
    </MotionBox>
  )
}
