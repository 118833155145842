import React, {useContext} from 'react';
import {useInitializeStripeMutation, useStripeOnboardingStatusQuery} from "../../../graphql";
import {Alert, Box, Button, Flex, Heading, Link, Text} from '@chakra-ui/react';
import {Icon} from "../../../../../components/FontAwesomeIcon";
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLink
} from "@fortawesome/pro-solid-svg-icons";
import {gql} from "@apollo/client";
import {OrganizationViewingContext} from "../../../index";

export function ConnectToStripePage() {

  const { data } = useStripeOnboardingStatusQuery()

  if (!data?.organization?.settings) {
    return <div>loading...</div>
  }

  const stripeSettings = data.organization.settings.stripe

  let stripeSettingsComponent = null
  let stripeSettingsIcon = null

  if (stripeSettings === null) {
    stripeSettingsComponent = <InitialLinkStripeBox />
    stripeSettingsIcon = <Icon icon={faExclamationCircle} color={'red.500'} />
  } else if (stripeSettings?.onboardStatus?.__typename === 'Incomplete') {
    stripeSettingsComponent = <NeedAttentionStripeBox accountId={stripeSettings.accountId} onboardUrl={stripeSettings.onboardStatus.accountLinkUrl} />
    stripeSettingsIcon = <Icon icon={faExclamationTriangle} color={'yellow.500'} />
  } else if (stripeSettings?.onboardStatus?.__typename === 'Completed') {
    stripeSettingsComponent = <StripeIsLinkedBox accountId={stripeSettings.accountId} />
    stripeSettingsIcon = <Icon icon={faCheckCircle} color={'green.500'} />
  }

  return (
    <Box>
      <Flex align={'center'}>
        <Heading fontSize={'2xl'} mr={2}>Connect to Stripe</Heading>
        {stripeSettingsIcon}
      </Flex>
      {stripeSettingsComponent}
    </Box>
  )
}

function InitialLinkStripeBox() {

  const { organizationId } = useContext(OrganizationViewingContext)

  const [initializeStripe, { loading }] = useInitializeStripeMutation({
    update(cache, { data }) {
      cache.writeFragment({
        id: `Organization:${organizationId}`,
        fragment: gql`
            fragment UpdateFragment on Organization {
                settings {
                    stripe {
                        accountId
                        onboardStatus {
                            isOnboarded
                            ... on Incomplete {
                                accountLinkUrl
                            }
                        }
                    }
                }
            }
        `,
        data: {
          settings: {
            stripe: data.initializeStripe
          }
        }
      })
    }
  })

  return (
    <Box>
      <Text mr={1}>Start accepting payments by integrating with Stripe</Text>
      <Button isLoading={loading} onClick={() => initializeStripe()} colorScheme={'blue'}>
        Connect to Stripe
      </Button>
    </Box>
  )
}

type NeedAttentionStripeBoxProps = {
  accountId: string
  onboardUrl: string
}

function NeedAttentionStripeBox(props: NeedAttentionStripeBoxProps) {

  const {
    accountId,
    onboardUrl
  } = props

  return (
    <Box>
      {/*<Text>Stripe account id: {accountId}</Text>*/}
      <Alert status='warning' my={1}>
        You haven't finished setting up your Stripe account.
      </Alert>
        <Link href={onboardUrl}>
          <Button colorScheme={'blue'} rightIcon={<Icon icon={faExternalLink} />}>
            Finish Account Setup
          </Button>
        </Link>
    </Box>
  )
}

type StripeIsLinkedBoxProps = {
  accountId: string
}

function StripeIsLinkedBox(props: StripeIsLinkedBoxProps) {

  const {
    accountId
  } = props

  return (
    <Box>
      <Text>You are ready to accept payments!</Text>
      <Text>Stripe account id: {accountId}</Text>
      <Link>
        <Button colorScheme={'blue'} rightIcon={<Icon icon={faExternalLink} />}>
          View Linked Account
        </Button>
      </Link>
    </Box>
  )
}
