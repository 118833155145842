import React from 'react';
import {TextInput} from "@mantine/core";
import {useFormContext} from "react-hook-form";

export interface TextQuestionProps {
  questionKey: string
  definition: {
    key: string
    label: string
    description?: (null | string)
    optional?: boolean
    type: 'Text'
  }
}

export function TextQuestion(props: TextQuestionProps) {

  const {
    questionKey,
    definition,
  } = props

  const methods = useFormContext();

  return (
    <TextInput
      {...methods.register(questionKey)}
      required={!definition.optional ?? true}
      label={definition.label}
      description={definition.description}
    />
  )
}
