import React from 'react';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  RenderProps,
  Stack,
  StackDivider,
  StylesProvider,
  useColorModeValue,
  useMultiStyleConfig,
  useToast,
  VStack
} from "@chakra-ui/react";
import {DefaultValues, UnpackNestedValue, useForm} from "react-hook-form";
import {FieldGroup} from "../../pages/user/manage-profile/FieldGroup";
import {Icon} from "../FontAwesomeIcon";
import {useNavigate, useLocation} from "react-router-dom";
import {faTimes} from "@fortawesome/pro-regular-svg-icons";
import * as _ from 'lodash';
import {SmartFormContextProvider, useSmartFormContext} from './context';

const qs = require('qs');

export const SmartFormStyling = {
  parts: ["container", "title", "item"],
  baseStyle: {
    container: {
      px: { base: '4', md: '6' },
      py: '4',
      w: 'full',
      h: 'full',
      mx: 'auto',
      overflowY: 'auto'
    },
    item: {
      fontWeight: "medium",
      lineHeight: "normal",
      color: "gray.600",
    },
    title: {
      size: 'xl',
      pb: 4,
      as: 'h1'
    }
  },
  sizes: {
    sm: {
      item: {
        fontSize: "0.75rem",
        px: 2,
        py: 1,
      },
    },
    md: {
      item: {
        fontSize: "0.875rem",
        px: 3,
        py: 2,
      },
    },
  },
  defaultProps: {
    size: "md",
  },
}
interface UseCompletionPathReturn<T> {
  followCompletionPath(data: T, response: any): void
}

function useCompletionPath<T>(defaultPath: string | ((data: T, response: any) => string) | undefined): UseCompletionPathReturn<T> {
  const location = useLocation()
  const navigate = useNavigate()

  return {
    followCompletionPath: (data: T, response: any) => {
      let completionPath = qs.parse(location.search.substr(1))['owc'] ? JSON.parse(atob(qs.parse(location.search.substr(1))['owc'])).path : defaultPath

      if (_.isFunction(completionPath)) {
        completionPath = completionPath(data, response)
      }

      if (completionPath) {
        navigate(completionPath)
      }
    }
  }
}

const defaultContextValues = {
  shouldEnterSubmitForm: true
}

export function SmartFormWrapper(props: React.PropsWithChildren<BoxProps>) {

  const {
    children,
    ...rest
  } = props

  const styles = useMultiStyleConfig('SmartForm', {})

  return (
    <StylesProvider value={styles}>
      <Stack
        __css={styles.container}
        divider={<StackDivider />}
        {...rest}
      >
        {props.children}
      </Stack>
    </StylesProvider>
  )
}

export type OnCompletionAction = {
  toast?: {
    title: string
  }
}

export interface SmartFormProps<T, R = undefined> {
  defaultValues?: DefaultValues<T>
  onSubmit(data: UnpackNestedValue<T>): R | Promise<R>
  onComplete?: ((data: R) => OnCompletionAction) | OnCompletionAction
}

export function SmartForm<T, R = void | undefined>(props: React.PropsWithChildren<SmartFormProps<T, R>>) {
  const {
    defaultValues,
    onSubmit,
    onComplete,
    children
  } = props

  const formMethods = useForm<T>( {
    defaultValues: _.cloneDeep(defaultValues)
  })

  React.useEffect(() => {
    formMethods.reset(_.cloneDeep(defaultValues))
  }, [JSON.stringify(defaultValues)])

  const toast = useToast()
  const toastBgColor = useColorModeValue('white', 'gray.700')
  const toastBorderColor = useColorModeValue('gray.200', 'gray.600')
  const toastDescriptionColor = useColorModeValue('gray.800', 'gray.100')
  const toastCloseButtonColor = useColorModeValue('gray.600', 'gray.400')
  const toastCloseButtonHoverColor = useColorModeValue('gray.100', 'gray.600')

  async function decoratedOnSubmit(data: UnpackNestedValue<T>) {
    const retValue = await onSubmit(data)

    let defaultOnComplete: OnCompletionAction = {}

    if (typeof onComplete === 'function') {
      defaultOnComplete = _.assign(defaultOnComplete, onComplete(retValue))
    } else if (onComplete) {
      defaultOnComplete = _.assign(defaultOnComplete, onComplete)
    }

    if (defaultOnComplete.toast) {
      toast({
        status: "success",
        duration: 1500,
        isClosable: true,
        position: 'top',
        render: (props: RenderProps) => (
          <Flex
            position={'relative'}
            borderRadius={'4px'}
            bgColor={toastBgColor}
            boxShadow={'md'}
            h={'48px'}
            pl={6}
            align={'center'}
            border={'1px solid'}
            borderLeft={'none'}
            borderColor={toastBorderColor}
            justify={'space-between'}
          >
            <Box position="absolute" border={'1px solid'} borderColor={'green.400'} left={0} top={'-1px'} bottom={'-1px'} width="6px" bg="green.400" roundedLeft="inherit" />

            <Heading wordBreak={'break-word'} p={0} m={0} flexGrow={1} fontWeight={'500'} fontSize={'md'} color={toastDescriptionColor}>{defaultOnComplete!!.toast!!.title}</Heading>

            <Divider flexGrow={0} orientation="vertical" h={'20px'} />

            <IconButton
              aria-label={'Close Toast'}
              icon={<Icon icon={faTimes} />}
              variant={'unstyled'}
              borderRadius={'50%'}
              onClick={props.onClose}
              mx={1}
              color={toastCloseButtonColor}
              sx={{
                '&:hover': {
                  bgColor: toastCloseButtonHoverColor
                }
              }}
            />
          </Flex>
        )
      })
    }
  }

  return (
    <Box
      as={'form'}
      onSubmit={formMethods.handleSubmit(decoratedOnSubmit)}
    >
      <SmartFormContextProvider
        value={{
          useFormReturn: formMethods,
          ...defaultContextValues
        }}
      >
        <Stack>
          {children}
        </Stack>
      </SmartFormContextProvider>
    </Box>
  )
}

export interface SmartFormSectionProps {
  title?: string
}

export function SmartFormSection(props: React.PropsWithChildren<SmartFormSectionProps>) {

  const {
    title
  } = props

  return (
    <FieldGroup title={title}>
      <VStack width="full" spacing="6">
        {props.children}
      </VStack>
    </FieldGroup>
  )
}

export function SmartFormFooter(props: React.PropsWithChildren<any>) {
  return (
    <FieldGroup py={{ base: 0, xl: 4 }} pb={4}>
      <HStack width="full">
        {props.children}
      </HStack>
    </FieldGroup>
  )
}

interface SmartFormSubmitButtonProps {
  isAlwaysEnabled?: boolean
}

export function SmartFormSubmitButton(props: React.PropsWithChildren<Omit<ButtonProps, 'onClick' | 'type'> & SmartFormSubmitButtonProps>) {

  const {
    isAlwaysEnabled = false
  } = props

  const { useFormReturn: { formState: { isSubmitting, dirtyFields } } } = useSmartFormContext();

  return (
    <Button
      colorScheme={'blue'}
      isLoading={isSubmitting}
      loadingText={'Saving...'}
      type={'submit'}
      isDisabled={!isAlwaysEnabled && (Object.keys(dirtyFields).length === 0)}
      {...props}
    >
      {props.children}
    </Button>
  )
}

export function SmartFormCancelButton(props: React.PropsWithChildren<Omit<ButtonProps, 'onClick'>>) {

  const { useFormReturn: { reset } } = useSmartFormContext();

  return (
    <Button variant={'outline'} onClick={() => reset()} {...props}>
      {props.children ?? 'Cancel'}
    </Button>
  )
}
