import React from "react";

import {ApolloClient, ApolloProvider as Apollo, createHttpLink, InMemoryCache, split} from '@apollo/client';
import {getMainDefinition} from '@apollo/client/utilities';
import {onError} from "@apollo/client/link/error";
import {useToast} from "@chakra-ui/react";
import introspection from "./pages/public/graphql.tsx";

function httpLink() {
  return createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT + `/public/graphql`
  })
}

/**
 *  Injects children with Apollo Client
 */
export const ApolloProviderPublic = ({ children }) => {

  const toast = useToast()

  // Memoize client to avoid rerenders on children change
  const client = React.useMemo(() => {

    const errorLink = onError(({ response, graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          )

          let errorMessage = message
          if (process.env.NODE_ENV === 'production') {
            errorMessage = 'Something went wrong!'
          }

          toast({
            title: "Whoops!",
            description: errorMessage,
            status: "error",
            position: "top",
            duration: 5000,
            isClosable: true,
          })
        });

      if (networkError) console.log(`[Network error]: ${networkError}`);

      if (response) {
        response.errors = null
      }

    });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      // wsLink,
      httpLink()
    );

    return new ApolloClient({
      link: httpLink(),
      cache: new InMemoryCache({
        possibleTypes: introspection.possibleTypes
      }),
    });
  }, []);

  return (
    <Apollo client={client}>
      {children}
    </Apollo>
  );
};
