import React from 'react';
import {MobileTopBar} from "../../nav/MobileTopBar";
import {Flex, useColorModeValue as mode} from "@chakra-ui/react";
import {Sidebar} from "../../nav/Sidebar";
import {ProfileDropdown} from "../../nav/ProfileDropdown";
import {Navigate, Route, Routes} from "react-router-dom";
import {TeamPageRouter} from "./teams";
import {SearchPage} from "../../nav/search";
import {ManageProfilePage} from "./manage-profile";
import {PublicUserProfile} from "./public-profile";
import {AcceptTeamInvitePage} from "./teams/invite/accept-team-invite";
import {SeasonsPage} from "./seasons";
import {RegistrationForm} from "../../forms/updated";

export const UserHomePageRouter = () => {

  return (
    <Flex h="100vh" flexDirection="column">
      <MobileTopBar />
      <Flex flex="1" overflow="hidden">
        <Sidebar display={{ base: 'none', md: 'flex' }} flexShrink={0} />

        <Flex flex="1" direction={'column'}>
          <Flex
            display={{ base: 'none', md: 'flex' }}
            position={'sticky'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            px={4}
            zIndex={2}
            bg={mode('white', 'gray.800')}
            borderBottom={'2px solid'}
            borderColor={mode('gray.200', 'gray.700')}
            flexBasis={'72px'}
            flexShrink={0}
            flexGrow={0}
          >
            {/*
              <SearchBar />
              <BaseSearchBar />
              */}

            {/*
            <HStack spacing={4} mr={4}>
              <FeedbackPopover />

              <Center pos={'relative'}>
                <Box
                  pos={'absolute'}
                  top={0}
                  right={4}
                  zIndex={2}
                >
                  <Tag
                    borderRadius={'full'}
                    size={'sm'}
                    colorScheme={'red'}
                  >
                    <TagLabel >
                      3
                    </TagLabel>
                  </Tag>
                </Box>

                <IconButton
                  aria-label={'notifications'}
                  icon={<Icon icon={faBell} size={'lg'} color={'gray.400'} />}
                  borderRadius={'50%'}
                  size={'lg'}
                  variant={'unstyled'}
                  mr={4}
                />
              </Center>
            </HStack>
            */}

            <ProfileDropdown />
          </Flex>

          <Routes>
            <Route
              index
              element={<Navigate to={`./home`} replace={true} />}
            />

            <Route
              path={`home`}
              element={<Navigate to={`../seasons`} replace={true} />}
            />

            <Route
              path={`teams/*`}
              element={<TeamPageRouter />}
            />

            <Route
              path={`seasons/*`}
              element={<SeasonsPage />}
            />

            <Route
              path={`search/*`}
              element={<SearchPage />}
            />

            <Route
              path={`profile/*`}
              element={<ManageProfilePage />}
            />

            <Route
              path={`users/:userId`}
              element={<PublicUserProfile />}
            />

            <Route
              path={`accept-team-invite/:inviteId`}
              element={<AcceptTeamInvitePage />}
            />

            <Route
              path={`org/:organizationId/season/:seasonId`}
              // element={<OrganizationRegistrationForm />}
              element={<RegistrationForm />}
            />

            <Route path={'*'} element={<h1>TODO: replace with home page</h1>} />
          </Routes>

        </Flex>

      </Flex>
    </Flex>
  )
}
