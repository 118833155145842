import React from 'react';
import {MobileTopBar} from "./components/MobileTopBar";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";
import {Sidebar} from "./components/Sidebar";
import {Icon} from "../../../components/FontAwesomeIcon";
import {ProfileDropdown} from "./components/MobileTopBar/ProfileDropdown";
import {Link as ReactRouterLink, Navigate, Route, Routes, useLocation, useMatch, useParams} from "react-router-dom";
import {useSeasonTeamViewingContextQuery} from "../graphql";
import {faPortalExit} from '@fortawesome/pro-regular-svg-icons';

const SeasonTeamPortalHomePageLazyComponent = (
  React.lazy(() => import('./SeasonTeamPortalHomePage'))
)

const RequirementsPageLazyComponent = (
  React.lazy(() => import('./requirements'))
)

const GameSchedulingPageLazyComponent = (
  React.lazy(() => import('./game-scheduler'))
)

const TeamContactsPageLazyComponent = (
  React.lazy(() => import('./team-contacts'))
)

const ReportScorePageLazyComponent = (
  React.lazy(() => import('./report-score'))
)

const TeamOrdersPageLazyComponent = (
  React.lazy(() => import('./orders'))
)

export type SeasonTeamViewingContextType = {
  organizationId: string
  organizationName: string
  seasonId: string
  seasonName: string
  seasonTeamId: string
  seasonTeamName: string
  viewerId: string
  viewerTeamRole: string
}

export const SeasonTeamViewingContext = React.createContext<SeasonTeamViewingContextType>({
  organizationId: '',
  organizationName: '',
  seasonId: '',
  seasonName: '',
  seasonTeamId: '',
  seasonTeamName: '',
  viewerId: '',
  viewerTeamRole: ''
})

export const SeasonTeamHomePageRouter = () => {

  const { pathname: url } = useLocation();
  const { seasonTeamId } = useParams()

  const { data } = useSeasonTeamViewingContextQuery({
    variables: {
      seasonTeamId: seasonTeamId!!
    }
  })

  if (!data) {
    return <>loading...</>
  }

  const context: SeasonTeamViewingContextType = {
    organizationId: data.seasonTeam!!.organization.id,
    organizationName: data.seasonTeam!!.organization.name,
    seasonId: data.seasonTeam!!.season.id,
    seasonName: data.seasonTeam!!.season.name,
    seasonTeamId: seasonTeamId!!,
    seasonTeamName: data.seasonTeam!!.name,
    viewerId: data.viewer.id,
    viewerTeamRole: data.seasonTeam!!.viewerRole
  }

  const isSchedulingOpen = data.seasonTeam?.season?.isSchedulingOpen ?? false

  return (
    <SeasonTeamViewingContext.Provider value={context}>
    <Flex h="100vh" flexDirection="column">
      <MobileTopBar />
      <Flex flex="1" overflow="hidden">
        <Sidebar display={{ base: 'none', md: 'flex' }} flexShrink={0} />

        <Flex flex="1" direction={'column'} overflowX={'auto'}>
          <Flex
            display={{ base: 'none', md: 'flex' }}
            position={'sticky'}
            w={'full'}
            justifyContent={'space-between'}
            alignItems={'center'}
            px={4}
            zIndex={2}
            bg={mode('white', 'gray.800')}
            borderBottom={'2px solid'}
            borderColor={mode('gray.200', 'gray.700')}
            flexBasis={'72px'}
            flexShrink={0}
            flexGrow={0}
          >
            <HStack align={'center'} spacing={4}>
              <Button
                aria-label={'back'}
                leftIcon={<Icon icon={faPortalExit} transform={'scaleX(-1)'} />}
                as={ReactRouterLink}
                to={'/u/seasons'}
              >
                Exit Portal
              </Button>

              <Breadcrumb spacing='8px' fontWeight={'md'}>
                <BreadcrumbItem>
                  <Text size={'sm'}>{context.organizationName}</Text>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Text size={'sm'}>{context.seasonName}</Text>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Text size={'sm'}>{data.seasonTeam?.division?.name} {context.seasonTeamName}</Text>
                </BreadcrumbItem>
              </Breadcrumb>
            </HStack>

            {/*
            <HStack spacing={4} mr={4}>
              <FeedbackPopover />

              <Center pos={'relative'}>
                <Box
                  pos={'absolute'}
                  top={0}
                  right={4}
                  zIndex={2}
                >
                  <Tag
                    borderRadius={'full'}
                    size={'sm'}
                    colorScheme={'red'}
                  >
                    <TagLabel >
                      3
                    </TagLabel>
                  </Tag>
                </Box>

                <IconButton
                  aria-label={'notifications'}
                  icon={<Icon icon={faBell} size={'lg'} color={'gray.400'} />}
                  borderRadius={'50%'}
                  size={'lg'}
                  variant={'unstyled'}
                  mr={4}
                />
              </Center>
            </HStack>
            */}

            <ProfileDropdown />
          </Flex>

          <React.Suspense fallback={<>loading...</>}>
            <Routes>
              <Route
                index
                element={<Navigate to={`./home`} replace={true} />}
              />

              <Route
                path={`home`}
                element={<SeasonTeamPortalHomePageLazyComponent />}
              />

              <Route
                path={`orders/*`}
                element={<TeamOrdersPageLazyComponent />}
              />

              <Route
                path={`requirements`}
                element={<RequirementsPageLazyComponent />}
              />

              <Route
                path={`schedule`}
                element={
                  <ComingSoonRoute comingSoon={!isSchedulingOpen}>
                    <GameSchedulingPageLazyComponent />
                  </ComingSoonRoute>
                }
              />

              <Route
                path={`team-contacts`}
                element={
                  <ComingSoonRoute comingSoon={!isSchedulingOpen}>
                    <TeamContactsPageLazyComponent />
                  </ComingSoonRoute>
              }
              />

              <Route
                path={`report-score/*`}
                element={
                  <ComingSoonRoute comingSoon={!isSchedulingOpen}>
                    <ReportScorePageLazyComponent />
                  </ComingSoonRoute>
                }
              />

              <Route path={'*'} element={<h1>404</h1>} />
            </Routes>
          </React.Suspense>

        </Flex>

      </Flex>
    </Flex>
    </SeasonTeamViewingContext.Provider>
  )
}

function ComingSoonRoute(props: React.PropsWithChildren<any> & { comingSoon: boolean }) {
  if (props.comingSoon) {
    return (
      <Center h={'120px'}>
        <Heading>Coming Soon</Heading>
      </Center>
    )
  }

  return props.children
}
