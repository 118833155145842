import React from 'react';
import {Box, useStyleConfig, useToken} from "@chakra-ui/react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const Icon = React.forwardRef((props: React.PropsWithChildren<any>, ref) => {
  const { variant, children, primaryColor, secondaryColor, primaryOpacity, secondaryOpacity, ...rest } = props
  const styles = useStyleConfig("FontAwesomeIcon", { variant })

  const [primary, secondary] = useToken(
    // the key within the theme, in this case `theme.colors`
    'colors',
    // the subkey(s), resolving to `theme.colors.red.100`
    [primaryColor, secondaryColor],
    // a single fallback or fallback array matching the length of the previous arg
  )

  const composedStyles = {
    ...styles,
    '--fa-primary-color': primary,
    '--fa-secondary-color': secondary,
    '--fa-primary-opacity': primaryOpacity,
    '--fa-secondary-opacity': secondaryOpacity,
  }

  return (
    <Box as={FontAwesomeIcon} forwardedRef={ref} __css={composedStyles} {...rest} />
  )
})
