import React from "react";
import {DatePicker} from "@mantine/dates";
import {getHotkeyHandler} from "@mantine/hooks";
import {CellRenderProps} from "./CellRenderProps";
import {TableContext} from "../TableContext";

export const EditableDatePickerCell = (props: CellRenderProps<any>) => {

  const {
    value: initialValue,
    row: {index, original: {id: rowId}},
    column: {id},
    updateMyData, // This is a custom function that we supplied to our table instance
    ...rest
  } = props

  const tableContext = React.useContext(TableContext)

  const inputRef = React.useRef(null)

  React.useEffect(() => {
    inputRef?.current?.focus()
  }, [inputRef])

  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(id)

  const onChange = (e: Date) => {
    setValue(e)
    updateMyData(rowId, id, e.toLocaleDateString())
    tableContext.endEditing()
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    // updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(id)
  }, [id])

  return (
    <DatePicker
      ref={inputRef}
      value={new Date()}
      onChange={onChange}
      onBlur={onBlur}
      variant={'unstyled'}
      transitionDuration={0}
      initiallyOpened={true}
      clearable={false}
      sx={{width: '100%'}}
      onDropdownClose={() => {
        tableContext.endEditing()
      }}
      styles={{
        input: {
          height: '100%'
        },
        arrow: {
          display: 'none'
        }
      }}
      onKeyDown={getHotkeyHandler([
        ['Escape', () => {
          tableContext.endEditing()
        }],
      ])}
    />
  )
}
