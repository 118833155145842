import React, {PropsWithChildren} from 'react';
import {Box, Heading, Link, LinkProps, SimpleGrid, Text, VStack} from "@chakra-ui/react";
import {Link as ReactRouterLink, useMatch, useLocation} from 'react-router-dom';

export function SeasonSettingsPage() {

  const { pathname: url } = useLocation()!!

  return (
    <Box mt={4}>
      <Heading size={'lg'}>Season Settings</Heading>

      <SimpleGrid columns={3} spacing={0} mt={4}>
        <SettingsBox title={'Season Configuration'} description={'Manage this season.'}>
          <SettingsBoxLink to={`${url}/season`}>Season details</SettingsBoxLink>
          <SettingsBoxLink to={`${url}/season/divisions`}>Divisions and standings</SettingsBoxLink>
          <SettingsBoxLink to={`${url}/season/activity`}>Activity history</SettingsBoxLink>
        </SettingsBox>

        <SettingsBox title={'Games'} description={'Schedule and score games.'}>
          <SettingsBoxLink to={`${url}/games/notifications`}>Alerts and notifications</SettingsBoxLink>
          <SettingsBoxLink to={`${url}/games/collaborative_scheduling`}>Collaborative scheduling</SettingsBoxLink>
        </SettingsBox>
      </SimpleGrid>
    </Box>
  )
}

interface SettingsBoxProps {
  title: string
  description?: string
}

function SettingsBox(props: PropsWithChildren<SettingsBoxProps>) {

  const {
    title,
    description
  } = props

  return (
    <Box
      height={'140px'}
      borderTop={'1px solid'}
      borderColor={'gray.200'}
      _notLast={{
        borderRight: '1px solid',
        borderColor: 'gray.200'
      }}
      p={4}
    >
      <Heading
        fontSize={'md'}
        fontWeight={'semibold'}
        color={'gray.700'}
      >
        {title}
      </Heading>

      <Text mt={1} fontSize={'sm'}>{description}</Text>

      <VStack align={'left'} spacing={0} pt={1}>
        {props.children}
      </VStack>

    </Box>
  )
}

function SettingsBoxLink(props: PropsWithChildren<LinkProps & { to: string }>) {
  return (
    <Link
      as={ReactRouterLink}
      to={props.to}
      fontSize={'sm'}
      fontWeight={'500'}
      color={'purple.500'}
    >
      {props.children}
    </Link>
  )
}
