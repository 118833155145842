import React from 'react';
import {FormDefinition} from "./formDefinition";
import {TextQuestion} from "./questions/TextQuestion";

export interface FormQuestionProps {
  sectionKey: string
  definition: FormDefinition['sections'][number]['questions'][number]
}

export function FormQuestion(props: FormQuestionProps) {

  const {
    sectionKey,
    definition,
  } = props

  switch (definition.type) {
    case "Text":
      return <TextQuestion questionKey={`${sectionKey}.${definition.key}`} definition={definition} />

    case "Number":
    default:
      return <>Invalid Question Type</>
  }

}
