import React from 'react';
import {AppShell, Box, ScrollArea, useMantineTheme} from '@mantine/core';
import {Outlet} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import {ServerError} from "./500";
import {ApplicationNavbar} from "./navbar";
import {ApplicationHeader} from "./header";


export interface DashboardProps {
  navigationDefinition: any[]
  rightNavbarContent?: React.ReactNode
}

export function Dashboard(props: DashboardProps) {

  const theme = useMantineTheme();
  const [opened, setOpened] = React.useState(false);

  return (
    <AppShell
      fixed
      navbarOffsetBreakpoint="md"
      padding={'sm'}
      styles={theme => ({
        main: {
          position: 'relative',
          background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.fn.lighten(theme.colors.gray[0], 0.2),
          paddingBottom: 0
        },
      })}
      navbar={
        <ApplicationNavbar
          navigationDefinition={props.navigationDefinition}
          isOpen={opened}
        />
      }
      header={
        <ApplicationHeader
          isOpen={opened}
          toggleOpen={() => setOpened(o => !o)}
          rightContent={props.rightNavbarContent}
        />
      }
    >
      <ErrorBoundary fallbackRender={(props) => <ServerError {...props} />}>
        <Outlet />
      </ErrorBoundary>
    </AppShell>
  );
}
