import React from 'react';
import {PageNameContext, usePageNameContext} from "./PageNameContext";
import {matchPath, resolvePath, useLocation, useResolvedPath} from "react-router-dom";
import {PageDetails, usePageDetails} from "../../../../../components/router-traversal";
import {RouteDefinition} from '../../../../../components/router-traversal/route-visitor';

export interface PageNameSynchronizerProps {
  routes: RouteDefinition | RouteDefinition[]
}

export function PageNameSynchronizer(props: PageNameSynchronizerProps) {

  const pageContext = usePageNameContext()
  const location = useLocation()
  const pageDetails = usePageDetails(props.routes)
  const resolved = useResolvedPath('.');

  React.useEffect(() => {
    let detailsResult: PageDetails | null = null

    for (let i = 0; i < Object.keys(pageDetails).length; i++) {
      const path = Object.keys(pageDetails)[i]
      const details = pageDetails[path]

      const rresolved = resolvePath(path, resolved.pathname)

      const match = matchPath({ path: rresolved.pathname, end: true }, location.pathname)

      if (!!match) {
        detailsResult = details
        break
      }
    }

    if (detailsResult) {
      pageContext.setPageName(detailsResult.pageName)
    }
  }, [location.pathname, pageContext, pageDetails, resolved.pathname])

  return null
}

export interface PageNameContextProviderProps extends React.PropsWithChildren<any> {

}

export function PageNameContextProvider({ children } : PageNameContextProviderProps) {
  const [pageName, setPageName] = React.useState({ name: '', id: '' });

  const setName = React.useCallback((name: string, id?: string) => {
    setPageName({
      name: name,
      id: id ?? ''
    })
  }, [])

  const context = React.useMemo(() => {
    return {
      pageName: pageName.name,
      setPageName: setName
    }
  }, [pageName.name, setName])

  return (
    <>
      <PageNameContext.Provider value={context}>
        {children}
      </PageNameContext.Provider>
    </>
  )
}
