import React from 'react';
import {
  Avatar,
  Box,
  Divider,
  Group,
  MantineColor,
  Menu,
  Sx,
  Text,
  UnstyledButton,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import {faChevronDown} from "@fortawesome/pro-solid-svg-icons";
import {
  faAddressCard,
  faMoonStars,
  faRightLeft,
  faSignOut,
  faSun,
  IconDefinition
} from "@fortawesome/pro-duotone-svg-icons";
import {getLeadingCharacters} from '.';
import {useOrganization} from "../../../organization/routes";
import {useUserContext} from "../../../organization/season";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export function UserProfileMenu() {

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const theme = useMantineTheme()

  const organizationContext = useOrganization()
  const userContext = useUserContext()

  const userInfo = {
    displayName: userContext.displayName, // userProfile?.firstName ?? '' + ' ' + userProfile?.lastName ?? '',
    organizationRole: organizationContext.viewerRole,
    profilePhotoUrl: userContext.avatar
  }

  return (
    <Menu
      size={'lg'}
      control={
        <UnstyledButton>
          <Group>
            <Box
              sx={{
                position: 'relative'
              }}
            >
              <Avatar
                src={organizationContext.organizationAvatar}
                radius="xl"
              >
                {getLeadingCharacters(organizationContext.organizationName)}
              </Avatar>

              <Avatar
                size="sm"
                radius={'xl'}
                color={'blue'}
                sx={{
                  position: 'absolute',
                  bottom: '-15%',
                  right: '-25%',
                }}
                styles={(theme) => ({
                  root: {
                    opacity: '1 !important',
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.white,
                    border: `2px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white}`
                  }
                })}
              >
                {getLeadingCharacters(userInfo.displayName)}
              </Avatar>
            </Box>

            <Icon icon={faChevronDown} size={'sm'} color={'gray'} />
          </Group>
        </UnstyledButton>
      }
    >
      <Group m={'xs'} spacing={'xs'} noWrap>
        <Avatar
          radius={'xl'}
          color={'blue'}
          src={userInfo.profilePhotoUrl}
        >
          {getLeadingCharacters(userInfo.displayName)}
        </Avatar>

        <Group direction={'column'} spacing={0}>
          <Text size="sm" weight={500} lineClamp={1}>
            {userInfo.displayName}
          </Text>

          <Text color="dimmed" size="xs" lineClamp={1}>
            {userInfo.organizationRole}
          </Text>
        </Group>
      </Group>

      <Menu.Item icon={<Icon icon={faAddressCard} />}>View Profile</Menu.Item>

      <Menu.Item icon={<Icon icon={faRightLeft} />}>Switch Organization</Menu.Item>

      <Menu.Item
        icon={<Icon icon={colorScheme === 'dark' ? faSun : faMoonStars} />}
        onClick={() => toggleColorScheme()}
      >
        {colorScheme === 'dark' ? 'Disable' : 'Enable'} Dark Mode
      </Menu.Item>

      <Divider />

      <Menu.Item color="red" icon={<Icon icon={faSignOut} />}>Sign out</Menu.Item>
    </Menu>
  )
}

type IconProps = {
  icon: IconDefinition
  color?: MantineColor
  sx?: Sx
}

const Icon = React.forwardRef((props: React.PropsWithChildren<IconProps>, ref) => {
  const { children, icon, color, sx, ...rest } = props

  const theme = useMantineTheme()

  const composedStyles = {
    '--fa-primary-color': color ? theme.colors[color][3] : undefined,
    '--fa-secondary-color': color ? theme.colors[color][3] : undefined,
    '--fa-primary-opacity': 0.8,
    '--fa-secondary-opacity': 0.4,
  }

  return (
    <Box<typeof FontAwesomeIcon>
      component={FontAwesomeIcon}
      icon={icon}
      forwardedRef={ref}
      sx={{ ...composedStyles, ...sx }}
      {...rest}
    />
  )
})
