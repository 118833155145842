import {RouteDefinition} from "../../../../../components/router-traversal/route-visitor";
import React from "react";
import {generatePath, Link, useParams} from "react-router-dom";
import {useAllSeasonsQuery} from "../../graphql";
import {Box, Group, Paper, Text, Title, UnstyledButton} from "@mantine/core";
import {Icon} from "../../../../../components/FontAwesomeIcon";
import {faArrowRight} from "@fortawesome/pro-solid-svg-icons";
import {useSeasonContext} from "../../season";

export const seasonRoutes: RouteDefinition[] = [
  {
    path: ':seasonId',
    element: <TestSeasonPage />,
  }
]

export function SeasonDashboardPage() {
  return (
    <Text>Dashboard for <b>{useSeasonContext().seasonName}</b> season</Text>
  )
}

function TestSeasonPage() {
  const { seasonId } = useParams()

  return (
    <>
      Current Season: {seasonId}
    </>
  )
}

export function SeasonsListPage() {
  const { data } = useAllSeasonsQuery()

  return (
    <>
      <Title>All Seasons</Title>

      <Group mt={'lg'} direction={'column'} align={'stretch'}>
        {(data?.organization?.seasons ?? []).map(season => (
          <UnstyledButton<typeof Link>
            component={Link}
            to={generatePath('../s/:seasonId', { seasonId: season.id })}
            sx={{ borderRadius: '4px' }}
          >
            <Paper
              p={'md'}
              shadow={"xs"}
            >
              <Group position={'apart'}>
                <Title order={5}>{season.name}</Title>

                <Box>
                  <Icon icon={faArrowRight} />
                </Box>
              </Group>
            </Paper>
          </UnstyledButton>
        ))}
      </Group>
    </>
  )
}
