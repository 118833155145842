import React from 'react';
import {useParams} from "react-router-dom";
import {Box} from "@chakra-ui/react";
import {useTeamByIdQuery, useUpdateTeamDetailsMutation} from "../../graphql";
import {
  SmartForm,
  SmartFormCancelButton,
  SmartFormFooter,
  SmartFormSection,
  SmartFormSubmitButton,
} from "../../../../components/smart-forms";
import {BasicFileProperties} from "../../manage-profile/SingleFileInput";
import {SmartFormTextInput} from '../../../../components/smart-forms/inputs/TextInput';
import {SmartFileUploadSingleImage} from '../../../../components/smart-forms/inputs/SmartFileUploadSingleImage';

export interface EditTeamPageProps {

}

interface EditTeamFormData {
  avatar?: BasicFileProperties | null
  teamName: string
  description?: string | null | undefined
}

export function EditTeamPage(props: EditTeamPageProps) {

  const { teamId } = useParams()

  const { data, refetch } = useTeamByIdQuery({
    variables: {
      id: teamId
    }
  })

  const [updateTeamDetailsMutation] = useUpdateTeamDetailsMutation()

  if (!data) return <div />

  const team = data!!.team!!

  return (
    <Box w={'full'} p={4}>
      <SmartForm<EditTeamFormData>
        onSubmit={async (data: EditTeamFormData) => {
          await updateTeamDetailsMutation({
            variables: {
              teamId: teamId,
              details: {
                ...data,
                avatar: data.avatar?.id
              }
            },
            onCompleted() { refetch() }
          })
        }}
        defaultValues={{
          /*avatar: {
            id: 'u',
            url: team.avatar
          },
          */
          avatar: null,
          teamName: team.name,
          description: team.description
        }}
      >
        <SmartFormSection
          title={'Basic Info'}
        >
          {/*
          <SmartFileUploadSingleImage
            label={'Avatar'}
            name={'avatar'}
            isRequired={false}
          />
          */}

          <SmartFormTextInput
            isRequired
            label={'Team Name'}
            name={'teamName'}
            placeholder={'E.g. Yorktown Huskers'}
            helperText={'Helper Text'}
            registerOptions={{
              minLength: {
                value: 4,
                message: 'Must be at least 4 characters'
              }
            }}
          />

          <SmartFormTextInput
            label={'Description'}
            name={'description'}
          />
        </SmartFormSection>

        <SmartFormFooter>
          <SmartFormCancelButton />
          <SmartFormSubmitButton>Save Changes</SmartFormSubmitButton>
        </SmartFormFooter>
      </SmartForm>

    </Box>
  )
}
