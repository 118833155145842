import React from "react";

import {ApolloClient, ApolloProvider as Apollo, createHttpLink, InMemoryCache, split} from '@apollo/client';
import {getMainDefinition} from '@apollo/client/utilities';
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import {useKeycloak} from "@react-keycloak/web";
import {WebSocketLink} from "./ApolloWebsocketLink";
import {useToast} from "@chakra-ui/react";
import introspection from "./pages/organization/graphql.tsx";

function httpLink(organizationId) {
 return createHttpLink({
   uri: process.env.REACT_APP_GRAPHQL_ORGANIZATION_ENDPOINT + `/organization/${organizationId}/graphql`,
   credentials: 'include'
 })
}

/**
 *  Injects children with Apollo Client
 */
export const ApolloProviderOrganization = ({ organizationId, children }) => {

  const { keycloak } = useKeycloak()
  const toast = useToast()

  React.useEffect(() => {
    const refreshIntervalId = setInterval(() => {
      keycloak.updateToken(10).then(function(refreshed) {
        if (refreshed) {
          console.log('Token was successfully refreshed');
        } else {
          console.log('Token is still valid');
        }
      }).catch(function() {
        console.log('Failed to refresh the token, or the session has expired');
      });
    }, 5000)

    return () => clearInterval(refreshIntervalId)
  }, [])

  const wsLink = React.useMemo(() => {
    return new WebSocketLink({
      url: process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_ENDPOINT,
      connectionParams: () => {
        if (!keycloak.isTokenExpired(10)) {
          return {
            Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
          }
        }

        return new Promise(function(resolve, reject) {
          keycloak.updateToken(30).then(function(refreshed) {
            resolve({
              Authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
            })
          }).catch(() => reject())
        });
      },
    });
  }, [])

  // Memoize client to avoid rerenders on children change
  const client = React.useMemo(() => {

    const authLink = setContext((_, { headers }) => {
      const { token } = keycloak

      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : "",
        }
      }
    });

    const constructedHttpLink = httpLink(organizationId)

    const errorLink = onError(({ response, graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            )

          let errorMessage = message
          if (process.env.NODE_ENV === 'production') {
            errorMessage = 'Something went wrong!'
          }

          toast({
            title: "Whoops!",
            description: errorMessage,
            status: "error",
            position: "top",
            duration: 5000,
            isClosable: true,
          })
        });

      if (networkError) console.log(`[Network error]: ${networkError}`);

      if (response) {
        response.errors = null
      }

    });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      // wsLink,
     constructedHttpLink, constructedHttpLink
    );

    return new ApolloClient({
      link: authLink.concat(errorLink).concat(splitLink),
      cache: new InMemoryCache({
        typePolicies: {
          StripeSettings: {
            keyFields: ['accountId']
          },
          GhostGame: {
            keyFields: ['id', 'version']
          },
          GameSchedulingRow: {
            fields: {
              assignedOfficials: {
                merge(existing = [], incoming) {
                  return [...incoming];
                },
              },
            },
          },
        },
        possibleTypes: introspection.possibleTypes
      }),
    });
  }, []);

  return (
    <Apollo client={client}>
      {children}
    </Apollo>
  );
};
