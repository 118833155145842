import React, {useContext} from 'react';
import {Link as ReactRouterLink, Route, Routes, useLocation, useParams} from "react-router-dom";
import {Box, Button} from "@chakra-ui/react";
import {NavBar} from "../components/nav";
import {seasonPageLinks} from "../components/nav/_data";
import {Header} from "../components/header";
import {Icon} from "../../../components/FontAwesomeIcon";
import {faChevronLeft} from "@fortawesome/pro-solid-svg-icons";
import {OrganizationViewingContext, OrganizationViewingContextType} from "../index";
import {SeasonSettingsRouter} from "./settings/router";
import {useSeasonContextInfoQuery} from "../graphql";
import {HomePage} from "./HomePage";

export type SeasonViewingContextType = {
  seasonId: string
  seasonName: string
} & OrganizationViewingContextType

export const SeasonViewingContext = React.createContext<SeasonViewingContextType>({
  organizationId: '',
  organizationName: '',
  organizationAvatar: '',
  viewerId: '',
  displayName: '',
  viewerRole: '',
  seasonId: '',
  seasonName: ''
})

const SeasonTeamPageLazyComponent = (
  React.lazy(() => import('./teams/registration-fee'))
)

const VirtualizedSeasonTeamPageLazyComponent = (
  React.lazy(() => import('./teams/registration-fee/index-virtualized'))
)

const GamesPageLazyComponent = (
  React.lazy(() => import('./games/index'))
)


export function SeasonHomePage() {

  const { pathname: url } = useLocation()
  const { seasonId } = useParams()

  const organizationViewingContext = useContext(OrganizationViewingContext)

  const { data } = useSeasonContextInfoQuery({
    variables: {
      seasonId: seasonId!!
    }
  })

  const { pathname: path } = useLocation()

  if (!data?.season) return <div />

  const isAssignor = path.endsWith('/assignor')

  if (isAssignor || organizationViewingContext.viewerRole === 'OFFICIAL_ASSIGNOR') {
    return (
      <SeasonViewingContext.Provider value={{
        ...organizationViewingContext,
        seasonId: data.season.id,
        seasonName: data.season.name,
      }}>
        <React.Suspense fallback={<>loading...</>}>
          <GamesPageLazyComponent />
        </React.Suspense>
      </SeasonViewingContext.Provider>
    )
  }

  return (
    <SeasonViewingContext.Provider value={{
      ...organizationViewingContext,
      seasonId: data.season.id,
      seasonName: data.season.name,
    }}>
      <Box sx={{ height: 'calc(100% - 56px)' }}>
        <Header
          leftElement={
            <Button
              as={'a'}
              href={`${process.env.REACT_APP_REFRESHED_ADMIN_BASE}/o/${organizationViewingContext.organizationId}/s/${data.season.id}/`}
              leftIcon={<Icon icon={faChevronLeft}/>}
            >
              {data.season.name}
            </Button>
          }
        />
        <Box px={1} sx={{ height: '100%' }}>
          <Box px={24}>
            <NavBar links={seasonPageLinks(organizationViewingContext.organizationId, seasonId || '')} />
          </Box>

          <Box sx={{ height: 'calc(100% - 40px)' }}>
            <Routes>
              <Route
                path={`settings`}
                element={<SeasonSettingsRouter />}
              />
              <Route
                path={`/`}
                element={<HomePage />}
              />
              <Route
                path={`teams`}
                element={
                  <React.Suspense fallback={<>loading...</>}>
                    <SeasonTeamPageLazyComponent />
                  </React.Suspense>
                }
              />
              <Route
                path={`teams-virtualized`}
                element={
                  <React.Suspense fallback={<>loading...</>}>
                    <VirtualizedSeasonTeamPageLazyComponent />
                  </React.Suspense>
                }
              />
              <Route
                path={`games`}
                element={
                  <React.Suspense fallback={<>loading...</>}>
                    <GamesPageLazyComponent />
                  </React.Suspense>
                }
              />
            </Routes>
          </Box>

        </Box>
      </Box>
    </SeasonViewingContext.Provider>
  )
}

