import React from 'react';
import {Center, Spinner, useColorModeValue as mode} from "@chakra-ui/react";

export function FullScreenLoader() {
  return (
    <Center
      position={'fixed'}
      top={0}
      right={0}
      bottom={0}
      left={0}
      bgColor={mode('white', 'gray.800')}
      opacity={0.5}
    >
      <Spinner size={'xl'} />
    </Center>
  )
}
