import {
  Box,
  Divider,
  Flex,
  FlexProps,
  Heading,
  Spacer,
  Stack,
  useColorMode,
  useColorModeValue as mode
} from '@chakra-ui/react'
import * as React from 'react'
import {useEffect} from 'react'
import {Logo} from './Logo'
import {NavLink} from './NavLink'
import {UserProfile} from './UserProfile'
import {useKeycloak} from "@react-keycloak/web";
import {KeycloakProfile} from "keycloak-js";
import {
  faBell,
  faCalendarDay,
  faClipboardListCheck,
  faCogs,
  faHome,
  faMoonStars,
  faShoppingCart,
  faSignOut,
  faSun,
  faCheckCircle, faBellExclamation, faTally, faAddressBook
} from "@fortawesome/pro-duotone-svg-icons";
import {useLocation, useMatch} from "react-router-dom";
import {useGetUserInfoQuery, useSeasonTeamRequirementsQuery} from "../../../graphql";
import {SeasonTeamViewingContext} from "../../index";
import {Icon} from "../../../../../components/FontAwesomeIcon";
import {faArrowLeft, faBan, faSpinner} from "@fortawesome/pro-solid-svg-icons";
import {faPortalExit} from "@fortawesome/pro-duotone-svg-icons";

export const Sidebar = (props: FlexProps) => {

  const { keycloak } = useKeycloak()

  let { pathname: url } = useLocation()!!;

  const { data } = useGetUserInfoQuery()
  const seasonTeamViewingContext = React.useContext(SeasonTeamViewingContext)

  const [profileInfo, setProfileInfo] = React.useState<KeycloakProfile | null>(null);

  const { toggleColorMode } = useColorMode()
  const switchIcon = mode(faMoonStars, faSun)

  const { data: seasonTeamRequirementData } = useSeasonTeamRequirementsQuery({
    variables: {
      teamId: seasonTeamViewingContext.seasonTeamId
    }
  })

  useEffect(() => {
    keycloak.loadUserProfile().then(profile => setProfileInfo(profile))
  }, [keycloak])

  return (
    <Flex
      bg={mode({ base: 'white', md: '#0A2A47' }, 'gray.800')}
      direction="column"
      borderRightWidth="1px"
      width="64"
      color={mode({ base: 'base', md: 'white' }, 'gray.50')}
      {...props}
    >
      <Flex direction="column" flex="1" pt="5" pb="4" overflowY="auto" px="4">
        <Box mb="6">
          {/*
          <Logo color={mode('blue.600', 'blue.400')} h="6" />
          */}

          <Flex justifyContent={'flex-start'} alignItems={'center'}>
            <Heading size={'md'} m={0} p={0} pl={2}>
              {seasonTeamViewingContext.organizationName}
            </Heading>
          </Flex>
        </Box>

        <Stack spacing="4" as="nav" aria-label="Sidebar Navigation">
          <Stack spacing="1" display={{ base: 'block', md: 'none' }}>
            <NavLink label="Exit Portal" path={`/`} icon={faArrowLeft} />
          </Stack>

          <Stack spacing="1">
            <NavLink label="Portal Home" path={`home`} icon={faHome} />
          </Stack>

          <Divider borderColor={'gray.500'} />

          <Stack spacing="1">
            <NavLink
              label="Requirements"
              path={`requirements`}
              icon={faClipboardListCheck}
              rightIcon={() => {
                if (!seasonTeamRequirementData?.seasonTeam) {
                  return <Icon icon={faSpinner} />
                }

                if (!seasonTeamRequirementData.seasonTeam.requirementCompletionStatus.isComplete || !seasonTeamRequirementData.seasonTeam.hasPaidRegistrationFee) {
                  return <Icon size={'lg'} icon={faBellExclamation} primaryOpacity={1} secondaryOpacity={1} primaryColor={'yellow.200'} secondaryColor={'yellow.500'} />
                }
              }}
            />

            <NavLink label="Team Contacts" path={`team-contacts`} icon={faAddressBook} />
            <NavLink label="Schedule Games" path={`schedule`} icon={faCalendarDay} />
            <NavLink label="Report Game Score" path={`report-score`} icon={faTally} />

            <NavLink label="Team Orders" path={`orders`} icon={faShoppingCart} />
          </Stack>

          {/*
          <Divider borderColor={'gray.500'} />

          <Stack spacing={1}>
            <NavLink label="Notifications" path={`${url}/notifications`} icon={faBell} />
          </Stack>
          */}
        </Stack>

        <Spacer />

        <Stack display={{ base: 'flex', md: 'none' }}>
          {/*
          <NavLink
            icon={switchIcon}
            label={'Toggle Theme'}
            onClick={toggleColorMode}
          />
          */}

          {/*
          <NavLink
            icon={faCogs}
            label={'Account Settings'}
            path={`${url}/profile`}
          />
          */}

          <NavLink
            icon={faSignOut}
            color={mode('red.500', 'red.300')}
            label={'Sign Out'}
            href={keycloak?.createLogoutUrl()}
          />
        </Stack>
      </Flex>

      <UserProfile
        display={{ base: 'flex', md: 'none' }}
        image={data?.viewer?.avatar || data?.viewer?.defaultAvatar}
        email={profileInfo?.email + ''}
      />
    </Flex>
  )
}
