import React from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import {Icon} from "../../../../components/FontAwesomeIcon";
import {faEllipsisH} from "@fortawesome/pro-solid-svg-icons";
import {
  OrderDetailsFragment,
  useMarkSeasonTeamOrderAsPaidMutation,
  useOrdersForSeasonTeamQuery,
  useTeamRegistrationFeeStatusQuery,
  useUpdateSeasonTeamPlayoffCommitmentMutation,
  useUpdateTeamRegistrationFeeStatusMutation
} from "../../graphql";
import {Accordion, Group, Menu as MantineMenu, Table, Text} from '@mantine/core';
import {gql} from "@apollo/client";
import {SeasonViewingContext} from "../index";
import {useClipboard} from "@mantine/hooks";

interface TeamActionsMenuProps {
  teamId: string
  isCommittedToPlayoffs?: boolean
}

export const TeamActionsMenu = (props: TeamActionsMenuProps) => {

  const {
    teamId,
    isCommittedToPlayoffs
  } = props;

  const { onOpen, onClose, isOpen } = useDisclosure()
  const { onOpen: openOrders, onClose: closeOrders, isOpen: isOrdersOpen } = useDisclosure()

  const { seasonId } = React.useContext(SeasonViewingContext)

  const [updatePlayoffStatusMutation, { client }] = useUpdateSeasonTeamPlayoffCommitmentMutation()

  const data = client.readFragment({
    id: `SeasonTeam:${teamId}`, // The value of the to-do item's cache ID
    fragment: gql`
        fragment InlineSeasonTeamDetails on
        SeasonTeam {
            id
            isCommittedToPlayoffs
        }
    `,
  });

  if (!data && isCommittedToPlayoffs === undefined) {
    return <></>
  }

  const resultIsCommittedToPlayoffs = data?.isCommittedToPlayoffs || isCommittedToPlayoffs

  return (
    <>
      <Menu isLazy>
        <MenuButton
          as={IconButton}
          icon={<Icon icon={faEllipsisH} />}
          aria-label={'Actions'}
          size={'xs'}
        />
        <MenuList>
          <MenuItem onClick={onOpen}>
            Registration Fee
          </MenuItem>
          <MenuItem>
            Requirements
          </MenuItem>
          <MenuItem onClick={openOrders}>
            Orders
          </MenuItem>
          <MenuItem
            onClick={() => {
              updatePlayoffStatusMutation({
                variables: {
                  seasonId: seasonId,
                  seasonTeamId: teamId,
                  status: !resultIsCommittedToPlayoffs
                }
              })
            }}
          >
            {resultIsCommittedToPlayoffs ? 'Remove from' : 'Add to'} Playoffs
          </MenuItem>
        </MenuList>
      </Menu>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Registration Fee</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RegistrationFeePopup teamId={teamId} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOrdersOpen} onClose={closeOrders}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Team Orders</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CurrentSeasonTeamOrderPopup teamId={teamId} />
          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  )
}

export interface MantineTeamActionsMenuProps {
  refetch(): void
  row: any
}

export const MantineTeamActionsMenu = (props: TeamActionsMenuProps & MantineTeamActionsMenuProps) => {

  const {
    teamId,
    isCommittedToPlayoffs,
    refetch,
    row
  } = props;

  const { onOpen, onClose, isOpen } = useDisclosure()
  const { onOpen: openOrders, onClose: closeOrders, isOpen: isOrdersOpen } = useDisclosure()

  const { seasonId } = React.useContext(SeasonViewingContext)

  const [updatePlayoffStatusMutation] = useUpdateSeasonTeamPlayoffCommitmentMutation()

  const clipboard = useClipboard()

  if (isCommittedToPlayoffs === undefined) {
    return <></>
  }

  return (
    <>
      <MantineMenu>
        <MantineMenu.Item
          onClick={() => {
            const valueToCopy = `
            ${row.values['team.division']} ${row.values['team.name']}
            ${row.values['team.registration.head_coach_name']}
            ${row.values['team.registration.head_coach_email']}
            ${row.values['team.registration.head_coach_cell_phone']}
            ${row.values['team.registration.scheduler_name']}
            ${row.values['team.registration.scheduler_email']}
            ${row.values['team.registration.scheduler_cell_phone']}
            `

            clipboard.copy(valueToCopy)
          }}
        >
          Copy Row to Clipboard
        </MantineMenu.Item>

        <MantineMenu.Item onClick={onOpen}>
          Registration Fee
        </MantineMenu.Item>

        <MantineMenu.Item>
          Requirements
        </MantineMenu.Item>
        <MantineMenu.Item onClick={openOrders}>
          Orders
        </MantineMenu.Item>
        <MantineMenu.Item
          onClick={() => {
            updatePlayoffStatusMutation({
              variables: {
                seasonId: seasonId,
                seasonTeamId: teamId,
                status: !isCommittedToPlayoffs
              }
            }).then(() => refetch())
          }}
        >
          {isCommittedToPlayoffs ? 'Remove from' : 'Add to'} Playoffs
        </MantineMenu.Item>
      </MantineMenu>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          refetch()
          onClose()
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Registration Fee</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RegistrationFeePopup teamId={teamId} />
          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOrdersOpen}
        onClose={() => {
          refetch()
          closeOrders()
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Team Orders</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CurrentSeasonTeamOrderPopup teamId={teamId} />
          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  )
}

interface RegistrationFeePopupProps {
  teamId: string
}

function RegistrationFeePopup(props: RegistrationFeePopupProps) {

  const {
    teamId
  } = props;

  const { data } = useTeamRegistrationFeeStatusQuery({
    variables: {
      teamId: teamId
    }
  })

  const [updatePaymentMutation] = useUpdateTeamRegistrationFeeStatusMutation()

  const [checkId, setCheckId] = React.useState('');

  if (!data?.team) {
    return <>loading...</>
  }

  return (
    <Box>
      {data.team.registrationFeePaymentStatus.isPaid &&
        <Box>
          This team has already paid their registration fee
        </Box>
      }

      {!data.team.registrationFeePaymentStatus.isPaid &&
        <VStack align={'start'}>
          <Box>
            <Button
              colorScheme={'blue'}
              onClick={() => {
                updatePaymentMutation({
                  variables: {
                    teamId: teamId,
                    paymentDetails: {
                      cash: {
                        amountReceived: 35000
                      }
                    }
                  }
                })
              }}
            >
              Mark as paid by CASH
            </Button>
          </Box>

          <Divider />

          <Box>
            <Input
              placeholder={'Check ID'}
              value={checkId}
              onChange={(e: any) => setCheckId(e.target.value)}
            />
            <Button
              colorScheme={'blue'}
              isDisabled={!checkId}
              onClick={() => {
                updatePaymentMutation({
                  variables: {
                    teamId: teamId,
                    paymentDetails: {
                      check: {
                        checkId: checkId
                      }
                    }
                  }
                })
              }}
            >
              Mark as paid by CHECK
            </Button>
          </Box>
        </VStack>
      }

    </Box>
  )
}

interface CurrentSeasonTeamOrderPopupProps {
  teamId: string
}

function CurrentSeasonTeamOrderPopup(props: CurrentSeasonTeamOrderPopupProps) {

  const {
    teamId
  } = props;

  const { data } = useOrdersForSeasonTeamQuery({
    variables: {
      seasonTeamId: teamId
    }
  })

  if (!data?.team) {
    return <>loading...</>
  }

  return (
    <Box>
      <Accordion offsetIcon={false}>
        {data.team.orders.map((order, idx) => {
          return (
            <Accordion.Item label={
              <Group position={'apart'}>
                <Text>Order {idx + 1} - <Text sx={{display: 'inline'}} weight={'bold'}>{order.formattedTotal}</Text></Text>
                <Text>{order.haveReceivedPayment ? 'PAID' : 'UNPAID'}</Text>
              </Group>
            }
            >
              <OrderRow seasonTeamId={teamId} order={order} />
            </Accordion.Item>
          )
        })}
      </Accordion>
    </Box>
  )
}

interface OrderRowProps {
  seasonTeamId: string
  order: OrderDetailsFragment
}

function OrderRow(props: OrderRowProps) {

  const {
    seasonTeamId,
    order
  } = props;

  const [updatePaymentMutation] = useMarkSeasonTeamOrderAsPaidMutation()

  const [checkId, setCheckId] = React.useState('');

  const rows = order.items.map((lineItem, idx) => (
    <tr key={idx}>
      <td>{lineItem.productName}</td>
      <td>{lineItem.quantity}</td>
      <td>{lineItem.formattedPrice}</td>
    </tr>
  ));

  return (
    <>
      <Table>
        <thead>
        <tr>
          <th>Product</th>
          <th>Quantity</th>
          <th>Unit Price</th>
        </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      {(!order.haveReceivedPayment && order.items.length > 0) &&
        <VStack align={'start'}>
          <Box>
            <Button
              colorScheme={'blue'}
              onClick={() => {
                updatePaymentMutation({
                  variables: {
                    seasonTeamId: seasonTeamId,
                    data: {
                      orderId: order.id,
                      paymentDetails: {
                        cash: {
                          amountReceived: 35000
                        }
                      }
                    }
                  }
                })
              }}
            >
              Mark as paid by CASH
            </Button>
          </Box>

          <Divider/>

          <Box>
            <Input
              placeholder={'Check ID'}
              value={checkId}
              onChange={(e: any) => setCheckId(e.target.value)}
            />
            <Button
              colorScheme={'blue'}
              isDisabled={!checkId}
              onClick={() => {
                updatePaymentMutation({
                  variables: {
                    seasonTeamId: seasonTeamId,
                    data: {
                      orderId: order.id,
                      paymentDetails: {
                        check: {
                          checkId: checkId
                        }
                      }
                    }
                  }
                })
              }}
            >
              Mark as paid by CHECK
            </Button>
          </Box>
        </VStack>
      }
    </>
  )
}
