import React from 'react';
import {useParams} from "react-router-dom";
import {useTodaysGamesBySeasonQuery} from "../../graphql";
import {AppShell, Box, Header, LoadingOverlay, Table, Title, useMantineTheme} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";
import {DateTime} from "luxon";

export interface TodaysGamesPageProps {

}

export function TodaysGamesPage(props: TodaysGamesPageProps) {

  const { seasonId } = useParams()

  const { loading, data } = useTodaysGamesBySeasonQuery({
    variables: {
      seasonId: seasonId
    }
  })

  const theme = useMantineTheme()
  const matches = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);

  const rows = (data?.season?.todaysGames ?? []).map((game) => (
    <tr key={game.id}>
      <td>{game.homeTeam.division.name} {game.homeTeam.name}</td>
      <td>{game.awayTeam.division.name} {game.awayTeam.name}</td>
      <td>{DateTime.fromISO(game.date.timestamp, { zone: game.date.timezone, setZone: false }).toFormat('ccc, LLLL d @ h:mm a')}</td>
      <td>{game.venue.name}</td>
      <td>{game.officials.join(', ')}</td>
    </tr>
  ));

  return (
    <AppShell
      // navbarOffsetBreakpoint controls when navbar should no longer be offset with padding-left
      navbarOffsetBreakpoint="sm"
      // fixed prop on AppShell will be automatically added to Header and Navbar
      fixed
      padding={0}
      /*
      navbar={
        <Navbar
          p="md"
          // Breakpoint at which navbar will be hidden if hidden prop is true
          hiddenBreakpoint="sm"
          // Hides navbar when viewport size is less than value specified in hiddenBreakpoint
          hidden={!opened}
          // when viewport size is less than theme.breakpoints.sm navbar width is 100%
          // viewport size > theme.breakpoints.sm – width is 300px
          width={{ sm: 300 }}
        >
          <Text>Application navbar</Text>
        </Navbar>
      }
       */
      header={
        <Header sx={{ backgroundColor: '#0F3F6B !important' }} height={matches ? 70 : 100} p="md">
          {/* Handle other responsive styles with MediaQuery component or createStyles function */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: matches ? 'row' : 'column-reverse',
              alignItems: 'center',
              justifyContent: matches ? 'space-between' : 'flex-start',
              gap: '8px'
            }}
          >
            <Title sx={{ color: 'white' }} order={4}>{data?.season?.name} Games</Title>
          </Box>

          {/*
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>

            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>

          </div>
          */}
        </Header>
      }
    >
      <LoadingOverlay visible={loading} />

      <Box
        style={{
          position: 'absolute',
          inset: 0,
          top: matches ? 70 : 100,
          left: 0, // matches ? '300px' : 0,
          padding: '12px',
          overflowY: 'auto',
        }}
      >
        {(data?.season?.todaysGames ?? []).length === 0 && (
          <>No games today.</>
        )}

        <Table striped sx={{ minWidth: '800px' }}>
          <thead>
            <tr>
              <th>Home Team</th>
              <th>Away Team</th>
              <th>Date</th>
              <th>Field</th>
              <th>Officials</th>
            </tr>
          </thead>

          <tbody>
            {rows}
          </tbody>
        </Table>

      </Box>
    </AppShell>
  )
}
