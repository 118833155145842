import React from 'react';
import {Box} from "@chakra-ui/react";
import {TeamListHeader} from "./TeamListHeader";
import {Route, Routes} from "react-router-dom";
import {ViewTeamPageRouter} from "./ViewTeamPageRouter";
import {CreateTeamPage} from "./create";


export function TeamPageRouter() {

  return (
    <Routes>
      <Route
        index
        element={
          <Box h={'full'} w={"full"}>
            <TeamListHeader />
          </Box>
        }
      />

      <Route
        path={`create`}
        element={<CreateTeamPage />}
      />

      <Route
        path={`:teamId/*`}
        element={<ViewTeamPageRouter />}
      />

    </Routes>
  )
}
