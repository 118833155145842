import React from 'react';
import {Button, Group} from "@mantine/core";

export function HomePage() {

  return (
    <Group pt={12} direction={'column'} align={'center'}>
      <Button
        onClick={() => {
          downloadCsv('spring_2022_teams.csv', 'this feature is defunct')
        }}
      >
        Download Teams CSV
      </Button>
    </Group>
  )
}

export function downloadCsv(filename: string, text: string) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
