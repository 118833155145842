import {useToast} from "@chakra-ui/react";
import {UserError} from "../../pages/user/graphql";

export type ToastContent = {
  title: string
  description?: string
}

type UseUserErrorToastReturn = (userError: UserError | null | undefined) => void

export function useUserErrorToast(): UseUserErrorToastReturn {

  const toast = useToast()

  return function (userError) {
    toast({
      position: 'top',
      status: 'error',
      isClosable: true,
      title: userError?.type ?? 'Whoops',
      description: userError?.description ?? 'Something went wrong.'
    })
  }
}

type UseActionSuccessToastReturn = (content: ToastContent) => void

export function useActionSuccessToast(): UseActionSuccessToastReturn {

  const toast = useToast()

  return function (content) {
    toast({
      position: 'top',
      status: 'success',
      isClosable: true,
      title: content.title,
      description: content.description
    })
  }
}
