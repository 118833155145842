import React from 'react';
import {Outlet, useParams, useRoutes} from "react-router-dom";
import {Dashboard} from '../../../components/app-shell';
import {LoadingOverlay, Text} from '@mantine/core';
import {NothingFoundBackground} from "../../../components/app-shell/404/404-page";
import {faCalendarLines, faGear, faHome, faSquarePollHorizontal, faUsersLine} from "@fortawesome/pro-duotone-svg-icons";
import {ApolloProviderOrganization} from '../../../../../ApolloProviderOrganization';
import {SeasonNavBarItem} from "../../../components/app-shell/SeasonNavBarItem";
import {useOrganization} from "../../routes";
import {useKeycloak} from "@react-keycloak/web";
import {KeycloakProfile} from "keycloak-js";
import {PageNameContextProvider, PageNameSynchronizer} from "../../../components/app-shell/page-name";
import {OrganizationViewingContext} from "../../../../organization";
import {RouteDefinition} from "../../../../../components/router-traversal/route-visitor";
import {useSpotlightSearch} from "../../../../../components/router-traversal";
import {teamRoutes} from "./teams";
import {gameRoutes} from "./games";
import {formRoutes} from "./forms";
import {useSeasonViewingContextQuery} from '../../graphql';
import {SeasonViewingContext} from "../../../../organization/season";
import {UserContext} from "../../../components/app-shell/user-context";
import {SeasonDashboardPage} from "../../routes/seasons";

const seasonNavigation = [
  {
    label: 'Dashboard',
    icon: faHome,
    link: ''
  },
  {
    label: 'Teams',
    icon: faUsersLine,
    link: 'teams',
    links: [
      { label: 'All Teams', link: '' },
      { label: 'Requirements', link: 'requirements' },
    ],
  },
  {
    label: 'Games',
    icon: faCalendarLines,
    link: 'games',
    links: [
      { label: 'Overview', link: '' },
      { label: 'Master', link: 'master' },
    ],
  },
  {
    label: 'Forms',
    icon: faSquarePollHorizontal,
    link: 'forms',
    links: [
      { label: 'Overview', link: '' },
      { label: 'Responses', link: 'responses' },
    ],
  },
  {
    label: 'Settings',
    icon: faGear,
    link: 'settings',
  },
];

const seasonRoutes: RouteDefinition[] = [
  {
    path: '/',
    element: <ConfiguredDashboard />,
    extras: {
      details: {
        pageName: 'Dashboard'
      },
      spotlight: {
        title: 'Dashboard',
        description: 'Go to season dashboard'
      },
    },
    children: [
      {
        index: true,
        element: <SeasonDashboardPage />,
      },
      {
        path: 'teams',
        children: teamRoutes,
        extras: {
          details: {
            pageName: 'Teams'
          },
          spotlight: {
            title: 'Teams',
            description: 'Manage your teams in this season'
          },
        }
      },
      {
        path: 'games',
        element: <>
          <Text>Games</Text>
          <Outlet />
        </>,
        children: gameRoutes,
        extras: {
          details: {
            pageName: 'Games'
          },
          spotlight: {
            title: 'Games',
            description: 'Manage your games'
          },
        }
      },
      {
        path: 'forms',
        element: <>Forms</>,
        extras: {
          details: {
            pageName: 'Forms'
          },
          spotlight: {
            title: 'Forms',
            description: 'Manage your forms'
          },
        },
        children: formRoutes
      },
      {
        path: 'settings',
        element: <>Settings</>,
        extras: {
          details: {
            pageName: 'Settings'
          },
          spotlight: {
            title: 'Settings',
            description: "Manage your season's settings"
          },
        }
      },
      {
        path: '*',
        element: <NothingFoundBackground backButtonLabel={'Back to Season Dashboard'} />
      },
    ]
  },
]

export function LeagueBrainOrganizationSeasonRoot() {

  const { organizationId } = useParams()

  return (
    <ApolloProviderOrganization organizationId={organizationId}>
      <PageNameContextProvider>
        <RealRoot />
      </PageNameContextProvider>
    </ApolloProviderOrganization>
  )
}

function RealRoot() {

  const { seasonId } = useParams()

  const { loading, data } = useSeasonViewingContextQuery({
    variables: {
      seasonId: seasonId ?? ''
    }
  })

  let context: any = {
    id: '',
    firstName: '',
    lastName: '',
    displayName: '',
    avatar: '',

    organizationId: '',
    organizationName: '',
    organizationAvatar: '',
    viewerId: '',
    viewerRole: '',
    seasonId: '',
    seasonName: ''
  }

  if (data) {
    context = {
      id: data.viewer.id,
      firstName: '',
      lastName: '',
      displayName: data.viewer.displayName,
      avatar: null,

      organizationId: data.organization.id,
      organizationName: data.organization.name,
      organizationAvatar: 'https://www.ghvbl.com/wp-content/uploads/2020/11/cropped-2017-ghvbl-logo.png',
      viewerId: data.viewer.id,
      viewerRole: data.viewer.role,
      seasonId: data.season?.id ?? 'Unknown',
      seasonName: data.season?.name ?? 'Unknown',
    }
  }

  useSpotlightSearch(seasonRoutes)

  return (
    <UserContext.Provider value={context}>
      <OrganizationViewingContext.Provider value={context}>
        <SeasonViewingContext.Provider value={context}>
            <LoadingOverlay visible={loading} />
            <PageNameSynchronizer routes={seasonRoutes} />
            {useRoutes(seasonRoutes)}
        </SeasonViewingContext.Provider>
      </OrganizationViewingContext.Provider>
    </UserContext.Provider>
  )
}

function ConfiguredDashboard() {

  const organizationContext = useOrganization()
  const seasonContext = useSeasonContext()
  const keycloak = useKeycloak()

  const [userProfile, setUserProfile] = React.useState<KeycloakProfile | null>(null);

  React.useEffect(() => {
    keycloak.keycloak.loadUserProfile().then(res => setUserProfile(res))
  }, [])

  return (
    <Dashboard
      navigationDefinition={seasonNavigation}
      rightNavbarContent={
        <SeasonNavBarItem
          organization={organizationContext.organizationName}
          season={seasonContext.seasonName}
          userInfo={{
            displayName: userProfile?.firstName ?? '' + ' ' + userProfile?.lastName ?? '',
            organizationRole: organizationContext.viewerRole
          }}
        />
      }
    />
  )
}

export function useSeasonContext() {
  return React.useContext(SeasonViewingContext)
}

export function useUserContext() {
  return React.useContext(UserContext)
}
