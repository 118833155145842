import React from "react";
import {Box} from "@mantine/core";

export interface NormalBodyProps extends React.PropsWithChildren<any> {

}

export function NormalBody(props: NormalBodyProps) {

  return (
    <Box sx={{ position: 'relative', maxHeight: '100%', height: '100%' }}>
      {props.children}
    </Box>
  )
}
