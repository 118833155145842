import React from "react";
import {RouteDefinition} from "../../../../../../components/router-traversal/route-visitor";

export const formRoutes: RouteDefinition[] = [
  {
    path: 'responses',
    element: <>Responses</>,
    extras: {
      details: {
        pageName: 'Form Responses'
      },
      spotlight: {
        title: 'Form Responses',
        description: 'Manage your forms responses'
      },
    }
  }
]
