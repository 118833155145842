import React from 'react';
import {Group, Text, UnstyledButtonProps, Title,} from '@mantine/core';
import {Icon} from "../../../../components/FontAwesomeIcon";
import {faChevronDown, faChevronUp} from "@fortawesome/pro-solid-svg-icons";
import {SeasonSelector} from "../../organization/season/season-selector";

interface UserButtonProps extends UnstyledButtonProps<'button'> {
  organization: string;
  season: string;
  userInfo: {
    displayName: string;
    profilePhotoUrl?: string | null;
    organizationRole: string;
  }
}

export function SeasonNavBarItem({ organization, season }: UserButtonProps) {

  return (
    <Group mr={'md'}>
      <SeasonSelector
        target={
          <Group>
            <Group direction={'column'} spacing={0} align={'end'} sx={{ maxWidth: '180px' }}>
              <Title
                order={6}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: 'inherit'
                }}
              >
                {organization}
              </Title>

              <Text color="dimmed" size="xs" lineClamp={1}>
                {season}
              </Text>
            </Group>

            <Group direction={'column'} spacing={0}>
              <Icon icon={faChevronUp} size={'2xs'} color={'grey'} />
              <Icon icon={faChevronDown} size={'2xs'} color={'grey'} />
            </Group>
          </Group>
        }
      />
    </Group>
  )
}
